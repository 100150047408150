import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { Button, TextareaAutosize, withStyles } from '@material-ui/core';
import {
    contextPath,
    DONATION_TYPE,
    donationSuccessTxt,
    editableDonationStatuses,
    routePaths
} from '../../../constants';
import styles from '../../menu/ThemeStyle';
import '../../../styles/formStyles.css';
import '../../../styles/orgProfile.css';
import '../../../styles/donationOfMoney.css';
// import PaymentConfirmation from './PaymentConfirmation';
import { resetForm, saveFormData, saveFormDataFailure } from 'dynamic-form-components/lib/state/form/formDataActions';
import { downloadReportData } from '../../../state/report/reportDataActions';
import { cityStateMappingDataLoad, getOrgProfile } from '../../../state/OrgProfile/orgProfileActions';
import { closeDonationApprovalRespDailog, donationApprovalData, donationApprovalFailure }
    from '../../../state/donationOfApproval/donationOfApprovalActions';
import LoaderComponent from 'src/components/LoaderComponent';
import SuccessAlertDailog from 'src/components/dialogComponents/SuccessAlertDailog';
import { loggedInUserLoadSuccess } from '../../../state/loggedInUser/loggedInUserActions';
import { tableTranslations } from './../../../constants';
import { convertFromUtcToLocal, getDateOnlyForLocale } from '../../common/DateUtils';
import { selectedData } from 'src/state/selectedData/selectedDataActions';
import {
    isAwaitingForPaymentAcknowledgment,
    isDraft,
    isRequestForInformation,
    isUnderCAReview,
    resolvePath,
    showRGAppendixCBtnDonationStatus
} from '../../../components/common/utils';
import DeleteDonation from '../DeleteDonation';
import analytics from '../../../adobe-analytics/analytics';
import tracker from '../../../adobe-analytics/tracker';
import { countryList } from '../../../static/countryList';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';
const config = require(`./../../../config.${process.env.NODE_ENV}.json`);

declare global {
    interface Window {
        donationAttachments: any,
        organizationAttachments: any,
        provinceList: any
    }
}

export interface OwnProps {
    formAlias: string;
    classes: any;
    appi18n: any;
    orgProfile: any;
    formState: any;
    isNewRequest?: any;
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    selectedDonationData?: any;
    loggedInUserDetail: any;
    formState: any;
    approvalSuccess: any;
    approvalFailure: any;
    approvalLoading: any;
    approvalSuccessDetail: any;
    cityStateMappingLoading: boolean;
    cityStateMapping: any;
    donationStatus: { isDraft: boolean, isAwaitingForPaymentAcknowledgment: boolean, isRequestForInfo: boolean, isCaReview: boolean }
}

export interface DispatchProps {
    submitDonation: (data: any) => void;
    downloadPdf: (data: any) => void;
    getOrgProfile: (data: any) => void;
    approveDonationRequest: (data: any) => void;
    setLoggedInUserData: (data: any) => void;
    getCityStateMapping: (data: any) => void;
    updateFormSuccessStatus: () => void;
    addDataToStore: (data: any) => void;
    closeDonationApprovalDialog: () => void;
    updateAproveFormSuccessStatus: () => void;
    updateApproveDonationStatus: (data: any) => void;
    resetFormState: () => void;
}

const appi18n: any = {
    backBtnTxt: {
        en: 'Back',
        es: 'atrás',
        fr: 'Retour',
        ja: 'バック',
        zh: '背部'
    },
    viewMoreDetails: {
        en: 'View More Details',
        es: 'Ver más detalles',
        fr: 'Voir plus de détails',
        ja: '詳細を見る',
        zh: '查看更多詳情'
    },
    fillAppendixC: {
        en: 'View or Fill Appendix C',
        es: 'Complete el Apéndice C',
        fr: 'Remplir l\'annexe C',
        ja: '付録Cに記入',
        zh: '填寫附錄 C'
    },
    ...tableTranslations
}

const donationOfGoods: string = `type=${DONATION_TYPE.GOODSANDFOOD}`;

class DonationOfGoods extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        formAlias: 'donationOfGoodsExternalNGO'
    };

    public options: any;
    public submitCount: number = 0;
    public componentWillMount() {
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }
        if (this.props.loggedInUserDetail.countryCode) {
            this.props.getCityStateMapping({
                countryCode: this.props.loggedInUserDetail.countryCode
            })
        }
        if (this.props.isNewRequest) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
        } else if (this.props.selectedDonationData.donationOfGoods &&
            (this.props.donationStatus.isRequestForInfo || this.props.donationStatus.isDraft)) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
        } else {
            this.options = {
                ...this.options,
                noAlerts: true,
                readOnly: true,
                language: this.props.selectedLang
            }
        }
        this.setFormAlias();
    }

    public componentWillUnmount() {
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }
        this.removeEventListener();
    }

    private isMxUser = () => {
        return this.props.loggedInUserDetail?.countryCode === 'MX'
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang,
            }
        }
        if (newProps.cityStateMapping && newProps.cityStateMapping.length > 0) {
            const provinceList: any = [];
            newProps.cityStateMapping.forEach((item: any) => {
                if (item.label) {
                    provinceList.push(item.label)
                }
            })
            window.provinceList = provinceList;
        }
        if (this.props.loggedInUserDetail !== newProps.loggedInUserDetail) {
            if (newProps.loggedInUserDetail.countryCode) {
                this.props.getCityStateMapping({
                    countryCode: newProps.loggedInUserDetail.countryCode
                })
            }
        }

        if ((newProps.approvalSuccessDetail !== this.props.approvalSuccessDetail) && this.isMxUser()) {

            const donation = (newProps?.approvalSuccessDetail &&
                newProps?.approvalSuccessDetail?.data?.donationTaskDTOS) ?
                newProps?.approvalSuccessDetail?.data?.donationTaskDTOS[0].donation : {};


            this.redirectToMoreDetails(donation);
        }

        this.setFormAlias();
        this.translateGovernanceDetailsHeaders();
    }

    private setFormAlias = () => {
        if (this.props.loggedInUserDetail.countryCode === 'GB') {
            this.setState({
                formAlias: this.props.loggedInUserDetail?.primary ? 'donationOfGoodsUKExternalNGO' : 'donationOfGoodsUKExternalNGOFormForSecondaryUser'
            })
        } else {
            this.setState({
                formAlias: this.props?.loggedInUserDetail?.primary ? this.state.formAlias : 'donationOfGoodsExternalNGOFormForSecondaryUser'
            })
        }
    }

    private onDonationReSubmit = (submission: any) => {
        if (this.props.approvalLoading) {
            return true;
        }
        const waaSTasks: any = [
            {
                donation: {
                    ...this.props.selectedDonationData.donationOfGoods.donation,
                    ...submission.data
                },
                waaSTask: {
                    comments: submission?.data?.waaSTask?.reviewComments ? submission.data.waaSTask.reviewComments : '',
                    taskId: this.props.selectedDonationData.donationOfGoods
                        && this.props.selectedDonationData.donationOfGoods.waaSTaskMessage
                        ? this.props.selectedDonationData.donationOfGoods.waaSTaskMessage.taskId : '',
                    taskName: this.props.selectedDonationData.donationOfGoods
                        && this.props.selectedDonationData.donationOfGoods.waaSTaskMessage
                        ? this.props.selectedDonationData.donationOfGoods.waaSTaskMessage.taskName : '',
                    requestId: this.props.selectedDonationData.donationOfGoods
                        ? this.props.selectedDonationData.donationOfGoods.donation.id : '',
                    variables: {
                        userAction: 'resubmit',
                        user: this.props.loggedInUserDetail.id
                    }
                }
            }
        ];

        if (this.isMxUser()) {
            waaSTasks[0].saveAsDraft = true;
        }
        this.props.approveDonationRequest({ donationType: 'DONATION_OF_GOODS_RECURRING', waasTasks: waaSTasks });
        return null;
    }

    private onDonationSubmit = (submission: any) => {
        this.submitCount = this.submitCount + 1;
        if (this.props.formState.formSaveLoading) {
            return true;
        }
        // const donationType = 'donationOfGoods';
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.FILL_MORE_BUTTON_FOR_GOODS.name,
            location: ANALYTICS.goodsDonation.newrequestsPage
        });
        const formData = new FormData();
        const orgData = {
            ...submission.data.organization,
            orgAdditionalDetails: {
                isRegisteredNew: false
            }
        }
        const donationData = {
            ...submission.data,
            organization: {
                ...submission.data.organization,
                orgAdditionalDetails: {
                    isRegisteredNew: false
                }
            }
        }
        this.props.setLoggedInUserData(orgData);
        formData.append('donation', JSON.stringify(donationData));
        const donationAttachments = window.donationAttachments;
        const organizationAttachments = window.organizationAttachments;
        if (Array.isArray(donationAttachments)) {
            donationAttachments.forEach((attachment: any) => {
                formData.append('donationAttachments', attachment);
            });
        }
        if (Array.isArray(organizationAttachments)) {
            organizationAttachments.forEach((attachment: any) => {
                formData.append('organizationAttachments', attachment);
            });
        }
        if (window.donationAttachments) {
            delete window.donationAttachments;
        }
        if (window.organizationAttachments) {
            delete window.organizationAttachments;
        }
        if (this.isMxUser()) {
            formData.append('saveAsDraft', 'true');
        }
        if (this.submitCount > 1) {
            return true
        }
        this.props.submitDonation(
            {
                formAlias: this.state.formAlias,
                formData: { data: formData },
                submissionUrl: `${config.appBaseUrl}/donations/attachments`
            }
        );
        return null;
        // this.props.history.goBack();
    }

    private redirectToMoreDetails = (currentDonation: any) => {
        this.props.addDataToStore({
            value: {
                donation: currentDonation,
                currentStatusCode: currentDonation.currentStatusCode,
                id: currentDonation.id,
            },
            key: 'donationOfGoods'
        });
        this.props.history.push(`${contextPath}${routePaths.mx_fill_more_details_goods}?${donationOfGoods}`);
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.CONFIRM_FILLMOREDETAILS.name,
            location: ANALYTICS.goodsDonation.dashboardPage
        });
    }

    private readonly redirectToAppendixC = (currentDonation: any) => {

        this.props.addDataToStore({
            key: 'donationOfGoods',
            value: {
                donation: currentDonation,
                currentStatusCode: currentDonation.currentStatusCode,
                id: currentDonation.id,
            },
        })
        this.props.history.push(`${contextPath}${routePaths.recurring_goods_appendix_c}?${donationOfGoods}`);
    }
    private handleClick = () => {
        this.submitCount = 0;
        this.props.resetFormState()
        if (this.props.formState.formSaveSuccess) {
            this.props.updateFormSuccessStatus()
        }
        if (this.props.approvalSuccess) {
            this.props.closeDonationApprovalDialog()
            this.props.updateAproveFormSuccessStatus()
            this.props.updateApproveDonationStatus({})
        }
        if (this.isMxUser()) {
            this.props.updateFormSuccessStatus();
            this.redirectToMoreDetails(this.props.formState.formSaveResponse)
        }
        else {
            this.props.history.push(`${contextPath}/dashboard/donation-goods?type=donation-goods`);
        }
    }

    private onCustomEvent = (event: any) => {
        const donationType = 'donationOfGoods';
        const data = {
            donationDetail: {
                ...event.data,
                facilityNo: event.data.facility.facilityNo,
            },
            donationType
        }
        this.props.downloadPdf(data);
    }

    private translateGovernanceDetailsHeaders = () => {
        const boardMembersHeader = document.querySelectorAll('.board-members-cols')
        const dateValue = (this.props.selectedDonationData.donationOfGoods) ?
            JSON.parse(JSON.stringify(this.props.selectedDonationData.donationOfGoods)) : {};
        // @ts-ignore
        if (boardMembersHeader !== null) {
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.executiveOfficers?.[0]?.cityOfResidence);
            // @ts-ignore
            boardMembersHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.boardMemberHeaders?.[item.id]?.[this.props.selectedLang];
            })
        }

        const executiveOfficersHeader = document.querySelectorAll('.executive-officers-cols')
        // @ts-ignore
        if (executiveOfficersHeader !== null) {
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.keyPersons?.[0]?.cityOfResidence);
            // @ts-ignore
            executiveOfficersHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.executiveOfficerHeaders?.[item.id]?.[this.props.selectedLang];
            })
        }

        const keyPersonsHeader = document.querySelectorAll('.key-persons-cols')
        // @ts-ignore
        if (keyPersonsHeader !== null) {
            const showCitySateOfResidence = this.props.isNewRequest ? true :
                (editableDonationStatuses.includes(dateValue?.donation?.currentStatusCode)
                    || dateValue?.donation?.organization?.keyPersons?.[0]?.cityOfResidence);
            // @ts-ignore
            keyPersonsHeader.forEach((item: any, _index: number) => {
                if (item.id === 'cityOfResidence' || item.id === 'stateOfResidence' || item.id === 'countryOfResidence') {
                    item.style = showCitySateOfResidence ? 'display: block;' : 'display: none';
                }
                else if (item.id === 'birthPlace') {
                    item.style = showCitySateOfResidence ? 'display: none' : 'display: block;';
                }
                item.innerHTML = appi18n.keyPersonsHeaders?.[item.id]?.[this.props.selectedLang];
            })
        }

        const deleteBtns = document.querySelectorAll('.removeRow');
        if (deleteBtns !== null && !this.props.isNewRequest) {
            // @ts-ignore
            deleteBtns.forEach((btn: any, _index: number) => {
                if (btn) {
                    btn.style.display = 'none';
                }
            })
        } else if (deleteBtns !== null && this.props.isNewRequest) {
            deleteBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.deleteBtnText?.[this.props.selectedLang];
            })
        }
        const editBtns = document.querySelectorAll('.editRow');
        if (editBtns !== null && !this.props.isNewRequest) {
            // @ts-ignore
            editBtns.forEach((btn: any, _index: number) => {
                if (btn) {
                    btn.style.display = 'none';
                }
            })
        } else if (editBtns !== null && this.props.isNewRequest) {
            editBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.editBtnText?.[this.props.selectedLang];
            })
        }
        const messageElements = document.querySelectorAll('.editgrid-row-error.help-block');
        if (messageElements !== null) {
            messageElements.forEach((ele: any, _index: number) => {
                ele.style.display = 'none';
            })
        }
        const boardMembersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersInvalidMsg !== null && boardMembersInvalidMsg.nextElementSibling !== null &&
            boardMembersInvalidMsg.nextElementSibling != null && boardMembersInvalidMsg.nextElementSibling.innerHTML) {
            boardMembersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const executiveOfficersInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersInvalidMsg !== null && executiveOfficersInvalidMsg.nextElementSibling !== null &&
            executiveOfficersInvalidMsg.nextElementSibling != null && executiveOfficersInvalidMsg.nextElementSibling.innerHTML) {
            executiveOfficersInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        const keyPersonsInvalidMsg = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsInvalidMsg !== null && keyPersonsInvalidMsg.nextElementSibling !== null &&
            keyPersonsInvalidMsg.nextElementSibling != null && keyPersonsInvalidMsg.nextElementSibling.innerHTML) {
            keyPersonsInvalidMsg.nextElementSibling.innerHTML = `<div class="form-text error">${appi18n.editGridInvalidRowError[this.props.selectedLang]}</div>`
        }
        this.removeEventListener();
        this.addEventListener();
    }
    public componentDidMount() {
        this.addEventListener();
    }

    private addEventListener = () => {
        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.addEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.addEventListener('mouseenter', this.translateGovernanceDetailsHeaders);
        }
    }

    private removeEventListener = () => {

        // border member fields
        // @ts-ignore
        const boardMembersSavebtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-saveRow\']');
        if (boardMembersSavebtn) {
            boardMembersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const boardMembersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-cancelRow\']')
        if (boardMembersCancelBtn) {
            boardMembersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const boardMembersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.boardMembers-addRow\']');
        if (boardMembersAddMoreBtn) {
            boardMembersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        // executive officers fields
        const executiveOfficersSavebtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-saveRow\']');
        if (executiveOfficersSavebtn) {
            executiveOfficersSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const executiveOfficersCancelBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-cancelRow\']')
        if (executiveOfficersCancelBtn) {
            executiveOfficersCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const executiveOfficersAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.executiveOfficers-addRow\']');
        if (executiveOfficersAddMoreBtn) {
            executiveOfficersAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // key persons fields
        const keyPersonsSavebtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-saveRow\']');
        if (keyPersonsSavebtn) {
            keyPersonsSavebtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        // @ts-ignore
        const keyPersonsCancelBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-cancelRow\']')
        if (keyPersonsCancelBtn) {
            keyPersonsCancelBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }
        const keyPersonsAddMoreBtn = document.querySelector('button[ref=\'editgrid-organization.keyPersons-addRow\']');
        if (keyPersonsAddMoreBtn) {
            keyPersonsAddMoreBtn.removeEventListener('click', this.translateGovernanceDetailsHeaders);
        }

        const governanceDetailsSection = document.querySelector('.donation-of-money-form .governance-details');
        if (governanceDetailsSection) {
            governanceDetailsSection.removeEventListener('mouseenter', this.translateGovernanceDetailsHeaders);
        }
    }
    private onFormChange = (_event: any) => {
        this.translateGovernanceDetailsHeaders();
    }

    private readonly showViewMoreDetails = () => {
        return this.isMxUser()
            && !this.props.formState.formDataLoading
            && !this.props.isNewRequest
            && !this.props.donationStatus.isDraft
            && !this.props.donationStatus.isRequestForInfo
    }

    public render() {
        // const options = {
        //     ...this.options,
        //     readOnly: selectedData.readOnly
        // }
        const reviewComments: any = {
            en: 'Review Comments',
            es: 'Revisar Comentarios',
            fr: 'Examiner Les Commentaires',
            ja: 'レビュー コメント',
            zh: '評論評論'
        }
        const resubmitSuccessMessage: any = {
            en: 'Resubmitted Donation Successfully',
            es: 'La Donación Se Volvió A Enviar Con Éxito',
            fr: 'Le Don A Été Soumis De Nouveau Avec Succès',
            ja: '寄付は正常に再提出されました',
            zh: '捐款已成功重新提交'
        }
        const submitSuccessMessage: any = {
            en: 'Donation request has been submitted successfully!',
            es: '¡La solicitud de donación se ha enviado correctamente!',
            fr: 'La demande de don a été soumise avec succès!',
            ja: '寄付のリクエストが正常に送信されました。',
            zh: '捐赠请求已成功提交！'
        }
        const submitSuccessMessageMX: any = {
            en: 'Donation successfully saved as a draft. Please proceed to fill in additional detail',
            es: 'La donación se guardó correctamente como borrador. Por favor proceda a completar detalles adicionales.',
            fr: 'Le don a été enregistré avec succès en tant que brouillon. Veuillez continuer à remplir des détails supplémentaires',
            ja: '寄付は下書きとして正常に保存されました。追加の詳細を記入するために進んでください',
            zh: '捐赠已成功保存为草稿。请继续填写其他详细信息'
        }
        const resubmitFailureMessage: any = {
            en: 'Resubmission Of Donation Failed',
            es: 'Reenvío De Donación Fallido',
            fr: 'Échec De Resoumission Du Don',
            ja: '寄付の再提出の失敗',
            zh: '未能重新提交捐贈'
        }
        const submitFailureMessage: any = {
            en: 'Submission Of Donation Failed',
            es: 'Envío De Donación Fallido',
            fr: 'La Soumission Du Don A Échoué',
            ja: '寄付を送信できませんでした',
            zh: '捐款提交失敗'
        }

        const { formState, classes, loggedInUserDetail } = this.props;
        const donationOrganization: any = (this.props.selectedDonationData.donationOfGoods
            && this.props.selectedDonationData.donationOfGoods.donation
            && this.props.selectedDonationData.donationOfGoods.donation.organization
            && this.props.selectedDonationData.donationOfGoods.currentStatusCode !== 'REQUEST_FOR_INFORMATION'
        ) ? this.props.selectedDonationData.donationOfGoods.donation.organization : null;
        const newOrganizationData = loggedInUserDetail ? JSON.parse(JSON.stringify(loggedInUserDetail)) : {};
        let orgProfileData = donationOrganization ? donationOrganization : newOrganizationData;
        orgProfileData = { ...orgProfileData };
        const currentStatusCode = resolvePath('selectedDonationData.donationOfGoods.currentStatusCode', this.props, '')
        return (<>
            <Button className={`back-btn ${this.props.classes.buttonStyles}`}
                variant='contained' color='primary'
                onClick={this.handleClick}>
                {appi18n.backBtnTxt[this.props.selectedLang]}
            </Button>
            {((!this.props.isNewRequest) && this.props.selectedDonationData
                && this.props.selectedDonationData.donationOfGoods
                && this.props.selectedDonationData.donationOfGoods.currentStatusCode
                === 'REQUEST_FOR_INFORMATION') ?
                (<div className='review-section'>
                    <span className='review-section-text'>{reviewComments[this.props.selectedLang]}</span>
                    <br />
                    <TextareaAutosize rowsMax={8} style={{ margin: '3%', width: '94%' }}
                        placeholder={
                            (this.props.selectedDonationData.donationOfGoods.donation
                                && this.props.selectedDonationData.donationOfGoods.donation.donationDetail)
                                ? this.props.selectedDonationData.donationOfGoods.donation.donationDetail.reviewComments : ''
                        } disabled={true} />
                </div>) : (<div />)}
            <div className='donation-of-money-form'>
                <FormComponent
                    key={this.state.formAlias}
                    formAlias={this.state.formAlias}
                    options={this.options}
                    submission={{
                        data: this.props.isNewRequest ?
                            orgProfileData.orgAdditionalDetails && orgProfileData.orgAdditionalDetails.isRegisteredNew ? {
                                organization: {
                                    ...orgProfileData,
                                    authorized: '',
                                    corruptionOrBribery: '',
                                    issueReceipts: '',
                                    govtEntityRelation: '',
                                    govtOfficialRelation: '',
                                    politicalActivity: '',
                                    pastDonation: '',
                                    prevDeniedDonation: '',
                                    govtOrPoliticalOfficeRelative: '',
                                    govtOrPoliticalOffice: '',
                                    establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                        convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                    consentSignee: {},
                                    certificateSignee: {},
                                    address: {
                                        ...orgProfileData.address
                                    }
                                }, countryList, isNewRequest: this.props.isNewRequest
                            } : {
                                organization: {
                                    ...orgProfileData,
                                    establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                        convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                    consentSignee: {},
                                    certificateSignee: {},
                                    address: {
                                        ...orgProfileData.address
                                    }
                                }, countryList, isNewRequest: this.props.isNewRequest
                            }
                            : this.props.selectedDonationData
                                && this.props.selectedDonationData.donationOfGoods
                                && this.props.selectedDonationData.donationOfGoods.currentStatusCode === 'REQUEST_FOR_INFORMATION' ?
                                {
                                    ...(this.props.selectedDonationData
                                        && this.props.selectedDonationData.donationOfGoods
                                        && this.props.selectedDonationData.donationOfGoods.donation)
                                        ? (this.props.selectedDonationData.donationOfGoods.donation) : {},
                                    organization: {
                                        ...orgProfileData,
                                        consentSignee: {},
                                        certificateSignee: {},
                                        establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                            convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                        consentSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfGoods.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfGoods.donation?.organization?.consentSignedDate) : '',
                                        certificateSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfGoods.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfGoods.donation?.organization?.certificateSignedDate) : ''
                                    }, countryList,
                                    donationDetail: this.props.selectedDonationData.donationOfGoods ?
                                        this.props.selectedDonationData.donationOfGoods.donation.donationDetail : '',
                                    currentStatusCode: this.props.selectedDonationData.donationOfGoods ?
                                        this.props.selectedDonationData.donationOfGoods.donation.currentStatusCode :
                                        '',
                                    isNewRequest: this.props.isNewRequest
                                } :
                                {
                                    ...(this.props.selectedDonationData
                                        && this.props.selectedDonationData.donationOfGoods
                                        && this.props.selectedDonationData.donationOfGoods.donation)
                                        ? (this.props.selectedDonationData.donationOfGoods.donation) : {},
                                    organization: {
                                        ...orgProfileData,
                                        establishedDate: (orgProfileData && orgProfileData.establishedDate) ?
                                            convertFromUtcToLocal(orgProfileData.establishedDate) : '',
                                        consentSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfGoods.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfGoods.donation?.organization?.consentSignedDate) : '',
                                        certificateSignedDate: (this.props.selectedDonationData && this.props.selectedDonationData.donationOfGoods.donation?.organization) ?
                                            getDateOnlyForLocale(this.props.selectedDonationData.donationOfGoods.donation?.organization?.certificateSignedDate) : ''
                                    }, countryList,
                                    donationDetail:
                                        this.props.selectedDonationData.donationOfGoods ?
                                            this.props.selectedDonationData.donationOfGoods.donation.donationDetail :
                                            '',
                                    consentSignee: {
                                        terms: true,
                                        privacyPolicy: true
                                    },
                                    certifyCheckBox1: true,
                                    certifyCheckBox2: true,
                                    certifyCheckBox3: true,
                                    currentStatusCode: this.props.selectedDonationData.donationOfGoods ?
                                        this.props.selectedDonationData.donationOfGoods.donation.currentStatusCode :
                                        '',
                                    isNewRequest: this.props.isNewRequest,
                                }
                    }}
                    onSubmit={!this.props.isNewRequest && this.props.selectedDonationData.donationOfGoods
                        && this.props.selectedDonationData.donationOfGoods.currentStatusCode
                        === 'REQUEST_FOR_INFORMATION' ? this.onDonationReSubmit : this.onDonationSubmit}
                    submissionUrl={''}
                    handleCustomEvent={this.onCustomEvent}
                    handleOnFormChange={this.onFormChange}
                />
                {/* {
                                !this.props.isNewRequest && this.props.selectedDonationData.donationOfGoods
                                && this.props.selectedDonationData.donationOfGoods.currentStatusCode
                                === 'AWAITING_PAYMENT_ACKNOWLEDGMENT' ?
                                    <PaymentConfirmation
                                        // @ts-ignore
                                        isNewRequest={this.props.isNewRequest}/>
                                        :null
                            } */}
                {
                    this.showViewMoreDetails()
                    && (<button className='btn btn-primary btn-md'
                        onClick={() => this.redirectToMoreDetails(this.props.selectedDonationData.donationOfGoods.donation)}>
                        {appi18n.viewMoreDetails[this.props.selectedLang]}
                    </button>)
                }
                {
                    showRGAppendixCBtnDonationStatus.includes(currentStatusCode) &&
                    !this.props.formState.formDataLoading &&
                    (<button id='fill-appendix-c' className='btn btn-primary btn-md appendix-c'
                        onClick={() => this.redirectToAppendixC(this.props?.selectedDonationData?.donationOfGoods?.donation)}>
                        {appi18n.fillAppendixC[this.props.selectedLang]}
                    </button>)
                }
                {
                    (this.props.donationStatus.isDraft && !this.props.formState.formDataLoading
                        && this.props.selectedDonationData.donationOfGoods.donation.countryCode === 'MX'
                        && !this.props.selectedDonationData.donationOfGoods.donation.isInternal)
                    && <DeleteDonation donationId={this.props.selectedDonationData.donationOfGoods.donation?.id}
                        dashboardPath={`${contextPath}/dashboard/${DONATION_TYPE.GOODSANDFOOD}?${donationOfGoods}`} />
                }

            </div>
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={this.props.formState.formSaveSuccess}
                ok={this.handleClick}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={this.isMxUser() ?
                    submitSuccessMessageMX[this.props.selectedLang] : submitSuccessMessage[this.props.selectedLang]}
            />
            <SuccessAlertDailog
                isAsdaTheme={loggedInUserDetail && loggedInUserDetail.countryCode === 'GB'}
                showDialog={this.props.approvalSuccess}
                ok={this.handleClick}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={resubmitSuccessMessage[this.props.selectedLang]}
            />
            {this.props.formState.formSaveLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={formState.formSaveFailure} classes={classes}
                message={submitFailureMessage[this.props.selectedLang]} />
            {this.props.approvalLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={this.props.approvalFailure} classes={classes}
                message={resubmitFailureMessage[this.props.selectedLang]} />
            {this.props.cityStateMappingLoading ? <LoaderComponent fullScreen={true} /> : <></>}
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => {
    const statusCode = resolvePath('selectedDataState.addDataToStore.donationOfGoods.currentStatusCode', state, '');
    return ({
        submissionData: state.submissionDataState.submissionData,
        selectedLang: state.selectedLang,
        selectedDonationData: state.selectedDataState.addDataToStore,
        loggedInUserDetail: state.loggedInUserState.userDetail,
        formState: state.formState,
        approvalSuccess: state.donationApprovalState.approvalSuccess,
        approvalFailure: state.donationApprovalState.approvalFailure,
        approvalLoading: state.donationApprovalState.approvalLoading,
        approvalSuccessDetail: state.donationApprovalState.approvalSuccessDetail,
        cityStateMappingLoading: state.orgProfile.cityStateMappingLoading,
        cityStateMapping: state.orgProfile.cityStateMapping,
        donationStatus: {
            isRequestForInfo: isRequestForInformation(statusCode),
            isDraft: isDraft(statusCode),
            isAwaitingForPaymentAcknowledgment: isAwaitingForPaymentAcknowledgment(statusCode),
            isCaReview: isUnderCAReview(statusCode)
        }
    })
};

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    submitDonation: (data: any) => dispatch(saveFormData(data)),
    downloadPdf: (data: any) => dispatch(downloadReportData(data)),
    getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
    approveDonationRequest: (data: any) => dispatch(donationApprovalData(data)),
    setLoggedInUserData: (data: any) => dispatch(loggedInUserLoadSuccess(data)),
    getCityStateMapping: (data: any) => dispatch(cityStateMappingDataLoad(data)),
    updateFormSuccessStatus: () => dispatch(saveFormDataFailure('')),
    addDataToStore: (data: any) => dispatch(selectedData(data)),
    closeDonationApprovalDialog: () => dispatch(closeDonationApprovalRespDailog()),
    updateAproveFormSuccessStatus: () => dispatch(saveFormDataFailure('')),
    updateApproveDonationStatus: (data) => dispatch(donationApprovalFailure(data)),
    resetFormState: () => dispatch(resetForm())
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(DonationOfGoods)));
