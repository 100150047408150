import React, { useEffect, useState } from 'react';
import { Card, ProgressTracker, ProgressTrackerItem, Select } from '@walmart-web/livingdesign-components';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Checkbox from '@material-ui/core/Checkbox';
import './NgoNcSpaceReqStoreListComp.css';
import { Badge } from 'react-bootstrap';
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import DataTable from 'react-data-table-component';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import { Check, TrashCan, Calendar, ChevronLeft } from '@livingdesign/icons';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation, useQuery } from 'react-query';
import Loader from '../../commonComponents/appComponents/loader/loader';
import { useNavigate, useLocation } from 'react-router';
import { Snackbar, Tooltip } from '@material-ui/core';
import ReserveDateTime from './ReserveDateTime';
import { contextPath } from 'src/constants';
export interface NgoNcSpaceReqStoreListCompProp {
    history?: any[]
}
const service: Service = SpaceDonationService;

interface NcIDProps {
    pathname: string;
    state: {
        ncid: any
        orgid: any
    }
}

interface ErrorToastProps {
    open: boolean;
    message: string;
    handleClose?: () => void;
    isSuccess?: boolean
}

export const ErrorToast: React.FC<ErrorToastProps> = ({ open, handleClose, message, isSuccess = false }) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <Alert onClose={handleClose} severity={isSuccess ? 'success' : 'error'}>
                {message}
            </Alert>
        </Snackbar>
    )
}

const NgoNcSpaceReqStoreListComp: React.FC<NgoNcSpaceReqStoreListCompProp> = () => {
    const history = useNavigate()
    const { state } = useLocation() as NcIDProps;
    const ncid = state?.ncid;
    const orgid = state?.orgid;
    const {
        data: selectedStoreList,
        isLoading,
        refetch } =
        useQuery(['getSelectedStoreByOrgForNC', { ncid, orgid }],
            service.getSelectedStoreByOrgForNC, { refetchOnWindowFocus: false });

    const handlePreviousRedirect = () => {
        history(`${contextPath}/space-donation/create/store-select`,
            { state: { ncid, orgid } }
        )
    }
    const headerSection = {
        title: 'Request Space',
        progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
        activeIndex: 1,
        handlePreviousRedirect
    }

    return (
        <div>
            <WizardHeader {...headerSection} />
            <DisplayStoreList flow='create' history={history} selectedStoreList={selectedStoreList} isLoading={isLoading} refetch={refetch} />
        </div>
    )
}

interface WizardHeaderProp {
    title?: string
    progressTrackerItem?: any[]
    activeIndex?: number,
    handlePreviousRedirect: any
}


interface WizardFooterProp {
    isContinueDisabled?: boolean,
    previousButtonText?: string,
    nextButtonText?: string
    handleNextRedirect: any,
    handlePreviousRedirect: any
}

export const WizardHeader: React.FC<WizardHeaderProp> = ({ title, progressTrackerItem, activeIndex, handlePreviousRedirect }) => {
    return (
        <div className='ngo-nc-header-section'>
            <div className='ngo-nc-header-title-section'>
                <ChevronLeft size='large' data-testid='previous-redirect-chevr' onClick={() => handlePreviousRedirect()} />
                <span className='ngo-nc-header-title' >{title}</span>
            </div>
            <div className='ngo-nc-progress-tracker'>
                <ProgressTracker activeIndex={activeIndex} variant='info'>
                    {progressTrackerItem?.map?.((item, index) => <ProgressTrackerItem key={index}>{item}</ProgressTrackerItem>)}
                </ProgressTracker>
            </div>
        </div>
    )
}

export const WizardFooter: React.FC<WizardFooterProp> = (
    { isContinueDisabled, previousButtonText, nextButtonText, handleNextRedirect, handlePreviousRedirect }) => {
    return (
        <div className='ngo-nc-footer-section'>
            <div className='ngo-nc-footer-previous'>
                <LDButton variant='secondary' data-testid='previous-redirect'
                    onClick={() => handlePreviousRedirect()}>{previousButtonText}</LDButton>
            </div>
            <div className='ngo-nc-footer-continue'>
                <LDButton variant='primary'
                    disabled={isContinueDisabled}
                    data-testid='next-redirect'
                    onClick={() => handleNextRedirect()}>{nextButtonText}
                </LDButton>
            </div>
        </div>
    )
}

export default NgoNcSpaceReqStoreListComp;

interface DisplayStoreListProp {
    history?: any
    prevPath?: any
    continuePath?: any
    selectedStoreList: any[];
    isLoading: boolean;
    refetch: any;
    flow: string
}
export const calenderClickHandler = (row: any, setTimeSelectedStores: any, setViewDateTime: any) => {
    setTimeSelectedStores([row]);
    setViewDateTime(row);
}
export const getDataModified = (dateAndSlotList: any, selectedStores: any) => {
    const updatedSelection = selectedStores?.map((item: any) => {
        item.proposedEventDates = dateAndSlotList;
        return item;
    })
    const mergedUniq = [...new Map([...updatedSelection].map((item: any) => [item.storeNbr, item])).values()];
    const dataModified = mergedUniq.map((obj: any) => {
        return {
            storeNbr: obj.storeNbr,
            proposedEventDateRequestList: obj.proposedEventDates
        };
    });
    return dataModified;
}
export const DisplayStoreList: React.FC<DisplayStoreListProp> = ({
    history,
    prevPath,
    continuePath,
    selectedStoreList,
    isLoading,
    refetch,
    flow
}) => {
    const { state } = useLocation() as NcIDProps;
    const ncid = state?.ncid
    const orgid = state?.orgid

    if (ncid === undefined || orgid === undefined) {
        history(`${contextPath}/space-donation/create/`)
    }

    const { isLoading: isLoadingEventInfo, data: eventInfo } = useQuery(['getEventInfo', { id: ncid }],
        service.getEventInfo, { refetchOnWindowFocus: false });
    const saveDraftMutation = useMutation(service.saveDraftsNC);
    const removeStoreMutation = useMutation(service.removeStoresInNC);
    const [selectedStores, setSelectedStores] = useState<any[]>([]);
    const [timeSelectedStores, setTimeSelectedStores] = useState<any[]>([]);
    const [value, setValue] = useState('1');
    const [filterText, setFilterText] = useState('');
    const [placeholderData, setPlaceholderData] = useState<any[]>([]);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);
    const [showReserveDateTimeModal, setShowReserveDateTimeModal] = React.useState(false);
    const [viewDateTime, setViewDateTime] = React.useState(null);
    const [columnFilter, setColumnFilter] = useState('all')
    const [filteredDataForFirstTab, setFilteredDataForFirstTab] = useState<any[]>([]);
    const [filteredDataForSecTab, setFilteredDataForSecTab] = useState<any[]>([]);
    const [inputPlaceholder, setInputPlaceholder] = useState('Search by store #/store format/city/state/ZIP')
    const handleChange = (_event: any, newValue: string) => {
        if (value !== newValue) { handleClearSelected() };
        setValue(newValue);
    };
    useEffect(() => {
        if (!isLoading && selectedStoreList) {
            setPlaceholderData(selectedStoreList)
        }
    }, [isLoading, selectedStoreList])
    useEffect(() => {
        if (viewDateTime) {
            setShowReserveDateTimeModal(true);
        }
    }, [viewDateTime]);

    useEffect(() => {
        setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
            item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0
        )))

        setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
            item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0
        )))
    }, [placeholderData])

    const timeNotSelected = <span className='event-request-storeinfo-label'>
        <span>Time not selected</span>
        <Badge className='ngo-nc-badge'>{filteredDataForFirstTab?.length}</Badge>
    </span>
    const timeSelected = <span className='event-request-storeinfo-label'>
        <span>Time selected</span>
        <Badge className='ngo-nc-badge'>{filteredDataForSecTab?.length}</Badge>
    </span>

    const handleClearSelected = () => {
        setSelectedStores([]);
        setToggleClearRows(!toggledClearRows)
    }

    const onConfirmDateTimeSelection = (dateAndSlotList: any) => {
        const storeEventDateRequestNPOList = getDataModified(dateAndSlotList, selectedStores);
        saveDraftMutation.mutate({
            ncId: ncid,
            spaceOrganizationId: orgid,
            isUpdate: true,
            status: 'DRAFT',
            storeEventDateRequestNPOList
        }, {
            onSuccess: () => {
                handleClearSelected();
                refetch(['getSelectedStoreByOrgForNC', { ncid, orgid }],
                    service.getSelectedStoreByOrgForNC, { refetchOnWindowFocus: false });
                handleChange(null, '2');
            }
        });
    }

    const handleDeletion = () => {
        const deletedStoreNbr = selectedStores.map((d: any) => d.storeNbr)
        const isUpdate = selectedStores[0]?.proposedEventDates?.length > 0;
        removeStoreMutation.mutate({
            ncId: ncid,
            spaceOrganizationId: orgid,
            isUpdate,
            storeNbrList: deletedStoreNbr
        }, {
            onSuccess: () => {
                handleClearSelected();
                refetch(['getSelectedStoreByOrgForNC', { ncid, orgid }],
                    service.getSelectedStoreByOrgForNC, { refetchOnWindowFocus: false })
            }
        })
    }

    const handleContinueBtnClick = () => {
        handleRedirect();
    }
    const displayDataTableActionBar = <div className='ngo-nc-dataTable-action-bar'>
        <div className='ngo-nc-dataTable-action-bar-left'>
            <Check size='medium' />
            <span className='ngo-nc-row-selected-count'>{selectedStores?.length} row selected</span>
            <LDButton className='ngo-nc-row-clear-button'
                variant='tertiary'
                onClick={handleClearSelected}
                disabled={selectedStores?.length === 0}
            >Clear selected</LDButton>
        </div>
        <div>
            <LDButton variant='tertiary' className='delete-btn' onClick={handleDeletion}
                data-testid='delete-button' disabled={selectedStores?.length === 0} UNSAFE_className="del-btn">
                <TrashCan size='small' />
                <span className='ngo-nc-trash-icon'>Remove Store</span>
            </LDButton>
            <LDButton variant='primary'
                data-testid='reserve-date-time-btn'
                disabled={selectedStores?.length === 0}
                onClick={() => setShowReserveDateTimeModal(true)} UNSAFE_className="reserve-btn-date-time">
                <Calendar size='small' />
                <span className='ngo-nc-calender-icon'>{value === '1' ? 'Reserve' : 'Edit'} date and time</span>
            </LDButton>
            <ReserveDateTime
                isOpen={showReserveDateTimeModal}
                onClose={() => {
                    setShowReserveDateTimeModal(false);
                    setTimeSelectedStores([]);
                    setTimeout(() => setViewDateTime(null), 500);
                }}
                minDate={new Date(eventInfo?.eventStartDateString)}
                maxDate={new Date(eventInfo?.eventEndDateString)}
                eventSlots={eventInfo?.eventSlots || []}
                selectedStores={selectedStores}
                timeSelectedStores={timeSelectedStores}
                onConfirmSelection={onConfirmDateTimeSelection}
                disabled={!!viewDateTime}
                maxAllowedDays={eventInfo?.maxAllowedDays}
                consecutiveDaysRestriction={eventInfo?.consecutiveDays?.value}
                restrictedDays={eventInfo?.consecutiveDays?.detail}
            />
        </div>
    </div>
    const handleFilter = () => {
        if (filterText.length > 0) {
            switch (columnFilter) {
                case 'all':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.storeNbr.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.storeFormat.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.state.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.city.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.postalCode.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.addressLine1.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)));

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.storeNbr.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.storeFormat.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.state.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.city.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.postalCode.toString().toLowerCase().includes(filterText.toLowerCase())
                        || item.addressLine1.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0)))
                    break;

                case 'store_number':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.storeNbr.toString().trim().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.storeNbr.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
                case 'store_format':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.storeFormat.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.storeFormat.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
                case 'address':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.addressLine1.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.addressLine1.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
                case 'city':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.city.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.city.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
                case 'zip':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.postalCode.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.postalCode.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
                case 'state':
                    setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                        item.state.toString().toLowerCase().includes(filterText.toLowerCase())
                    ) && (item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0)))

                    setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                        item.state.toString().toLowerCase().includes(filterText.toLowerCase())
                        && (item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0))))
                    break;
            }
        }
        else {
            setFilteredDataForFirstTab(placeholderData?.filter((item?: any) => (
                item?.proposedEventDates === undefined || item?.proposedEventDates.length === 0
            )))

            setFilteredDataForSecTab(placeholderData?.filter((item?: any) => (
                item?.proposedEventDates !== undefined && item?.proposedEventDates.length !== 0
            )))
            switch (columnFilter) {
                case 'all':
                    setInputPlaceholder('Search by store #/store format/city/state/ZIP');
                    break;
                case 'zip':
                    setInputPlaceholder('Search by ZIP code')
                    break;
                case 'state':
                    setInputPlaceholder('Search by state');
                    break;
                case 'city':
                    setInputPlaceholder('Search by city');
                    break;
                case 'address':
                    setInputPlaceholder('Search by address');
                    break;
                case 'store_format':
                    setInputPlaceholder('Search by store format');
                    break;
                case 'store_number':
                    setInputPlaceholder('Search by store number');
                    break;
            }
        }


    }

    const handleRowSelect = (tableState: any) => {
        setSelectedStores(tableState.selectedRows);
    }

    const handleRedirect = () => {
        flow === 'create' ?
            history(
                continuePath || `${contextPath}/space-donation/create/confirmation`,
                { state: { ncid, orgid } }) :
            history(
                continuePath || `${contextPath}/space-donation/edit/store-details`,
                { state: { ncid, orgid } })

    }

    const handlePreviousRedirect = () => {
        flow === 'create' ?
            history(
                prevPath || `${contextPath}/space-donation/create/store-select`,
                { state: { ncid, orgid } }
            ) :
            history(
                prevPath || `${contextPath}/space-donation/edit/store-select`,
                { state: { ncid, orgid } }
            )

    }
    const customStyle = {
        rows: {
            style: {
                flexWrap: 'wrap'
            }
        }
    }
    const dateTimeColumn = {
        name: <span className='adding-tooltip-headrow'>View date and time</span>,
        selector: (row: any) => row?.range,
        cell: (row: any) => <Calendar data-testid='cal-click'
            onClick={() => calenderClickHandler(row, setTimeSelectedStores, setViewDateTime)} />,
        style: { justifyContent: 'flex-end', cursor: 'pointer' },
        sortable: false,
    }

    const handleSearchFilter = (value: any) => {
        setColumnFilter(value)
    }

    const handleTextFilter = (value: any) => {
        setFilterText(value);
    }
    useEffect(() => {
        handleFilter()
    }, [columnFilter, filterText])

    return (
        <>
            {saveDraftMutation.isLoading && <Loader />}
            {saveDraftMutation.isError && (
                <ErrorToast
                    open={saveDraftMutation.isError}
                    handleClose={saveDraftMutation.reset}
                    message='Something went wrong.' />
            )}
            <Card className='ngo-nc-card-section'>
                <Box>
                    <TabContext value={value}>
                        <Box>
                            <TabList onChange={handleChange}>
                                <Tab value='1'
                                    data-testid='time-not-selected'
                                    className='css-selector-1'
                                    label={timeNotSelected} />
                                <Tab value='2'
                                    className='css-selector-2'
                                    data-testid='time-selected'
                                    label={timeSelected} />
                            </TabList>
                        </Box>
                        <LDDivider />
                        <div className='ngo-nc-store-search-filter'>
                            <div className='ngo-nc-store-search-select'>
                                <Select label={undefined} size="small"
                                    value={columnFilter}
                                    onChange={(event) => handleSearchFilter(event.target.value)}>
                                    <option value="all">All</option>
                                    <option value="store_number">Store #</option>
                                    <option value="store_format">Store format</option>
                                    <option value="address">Address</option>
                                    <option value="city">City</option>
                                    <option value="state">State</option>
                                    <option value="zip">ZIP</option>
                                </Select>
                            </div>
                            <div className='ngo-nc-store-search'>
                                <input
                                    data-testid='search-input'
                                    className='ngo_nc_search_field'
                                    aria-label='Search Input'
                                    placeholder={inputPlaceholder}
                                    onChange={(event: any) => handleTextFilter(event?.target.value.trim())} />
                            </div>
                        </div>
                        {displayDataTableActionBar}
                        <TabPanel value='1' >
                            {(isLoading || isLoadingEventInfo || removeStoreMutation.isLoading) && <Loader />}
                            <DataTable
                                className='ngo-nc-store-detail-dataTable'
                                columns={getColumns()}
                                data={filteredDataForFirstTab ? filteredDataForFirstTab : []}
                                selectableRows={true}
                                onSelectedRowsChange={handleRowSelect}
                                pagination={true}
                                selectableRowsComponent={Checkbox as any}
                                clearSelectedRows={toggledClearRows}
                                customStyles={customStyle}
                            />
                        </TabPanel>
                        <TabPanel value='2' >
                            {(isLoading || isLoadingEventInfo || removeStoreMutation.isLoading) && <Loader />}
                            <DataTable
                                data-testid='dataTable-testid'
                                className='ngo-nc-store-detail-dataTable'
                                columns={[...getColumns(), dateTimeColumn]}
                                data={filteredDataForSecTab ? filteredDataForSecTab : []}
                                selectableRows={true}
                                onSelectedRowsChange={handleRowSelect}
                                pagination={true}
                                selectableRowsComponent={Checkbox as any}
                                clearSelectedRows={toggledClearRows}
                                customStyles={customStyle}
                            />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Card>
            <WizardFooter
                handleNextRedirect={handleContinueBtnClick}
                handlePreviousRedirect={handlePreviousRedirect}
                isContinueDisabled={value === '1' || filteredDataForSecTab?.length === 0}
                previousButtonText={'Previous'}
                nextButtonText={'Continue'} />
        </>
    )
}
export const getColumns = () => [
    {
        name: 'Store #',
        selector: (row: any) => row?.storeNbr,
        sortable: true,
    },
    {
        name: 'Store format',
        cell: (row: any) => <Tooltip title={row?.storeFormat} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.storeFormat}</span></Tooltip>,
        sortable: true,
    },
    {
        name: 'Address',
        cell: (row: any) => <Tooltip title={row?.addressLine1} placement='right'>
            <span className='ngo-nc-address-span-tooltip'> {row?.addressLine1}</span></Tooltip>,
        sortable: true,
    },
    {
        name: 'City',
        cell: (row: any) => <Tooltip title={row?.city} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.city}</span></Tooltip>,
        sortable: true,
    },
    {
        name: 'State',
        selector: (row: any) => row?.state,
        sortable: true,
    },
    {
        name: 'ZIP',
        selector: (row: any) => row?.postalCode,
        sortable: true,
    }
]
