import * as React from 'react';
import { connect } from 'react-redux';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { GlobalDonationState } from 'src/models/globalDonationState';

export interface StateProps {
    registerUserData: any;
}

class ErrorSnackbar extends React.PureComponent<any> {
    public render() {
        const {classes, errorType} = this.props;
        return <>
            <ErrorSnackbarComponent isError={this.props.registerUserData[errorType]} classes={classes}
            message={this.props.registerUserData.notificationMsg || `Failed!`} />
            </>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    registerUserData: state.registerUser,
});


export default connect(mapStateToProps, null)(ErrorSnackbar)