/* eslint-disable */

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import withRouter from "./../../../../polyfils/customRouter";
import StoreContactDetails from '../../CommonComponents/StoreContact';
import {
  Heading,
  Body,
  Card,
  Grid,
  Alert,
  Chip,
  ChipGroup,
  TextField,
  GridColumn,
  TextArea,
  Radio,
} from "@walmart-web/livingdesign-components";
import { Article } from "@livingdesign/icons";
import { contextPath } from "../../../../constants";

export interface OwnProps {
  history: any;
  data?: any;
  editable?: any;
  isContinued?: any;
  showAppendixH?: any;
  orgType?: any;
  setSelectedFacility?: any;
  selectedFacility?: any;
  setShowStoreSearchDialog?: any;
  handleTargetChange?: any;
  donationData?: any;
  setDonationData?: any;
  isLocationMandatory?: any;
}
const EmergencyDonations: FC<OwnProps> = ({
  history,
  data,
  editable,
  isContinued,
  orgType,
  selectedFacility,
  setSelectedFacility,
  setShowStoreSearchDialog,
  handleTargetChange,
  donationData,
  setDonationData,
  isLocationMandatory,
}) => {
  const { t } = useTranslation();

  const orgFormData = useMemo(() => {
    // Check if coming from viewEmergencyDonation or AddDonation
    let orgProfile = data?.organization ? data?.organization : data;
    return [
      {
        name: "emergencyDonations.form.name",
        sm: 12,
        field: orgProfile?.orgLegalName ?? "",
      },
      {
        name: "emergencyDonations.form.taxid",
        sm: 12,
        field: orgProfile?.taxId ?? "-",
      },
      {
        name: "emergencyDonations.form.street",
        sm: 12,
        field: orgProfile?.address?.addressLine1 ?? "-",
      },

      {
        name: "emergencyDonations.form.city",
        sm: 6,
        field: orgProfile?.address?.city ?? "-",
      },

      {
        name: "emergencyDonations.form.province",
        sm: 6,
        field: orgProfile?.address?.province ?? "-",
      },

      {
        name: "emergencyDonations.form.country",
        sm: 6,
        field: orgProfile?.country ?? "-",
      },
      {
        name: "emergencyDonations.form.postalCode",
        sm: 6,
        field: orgProfile?.address?.postalCode ?? "-",
      },
      {
        name: "emergencyDonations.form.website",
        sm: 12,
        field: orgProfile?.website ?? "-",
      },
      {
        name: "emergencyDonations.form.primaryName",
        sm: 12,
        field: orgProfile?.primaryOrgUser?.userName ?? "-",
      },
      {
        name: "emergencyDonations.form.primaryTitle",
        sm: 12,
        field: orgProfile?.primaryOrgUser?.userTitle ?? "-",
      },
      {
        name: "emergencyDonations.form.telephone",
        sm: 12,
        field: orgProfile?.telephone ?? "-",
      },
      {
        name: "emergencyDonations.form.userEmail",
        sm: 12,
        field: orgProfile?.primaryOrgUser?.userEmail ?? "-",
      },
    ];
  }, [data]);

  const handleModalAction = (show: boolean) => {
    setShowStoreSearchDialog(show);
  };

  const resetSelectFacility = () => {
    setSelectedFacility({
      facilityNo: "",
      postalCode: "",
      county: "",
      state: "",
      streetAddress: "",
      city: "",
      facilityType: "",
    });
  };

  return (
    <Card style={{ margin: "25px 0px", maxWidth: "1200" }}>
      <div
        className=' col-xs-12 col-md-12 col-sm-12 col-xs-12'
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        <Heading as='h3' size='medium' weight={700}>
          <Article size='medium' style={{ marginRight: "10px" }} />
          {editable
            ? t("emergencyDonations.form.reviewDetails")
            : t("emergencyDonations.form.recipientDetails")}
        </Heading>
        {editable && (
          <>
            <Alert
              actionButtonProps={{
                children: t("emergencyDonations.form.editProfile"),
                onClick() {
                  history.push({
                    pathname: `${contextPath}/dashboard/organization-profile?type=organization-profile`,
                    state: { currentPath: window.location.pathname + window.location.search }
                });
                },
              }}
              variant='info'
              style={{ marginTop: "24px" }}
            >
              {t("emergencyDonations.form.editRecipientBar")}
            </Alert>
            <div style={{ marginTop: "5px" }}>
              <Body as='p' size='medium' weight={400}>
                {t("emergencyDonations.form.requiredField")}
              </Body>
            </div>
          </>
        )}
        <div style={{ marginTop: "25px", marginBottom: "5px" }}>
          <Heading as='h5' size='small' weight={700}>
            {t("emergencyDonations.form.specifyDonation")}
          </Heading>
        </div>
        {/*@ts-ignore*/}
        <ChipGroup>
          {/*@ts-ignore*/}
          <Chip disabled={orgType !== "org_type_governmental"} placeholder={t("emergencyDonations.form.ge")}>
            {t("emergencyDonations.form.ge")}
          </Chip>
          {/*@ts-ignore*/}
          <Chip disabled={orgType !== "org_type_ngo"} placeholder={t("emergencyDonations.form.ngo")}>
            {t("emergencyDonations.form.ngo")}
          </Chip>
        </ChipGroup>

        <Grid style={{ marginTop: "25px" }}>
          {orgFormData.map((val, idx) => (
            <GridColumn
              sm={val.sm === 12 ? 12 : 6}
              key={idx}
              style={{ margin: "15px 0px" }}
            >
              <TextField
                label={t(val.name)}
                onChange={() => {}}
                data-testid={val.name}
                size='large'
                readOnly
                value={val.field}
              />
            </GridColumn>
          ))}
          <GridColumn sm={12} style={{ margin: "5px 12px 12px 0px" }}>
            <GridColumn
              sm={12}
              style={{ margin: "10px 0px", padding: "0px 4px" }}
            >
              <Body
                as='p'
                size='medium'
                weight={400}
                data-testid='location-radio'
              >
                <b>{t("emergencyDonations.location.locationRadioTitle")}</b>
                <br />
                <Radio
                  style={{ marginTop: "5px" }}
                  name='locationKnown'
                  data-testid='location-yes'
                  disabled={!editable}
                  checked={donationData.locationKnown === "Yes"}
                  label={t("common.buttonLabels.Yes2")}
                  onChange={() => {
                    handleTargetChange("locationKnown", "Yes");
                  }}
                />

                <br />
                <Radio
                  style={{ marginTop: "5px" }}
                  name='locationKnown'
                  checked={donationData.locationKnown === "No"}
                  disabled={!editable}
                  data-testid='location-no'
                  label={t("common.buttonLabels.no")}
                  onChange={() => {
                    handleTargetChange("locationKnown", "No");
                  }}
                />
              </Body>
            </GridColumn>
            <br />
            {editable && donationData.locationKnown === "Yes" && (
              <Alert variant='warning'>
                {t("emergencyDonations.form.warning")}
              </Alert>
            )}
            {donationData.locationKnown === "Yes" && (
              <Grid style={{ margin: "5px 12px 12px 0px" }}>
                <GridColumn
                  sm={12}
                  style={{ margin: "10px 0px", padding: "0px 4px" }}
                >
                  <Body
                    as='p'
                    size='medium'
                    weight={400}
                    data-testid='location-fromWhere'
                  >
                    <b>{t("emergencyDonations.location.fromWhere")}</b>
                    <br />
                    <Radio
                      style={{ marginTop: "5px" }}
                      name='locationType'
                      data-testid='radio-store'
                      disabled={!editable}
                      // disabled
                      checked={donationData.locationType === "STORE"}
                      label={
                        editable &&
                        donationData.locationType === "STORE" &&
                        selectedFacility.facilityNo !== "" ? (
                          <>
                            <b>
                              {t("emergencyDonations.location.store")} #
                              {selectedFacility.facilityNo}
                            </b>{" "}
                            {donationData.locationType === "STORE" && (
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleModalAction(true);
                                }}
                              >
                                {t("emergencyDonations.location.changeStore")}
                              </a>
                            )}
                          </>
                        ) : (
                          t("emergencyDonations.location.store")
                        )
                      }
                      onChange={() => {
                        resetSelectFacility();
                        handleTargetChange("locationType", "STORE");
                      }}
                    />

                    <br />
                    <Radio
                      style={{ marginTop: "5px" }}
                      name='donationFrom'
                      checked={donationData.locationType === "DC"}
                      disabled={!editable}
                      data-testid='radio-dc'
                      label={
                        editable &&
                        donationData.locationType === "DC" &&
                        selectedFacility.facilityNo !== "" ? (
                          <>
                            <b>
                              {t("emergencyDonations.location.DC")} #
                              {selectedFacility.facilityNo}
                            </b>{" "}
                            {donationData.locationType === "DC" && (
                              <a
                                href='#'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleModalAction(true);
                                }}
                              >
                                {t("emergencyDonations.location.changeDc")}
                              </a>
                            )}
                          </>
                        ) : (
                          t("emergencyDonations.location.DC")
                        )
                      }
                      onChange={() => {
                        resetSelectFacility();
                        handleTargetChange("locationType", "DC");
                      }}
                    />
                  </Body>
                </GridColumn>
                {selectedFacility.facilityNo !== "" && (
                  <GridColumn
                    sm={12}
                    style={{ margin: "10px 0px", padding: "0px 4px" }}
                  >
                    <b>{t("emergencyDonations.cards.facility")}</b>:{" "}
                    {selectedFacility.facilityNo}
                    <br />
                    <b>{t("emergencyDonations.cards.facilityType")}</b>:{" "}
                    {selectedFacility.facilityType}
                    <br />
                    <b>{t("orgRegForm.labels.streetAddr")}</b>:{" "}
                    {selectedFacility.streetAddress}
                    <br />
                    <b>
                      {t("monetaryDonation.organizationDetailsSection.city")}
                    </b>
                    : {selectedFacility.city}
                    <br />
                    <b>{t("orgRegForm.labels.postalCode")}</b>:{" "}
                    {selectedFacility.postalCode}
                    <br />
                  </GridColumn>
                )}

                {editable && donationData.locationType === "STORE" && (
                  <>
                    {selectedFacility.facilityNo === "" && (
                      <GridColumn
                        sm={12}
                        style={{ margin: "15px 0px", padding: "0px 1px" }}
                      >
                        <Body
                          as='p'
                          size='medium'
                          data-testid='location-selectStore'
                          weight={400}
                          onClick={() => {
                            handleModalAction(true);
                          }}
                        >
                          <u style={{ paddingLeft: "10px", cursor: "pointer" }}>
                            {t("emergencyDonations.location.selectStoreNumber")}
                          </u>
                        </Body>
                      </GridColumn>
                    )}
                  </>
                )}

                {editable &&
                  donationData.locationType === "DC" &&
                  selectedFacility.facilityNo === "" && (
                    <GridColumn
                      sm={12}
                      style={{ margin: "15px 0px", padding: "0px 1px" }}
                    >
                      <Body
                        as='p'
                        size='medium'
                        data-testid='location-selectDCNumber'
                        weight={400}
                        onClick={() => {
                          handleModalAction(true);
                        }}
                      >
                        <u style={{ paddingLeft: "10px", cursor: "pointer" }}>
                          {t("emergencyDonations.location.selectDCNumber")}
                        </u>
                      </Body>
                    </GridColumn>
                  )}
              </Grid>
            )}

            {isLocationMandatory &&
              isContinued &&
              donationData.locationKnown === "Yes" &&
              donationData.locationType === "STORE" &&
              selectedFacility.facilityNo === "" && (
                <small className='p-error'>
                  {t("emergencyDonations.form.selectFacility")}
                </small>
              )}
          </GridColumn>
          {((editable &&
            donationData.locationKnown === "Yes" &&
            selectedFacility.facilityNo !== "") ||
            (!editable && donationData.primaryEmail)) && (
            <StoreContactDetails
              disabled={false}
              readOnly={!editable}
              isLocationMandatory={true}
              contactDetails={donationData}
              setContactDetails={setDonationData}
            />
          )}

          <TextField
            // name="purposeOfDonation"
            data-testid='purpose-input'
            label={`${t("emergencyDonations.form.purpose")}*`}
            onChange={(event) =>
              handleTargetChange("purposeOfDonation", event.target.value)
            }
            error={
              isContinued && donationData.purposeOfDonation.trim().length < 2
                ? t("emergencyDonations.form.error")
                : false
            }
            size='large'
            readOnly={!editable}
            value={
              !editable && data?.donationDetails?.purposeOfDonation
                ? data?.donationDetails?.purposeOfDonation
                : donationData.purposeOfDonation
            }
          />
          <TextArea
            style={{ margin: "15px 0px" }}
            data-testid='products-input'
            label={`${t("emergencyDonations.form.product")}*`}
            onChange={(event) =>
              handleTargetChange("donationProducts", event.target.value)
            }
            size='large'
            readOnly={!editable}
            error={
              isContinued && donationData.donationProducts.trim().length < 2
                ? t("emergencyDonations.form.error")
                : false
            }
            value={
              !editable && data?.donationDetails?.donationProducts
                ? data?.donationDetails?.donationProducts
                : donationData.donationProducts
            }
          />
        </Grid>
      </div>
    </Card>
  );
};

export default withRouter(EmergencyDonations);
