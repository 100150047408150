import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, TextField, FormLabel } from '@material-ui/core';
import { useStyles } from './InputStyles';
import { resolvePath } from '../utils';

export interface InputBoxProps {
  required?: boolean,
  multiline?: boolean,
  disabled?: boolean,
  className?: string,
  classes?: any,
  formErrors?: any,
  fieldName?: string,
  control?: any,
  id?: string,
  label?: string,
  helperText?: string,
  defaultValue?: string,
  rules?: any,
  InputProps?: any,
  value?: any,
  errorMsg?: string
  placeholder?: string,
  rows?: number,
}

const defaultProps: InputBoxProps = {
  fieldName: '',
  control: {},
  formErrors: {},
}

const InputBox: FC<InputBoxProps> = ((props: any) => {
  const { t } = useTranslation();
  const defaultClasses = useStyles();
  const fieldError = resolvePath(props.fieldName, props?.formErrors, '')?.message;

  return (
    <div
      className={props?.className}
      id={props.id}
      style={{ marginTop: '16px', marginBottom: '8px' }}
    >
      <FormControl
        required={props.required}
        style={{ width: '100%' }}
        classes={{ root: props?.classes?.inputFormControl }}
        className={defaultClasses.formControl}
        variant='outlined'
        error={props.errorMsg ? props.errorMsg : fieldError}
      >
        <FormLabel>{t(props.label)}</FormLabel>
        <Controller
          id={props.fieldName}
          name={props.fieldName}
          control={props.control}
          defaultValue={props.defaultValue}
          as={
            <TextField
              id={props.fieldName}
              rows={props?.rows}
              required={props.required}
              helperText={props?.helperText}
              fullWidth={true}
              margin='normal'
              classes={{ root: props?.classes?.selectFormControl }}
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              InputProps={props?.InputProps}
              defaultValue={props.defaultValue}
              disabled={props.disabled}
              multiline={props.multiline}
              placeholder={props?.placeholder ? props.placeholder : ''}
              error={props.errorMsg ? props.errorMsg : fieldError}
            />
          }
          rules={{ ...props?.rules }}
        />
        {(props.errorMsg || fieldError) &&
          <span className='reg-form-error-container' style={{ color: 'red' }}>
            {props.errorMsg ? props.errorMsg : fieldError}
          </span>
        }
      </FormControl>
    </div>
  )
});

InputBox.defaultProps = defaultProps;
export default InputBox;