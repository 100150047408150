import { Action } from 'redux';

export enum SubmissionDataActionTypes {
    SUBMISSION_DATA_LOAD = 'SUBMISSION_DATA_LOAD',
    SUBMISSION_DATA_LOAD_SUCCESS = 'SUBMISSION_DATA_LOAD_SUCCESS',
    SUBMISSION_DATA_LOAD_FAILURE = 'SUBMISSION_DATA_LOAD_FAILURE'
}

export interface SubmissionData extends Action {
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD;
    data: string;
}

export const submissionData = (data: string): SubmissionData => ({
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD,
    data
});

export interface SubmissionDataSuccess extends Action {
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_SUCCESS;
    data: any;
}

export const submissionDataSuccess = (data: any): SubmissionDataSuccess => ({
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_SUCCESS,
    data
});

export interface SubmissionDataFailure extends Action {
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_FAILURE;
    data?: string;
}

export const submissionDataFailure = (data: string): SubmissionDataFailure => ({
    type: SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_FAILURE,
    data
});

export type SubmissionDataActions = SubmissionData | SubmissionDataSuccess | SubmissionDataFailure;