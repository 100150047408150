import {Grid, InputLabel, Typography} from '@material-ui/core';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import InformationBar from '../common/informationBar';
import {sectionClasses} from '../common/styles';
import {InputTextField} from '../../../common/Input/InputTextField';
import {BaseSectionProps} from '../organizationDetails/orgDetailsDefaults';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';

export interface ProjectBeneficiariesParticipationProps extends BaseSectionProps {
    data: any
}

export const ProjectBeneficiariesParticipation:
    FC<ProjectBeneficiariesParticipationProps> = (props: ProjectBeneficiariesParticipationProps) => {
        const { t } = useTranslation();
        const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
        const getSnackbar = (translationKey: string) => {
            return (<InformationBar>
                {getTranslation(translationKey)}</InformationBar>);
        }
        return (<div className={sectionClasses.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography classes={{ root: sectionClasses.title }}>{getTranslation('participationDetails')}</Typography>
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Grid item={true} xs={12} md={4}>
                        <InputLabel id={'beneficiariesProvideFinancialRes'}>
                            {getTranslation('beneficiariesProvideFinancialRes')}
                        </InputLabel>
                    </Grid>
                    <Grid container={true} item={true} spacing={3}>
                        <Grid item={true} xs={12} md={4}>
                            <InputTextField
                                required={true}
                                fieldName={`${props.fieldName}.provideFinancialResources`}
                                errorKeyName={`${props.fieldName}.provideFinancialResources`}
                                id={'provideFinancialResources'}
                                InputProps={{ readOnly: false }}
                                fullWidth={true}
                                multiline={true}
                                rows={4}
                                defaultValue={props?.data?.provideFinancialResources}
                            />
                             <TextFieldMaxSizeIndicator
                                fieldName = {`${props.fieldName}.provideFinancialResources`}
                                maxSize = {1000}/>
                        </Grid>
                        <Grid item={true} xs={12} md={5}>
                            {getSnackbar('beneficiariesProvideFinancialResDesc')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Grid item={true} xs={12} md={4}>
                        <InputLabel id={'beneficiariesProvideMaterialRes'}>
                            {getTranslation('beneficiariesProvideMaterialRes')}
                        </InputLabel>
                    </Grid>
                    <Grid container={true} item={true} spacing={3}>
                        <Grid item={true} xs={12} md={4}>
                            <InputTextField
                                required={true}
                                fieldName={`${props.fieldName}.provideMaterialResources`}
                                errorKeyName={`${props.fieldName}.provideMaterialResources`}
                                id={'provideMaterialResources'}
                                InputProps={{ readOnly: false }}
                                fullWidth={true}
                                rows={4}
                                multiline={true}
                                defaultValue={props?.data?.provideMaterialResources}
                            />
                            <TextFieldMaxSizeIndicator
                                fieldName = {`${props.fieldName}.provideMaterialResources`}
                                maxSize = {1000}/>
                        </Grid>
                        <Grid item={true} xs={12} md={5}>
                            {getSnackbar('beneficiariesProvideMaterialResDesc')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Grid item={true} xs={12} md={4}>
                        <InputLabel id={'describeFunctionsOrActivitiesLabel'}>
                            {getTranslation('describeFunctionsOrActivities')}
                        </InputLabel>
                    </Grid>
                    <Grid container={true} item={true} spacing={3}>
                        <Grid item={true} xs={12} md={4}>
                            <InputTextField
                                required={true}
                                fieldName={`${props.fieldName}.functionsOrActivities`}
                                errorKeyName={`${props.fieldName}.functionsOrActivities`}
                                id={'functionsOrActivities'}
                                InputProps={{ readOnly: false }}
                                fullWidth={true}
                                rows={4}
                                multiline={true}
                                defaultValue={props?.data?.functionsOrActivities}
                            />
                            <TextFieldMaxSizeIndicator
                                fieldName = {`${props.fieldName}.functionsOrActivities`}
                                maxSize = {1000}/>
                        </Grid>
                        <Grid item={true} xs={12} md={5}>
                            {getSnackbar('describeFunctionsOrActivitiesDesc')}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputLabel id={'participationMechanismLabel'}>
                        {getTranslation('participationMechanism')}
                    </InputLabel>
                    <InputTextField
                        required={true}
                        fieldName={`${props.fieldName}.participationMechanism`}
                        errorKeyName={`${props.fieldName}.participationMechanism`}
                        id={'participationMechanism'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        rows={4}
                        multiline={true}
                        defaultValue={props?.data?.participationMechanism}
                    />
                    <TextFieldMaxSizeIndicator
                                fieldName = {`${props.fieldName}.participationMechanism`}
                                maxSize = {1000}/>
                </Grid>
            </Grid>
        </div>
        );
    }
