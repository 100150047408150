import { useEffect, useState } from 'react';
import { countryCodesCookieValue, FormBlockHeading, getAppenidxCOrgInfo, wordLengthValidation } from '../utils';
import { DropDown, InputBox } from '../../common';
import { countryCodeTranslations } from '../../../constants';
import '../../../styles/oneTimeGoodDonations.css';
import '../../../styles/formStyles.css';
import GlobalDonationDataService from '../../../service';
import { useTranslation } from 'react-i18next';
import { stringFormat } from '../../common/utils';
import { getFormattedDate } from '../../common/DateUtils';

export const AboutOrganization = (props: any) => {
    const { t } = useTranslation();
    const countryCode = props?.data?.countryCode ? props.data.countryCode : countryCodesCookieValue;
    const [stateList, setStateList] = useState([]);
    const countryName = countryCodeTranslations.find(country => country.code === countryCode)?.label?.[props?.selectedLang];
    /* istanbul ignore next */
    useEffect(() => {
        props.setValue('countryCode', countryName);
        GlobalDonationDataService.getStateList({ countryCode }).then(res => {
            setStateList(res);
        })
    }, [props?.data?.countryCode]);

    const wordLengthValidate10 = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 10);
        return wordLengthValidation(value, message, 10)
    };

    const wordLengthValidate600 = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 600);
        return wordLengthValidation(value, message, 600)
    };
    const requiredValidationMsg = t('common.validationMsg.requiredField');
    useEffect(() => {
        const orgInfo = getAppenidxCOrgInfo(props?.data, props?.data?.goodsOneTimeDonation?.certifications?.[0])
        props.setValue('orgLegalName', orgInfo?.legalName ? orgInfo?.legalName : '');
        props.setValue('address.addressLine1', orgInfo?.streetAddress ? orgInfo?.streetAddress : '');
        props.setValue('address.province', orgInfo?.state ? orgInfo?.state : '');
        props.setValue('address.city', orgInfo?.city ? orgInfo?.city : '');
        props.setValue('address.postalCode', orgInfo?.postalCode ? orgInfo?.postalCode : '');
        props.setValue('telephone', orgInfo?.telephone)
        props.setValue('carton.deliveredDate', props?.data?.carton?.deliveredDate ?
            getFormattedDate(props.data.carton.deliveredDate) : '');
    }, [props.data]);

    return (
        <div>
            <FormBlockHeading heading={t('oneTimeGoodsDonation.heading.aboutOrganization')} />
            <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
                <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                    <InputBox
                        id={'org_details_legal_name'}
                        className={'org_details_legal_name'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('orgRegForm.labels.orglegalName')}
                        fieldName={'orgLegalName'}
                        defaultValue={''}
                        required={true}
                        rules={{
                            required:requiredValidationMsg as string,
                            validate: wordLengthValidate600
                        }}
                        errorMsg={props.errors?.orgLegalName?.message}
                        disabled={props?.data?.orgLegalName ? true : false}
                        InputProps={{
                            readOnly: props?.data?.orgLegalName ? true : false,
                            classes: {
                                disabled: props?.data?.orgLegalName ? props.classes.disabled : ''
                            }
                        }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4 block_wrapper'>
                    <InputBox
                        id={'countryCode'}
                        className={'org_details_country'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('oneTimeGoodsDonation.labels.countryRegion')}
                        fieldName={'countryCode'}
                        defaultValue={countryName}
                        disabled={true}
                        required={true}
                        InputProps={{
                            readOnly: true,
                            classes: { disabled: props?.classes?.disabled }
                        }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <InputBox
                        id={'org_details_street_address'}
                        className={'org_details_street_address'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('orgRegForm.labels.streetAddr')}
                        fieldName={'address.addressLine1'}
                        defaultValue={''}
                        multiline={true}
                        required={true}
                        rules={{
                            required: requiredValidationMsg as string,
                            validate: wordLengthValidate600
                        }}
                        errorMsg={props.errors?.orgLegalName?.message}
                        disabled={props?.data?.address?.addressLine1 ? true : false}
                        InputProps={{
                            readOnly: props?.data?.address?.addressLine1 ? true : false,
                            classes: {
                                disabled: props?.data?.address?.addressLine1 ? props.classes.disabled : ''
                            }
                        }}
                    />
                </div>
            </div>
            <div className='row org_details_row' style={{ display: 'flex', alignItems: 'center' }}>
                <div className={`col-md-2 state_dropDown`}>
                    <DropDown
                        id={'org_details_state'}
                        className={`org_details_state ${(props?.data?.address?.province) ? 'disabled-dropdown' : ''}`}
                        formErrors={props.errors}
                        control={props.control}
                        inputLabel={t('orgRegForm.labels.state')}
                        fieldName={'address.province'}
                        required={true}
                        defaultValue={''}
                        dropDownData={stateList}
                        rules={{
                            required: requiredValidationMsg as string,
                            validate: wordLengthValidate600
                        }}
                        errorMsg={props.errors?.address?.province?.message}
                        disabled={props?.data?.address?.province ? true : false}
                        classes={{
                            ...props.classes,
                            disabled: props?.data?.address?.province ? props.classes.disabled : ''
                        }}
                    />
                </div>
                <div className='col-md-2'>
                    <InputBox
                        id={'org_details_city'}
                        className={'org_details_city inputBox'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('orgRegForm.labels.city')}
                        fieldName={'address.city'}
                        defaultValue={''}
                        required={true}
                        rules={{
                            required: requiredValidationMsg,
                            validate: wordLengthValidate600
                        }}
                        errorMsg={props.errors?.address?.city?.message}
                        disabled={props.data?.address?.city ? true : false}
                        InputProps={{
                            readOnly: props.data?.address?.city ? true : false,
                            classes: { disabled: props.data?.address?.city ? props.classes.disabled : '' }
                        }}
                    />
                </div>
                <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                    <InputBox
                        id={'org_details_postal_code'}
                        className={'org_details_postal_code'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('orgRegForm.labels.postalCode')}
                        fieldName={'address.postalCode'}
                        defaultValue={''}
                        required={true}
                        rules={{
                            required: requiredValidationMsg,
                            validate: wordLengthValidate10
                        }}
                        errorMsg={props.errors?.address?.postalCode?.message}
                        disabled={props.data?.address?.postalCode ? true : false}
                        InputProps={{
                            readOnly: props.data?.address?.postalCode ? true : false,
                            classes: {
                                disabled: props.data?.address?.postalCode ? props.classes.disabled : ''
                            }
                        }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                    <InputBox
                        id={'donation-telephone'}
                        className={'donation-telephone'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('orgRegForm.labels.telephone')}
                        fieldName={'telephone'}
                        defaultValue={''}
                        required={true}
                        rules={{ required: requiredValidationMsg }}
                        errorMsg={props.errors?.telephone?.message}
                        disabled={true}
                        InputProps={{
                            classes: {
                                disabled: props?.classes?.disabled
                            }
                        }}
                    />
                </div>
                <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                    <InputBox
                        id={'donation-received-date'}
                        className={'donation-received-date'}
                        classes={props.classes}
                        formErrors={props.errors}
                        control={props.control}
                        label={t('oneTimeGoodsDonation.donationDate')}
                        fieldName={'carton.deliveredDate'}
                        defaultValue={''}
                        required={true}
                        rules={{ required: requiredValidationMsg, validate: wordLengthValidate600 }}
                        errorMsg={props.errors?.cartonDetails?.collectedDate?.message}
                        disabled={true}
                        InputProps={{
                            readOnly: true,
                            classes: {
                                disabled: props?.classes?.disabled
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
