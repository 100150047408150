import {useEffect} from 'react';
import Stepper from '../../../commonComponents/uiComponents/LDStepper';
import StepperItem from '../../../commonComponents/uiComponents/LDStepperItem';
import Button from '../../../commonComponents/uiComponents/LDButton';
import LoaderComponent from '../../../commonComponents/appComponents/loader/LoaderComponent';

export interface TrackerProps {
    activeIndex: any
}

const Tracker: any = (props: any) => {
    return <Stepper className='stepper-container' activeIndex={props.activeIndex} variant='info'>
        <StepperItem />
        <StepperItem />
        <StepperItem />
    </Stepper>
}

const ButtonSection: any = (props: any) => {

    useEffect(() => {
        if (props.mutation.isSuccess) {
            props.setSpaceDonation(props.mutation.data);
        }
    }, [props.mutation.isSuccess]);
    return <div className='row'>
        <div className='col-4 stepper-button'>
            {props.activeForm > 0 && <Button variant='secondary'
                disabled={props.disabled}
                size='small'
                onClick={() => props.setActiveForm(props.activeForm - 1)}
                className='stepper-button'>
                Back
        </Button>}
        </div>
        <Tracker className='col-4' activeIndex={props.activeForm} />
        {props.activeForm !== 2 && <div className='col-4 stepper-button'>
            <Button variant='primary'
                disabled={props.disabled}
                size='small'
                onClick={() => props.setActiveForm(props.activeForm + 1)}
                className='stepper-button'>
                {props.activeForm === 2 ? 'Done' : 'Next'}
        </Button>
        </div>}
        {props.activeForm === 2 && <div className='col-4 stepper-button'>
            <Button variant='primary'
                disabled={props.disabled}
                size='small'
                className='stepper-button' type={'submit'}>
                {props.activeForm === 2 ? 'Done' : 'Next'}
        </Button>
        </div>}
        {props.mutation.isLoading && <LoaderComponent />}
        {props.mutation.isSuccess ? props.setActiveForm(props.activeForm + 1) : ''}
    </div>
}

export default ButtonSection;