import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import '../../../styles/formStyles.css';
import '../../../styles/oneTimeGoodDonations.css';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { NgoCreateDonationForm } from './NgoCreateDonationForm';
import { BreadcrumbsComponent } from '../../common/index';
import { getOrgProfile } from '../../../state/OrgProfile/orgProfileActions';
import { breadcrumbsData, PageHeading, styles, } from '../utils';
import { contextPath } from '../../../constants';
import LoaderComponent from '../../../components/LoaderComponent';
import analytics from '../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';

export interface OwnProps {
  history: any;
  appi18n: any;
  classes?: any;
  t: (key: string, options?: any) => (string);
}

export interface StateProps {
  selectedLang: any;
  orgProfileState: any;
  loggedInUserDetail:any
}

export interface DispatchProps {
  getOrgProfile: (data: any) => void;
}
class OneTimeGoodsCreateComponent extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
  public state = {
    donationData: {
      currentStatusCode: ''
    },
    orgType: 'org_type_ngo',
    allowToCreateDonation: true,
  }

  public componentDidMount() {
    analytics.trackPageInfoGdmsExternal(ANALYTICS.oneTimeGoodsDonation.dashboardPage);
    const email: string = localStorage.getItem('userEmail') || ''
    this.props.getOrgProfile({ email });
  }

  public render() {
    const { allowToCreateDonation } = this.state;
    const { t } = this.props;
    const appendixBData = this.props?.loggedInUserDetail?.orgLegalName ?
      this.props?.loggedInUserDetail : this?.props?.orgProfileState?.orgProfile;
    const appendixB = appendixBData.orgAdditionalDetails && appendixBData.orgAdditionalDetails.isRegisteredNew ? {
      organization: {
        ...appendixBData,
        authorized: '',
        corruptionOrBribery: '',
        issueReceipts: '',
        govtEntityRelation: '',
        govtOfficialRelation: '',
        politicalActivity: '',
        pastDonation: '',
        prevDeniedDonation: '',
        govtOrPoliticalOfficeRelative: '',
        govtOrPoliticalOffice: '',
        boardMembers: '',
        executiveOfficers: '',
        keyPersons: ''
      }
    } : { organization: { ...appendixBData } }
    return (
      <>
        <BreadcrumbsComponent breadcrumbsData={breadcrumbsData} history={this.props.history} />
        <PageHeading heading={t('oneTimeGoodsDonation.oneTimeGoodsDonation')} />
        <div className={'bodyStyles ngoFormWrapper'}>
          <>
            { /* istanbul ignore next */
              allowToCreateDonation &&
              <NgoCreateDonationForm
                selectedLang={this.props.selectedLang}
                disabled={false}
                modifyOrg={true}
                history={this.props.history}
                defaultDonationData={this.state.donationData}
                redirectUrl={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                appendixB={appendixB}
              />
            }
          </>
        </div>
        {(this.props?.orgProfileState?.getOrgProfileLoading) && <LoaderComponent fullScreen={true} />}
      </>
    );
  }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  selectedLang: state.selectedLang,
  orgProfileState: state.orgProfile,
  loggedInUserDetail:state.loggedInUserState.userDetail
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
})

export default withTranslation()
  (withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(OneTimeGoodsCreateComponent))));
