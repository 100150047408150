import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';

const service: Service = SpaceDonationService;

const UserContext = React.createContext({
    token: '',
    isLoggedIn: false,
    organization: {},
    isLoading: true,
    login:
        // @ts-ignore
        (token: any, organization: any) => { /**/ },
    logout:
        // @ts-ignore
        () => {/**/ },
    setOrg:
        // @ts-ignore
        (data: any) => {/**/ },
})


export const UserContextProvider: any = (props: any) => {
    const [cookies, , removeCookie] = useCookies();
    const [token, setToken] = useState(cookies.authToken);
    const [organization, setOrganization] = useState({});

    const { status, data, isFetching, } = useQuery(['getMyOrgData'],
        service.getMyOrgData, {
        refetchOnWindowFocus: false,
        enabled: ('true' === localStorage.getItem('isLoggedIn') && Object.keys(organization).length <= 0)
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isFetching && 'success' === status) {
            setOrganization(data);
            setIsLoading(false);
        }
        if (!isFetching && 'error' === status) {
            logoutHandler();
            setIsLoading(false);
        }
        if(!isFetching && Object.keys(organization).length <= 0){
            setIsLoading(false);
        }
    }, [isFetching]);


    const isLoggedIn = !!token;
    localStorage.setItem('isLoggedIn', isLoggedIn ? 'true' : 'false');

    const loginHandler = (_token: any, _organization: any) => {
        setToken(_token);
        setOrganization(_organization);
        setIsLoading(false);
    }

    const logoutHandler = () => {
        setToken(null);
        setOrganization({});
        removeCookie('authToken', { path: '/' });
        removeCookie('userRole', { path: '/' });
        removeCookie('userId', { path: '/' });
        removeCookie('SPID', { path: '/' });
        removeCookie('CID', { path: '/' });
        localStorage.removeItem('isLoggedIn')
    }

    const setOrg = (org: any) => {
        setOrganization(org);
    }

    const contextValue = {
        token,
        isLoggedIn,
        isLoading,
        organization,
        setOrg,
        login: loginHandler,
        logout: logoutHandler

    }

    // @ts-ignore
    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>
}

export default UserContext;