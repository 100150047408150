import React, { useEffect } from 'react';
import { createPortal } from 'react-dom'
import startOfDay from 'date-fns/startOfDay';
import moment from 'moment';
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker
} from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Alert, Callout,IconButton } from '@walmart-web/livingdesign-components';
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import { InfoCircle } from '../../commonComponents/icons/icon';

export const findIndexDate = (dates: any, date: any) => {
  return dates.findIndex((item: any) => moment(item).isSame(moment(date), 'd'));
};

export const useTimeoutState = (duration = 5e3): any => {
  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (visible) {
      timeoutId = setTimeout(() => setVisible(false), duration);
    }
    return () => {
      clearTimeout(timeoutId);
    }
  }, [duration, visible]);

  return [visible, setVisible];
}

export interface DatePickerProps {
  selectedDates: any[];
  approvedDates: any[];
  submittedDates: any[];
  rejectedDates: any[];
  newSelectedDates: any[];
  setNewSelectedDates: (values: any) => void;
  deselectedDates: any[];
  setDeselectedDates: (values: any) => void;
  disabledDates: any[];
  onChangeAvailable: (changedDates: { addedDates: Date[], removedDates: Date[] }) => void;
  disabled: boolean;
  minDate: Date;
  maxDate: Date;
  defaultCalendarMonth?: Date;
  consecutiveDaysRestriction?: boolean;
  restrictedDays?: number;
  totalDays: number;
  panelContent: any;
  status?: string;
  availableEditsLeft?:number;
  editEventFlag?:boolean;
}
export const getNewSelectedDates = (previousValues: any, values: any, newValue: any, selectedDates: any) => {
  const array = [...previousValues];
  const date = startOfDay(newValue);
  const index = findIndexDate(selectedDates, date);
  const indexForOverallSelections = findIndexDate(values, date);
  if (indexForOverallSelections >= 0) {
    const indexForNewSelections = findIndexDate(array, date);
    array.splice(indexForNewSelections, 1);
  } else {
    if (index < 0) {
      array.push(date);
    }
  }

  return array;
}
export const getDeselectedDates = (previousValues: any, values: any, newValue: any, selectedDates: any) => {
  const array = [...previousValues];
  const date = startOfDay(newValue);
  const index = findIndexDate(selectedDates, date);
  const indexForOverallSelections = findIndexDate(values, date);
  if (indexForOverallSelections >= 0) {
    if (index >= 0) {
      array.push(date);
    }
  } else {
    const indexForNewSelections = findIndexDate(array, date);
    array.splice(indexForNewSelections, 1);
  }

  return array;
}

export const uniqDates = (dates: Date[]) => dates.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);

export const sortDates = (dates: Date[]) => dates.sort((a: Date, b: Date) => a.getTime() - b.getTime());

export const getDateDiff = (date1: Date, date2?: Date) => moment(date1).diff(moment(date2), 'days');

const findDate = (dates: any, date: any) => {
  return dates.find(
    (item: Date) => item?.setHours(0, 0, 0, 0) === date?.setHours(0, 0, 0, 0)
  );
};
const checkDateExistence = (submittedDates: any, day: any) => {
  return submittedDates.find((d: any) => moment(d).isSame(moment(day), 'date'));
};
const checkPast = (minDate: any, day: any) => {
  return (
    moment(day).isBefore(moment(minDate), 'date') ||
    moment(day).isSameOrBefore(moment().subtract(1, 'day'), 'date')
  );
};
const checkMultipleCondition = (
  isPast: boolean,
  isFuture: boolean,
  isRejected: boolean,
) => {
  return isPast || isFuture || isRejected;
};
const checkOnChangeCondition = (
  newValue: any,
  maxDate: any,
  minDate: any,
  disabledDates: any
) => {
  return (
    newValue &&
    startOfDay(newValue) <= maxDate &&
    startOfDay(newValue) >= startOfDay(minDate) &&
    !findDate(disabledDates, startOfDay(newValue)) &&
    startOfDay(newValue) >= startOfDay(new Date())
  );
};
const checkSelected = (values: any, day: any) => {
  return findDate(values, day);
};

const getPrevNDates = (currentDate: Date, restrictedDays: number): Date[] => {
  const prevDates: any = [];
  for (let i = restrictedDays; i > 0; i--) {
    const element = moment(currentDate).subtract(i, 'day');
    if (element) {
      prevDates.push(element.toDate());
    }
  }
  return prevDates;
}

const getNextNDates = (currentDate: Date, restrictedDays: number): Date[] => {
  const nextDates: any = [];
  for (let i = 1; i <= restrictedDays; i++) {
    const element = moment(currentDate).add(i, 'day');
    if (element) {
      nextDates.push(element.toDate());
    }
  }
  return nextDates;
}

export const checkConsecutiveDays = (dates: Date[], currentDate: Date, restrictedDays: number): boolean => {
  const sortedValues = sortDates(dates);

  const prevNDates = getPrevNDates(currentDate, restrictedDays);
  const nextNDates = getNextNDates(currentDate, restrictedDays);

  const isPrevAllConsecutive = prevNDates.every(d => checkDateExistence(sortedValues, d));
  const isNextAllConsecutive = nextNDates.every(d => checkDateExistence(sortedValues, d));

  if (isPrevAllConsecutive || isNextAllConsecutive) {
    return true;
  }

  const selectedRange = uniqDates(sortDates([...prevNDates, currentDate, ...nextNDates])).filter(d => checkDateExistence(sortedValues, d));

  const isSelectedRangeConsecutive = selectedRange.every((d: Date, i: number, thisArg: any[]) =>
    i === 0 ? true : Math.abs(getDateDiff(d, thisArg[i - 1])) === 1);

  if (selectedRange.length > restrictedDays) {
    if (isSelectedRangeConsecutive) {
      return true;
    } else {
      let counterP = 0;
      let counterN = 0;
      const prevNSelected = [...prevNDates.filter(d => checkDateExistence(sortedValues, d)), currentDate];
      const rangeP = Math.min(prevNSelected.length, restrictedDays);
      for (let i = rangeP - 1; i > 0; i--) {
        const curr = prevNSelected[i];
        const prev = prevNSelected[i - 1];
        const isConsecutive = Math.abs(getDateDiff(curr, prev)) === 1;
        if (isConsecutive) {
          counterP++;
        } else {
          break;
        }
      }

      const nextNSelected = [currentDate, ...nextNDates.filter(d => checkDateExistence(sortedValues, d))];
      for (let i = 0; i < restrictedDays; i++) {
        const curr = nextNSelected[i];
        const next = nextNSelected[i + 1];
        const isNextConsecutive = Math.abs(getDateDiff(curr, next)) === 1;
        if (isNextConsecutive) {
          counterN++;
        } else {
          break;
        }
      }

      if ((counterN + counterP) >= restrictedDays) {
        return true;
      }
    }
  }

  return false;
};

export const getInitialDate = (minDate: any) => new Date(Math.max(moment(minDate).valueOf(), new Date().getTime()));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    border: '1px solid #dadde9',
    marginBottom: '0 !important'
  }
}));

const MultiDatePicker: React.FunctionComponent<DatePickerProps> = ({
  selectedDates,
  approvedDates,
  submittedDates,
  rejectedDates,
  setNewSelectedDates,
  setDeselectedDates,
  disabledDates,
  onChangeAvailable,
  disabled,
  minDate,
  maxDate,
  consecutiveDaysRestriction = false,
  restrictedDays = 0,
  totalDays,
  panelContent,
  status,
  availableEditsLeft,
  editEventFlag,
}) => {
  const allowedDays = totalDays;
  const [addedDates, setAddedDates] = React.useState<Date[]>([]);
  const [removedDates, setRemovedDates] = React.useState<Date[]>([]);
  const [submitted, setSubmitted] = React.useState<Date[]>(submittedDates);
  const [approved, setApproved] = React.useState<Date[]>(approvedDates);
  const [showConsecutiveDaysAlert, setShowConsecutiveDaysAlert] = useTimeoutState();
  const [totalDaysCount, setTotalDaysCount] = React.useState(0);
  const [remainingDays, setRemainingDays] = React.useState(0);
  const [isCallOutOpen, setIsCallOutOpen] = React.useState(false);
  const [isToolTip, setIsToolTip] = React.useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    const total = allowedDays - (approved.length + rejectedDates.length + submitted.length);
    setTotalDaysCount(total);
  }, [approvedDates, rejectedDates, removedDates, submitted, allowedDays, totalDays, approved]);

  useEffect(() => {
    setSubmitted(submittedDates);
  }, [submittedDates]);

  useEffect(() => {
    setIsCallOutOpen(remainingDays === 0);
  }, [remainingDays]);

  useEffect(() => {
    const remDays = totalDaysCount - addedDates.length;
    setRemainingDays(remDays);
    if (remDays === 0) {
      setIsCallOutOpen(remDays === 0);
    }
  }, [totalDaysCount, addedDates]);

  useEffect(() => {
    if (disabled) {
      return;
    }
    onChangeAvailable({ addedDates, removedDates });
    setNewSelectedDates(addedDates);
    setDeselectedDates(removedDates);
  }, [addedDates, removedDates, disabled]);

  const renderPickerDay = (
    day: any,
    _selectedDate: any,
    pickersDayProps: any
  ) => {
    const dayInCurrentMonth = !pickersDayProps.outsideCurrentMonth;

    const isSubmittedInitially = checkDateExistence(submittedDates, day);
    const isSubmitted: any = checkDateExistence(submitted, day) && dayInCurrentMonth;
    const isApproved: any = checkDateExistence(approved, day) && dayInCurrentMonth;
    const isApprovedInitially = checkDateExistence(approvedDates, day);
    const isRejected: any = checkDateExistence(rejectedDates, day) && dayInCurrentMonth;
    const isPast = checkPast(new Date(), day);
    const isFuture = moment(day).isAfter(moment(maxDate));
    const selected = checkSelected(addedDates, day);
    const renderClassName = () => {
      let className = '';
      if (disabled !== true && !isRejected) {
        if (isSubmitted) {
          className = 'submitted-button';
        } else if (isApproved) {
          className = 'available-button';
        }
        if (selected && dayInCurrentMonth) {
          className = 'selected-button';
        }
        if (selected && isSubmittedInitially && dayInCurrentMonth) {
          className = '';
        }
        if (selected && isApprovedInitially && dayInCurrentMonth) {
          className = '';
        }

      } else {
        if (isSubmitted) {
          className = 'submitted-button';
        } else if (isRejected) {
          className = 'rejected-button';
        } else if (isApproved) {
          className = 'available-button';
        }
      }
      return className;
    };
    return (
      <div className={renderClassName()}>
        <PickersDay disabled={checkMultipleCondition(isPast, isFuture, isRejected)} {...pickersDayProps}>
          {dayInCurrentMonth ? <span>{day.getDate()}</span> : <></>}
        </PickersDay>
      </div>
    );
  };

  const panelHeadContainer = document.getElementById('panel-head-content');
  return (
    <>
      {showConsecutiveDaysAlert && (
        <div className='my-2'>
          <Alert variant="warning">
            {`Not allowed to select more than ${restrictedDays} consecutive days`}
          </Alert>
        </div>
      )}
      {!!panelHeadContainer && createPortal(
        <>
          {panelContent && (
            <div className='d-flex row justify-content-between align-items-center'>
              {status === 'APPROVED'&& editEventFlag && <Alert variant="warning">
                {`You have ${availableEditsLeft} more chance to edit the requested dates and time slots.`}
              </Alert>}
              {status === 'APPROVED'&& editEventFlag === false && <Alert variant="warning">
                {`You have already used up the one edit requests that is permitted.`}
              </Alert>}
              <div className='panel-content'>
                {panelContent}
              </div>
            </div>
          )}
          
       
            <li >Total requested <strong>{approved.length + submitted.length + addedDates.length}</strong> days 
            <HtmlTooltip
            arrow={true}
            placement='top'
            title={
              <React.Fragment>
                <div><strong>Requested Days</strong>: {`Approved(${approved.length}) + Pending(${submitted.length}) + Selected(${addedDates.length})`}</div>
                <div><strong>Remaining Days</strong>: {remainingDays}</div>
                <div><strong>Opted Out Days</strong>: {rejectedDates.length}</div>
              </React.Fragment>
            }

            open={isToolTip}
            onClose={()=>setIsToolTip(!isToolTip)}
          >
            <IconButton a11yLabel="icon" size="small" UNSAFE_style={{"display":"inline"}}onClick={()=>setIsToolTip(!isToolTip)}><InfoCircle/></IconButton>
          </HtmlTooltip>
          </li>
          <li>
            <Callout
              a11yContentLabel='Target information'
              content='You have reached maximum allowed days for selection'
              isOpen={isCallOutOpen}
              onClose={() => setIsCallOutOpen(false)}
              position='left'
              UNSAFE_className={'callOutMaxAllowedDays'}
              targetRef={ref}
            >
              <span ref={ref}>Remaining <strong>{remainingDays}</strong> day</span>
            </Callout>
          </li>
        </>, panelHeadContainer)
      }
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={disabled ? 'readOnlyPicker' : 'check123'}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            value={addedDates}
            onChange={(newValue: any) => {
              const isSubmitted = checkDateExistence(submitted, newValue);
              const isApproved: any = checkDateExistence(approved, newValue);
              const isRejected: any = checkDateExistence(rejectedDates, newValue);
              const isPast = checkPast(new Date(), newValue);
              if ((isPast || isRejected)) {
                return;
              }

              const selectedArray = [...approved, ...submitted, ...addedDates, newValue];
              const isConsecutive = consecutiveDaysRestriction && checkConsecutiveDays(selectedArray, newValue, restrictedDays);
              if (isConsecutive) {
                setShowConsecutiveDaysAlert(true);
              }

              if (
                checkOnChangeCondition(
                  newValue,
                  maxDate,
                  minDate,
                  disabledDates
                )
              ) {
                if (!isConsecutive && editEventFlag === true) {
                  const isSubmittedInitially = checkDateExistence(submittedDates, newValue);
                  const isApprovedInitially  = checkDateExistence(approvedDates, newValue);
                  // keep track of added and removed dates
                  if (isSubmitted) {
                    setSubmitted(prev => prev.filter((d: any) => !moment(d).isSame(moment(newValue), 'd')));
                    setRemovedDates(prev => [...prev, newValue]);
                  } else if (isSubmittedInitially) {
                    remainingDays > 0 && setSubmitted(prev => [...prev, newValue]);
                    setRemovedDates(prev => prev.filter((d: any) => !moment(d).isSame(moment(newValue), 'd')));
                  } else if(isApproved) {
                    moment(newValue).diff(moment(),'days') >= 3 && setApproved(prev => prev.filter((d: any) => !moment(d).isSame(moment(newValue), 'd')));
                    setRemovedDates(prev => [...prev, newValue]);
                  } else if(isApprovedInitially) {
                    moment(newValue).diff(moment(),'days') >= 3 && setApproved(prev => [...prev, newValue]);
                    setRemovedDates(prev => prev.filter((d: any) => !moment(d).isSame(moment(newValue), 'd')));
                  } else {
                    const alreadyAddedIndex = findIndexDate(addedDates, newValue);
                    if (alreadyAddedIndex > -1) {
                      setAddedDates(prev => prev.filter((d: any) => !moment(d).isSame(moment(newValue), 'd')));
                    } else {
                      remainingDays > 0 && setAddedDates(prev => [...prev, newValue]);
                    }
                  }
                  
                }

                setNewSelectedDates((previousValues: any) =>
                  getNewSelectedDates(
                    previousValues,
                    addedDates,
                    newValue,
                    selectedDates
                  )
                );
                setDeselectedDates((previousValues: any) =>
                  getDeselectedDates(
                    previousValues,
                    addedDates,
                    newValue,
                    selectedDates
                  )
                );
              }
            }}
            renderDay={renderPickerDay}
            renderInput={(params) => <TextField {...params} />}
            views={['day']}
            readOnly={disabled}
            maxDate={maxDate}
            minDate={minDate}
            showToolbar={false}
          />
        </div>
      </LocalizationProvider>
    </>
  );
};

export default MultiDatePicker;
