import { Action } from 'redux';

export enum SelectedDataActionTypes {
    SELECTED_DATA_LOAD = 'SELECTED_DATA_LOAD',
    SELECTED_DATA_LOAD_SUCCESS = 'SELECTED_DATA_LOAD_SUCCESS',
    SELECTED_DATA_LOAD_FAILURE = 'SELECTED_DATA_LOAD_FAILURE',
    SET_SELECTED_DATA_LOAD = 'SET_SELECTED_DATA_LOAD',
    CLEAR_SELECTED_DATA = 'CLEAR_SELECTED_DATA'
}

export interface SelectedData extends Action {
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD;
    data: any;
}

export const selectedData = (data: string): SelectedData => ({
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD,
    data
});

export interface SelectedDataLoadSuccess extends Action {
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD_SUCCESS;
    data: any;
}

export const selectedDataLoadSuccess = (data: any): SelectedDataLoadSuccess => ({
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD_SUCCESS,
    data
});

export interface SelectedDataLoadFailure extends Action {
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD_FAILURE;
    data?: string;
}

export const selectedDataLoadFailure = (data: string): SelectedDataLoadFailure => ({
    type: SelectedDataActionTypes.SELECTED_DATA_LOAD_FAILURE,
    data
});
export interface SetSelectedDataLoad extends Action {
    type: SelectedDataActionTypes.SET_SELECTED_DATA_LOAD;
    data?: any;
}

export const setSelectedDataLoad = (data: string): SetSelectedDataLoad => ({
    type: SelectedDataActionTypes.SET_SELECTED_DATA_LOAD,
    data
});

export interface ClearSelectedData extends Action {
    type: SelectedDataActionTypes.CLEAR_SELECTED_DATA,
    data: string
}

export const clearSelectedData = (data: string): ClearSelectedData => ({
    type: SelectedDataActionTypes.CLEAR_SELECTED_DATA,
    data
});


export type SelectedDataActions = SelectedData | SelectedDataLoadSuccess |
    SelectedDataLoadFailure | SetSelectedDataLoad | ClearSelectedData;