import {OrgUserManagementState} from './../../models/globalDonationState';
import {OrgUserManagementActions, OrgUserManagementActionTypes} from './orgUserManagementActions';

export const defaultOrgUserManagementState : OrgUserManagementState = {
    failedOrgUserData: {},
    selectedOrgUserData: {},
    addOrgUserLoading: false,
    addOrgUserSuccess: false,
    addOrgUserFailure: false,
    editOrgUserLoading: false,
    editOrgUserSuccess: false,
    editOrgUserFailure: false,
    removeOrgUserLoading: false,
    removeOrgUserSuccess: false,
    removeOrgUserFailure: false,
    sendTemporaryPasswordLoading: false,
    sendTemporaryPasswordSuccess: false,
    sendTemporaryPasswordFailure: false,
    setAsPrimaryOrgUserLoading: false,
    setAsPrimaryOrgUserSuccess: false,
    setAsPrimaryOrgUserFailure: false,
    addOrgUserErrorMessage: '',
    editOrgUserErrorMessage: '',
    removeOrgUserErrorMessage: '',
    sendTemporaryPasswordErrorMessage: '',
    setAsPrimaryOrgUserErrorMessage: '',
    addOrgUserValidationError: false,
    editOrgUserValidationError: false,
    removeOrgUserValidationError: false,
    sendTemporaryPasswordValidationError: false,
    setAsPrimaryOrgUserValidationError: false,
    gridDataRefresh: false,
    firstTimeLogIn:false,
    firstTimeLogInSuccess:false,
    savePasswordSuccessful: false,
    savePasswordFailure: false,
    savePasswordLoading: false
}

export const orgUserManagementReducer  = (state: OrgUserManagementState = defaultOrgUserManagementState,
    action: OrgUserManagementActions) : OrgUserManagementState => {
    switch(action.type) {
        case OrgUserManagementActionTypes.CHANGE_SELECTED_USER: return {
            ...state,
            selectedOrgUserData: {},
            failedOrgUserData: {},
            addOrgUserErrorMessage: '',
            addOrgUserValidationError: false,
            addOrgUserLoading: false,
            addOrgUserSuccess: false,
            addOrgUserFailure: false,
            editOrgUserErrorMessage: '',
            editOrgUserValidationError: false,
            editOrgUserLoading: false,
            editOrgUserSuccess: false,
            editOrgUserFailure: false,
            removeOrgUserErrorMessage: '',
            removeOrgUserValidationError: false,
            removeOrgUserLoading: false,
            removeOrgUserSuccess: false,
            removeOrgUserFailure: false,
            sendTemporaryPasswordErrorMessage: '',
            sendTemporaryPasswordValidationError: false,
            sendTemporaryPasswordLoading: false,
            sendTemporaryPasswordSuccess: false,
            sendTemporaryPasswordFailure: false,
            setAsPrimaryOrgUserErrorMessage: '',
            setAsPrimaryOrgUserValidationError: false,
            setAsPrimaryOrgUserLoading: false,
            setAsPrimaryOrgUserSuccess: false,
            setAsPrimaryOrgUserFailure: false,
            firstTimeLogInSuccess:false,
            firstTimeLogIn:false
        };
        case OrgUserManagementActionTypes.SELECTED_USER_CHANGED: return {
            ...state,
            failedOrgUserData: {},
            selectedOrgUserData: action.data.data,
            gridDataRefresh: (action.data.data && action.data.data.gridRefresh) ? !state.gridDataRefresh : state.gridDataRefresh
        };
        case OrgUserManagementActionTypes.ADD_ORG_USER: return {
            ...state,
            addOrgUserErrorMessage: '',
            addOrgUserValidationError: false,
            addOrgUserLoading: true,
            addOrgUserSuccess: false,
            addOrgUserFailure: false,
            failedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.ADD_ORG_USER_SUCCESS: return {
            ...state,
            addOrgUserErrorMessage: '',
            addOrgUserValidationError: false,
            addOrgUserLoading: false,
            addOrgUserSuccess: true,
            addOrgUserFailure: false,
            failedOrgUserData: {},
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.ADD_ORG_USER_FAILURE: return {
            ...state,
            addOrgUserErrorMessage: action.data.errorMessage,
            addOrgUserValidationError: action.data.validationError,
            addOrgUserLoading: false,
            addOrgUserSuccess: false,
            addOrgUserFailure: true,
            failedOrgUserData: action.data.failedOrgUserData,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.EDIT_ORG_USER: return {
            ...state,
            editOrgUserErrorMessage: '',
            editOrgUserValidationError: false,
            editOrgUserLoading: true,
            editOrgUserSuccess: false,
            editOrgUserFailure: false,
            failedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.EDIT_ORG_USER_SUCCESS: return {
            ...state,
            editOrgUserErrorMessage: '',
            editOrgUserValidationError: false,
            editOrgUserLoading: false,
            editOrgUserSuccess: true,
            editOrgUserFailure: false,
            failedOrgUserData: {},
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.EDIT_ORG_USER_FAILURE: return {
            ...state,
            editOrgUserErrorMessage: action.data.errorMessage,
            editOrgUserValidationError: action.data.validationError,
            editOrgUserLoading: false,
            editOrgUserSuccess: false,
            editOrgUserFailure: true,
            failedOrgUserData: action.data.failedOrgUserData,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.REMOVE_ORG_USER: return {
            ...state,
            removeOrgUserErrorMessage: '',
            removeOrgUserValidationError: false,
            removeOrgUserLoading: true,
            removeOrgUserSuccess: false,
            removeOrgUserFailure: false
        };
        case OrgUserManagementActionTypes.REMOVE_ORG_USER_SUCCESS: return {
            ...state,
            removeOrgUserErrorMessage: '',
            removeOrgUserValidationError: false,
            removeOrgUserLoading: false,
            removeOrgUserSuccess: true,
            removeOrgUserFailure: false,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.REMOVE_ORG_USER_FAILURE: return {
            ...state,
            removeOrgUserErrorMessage: action.data.errorMessage,
            removeOrgUserValidationError: action.data.validationError,
            removeOrgUserLoading: false,
            removeOrgUserSuccess: false,
            removeOrgUserFailure: true,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD: return {
            ...state,
            sendTemporaryPasswordErrorMessage: '',
            sendTemporaryPasswordValidationError: false,
            sendTemporaryPasswordLoading: true,
            sendTemporaryPasswordSuccess: false,
            sendTemporaryPasswordFailure: false
        };
        case OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_SUCCESS: return {
            ...state,
            sendTemporaryPasswordErrorMessage: '',
            sendTemporaryPasswordValidationError: false,
            sendTemporaryPasswordLoading: false,
            sendTemporaryPasswordSuccess: true,
            sendTemporaryPasswordFailure: false,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_FAILURE: return {
            ...state,
            sendTemporaryPasswordErrorMessage: action.data.errorMessage,
            sendTemporaryPasswordValidationError: action.data.validationError,
            sendTemporaryPasswordLoading: false,
            sendTemporaryPasswordSuccess: false,
            sendTemporaryPasswordFailure: true,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER: return {
            ...state,
            setAsPrimaryOrgUserErrorMessage: '',
            setAsPrimaryOrgUserValidationError: false,
            setAsPrimaryOrgUserLoading: true,
            setAsPrimaryOrgUserSuccess: false,
            setAsPrimaryOrgUserFailure: false
        };
        case OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_SUCCESS: return {
            ...state,
            setAsPrimaryOrgUserErrorMessage: '',
            setAsPrimaryOrgUserValidationError: false,
            setAsPrimaryOrgUserLoading: false,
            setAsPrimaryOrgUserSuccess: true,
            setAsPrimaryOrgUserFailure: false,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_FAILURE: return {
            ...state,
            setAsPrimaryOrgUserErrorMessage: action.data.errorMessage,
            setAsPrimaryOrgUserValidationError: action.data.validationError,
            setAsPrimaryOrgUserLoading: false,
            setAsPrimaryOrgUserSuccess: false,
            setAsPrimaryOrgUserFailure: true,
            selectedOrgUserData: {}
        };
        case OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN: return {
            ...state,
            firstTimeLogIn:false
        };
        case OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN_SUCCESSFUL: return {
            ...state,
            firstTimeLogInSuccess:action.data
        };
        case OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD: return {
            ...state,
            savePasswordLoading:true,
            savePasswordSuccessful:false,
            savePasswordFailure:false
        };
        case OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_SUCCESSFUL: return {
            ...state,
            savePasswordLoading:false,
            savePasswordSuccessful:true,
            savePasswordFailure:false
        };
        case OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_FAILURE: return {
            ...state,
            savePasswordLoading:false,
            savePasswordSuccessful:false,
            savePasswordFailure:true
        };
        default: return state;
    }
}
