import { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import { Badge } from '@material-ui/core';
import NotificationPanel from './NotificationSection';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import UserContext from '../../store/user-context';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponentFitDiv';

export const NotificationIcon = () => {
    const userContext = useContext(UserContext);
    const mutation = useMutation(() => service.fetchMessages());
    return <>
    {userContext.isLoggedIn && <NotificationPanelSection mutation={mutation} />}
    {!userContext.isLoggedIn && <LoaderComponent />}
    </>
}

const service: Service = SpaceDonationService;

export const NotificationPanelSection = (props: any) => {
    const closedIds = JSON.parse(localStorage.getItem('closedMessageIds') + '');
    const [closedMessageIds, setClosedMessageIds] = useState(closedIds);
    const addToClosedMessageIds = (id: any) => {
        let ids = JSON.parse(localStorage.getItem('closedMessageIds') + '');
        if (ids) {
            ids.push(id);
        } else {
            ids = [id];
        }
        localStorage.setItem('closedMessageIds', JSON.stringify(ids));
        setClosedMessageIds(ids);
    }
    useEffect(() => {
        props?.mutation.mutate();
        const interval = setInterval(() => {
            props?.mutation?.mutate();
        }, 300000);
        return () => clearInterval(interval);
    }, []);
    const [lastUpdatedDate, setLastUpdatedDate] = useState(new Date());
    useEffect(() => {
        setLastUpdatedDate(new Date());
    }, [props?.mutation.isSuccess]);
    const [showPanel, setShowPanel] = useState(false);
    const messageData = props?.mutation?.data && props?.mutation?.data?.length>0? props?.mutation?.data :[];
    return <>
        <IconButton
            aria-haspopup='true'
            aria-owns='material-appbar'
            color='inherit'
            data-testid='show-panel'
            onClick={() => setShowPanel(true)}
        >
            <Badge badgeContent={messageData
                ?.filter((z: any) => closedMessageIds ? !closedMessageIds.includes(z.id) : true).length > 0 ?
                messageData?.filter((z: any) => closedMessageIds ? !closedMessageIds.includes(z.id) : true).length : 0}
                color='secondary'>
                <NotificationsIcon />
            </Badge>
        </IconButton>
        {showPanel && <NotificationPanel setShowPanel={setShowPanel}
            addToClosedMessageIds={addToClosedMessageIds}
            data={messageData?.filter((z: any) => closedMessageIds ? !closedMessageIds.includes(z.id) : true)
                .sort((a: any, b: any) => b.epochStartDate - a.epochStartDate)}
            lastUpdatedDate={lastUpdatedDate}
            count={messageData?.filter((z: any) => closedMessageIds ? !closedMessageIds.includes(z.id) : true)?.length} />}
    </>
}
