const LDCardItem = (props:any) => {
  const { className, style, ...rest } = props;
  const classes = `ld-card-item ${className}`;
  return <div className={classes} style={{ padding: '0.75rem', ...style }}  {...rest} />
}

LDCardItem.defaultProps = {
  className: '',
  style: {}
}

export default LDCardItem;
