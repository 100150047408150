import React from 'react';
import withRouter from 'src/polyfils/customRouter';

class Faq extends React.Component {
    public componentDidMount(){
        window.open(`https://www.walmart.org/how-we-give/faqs`);
        // @ts-ignore
        this.props.history.goBack();
    }
    public render(){
        return <div>downloading....</div>
    }
}

// @ts-ignore
export default withRouter(Faq);