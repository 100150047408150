import * as React from 'react';
import './style.css';
class FooterComponentSpace extends React.PureComponent {
    public render() {
        return (<>
            <div className='app-footer-space'>
                <div className='links-wrapper-footer'>
                    <a target='_blank'
                        rel='noreferrer'
                        // tslint:disable-next-line:max-line-length
                        href='https://walmart.org/media-library/document/notice-of-solicitation-and-distribution-of-literature-rules-national/_proxyDocument?id=0000017e-bc41-d7ab-ab7f-ff7fceb00000'>
                        Terms of Use and Notice of Solicitation and Distribution of Literature Rules - National </a>
                </div>
                {/*<p>© Walmart Stores, Inc.</p>*/}
            </div>
        </>);
    }
}

export default FooterComponentSpace;
