import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    Theme,
    FormControl,
    Select,
    MenuItem,
    FormLabel
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrefLangProps } from './../../../types/formComponentTypes';
import { AppendixType, DownloadAppendixProps } from './../../../types/downloadPdfTypes';
import { PrefLangSelectComponent } from './../formComponents/PrefLangSelectComponent';
import { DONATION_TYPE_VALUES } from '../../../constants';
import KeyValueDropdown from '../../common/Dropdown/keyValueDropdown';
import { getListOfAppendixCDates } from '../../../service/apiService';
import { getFormattedAppendixCDates } from '../../../components/donations/donationOfGoods/helper';

const inputTextColor = 'var(--inputTextColor)';
const fontFamily = ' var(--appFont)';
const useStyles = makeStyles((_theme: Theme) => ({
    selectRoot: {
        color: inputTextColor,
        fontFamily
    },
    selectFormControl: {
        color: 'rgba(0, 0, 0, 0.54) !important',
        minWidth: '300px',
        fontFamily,
        backgroundColor: '#fff',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontSize: '14px !important',
    },
    selectLabel: {
        color: 'rgba(0, 0, 0, 0.54)  !important',
        fontFamily,
        marginLeft: '10px',
        backgroundColor: '#fff',
        fontSize: '14px !important',
    },
    selectIcon: {
        color: 'rgba(0, 0, 0, 0.54)  !important'
    },
    menuItemRoot: {
        color: 'rgba(0, 0, 0, 0.54) ',
        fontFamily,
        minWidth: '250px',
    },
    menuItemselected: {
        color: 'rgba(0, 0, 0, 0.54) '
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    formContainer: {
        padding: '10px 20px 0px 20px;',
        width: '650px',
    }
}));


const DownloadAppendixModal: React.FC<DownloadAppendixProps> = (props: DownloadAppendixProps) => {

    const classes = useStyles()
    const { t } = useTranslation()
    const [appendixCDateList, setAppendixCDateList] = useState<any[]>([])
    const [appendixKeyText, setAppendixKeyText] = useState<string | undefined>(props?.appendixKeyText)
    const [noAppendixcFound, setNoAppendixcFound] = useState<boolean>(false)
    const [disableSubmitOnAppendixc, setDisableSubmitOnAppendixc] = useState<boolean>(false);
    const { donationType, quartersList } = props
    const {
        handleSubmit,
        control,
        errors,
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            langCode: '',
            quarterDateRange: '',
            appendixType: props.appendixType
        }
    })

    const preLangProps: PrefLangProps = {
        countryCode: props.countryCode,
        fieldName: 'langCode',
        required: true,
        classes,
        control,
        formErrors: errors,
        rules: {
            required: t('common.validationMsg.requiredField') as string
        },
        translationKey: 'ddQModalMsg.languageDropdown',
        defaultValue: ''
    }

    const handleOnchange = (data: any) => {
        setValue('quarterDateRange', data)
        setNoAppendixcFound(false)
        setDisableSubmitOnAppendixc(true);
        getListOfAppendixCDates({
            startDate: data?.startDate,
            endDate: data?.endDate,
            donationId: props?.donationDetails?.donation?.id
        }).then((res: any) => {
            if (res?.length < 1) {
                setNoAppendixcFound(true)
                setDisableSubmitOnAppendixc(true);
            } else {
                setDisableSubmitOnAppendixc(false);
            }
            setAppendixCDateList(getFormattedAppendixCDates(res))
        }).catch((_error: any) => {
            setNoAppendixcFound(true)
            setDisableSubmitOnAppendixc(true);
        })
    }

    const handleOnClose = () => {
        setAppendixKeyText('')
        setAppendixCDateList([])
        setNoAppendixcFound(false)
        props.closeDailog()
    }

    /* istanbul ignore next */
    const submit = (data: any) => {
        setAppendixKeyText('')
        setAppendixCDateList([])
        props.onDownload({ ...data, donation: { ...props?.donationDetails } })
    }
    return (<Dialog
        fullWidth={true}
        maxWidth={'sm'}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        open={props.showDialog}
        onClose={handleOnClose}
        aria-labelledby='ddq-dialog'
        aria-describedby='ddq-dialog'>
        <DialogTitle id='ddq-dialog'>
            <div>
                <div style={{ textAlign: 'left', width: '50%', float: 'left' }}>
                    <b>{t('downloadAppendixType.title')}</b>
                </div>
                <div style={{ textAlign: 'right', width: '50%', float: 'right' }}>
                    <IconButton size='small' onClick={handleOnClose} style={{ color: 'black' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </DialogTitle>
        <DialogContent>
            {noAppendixcFound && <div className='row' style={{ marginBottom: '20px', textAlign: 'center' }}>
                <div className='col-md-12 col-xs-12 col-lg-12'>
                    <Alert severity='error'>
                        {t('appendices.c.download.noAppendixcFound')}
                    </Alert></div>
            </div>
            }
            <div className={`org-user-form-container`}>
                {props.errorMessage && <div style={{ marginBottom: '20px' }}>
                    <Alert severity='error'>
                        {props.errorMessage}
                    </Alert></div>}
                {props.errorMessage === '' &&
                    <form onSubmit={handleSubmit(submit)} id={'org-user-form'}
                        noValidate={true} autoComplete='off' style={{ width: '60%' }}>
                        <div>
                            <FormControl
                                required={true}
                                style={{ width: '100%', color: 'black', backgroundColor: 'white', marginTop: '25px' }}
                                classes={{ root: classes.selectFormControl }}
                                size='medium' variant='outlined'
                                error={errors.appendixType?.message ? true : false}>
                                <FormLabel classes={{ root: classes.selectLabel }}
                                    id='appendixType-selecct-input'
                                >
                                    {props.title || t('downloadAppendixType.appendixTypeDropDownLabel')}
                                </FormLabel>
                                <Controller
                                    control={control}
                                    name='appendixType'
                                    defaultValue={''}
                                    render={(feild: any) => (
                                        <Select
                                            data-testid={'appendixType'}
                                            style={{ color: 'black', backgroundColor: 'white' }}
                                            classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                            labelId='appendixType-select-label'
                                            id='appendixType'
                                            name='appendixType'
                                            autoWidth={false}
                                            defaultValue={''}
                                            onBlur={feild.onBlur}
                                            value={feild.value}
                                            displayEmpty={true}
                                            inputRef={feild.ref}
                                            onChange={
                                                (_event: any) => {
                                                    setNoAppendixcFound(false)
                                                    setDisableSubmitOnAppendixc(false);
                                                    setAppendixKeyText(_event?.target?.value)
                                                    setAppendixCDateList([])
                                                    feild.onChange(_event?.target?.value)
                                                }
                                            }>
                                            {props.appendixTypeList?.map((item: AppendixType) => {
                                                return (<MenuItem
                                                    classes={{
                                                        root: classes.menuItemRoot,
                                                        selected: classes.menuItemselected
                                                    }}
                                                    key={item.keyText} value={item.keyText}>{item?.language?.[props.selectedLang]}
                                                </MenuItem>
                                                )
                                            })}

                                        </Select>
                                    )}
                                    rules={{
                                        required: t('common.validationMsg.requiredField') as string
                                    }}
                                />
                                {errors.appendixType?.message && <span className='form-error-container'
                                    style={{ color: 'red', fontWeight: 'normal' }}>{errors.appendixType?.message}</span>}
                            </FormControl>
                        </div>
                        {donationType === DONATION_TYPE_VALUES.GOODSANDFOOD &&
                            // @ts-gnore
                            appendixKeyText === 'appendixc' &&
                            <div style={{ marginBottom: '30px' }}>
                                <KeyValueDropdown
                                    data-testid={'quarterDateRange'}
                                    required={true}
                                    inputLabel={t('appendices.c.download.selectQuarter')}
                                    control={control}
                                    errors={errors}
                                    fieldName={'quarterDateRange'}
                                    // @ts-ignore
                                    options={quartersList}
                                    errorKeyName={'quarterDateRange'}
                                    onChange={handleOnchange}
                                    rules={{
                                        required: t('common.validationMsg.requiredField') as string
                                    }}
                                    defaultValue={''} />
                            </div>
                        }
                        {donationType === DONATION_TYPE_VALUES.GOODSANDFOOD &&
                            // @ts-gnore
                            appendixKeyText === 'appendixc' && appendixCDateList?.length > 0 &&
                            <div style={{ marginBottom: '30px' }}>
                                <KeyValueDropdown
                                    data-testid={'appendixcDate'}
                                    required={true}
                                    inputLabel={t('appendices.c.download.dateOfAppenidxC')}
                                    control={control}
                                    errors={errors}
                                    fieldName={'appendixcDate'}
                                    // @ts-ignore
                                    options={appendixCDateList}
                                    errorKeyName={'appendixcDate'}
                                    rules={{
                                        required: t('common.validationMsg.requiredField') as string
                                    }}
                                    defaultValue={''} />
                            </div>
                        }
                        <PrefLangSelectComponent {...{ ...preLangProps, setValue }} />
                        <div style={{ marginBottom: '30px' }}>
                            <div className='org-user-btn-group'>
                                <div className='org-user-btn-group'>
                                    <div className='inline-flex-container'
                                        style={{ marginTop: '-8px', alignItems: 'center' }}>
                                        <button className={`sip-submit-btn ${noAppendixcFound || disableSubmitOnAppendixc ? 'disabled' : ''}`}
                                            disabled={noAppendixcFound || disableSubmitOnAppendixc}
                                            type='submit' data-testid='submit-btn'>
                                            {t('common.buttonLabels.download')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </DialogContent>
    </Dialog>)
}

export default React.memo(DownloadAppendixModal)
