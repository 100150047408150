import { Bell, Car, CheckCircle, ExclamationCircle, History, Warning,ExclamationCircleFill } from "@livingdesign/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CardOutline } from "src/components/common/CardOutline";
import { DONATION_TYPE_VALUES } from "src/constants";
import { isGenericType } from "src/service/routeConstants";
import { SpaceAlternateProposalDetails } from "../../gdmsSpaceDonation/SpaceInternationalAlternateProposal";


export interface FormProps {
  setIsWorkflowOpen?: any;
  data?: any;
  status?: any;
  donationType?: DONATION_TYPE_VALUES;
}

export interface CardProps {
  HeaderIcon?: any;
  headerContent: any;
  bodyContent?: any;
  openWorkflow?: any;
  bodyTrans?: any;
}
export interface LocProps {
  data?: any;
}

const getCancelledAndRejectedLabels = (donationType: any, status: string) => {
  let statusHeader = "emergencyDonations.cards.rejectedHead";
  let statusBody = "emergencyDonations.cards.rejectedBody";
  if (status === "CANCELLED" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL) {
    statusHeader = "spaceDonations.cards.cancelledHead";
    statusBody = "spaceDonations.cards.cancelledBody";
  }
  if (status === "REJECTED" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL) {
    statusHeader = "spaceDonations.cards.rejectedHead";
    statusBody = "spaceDonations.cards.rejectedBody";
  }

  if (status === "CANCELLED" && donationType === DONATION_TYPE_VALUES.SERVICE_DONATION) {
    statusHeader = "serviceDonations.cards.cancelledHead";
    statusBody = "serviceDonations.cards.cancelledBody";
  }

  return {
    statusHeader: statusHeader,
    statusBody: statusBody
  }
}

const getAwaitingRDALabels = (donationType: any) => {
  let statusHeader = "spaceDonations.cards.awaitingRdaHead";
  let statusBody = "spaceDonations.cards.awaitingRdaBody";


  if (donationType === DONATION_TYPE_VALUES.SERVICE_DONATION) {
    statusHeader = "serviceDonations.cards.awaitingRdaHead";
    statusBody = "serviceDonations.cards.awaitingRdaBody";
  }

  return {
    statusHeader: statusHeader,
    statusBody: statusBody
  }
}

const getCompletedLabels = (donationType: any) => {
  let statusHeader = "emergencyDonations.cards.completedHead";
  let statusBody = "emergencyDonations.cards.completedBody";
  if (donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL) {
    statusHeader = "spaceDonations.cards.completedHead";
    statusBody = "spaceDonations.cards.completedBody";
  }

  return {
    statusHeader: statusHeader,
    statusBody: statusBody
  }
}

const GetLocation: FC<LocProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <CardOutline
      HeaderIcon={<Car size='medium' style={{ marginRight: "10px" }} />}
      headerContent={"emergencyDonations.cards.pickup"}
      bodyContent={
        <>
          <b>{`${t("emergencyDonations.cards.location")}:`}</b>
          <br />
          <div style={{ paddingLeft: "8px" }}>
            {data.donationDetails?.pickUpAddress ? (
              <>
                <b>{t("emergencyDonations.cards.facility")}</b>:{" "}
                {`${data.donationDetails?.dcNumber ??
                  data.donationDetails?.storeNumber ?? "-"
                  }`}
                <br />
                <b>{t("emergencyDonations.cards.facilityType")}</b>:{" "}
                {data.donationDetails?.pickUpAddress?.facilityType}
                <br />
                <b>{t("orgRegForm.labels.streetAddr")}</b>:{" "}
                {data.donationDetails?.pickUpAddress?.streetAddress}
                <br />
                <b>
                  {t("monetaryDonation.organizationDetailsSection.city")}
                </b>: {data.donationDetails?.pickUpAddress?.city}
                <br />
                <b>{t("orgRegForm.labels.postalCode")}</b>:{" "}
                {data.donationDetails?.pickUpAddress?.postalCode}
                <br />
              </>
            ) : "-"}
          </div>
          <br />
          <b>{`${t("commonDashboardLabels.columns.projectTitle")}:`}</b>{" "}
          {data.donationDetails?.donationTitle ?? "-"}
        </>
      }
    />
  );
};
const StatusCards: FC<FormProps> = ({ setIsWorkflowOpen, status, data, donationType }) => {
  const openWorkflow = () => {
    setIsWorkflowOpen(true);
  };

  let statusLabels = getCancelledAndRejectedLabels(donationType, status);
  let completedStatusLabels = getCompletedLabels(donationType);
  let awaitingRDALabels = getAwaitingRDALabels(donationType);

  return (
    <>
      {/* UNDER CA REVIEW CARDS */}
      {status === "UNDER_RDA_REVIEW" && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<Bell size='medium' style={{ marginRight: "10px" }} />}
            headerContent={"emergencyDonations.cards.underRdaStatus"}
            bodyTrans={"emergencyDonations.cards.underRdaBody"}
          />
          {isGenericType(donationType) ? "" : <GetLocation data={data} />}
        </>
      )}


      {status === "APPROVED" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<CheckCircle size='medium' style={{ marginRight: "10px" }} />}
            headerContent={"spaceDonations.cards.approved"}
            // bodyTrans={"spaceDonations.cards.approvedBody"}
            // bodyContent={data.comments || null}

            bodyTrans={
              data.comments && data.comments !== "" ? null : "spaceDonations.cards.approvedBody"
            }
            bodyContent={data.comments || null}
          />
          {isGenericType(donationType) ? "" : <GetLocation data={data} />}
        </>
      )}


      {status === "APPROVED" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<CheckCircle size='medium' style={{ marginRight: "10px" }} />}
            headerContent={"spaceDonations.cards.approved"}
            // bodyTrans={"spaceDonations.cards.approvedBody"}
            // bodyContent={data.comments || null}

            bodyTrans={
              data.comments && data.comments !== "" ? null : "spaceDonations.cards.approvedBody"
            }
            bodyContent={data.comments || null}
          />
        </>
      )}

      {status === "PENDING_APPROVAL" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<ExclamationCircle size="medium" style={{ marginRight: "10px" }} />}
            headerContent={"spaceDonations.cards.pendingApproval"}
            bodyTrans={"spaceDonations.cards.pendingApprovalBody"}
          >
            <SpaceAlternateProposalDetails data={data} />
          </CardOutline>
        </>
      )}

      {status === "PENDING_APPROVAL" && donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<ExclamationCircle size="medium" style={{ marginRight: "10px" }} />}
            headerContent={"spaceDonations.cards.pendingApproval"}
            bodyTrans={"spaceDonations.cards.pendingApprovalBody"}
          >
            <SpaceAlternateProposalDetails data={data} />
          </CardOutline>
        </>
      )}


      {status === "CANCELLATION_DUE_TO_INACTIVITY" && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<ExclamationCircleFill size="medium" style={{ marginRight: "10px" }} />}
            headerContent={"spaceDonations.cards.inactivityCancelation"}
            bodyTrans={"spaceDonations.cards.inactivityCancelationBody"}
          />
        </>
      )}
      
      {status === "COMPLETED" && (
        <>
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<Bell size='medium' style={{ marginRight: "10px" }} />}
            headerContent={completedStatusLabels.statusHeader}
            bodyTrans={
              data.comments && data.comments !== "" ? null : completedStatusLabels.statusBody
            }
            bodyContent={data.comments || null}
          />

          {donationType !== DONATION_TYPE_VALUES.SPACE_INTERNATIONAL ? <GetLocation data={data} /> : ""}
        </>
      )}
      {status === "WAITING_FOR_COLLECTION_FROM_STORE" && (
        <>
          <CardOutline
            HeaderIcon={
              <History size='medium' style={{ marginRight: "10px" }} />
            }
            headerContent={"emergencyDonations.cards.nextSteps"}
            bodyTrans={"emergencyDonations.cards.waitingForCollectionNext"}
          />
          <GetLocation data={data} />
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<Bell size='medium' style={{ marginRight: "10px" }} />}
            headerContent={
              "emergencyDonations.cards.waitingForCollectionStatus"
            }
            bodyTrans={
              "emergencyDonations.cards.waitingForCollectionStatusBody"
            }
          />
        </>
      )}
      {status === "WAITING_FOR_APPENDIX_H" && (
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={<Bell size='medium' style={{ marginRight: "10px" }} />}
            headerContent={"emergencyDonations.cards.appendixHHead"}
            bodyTrans={data.comments && data.comments !== "" ? null : "emergencyDonations.cards.appendixHBody"}
            bodyContent={data.comments || null}

          />
      )}
      {(status === "REJECTED" || status === "CANCELLED") && (
          <CardOutline
            openWorkflow={openWorkflow}
            HeaderIcon={
              <Warning size='medium' style={{ marginRight: "10px" }} />
            }

            headerContent={statusLabels.statusHeader}
            bodyTrans={data.comments && data.comments !== "" ? null : statusLabels.statusBody}
            bodyContent={data.comments || null}

          />
      )}
      {status === "AWAITING_RDA_ACKNOWLEDGEMENT" &&
        (donationType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL || donationType === DONATION_TYPE_VALUES.SERVICE_DONATION) && (
            <CardOutline
              HeaderIcon={<ExclamationCircle size="medium" style={{ marginRight: "10px" }} />}
              headerContent={awaitingRDALabels.statusHeader}
              bodyTrans={data.comments && data.comments !== "" ? null : awaitingRDALabels.statusBody}
              bodyContent={data.comments || null}
            />
        )}
    </>
  );
};

export default StatusCards;
