import { AppLogoActions, AppLogoActionTypes } from './AppLogoActions';
import { AppLogoState } from '../../../models/globalDonationState';

export const defaultAppLogoState: AppLogoState = {
    show: true
};

export const appLogoReducer = (state: AppLogoState = defaultAppLogoState, action: AppLogoActions):
AppLogoState => {
    switch (action.type) {
        case AppLogoActionTypes.SHOW_APPLOGO: return {
            show:true
        };
        case AppLogoActionTypes.HIDE_APPLOGO: return {
            show:false
        };
        default: return state;
    }
}