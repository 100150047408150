import CustomDateComponent from './formio/CustomDateComponent';
import FacilitySearchComponent from './formio/FacilitySearchComponent';
import CustomAttachFiles from './formio/CustomAttachFiles';
import CustomAttachFilesView from './formio/CustomAttachFilesView';

export default {
    customDateComponent: CustomDateComponent,
    facilitySearchComponent: FacilitySearchComponent,
    attachFiles: CustomAttachFiles,
    attachFilesView:CustomAttachFilesView
}