import { useQuery } from 'react-query';
import { reactQueryConfigOptions } from '../../../constants';
import { getDonationsData, getOneTimeFoodDonations, getOneTimeGoodsDonations, getDonationsForDashboard } from '../../../service/apiService';
import { isGenericType } from '../../../service/routeConstants';

export const useDashboardData = (payload: any) => {
  const donationCb: {[key: string]: any} = {
    DONATION_OF_MONEY: getDonationsData,
    DONATION_OF_GOODS_RECURRING: getDonationsData,
    FOOD_ONE_TIME: getOneTimeFoodDonations,
    DONATION_OF_GOODS_ONE_TIME: getOneTimeGoodsDonations,
    DEFAULT_DONATION: getDonationsForDashboard
  }

  const callBackType = isGenericType(payload.type) ? 'DEFAULT_DONATION': payload.type;
  const {
      isLoading,
      isSuccess,
      error,
      isError,
      isFetching,
      data
  } = useQuery([payload.type, { ...payload, pageNumber: payload?.page }], donationCb[callBackType],
      { ...reactQueryConfigOptions, keepPreviousData: true, cacheTime: 0 });

  return { data, isLoading, isSuccess, isError, isFetching, error };
}