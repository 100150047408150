import { DonationApprovalActions, DonationApprovalActionTypes } from './donationOfApprovalActions';
import { DonationApprovalState } from '../../models/donationApprovalModel';

export const defaultDonationApprovalState: DonationApprovalState = {
    approvalData: [],
    approvalLoading: false,
    approvalFailedDetail: '',
    approvalSuccessDetail: '',
    approvalFailure: false,
    approvalSuccess: false,
    openApprovalRespDailog:false,
    closeApprovalRespDailog:false
};

export const donationApprovalReducer = (state: DonationApprovalState = defaultDonationApprovalState, action: DonationApprovalActions):
    DonationApprovalState => {
    switch (action.type) {
        case DonationApprovalActionTypes.DONATION_APPROVAL_LOAD: return {
            ...state,
            approvalLoading: true,
            approvalFailedDetail: '',
            approvalSuccessDetail: '',
            approvalFailure:false,
            approvalSuccess:false
        };
        case DonationApprovalActionTypes.DONATION_APPROVAL_SUCCESS: return {
            ...state,
            approvalData: action.data,
            approvalLoading: false,
            approvalFailedDetail: '',
            approvalSuccessDetail: action.data,
            approvalFailure: false,
            approvalSuccess: true
        };
        case DonationApprovalActionTypes.DONATION_APPROVAL_FAILURE: return {
            ...state,
            approvalLoading: false,
            approvalFailedDetail: action.data,
            approvalSuccessDetail:'',
            approvalFailure: true,
            approvalSuccess: false
        };
        case DonationApprovalActionTypes.DONATION_APPROVAL_FAILURE: return {
            ...state,
            approvalLoading: false,
            approvalFailedDetail: action.data,
            approvalSuccessDetail: '',
            approvalFailure: true,
            approvalSuccess: false
        };
        case DonationApprovalActionTypes.OPEN_APPROVAL_RESP_DAILOG: return {
            ...state,
            openApprovalRespDailog: true
        };
        case DonationApprovalActionTypes.CLOSE_APPROVAL_RESP_DAILOG: return {
            ...state,
            openApprovalRespDailog: false
        };
        default: return state;
    }
}