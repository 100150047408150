import { Component } from 'react';
import { Table, Button, TableContainer, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import '../../../styles/formStyles.css';
import { uploadFileSize } from './../../../constants';

export interface OwnProps {
    component: any;
    value: any;
    selectedLang?: any;
    onChange?: (param: any, data: any) => void;
}
const appi18n: any = {
    files: {
        en: 'files',
        es: 'archivos',
        fr: 'des dossiers',
        ja: 'ファイル',
        zh: '檔案'
    },
    chooseFile: {
        en: 'Choose Files',
        es: 'Elija el archivos',
        fr: 'Choisir des fichiers',
        ja: 'ファイルを選択',
        zh: '選擇文件'
    },
    noFileSelected: {
        en: 'No File Chosen',
        es: 'Ningún archivo elegido',
        fr: 'Aucun fichier choisi',
        ja: 'ファイルが選択されていません',
        zh: '沒有選中任何文件'
    },
    maxSize: {
        en: 'Files which were chosen currently have exceeded the maximum size. Please choose files whose aggregate size does not exceed the maximum size',
        es: 'Los archivos que se eligieron actualmente han superado el tamaño máximo. Elija archivos cuyo tamaño total no exceda el tamaño máximo',
        fr: 'Les fichiers actuellement choisis ont dépassé la taille maximale. Choisissez des fichiers dont la taille totale ne dépasse pas la taille maximale',
        ja: '現在選択されているファイルが最大サイズを超えています。集計サイズが最大サイズを超えないファイルを選択してください',
        zh: '當前選擇的文件已超過最大大小。請選擇總大小不超過最大大小的文件'
    },
    deleteFile: {
        en: 'Delete',
        es: 'Eliminar',
        fr: 'Supprimer',
        ja: 'デリート',
        zh: '刪除'
    }
}

class AttachFiles extends Component<OwnProps> {

    public state = {
        value: '',
        files: [],
        maxFileSizeExceeded: false
    }
    constructor(props: any) {
        super(props);
        this.state = {
            value: props.value,
            files: [],
            maxFileSizeExceeded: false
        }
    }
    public handleChange = (event: any) => {
        const uploadedFiles = [...event.target.files, ...this.state.files];
        let aggregateSize = 0;
        uploadedFiles.forEach((attachment: any) => {
            aggregateSize = aggregateSize + attachment.size;
        });
        if (aggregateSize > uploadFileSize) {
            const fileInput = document.getElementById(this.props.component.key);
            // @ts-ignore
            fileInput.value = fileInput.defaultValue;
            this.setState({
                maxFileSizeExceeded: true
            });
        }
        else {
            this.setState({
                value: event.target.value,
                files: uploadedFiles,
                maxFileSizeExceeded: false
                // @ts-ignore
            }, () => {
                // @ts-ignore
                window[this.props.component.key] = Object.values(this.state.files);
                // @ts-ignore
                this.props.onChange({ files: this.state.files }, null);
            });
            const element = document.getElementById('selected' + this.props.component.key);
            if (element) {
                if (uploadedFiles.length > 1) {
                    element.innerHTML = uploadedFiles.length + ' ' + appi18n.files[this.props.selectedLang];
                } else {
                    element.innerHTML = uploadedFiles && uploadedFiles[0] && uploadedFiles[0].name ? uploadedFiles[0].name : '';
                }
            }
        }
    }
    private browseFile = () => {
        const element = document.getElementById(this.props.component.key);
        if (element) {
            element.click();
        }
    }
    private removeFile = (index: number) => {
        const uploadedFiles = this.state.files;
        uploadedFiles.splice(index, 1);
        this.setState({
            files: uploadedFiles,
            maxFileSizeExceeded: false
            // @ts-ignore
        }, () => {
            // @ts-ignore
            window[this.props.component.key] = Object.values(this.state.files);
            // @ts-ignore
            this.props.onChange({ files: this.state.files }, null);
        });
        const element = document.getElementById('selected' + this.props.component.key);
        if (element) {
            if (uploadedFiles.length > 1) {
                element.innerHTML = uploadedFiles.length + ' ' + appi18n.files[this.props.selectedLang];
            } else {
                // @ts-ignore
                element.innerHTML = uploadedFiles && uploadedFiles[0] && uploadedFiles[0].name ? uploadedFiles[0].name : '';
            }
        }
    }
    public render() {
        return (
            <div className='row'>
                <div className='col-md-12'>
                    {this.state.maxFileSizeExceeded
                        ? (<div style={{ color: 'red' }}>
                            {appi18n.maxSize[this.props.selectedLang]}
                        </div>) : (<div />)}
                    <div className='form-group'>
                        <button style={{
                            border: '1px solid rgb(73, 80, 87)',
                            fontSize: '15px'
                        }} onClick={this.browseFile}>{appi18n.chooseFile[this.props.selectedLang]}</button>
                        <input type='file' multiple={true} className='form-control'
                            name={this.props.component.key}
                            hidden={true}
                            id={this.props.component.key}
                            onChange={this.handleChange} />
                        <label style={{ margin: '10px' }} id={'selected' + this.props.component.key}>
                            {appi18n.noFileSelected[this.props.selectedLang]}</label>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 fileupload-section'>
                            <TableContainer component={Paper}>
                                <Table size='small' aria-label='a dense table'>
                                    <TableBody>
                                        {Array.from(this.state.files).map((file, index) => {
                                            const name = 'name';
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell align='left'>
                                                        <a href={URL.createObjectURL(file)} target='_blank'>{file[name]}</a>
                                                    </TableCell>
                                                    <TableCell style={{ borderBottom: 'none' }} align='left'>
                                                        <Button className='delete-btn-file-upload' size='small' variant='contained'
                                                            onClick={() => { this.removeFile(index) }}>
                                                            {appi18n.deleteFile[this.props.selectedLang]}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AttachFiles;