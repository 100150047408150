import {Box, FormControl, InputLabel, makeStyles, MenuItem, Select} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import { IDropdownOptions } from 'src/components/common/Dropdown/keyValueDropdown';
import {ErrorMessageComponent} from 'src/components/common/formComponents/ErrorMessage';
import {resolvePath} from 'src/components/common/utils';
import {RadioOption} from '../organizationDetails/orgDetailsDefaults';


const useStyles = makeStyles({
    selectRoot: {
        color: '#181818 !important'
    },
    selectIcon:  {
        color: '#181818 !important'
    },
    selectRootLabel : {
        top: '-8px',
        left: '15px'
    }
});

export interface IMXLanguage {
    en: string,
    es: string
    [key: string]: string
}
export interface IMDDropdownOption {
    keyText: string,
    language: IMXLanguage,
    active: boolean,
    audit: any
}
export interface IMDGroupDropdownOption {
    type: IMDDropdownOption,
    subType: IMDDropdownOption[]
}
interface BiLingualDropdownProps {
    id?: string,
    fieldName: string,
    required?: boolean,
    disabled?: boolean,
    control: any,
    errors: any,
    autoWidth?:boolean,
    inputLabel?: string,
    isSecondary?: boolean,
    primaryValue?: string,
    optionKey: string,
    rules?: any,
    errorKeyName: string,
    setValue?: (name: string, value: any) => void
}
export interface IDropdownContext {
    optionsMap: Map<string, IMDDropdownOption[] | IMDGroupDropdownOption[]>;
    languageKey: string;
    stateList: IDropdownOptions[]
}
export const BiLingualDropdownContext = React.createContext<IDropdownContext>({
    languageKey: 'en',
    optionsMap: new Map(),
    stateList: []
});
export enum BiLingualDropdownTypes {
    INCOMES_TYPE = 'INCOMES_TYPE',
    GEOGRAPHIC_AREAS_TYPE = 'GEOGRAPHIC_AREAS_TYPE',
    PROJECT_SCHEDULE_ACTIVITIES = 'PROJECT_SCHEDULE_ACTIVITIES',
    EXPENSES_TYPE = 'EXPENSES_TYPE'
}

const defaultProps: BiLingualDropdownProps = {
    fieldName: '',
    control: {},
    inputLabel: '',
    autoWidth:false,
    optionKey : '',
    errorKeyName: '',
    errors : {}
  }

const BiLingualDropdown = (props: BiLingualDropdownProps = defaultProps) => {
    const classes = useStyles();
    const dropdownContext = useContext(BiLingualDropdownContext);
    const [options, setOptions] = useState<IMDDropdownOption[]>([]);
    const errorKey = props.errorKeyName;
    const fieldError = resolvePath(errorKey, props.errors, '')?.message;

    useEffect(() => {
        if(props.isSecondary) {
            (dropdownContext.optionsMap.get(props.optionKey) as unknown as IMDGroupDropdownOption[])?.forEach(itm => {
                if(itm.type?.keyText === props.primaryValue) {
                    setOptions(itm.subType);
                }
            });
        } else {
            let list;
            if(props.optionKey === BiLingualDropdownTypes.PROJECT_SCHEDULE_ACTIVITIES) {
                list = (dropdownContext.optionsMap.get(props.optionKey) as unknown as IMDGroupDropdownOption[])?.map(itm => {
                    return itm.type;
                });
            } else {
                list = dropdownContext.optionsMap.get(props.optionKey) as unknown as IMDDropdownOption[];
            }
            setOptions(list);
        }
    }, [dropdownContext.optionsMap, props.optionKey, props.isSecondary, props.primaryValue])

    useEffect(() => {
        if(props.setValue && (!options || options.length < 1)) {
            props.setValue(props.fieldName, RadioOption.None)
        }
    },[props.fieldName, options])


    return  <Box data-testid='biLingual-dropdown-container'>

        {options && options.length > 0 && <FormControl fullWidth={true}
                data-testid='biLingual-dropdown-control'
                error={fieldError ? true : false}>
                <InputLabel
                    classes={{root: classes.selectRootLabel}}
                    required = {props.required}
                    id='bilingualdropdown-label'>
                        {props.inputLabel}
                    </InputLabel>
                <Controller
                        control={props.control}
                        name={props.fieldName}
                        rules={{ ...props?.rules }}
                        render = {(field, _state) => <Select
                        classes={{root: classes.selectRoot, icon: classes.selectIcon}}
                        defaultValue = ''
                        labelId='bilingualdropdown-label'
                        id='bilingualdropdown'
                        label={props.inputLabel}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        value={field.value}
                        inputRef={field.ref}
                        name={field.name}
                        displayEmpty={true}
                        variant = 'outlined'
                        required = {props.required ?? false}
                        disabled= {props.disabled ?? false}
                        data-testid='biLingual-select'
                        >
                            {options.map((itm: IMDDropdownOption, index:number) => (
                                <MenuItem
                                key={`dropDownChild-${index}`}
                                value={itm?.keyText}
                                >{itm.language[dropdownContext.languageKey]}</MenuItem>
                            ))}
                        </Select>}
                />
                <ErrorMessageComponent errors={props.errors} fieldName={props.errorKeyName} />
            </FormControl>}
    </Box>
}

export default BiLingualDropdown;