import React, { useState } from 'react';
import './styles.css';

const frownRed = require('src/components/spacedonation/commonComponents/assets/logos/emoji-frown-red.svg');
const frownBlack = require('src/components/spacedonation/commonComponents/assets/logos/emoji-frown-black.svg');
const neutralBlue = require('src/components/spacedonation/commonComponents/assets/logos/emoji-neutral-blue.svg');
const neutralBlack = require('src/components/spacedonation/commonComponents/assets/logos/emoji-neutral-black.svg');
const smileGreen = require('src/components/spacedonation/commonComponents/assets/logos/emoji-smile-green.svg');
const smileBlack = require('src/components/spacedonation/commonComponents/assets/logos/emoji-smile-black.svg');

export interface EmojiRatingProps {
    setRating: any
    rating: any
}

const EmojiRating: React.SFC<EmojiRatingProps> = (props) => {
    const [frownColor, setFrownColor] = useState(props.rating === 1 ? frownRed : frownBlack);
    const [neutralColor, setNeutralColor] = useState(props.rating === 2 ? neutralBlue : neutralBlack);
    const [smileColor, setSmileColor] = useState(props.rating === 3 ? smileGreen : smileBlack);

    return <div className='emoji-rating-container'>
        <div className='row'>
            <img className='emoji-frown col-4' height='70px' width='70px'
                src={frownColor}
                onClick={() => {
                    props.setRating(props.rating === 1 ? 0 : 1);
                    setFrownColor(frownColor === frownBlack ? frownRed : frownBlack);
                    setNeutralColor(neutralBlack);
                    setSmileColor(smileBlack);
                }}
            />
            <img className='emoji-neutral col-4' height='70px' width='70px'
                src={neutralColor}
                onClick={() => {
                    props.setRating(props.rating === 2 ? 0 : 2);
                    setNeutralColor(neutralColor === neutralBlack ? neutralBlue : neutralBlack);
                    setSmileColor(smileBlack);
                    setFrownColor(frownBlack);
                }}
            />
            <img className='emoji-smile col-4' height='70px' width='70px'
                src={smileColor}
                onClick={() => {
                    props.setRating(props.rating === 3 ? 0 : 3);
                    setSmileColor(smileColor === smileBlack ? smileGreen : smileBlack);
                    setNeutralColor(neutralBlack);
                    setFrownColor(frownBlack);
                }} />
        </div>
    </div>
}
export default EmojiRating;