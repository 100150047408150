import { TextField } from "@walmart-web/livingdesign-components";
import { useState } from "react";
import { contextPath } from "../../../constants";
import { defaultButton } from "../../../models/GenericDashBoardModel";
import { getBreadCrumbDashBoardData } from "../CommonComponents/BreadCrumbRouting";
import { createButtons, getFacilityTypes, LocationTypes } from "../commonFunctions";
export const servicePageType = 'service-donation';

export const getCustomFacilityTypes = (t: any) => {
    let facilityTypes = getFacilityTypes(t);
    let customTypes = [{
        label: t("common.location.others"),
        value: LocationTypes.other
    }];
    return [...facilityTypes, ...customTypes];
}

export const getServiceFacilityLabel = (t: any, facilityValue: string) => {
    if (facilityValue && facilityValue !== "NA") {
        let typeObject = getCustomFacilityTypes(t).find((facilty: any) => facilty.value.toLowerCase() === facilityValue.toLowerCase());
        return typeObject?.label;
    }
    return facilityValue;
}

export const discardModal = (t: any, onClose: any, onFormDiscard: any) => {
    return {
        header: t("serviceDonations.form.discardHead"),
        children: t("serviceDonations.form.discardHelper"),
        buttons: createButtons(() => onClose(false), () => { onFormDiscard() },
            t("serviceDonations.form.cancel"), t("serviceDonations.form.discard"), "", "primary")
    }
};

export const continueModal = (t: any, onClose: any, onFormSubmit: any) => {
    return {
        header: t("serviceDonations.form.submitHead"),
        children: t("serviceDonations.form.submitHelper"),
        buttons: createButtons(() => onClose(false), () => { onFormSubmit() },
            t("serviceDonations.form.cancel"), t("serviceDonations.form.submit"), "tertiary", "primary")
    }
};

const dashBoardStatusActions = [
    "UNDER_RDA_REVIEW",
    "AWAITING_RDA_ACKNOWLEDGEMENT",
    "APPROVED",
    "REJECTED",
    "CANCELLED",
    "COMPLETED"
];

export const getDashboardStatusActions = () => {
    return dashBoardStatusActions;
}


export const getDashboardTabFilters =  (lang: string) => {
    return {
        selectedLang: lang,
        defaultFilters: {
            currentStatusCode: {
                value: getDashboardStatusActions(),
                matchMode: 'equals'
            }
        },
        tableRowFilters: {
            selectedDonationFrom: '',
            selectedServiceType: ''
        },
        showStatusFilter: false,
        showExportExcel: true
    }
}

export const getTabsGroup = (t: any, lang: string) => {
    return [
       // { label: t("serviceDonations.tabs.myDonationTitle"), isActive: true, tableFilters: getDashboardTabFilters(0, lang) },
        { label: t("serviceDonations.tabs.allDonationTitle"), isActive: true, tableFilters: getDashboardTabFilters( lang) }
    ];
}

const onAdd = (history: any) => {
    history.push({ pathname: `${contextPath}/add/${servicePageType}?type=${servicePageType}` });
}

export const ModalTextBox = (props: any) => {
    const [value, setValue] = useState<string>("");
    const handleChange = (event: any) => {
        setValue(event.target.value);
        window.modalBoxComments = event.target.value;
    }

    return (
        <TextField
            data-testid="service-donation-event-input"
            label={props.label}
            className={"commentBox-Text"}
            onChange={handleChange}
            size="large"
            value={value}
            placeholder={props.placeHolder}
        />
    )
}

export const cancelModal = (t: any, onClose: any, onDonationCancel: any, action: string) => {
    return {
        header: t("serviceDonations.form.cancelHead"),
        children: <ModalTextBox placeHolder={t("serviceDonations.form.commentPlaceHolder")}
            label={t("serviceDonations.form.cancelHelper")} />,
        buttons: createButtons(() => onClose(false), () => { onDonationCancel(action) },
            t("serviceDonations.form.discard"), t("serviceDonations.form.cancelProceed"), "", "primary")
    }
};

export const acceptModal = (t: any, onClose: any, onDonationAccept: any, action: string) => {
    return {
        header: t("serviceDonations.form.alternateProposalHead"),
        children: <ModalTextBox placeHolder={t("serviceDonations.form.commentPlaceHolder")}
            label={t("serviceDonations.form.alternateProposalHelper")} />,
        buttons: createButtons(() => onClose(false), () => { onDonationAccept(action) },
            t("serviceDonations.form.discard"), t("serviceDonations.form.alternateProposalProceed"), "", "primary")
    }
};

export const createPayloadForAction = (
    data: any,
    comments: any,
    action: string
) => {

    let serviceDonationObject = { ...data.spaceAndServiceDonation, comments: comments };
    const payload = {
        ...data,
        spaceAndServiceDonation: serviceDonationObject,
        waaSTask: {
            taskId: data.waaSTaskMessage.taskId,
            requestId: data.spaceAndServiceDonation.id,
            taskName: data.waaSTaskMessage.taskName,
            variables: {
                userAction: action,
            },
        },
    };

    const form = new FormData();
    form.append("donationWaaSTask", JSON.stringify(payload));
    return form;
};

export const topButtons = (t: any, history: any) => {
    // In this usecase i want only one Button
    return [{
        ...defaultButton,
        buttonText: t("serviceDonations.form.newDonation"),
        callback: () => onAdd(history),
        buttonId: 'service-add-donation-button-id',
        variant: "primary",
        className: ""
    }
    ];
}

export const getServiceDashBoardData = (t: any, lang: string, history: any) => {
    const breadCrumbArray = getBreadCrumbDashBoardData("serviceDonations");
    return {
        pageTitle: t("serviceDonations.dashboard.heading"),
        breadcrumbs: breadCrumbArray,
        tabs: getTabsGroup(t, lang),
        topRightHeaderButtons: topButtons(t, history),
        //isTabs: true when you want to display as Tab Group
        isTabs: false
    };
}