import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, makeStyles } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import analytics from '../../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../../adobe-analytics/analytics-dataelemets';
import { CustomAccordion } from 'src/components/common/Accordion/CustomAccordion';
import { resolvePath } from 'src/components/common/utils';

import { BoardMembers } from './boardmembers';
import { OrganizationExpenses } from './expenses';
import { GeographicCoverage } from './geographicCoverage';
import { OrganizationIncome } from './income';
import { GetOrganizationDetailsSchema } from './organizationDetailsValidationSchema';
import { OrganizationMission } from './organizationMission';
import { OrganizationProfile } from './organizationProfile';
import {
    DefaultBoardMembers,
    DefaultGeographicData,
    DefaultIncomeAndExpenseTotals,
    DefaultOrganizationExpense,
    DefaultOrganizationIncome,
    DefaultOrgMissionFields,
    DefaultOrgProfile,
    DefaultPrincipalProgramFields,
    IBoardMembersFields,
    OrganizationDetailsProps,
    RadioOption,
    Sections,
} from './orgDetailsDefaults';
import { PrincipleProgram } from './principleProgram';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    formElement: {
        width: '100%'
    }
});

const getBoardMembersData = (boardMembers: any, isOrgDataSaved: boolean, personsOnPayroll: number) => {
    if (boardMembers === undefined) {
        return DefaultBoardMembers
    }

    const isReceivingSalary = boardMembers?.some((bm: IBoardMembersFields) => (bm.additionalUserDetails.isReceivingSalary === true));

    return {
        isReceivingSalary: isOrgDataSaved ? (isReceivingSalary ? RadioOption.Yes : RadioOption.No) : RadioOption.None,
        boardMembers: [...boardMembers],
        personsOnPayroll,
    }
}

const getOrgData = (orgData: any, isDefaults: boolean = false) => {
    if (isDefaults) {
        return {
            [Sections.organizationProfile]: DefaultOrgProfile,
            [Sections.organizationMission]: DefaultOrgMissionFields,
            [Sections.boardMembers]: DefaultBoardMembers,
            [Sections.geographicalCoverage]: DefaultGeographicData,
            [Sections.principlePrograms]: DefaultPrincipalProgramFields,
            [Sections.currentIncome]: DefaultOrganizationIncome.currentFinancialYear,
            [Sections.previousIncome]: DefaultOrganizationIncome.previousFinancialYear,
            [Sections.currentExpense]: DefaultOrganizationExpense.currentFinancialYear,
            [Sections.previousExpense]: DefaultOrganizationExpense.previousFinancialYear,
            [Sections.expenseAttachments]: [],
            [Sections.incomeAttachments]: [],
            [Sections.percentTotals]: DefaultIncomeAndExpenseTotals,
            [Sections.boardMemberSelection]: RadioOption.Yes
        }
    }

    const { principlePrograms, mission, legalRepresentativeDetails, income,
        geographicalCoverage, expenditure, boardMemberSalaryInfo } = orgData;
    const boardMembersData = getBoardMembersData(boardMemberSalaryInfo, orgData?.saved, mission?.personsOnPayroll);
    const data = {
        [Sections.organizationProfile]: legalRepresentativeDetails,
        [Sections.organizationMission]: mission || DefaultOrgMissionFields,
        [Sections.boardMembers]: boardMembersData,
        [Sections.geographicalCoverage]: geographicalCoverage || DefaultGeographicData,
        [Sections.currentIncome]: income?.currentFinancialYear || DefaultOrganizationIncome.currentFinancialYear,
        [Sections.previousIncome]: income?.previousFinancialYear || DefaultOrganizationIncome.previousFinancialYear,
        [Sections.incomeAttachments]: income?.fileDetails || [],
        [Sections.currentExpense]: expenditure?.currentFinancialYear || DefaultOrganizationExpense.currentFinancialYear,
        [Sections.previousExpense]: expenditure?.previousFinancialYear || DefaultOrganizationExpense.previousFinancialYear,
        [Sections.expenseAttachments]: expenditure?.fileDetails || [],
        [Sections.principlePrograms]: principlePrograms || DefaultPrincipalProgramFields,
        [Sections.percentTotals]: DefaultIncomeAndExpenseTotals,
        [Sections.boardMemberSelection]: RadioOption.Yes
    };

    return data;

}


export const OrganizationDetails: FC<OrganizationDetailsProps> = ({ orgData, setFormStatus,
    onOrgSubmit, isFormEditable, fileDownloader }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const methods =
        useForm({
            mode: 'all', defaultValues: getOrgData(orgData, false),
            resolver: yupResolver(GetOrganizationDetailsSchema())
        });

    const { errors } = methods.formState;
    useEffect(() => {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.organization.organizationProfilePage);
        const timeOut = window.setTimeout(() => {
            methods.trigger();
            window.clearTimeout(timeOut);
        }, 0);
    }, [orgData])
    const getTranslations = (key: string) => {
        return t(`monetaryDonation.organizationDetailsSection.${key}`)
    }

    const onFormSubmit = (data: any) => { onOrgSubmit(data); };
    const incomeSectionHasErrors = () => {

        return (Sections.currentIncome in methods.errors) ||
            (Sections.previousIncome in methods.errors) ||
            (Sections.incomeAttachments in methods.errors) ||
            resolvePath(`${Sections.percentTotals}.incomeCurrentFinancialYear`, methods.errors, false) ||
            resolvePath(`${Sections.percentTotals}.incomePreviousFinancialYear`, methods.errors, false);

    }

    const expenditureSectionHasErrors = () => {

        return (Sections.currentExpense in methods.errors) ||
            (Sections.previousExpense in methods.errors) ||
            (Sections.expenseAttachments in methods.errors) ||
            resolvePath(`${Sections.percentTotals}.expenseCurrentFinancialYear`, methods.errors, false) ||
            resolvePath(`${Sections.percentTotals}.expensePreviousFinancialYear`, methods.errors, false);

    }


    setFormStatus(errors || {});

    return (<>
        <div className={classes.root} id='organizationDetailsTab'>
            <Grid container={true} spacing={3}>
                <FormProvider {...methods}>
                    <form className={classes.formElement}
                        name='organization_details' id='organization_details' noValidate={true} autoComplete='off'
                        encType='application/json' onSubmit={methods.handleSubmit(onFormSubmit)}>
                        <CustomAccordion title={getTranslations('orgPro')} isEditable={isFormEditable} id='orgPro'>
                            <OrganizationProfile fieldName={Sections.organizationProfile}
                                data={orgData.legalRepresentativeDetails} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('orgMis')} isEditable={isFormEditable}
                            id='orgMis'
                            hasError={(Sections.organizationMission in methods.errors)} >
                            <OrganizationMission fieldName={Sections.organizationMission} data={orgData.mission} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('geoCov')} isEditable={isFormEditable}
                            id='geoCov'
                            hasError={(Sections.geographicalCoverage in methods.errors)} >
                            <GeographicCoverage fieldName={Sections.geographicalCoverage} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('boaMem')} isEditable={isFormEditable} id='boaMem'
                            hasError={(Sections.boardMembers in methods.errors ||
                                Sections.boardMemberSelection in methods.errors)} >
                            <BoardMembers fieldName={Sections.boardMembers} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('orgInc')} isEditable={isFormEditable} id='orgInc'
                            hasError={incomeSectionHasErrors()} >
                            <OrganizationIncome fileDownloader={fileDownloader} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('orgExp')} isEditable={isFormEditable} id='orgExp'
                            hasError={expenditureSectionHasErrors()} >
                            <OrganizationExpenses fileDownloader={fileDownloader} />
                        </CustomAccordion>
                        <CustomAccordion title={getTranslations('pProgram')} isEditable={isFormEditable} id='pProgram'
                            hasError={(Sections.principlePrograms in methods.errors)} >
                            <PrincipleProgram fieldName={Sections.principlePrograms} />
                        </CustomAccordion>
                    </form>
                </FormProvider>
            </Grid>
        </div >
    </>);
}
