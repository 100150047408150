import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {FC} from 'react';

export const LoadingSkeleton: FC<{}> = () => {

    const CustomSkeleton = () => (<Skeleton variant='text' height={50} />);
    return (<>

        <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={6}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={6}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={12}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={4}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={4}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={4}>
                <CustomSkeleton />
            </Grid>
            <Grid item={true} xs={12}>
                <CustomSkeleton />
            </Grid>
        </Grid>
    </>);
}