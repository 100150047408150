import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePickerField } from '../common/DatePickerField';
import InformationBar from '../common/informationBar';
import { InputTextField } from 'src/components/common/Input/InputTextField';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';
import { BaseSectionProps } from '../organizationDetails/orgDetailsDefaults';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    multilineInput: {
        height: '60px'
    },
    title: {
        fontWeight: 'bold'
    },
    outlinedError: {
        color: 'black',
        border: '1px solid black',
    },
    icon: {
        color: 'black !important',
    },
    alertRoot: {
        'padding': '0 16px',
        borderLeft: '4px solid black'
    }
})

export interface ProjectJustificationDetailProps extends BaseSectionProps {
    data: any
}

export const ProjectJustification: FC< ProjectJustificationDetailProps > = (props: ProjectJustificationDetailProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const getSnackbar = (translationKey: string) => {
        return (<InformationBar>
            {getTranslation(translationKey)}</InformationBar>);
    }
    const { control, trigger } = useFormContext();

    const requestAmountWatcher = useWatch({ control, name: `${props.fieldName}.requestAmount` });
    useEffect(() => {
        trigger('justificationTotal');
    }, [requestAmountWatcher]);

    return (<div className={classes.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography classes={{root: classes.title}}>{getTranslation('justificationDetails')}</Typography>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            fieldName={`${props.fieldName}.projectNeed`}
                            errorKeyName={`${props.fieldName}.projectNeed`}
                            label={getTranslation('explanationProjectNeed')}
                            id={'explanationProjectNeed'}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={4}
                            multiline={true}
                            defaultValue={props?.data?.projectNeed}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${props.fieldName}.projectNeed`}
                            maxSize = {1000}/>
                    </Grid>
                    <Grid item={true} xs={12} md={4}>
                        {getSnackbar('explanationProjectNeedDesc')}
                    </Grid>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            fieldName={`${props.fieldName}.economicStatusDirectBeneficiaries`}
                            errorKeyName={`${props.fieldName}.economicStatusDirectBeneficiaries`}
                            label={getTranslation('economicStatus')}
                            id={'economicStatus'}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={4}
                            multiline={true}
                            defaultValue={props?.data?.economicStatusDirectBeneficiaries}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${props.fieldName}.economicStatusDirectBeneficiaries`}
                            maxSize = {1000}/>
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        {getSnackbar('economicStatusDesc')}
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            fieldName={`${props.fieldName}.expertiseInArea`}
                            errorKeyName={`${props.fieldName}.expertiseInArea`}
                            label={getTranslation('describeExpertise')}
                            id={'describeExpertise'}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={4}
                            multiline={true}
                            defaultValue={props?.data?.expertiseInArea}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${props.fieldName}.expertiseInArea`}
                            maxSize = {1000}/>
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        {getSnackbar('describeExpertiseDesc')}
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            fieldName={`${props.fieldName}.requestAmount`}
                            errorKeyName={`${props.fieldName}.requestAmount`}
                            label={getTranslation('requestAmount')}
                            id={'requestAmount'}
                            type={'number'}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            defaultValue={props?.data?.requestAmount}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            fieldName={`${props.fieldName}.projectCarriedOut`}
                            errorKeyName={`${props.fieldName}.projectCarriedOut`}
                            label={getTranslation('describeProjectCarried')}
                            id={'describeProjectCarried'}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={4}
                            multiline={true}
                            defaultValue={props?.data?.projectCarriedOut}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${props.fieldName}.projectCarriedOut`}
                            maxSize = {1000}/>
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        {getSnackbar('describeProjectCarriedDesc')}
                    </Grid>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <DatePickerField
                            defaultValue={props?.data?.projectStartDate}
                            fieldName={`${props.fieldName}.projectStartDate`}
                            label={getTranslation('startDate')} errorKeyName={`${props.fieldName}.projectStartDate`}/>
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        {getSnackbar('dateFormatDesc')}
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <DatePickerField
                            defaultValue={props?.data?.projectEndDate}
                            fieldName={`${props.fieldName}.projectEndDate`}
                            label={getTranslation('endDate')} errorKeyName={`${props.fieldName}.projectEndDate`}/>
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        {getSnackbar('dateFormatDesc')}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}