import * as React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import '../../styles/formStyles.css';

const styles = {
    buttonStyles: {
        marginBottom: 20
    }
}

interface OwnProps {
    id?: string,
    showDialog: boolean;
    isAsdaTheme?: boolean;
    cancel: () => void;
    confirm: () => void;
    title?: string;
    message?: string;
    confirmButton?: string;
    cancelButton?: string;
    maxWidth?: any;
    cancelBtnClasses?:string;
    confirmBtnClasses?: string;
    customStyle?:any;
}
export interface DispatchProps {
    updateFormData: (data: any) => void;
}

export interface StateProps {
    selectedLang: string;
    translationData: any;
}

class GenericConfirmDialog extends React.PureComponent<OwnProps>{

    public translationData: any;

    public render() {
        return <Dialog
            open={this.props.showDialog}
            onClose={this.props.cancel}
            aria-labelledby='confirm-dialog'
            aria-describedby='confirm-dialog'
            maxWidth={this.props.maxWidth}
            data-testid= 'genericConfirmDialog'
        >
            <DialogTitle className='dialog-title' id='confirm-dialog'>{this.props.title
                || 'Delete record'}</DialogTitle>
            <DialogContent>
                <DialogContentText id='confirm-dialog-description'>
                    {this.props.message ||
                        'Are you sure you want to continue?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{ ...this.props.customStyle}}>
                <Button className={this.props.cancelBtnClasses ? this.props.cancelBtnClasses : 'sip-cancel-btn'}
                    onClick={this.props.cancel} color='primary'>
                    {this.props.cancelButton ||
                        'Cancel'}
                </Button>
                <Button className={`${this.props.confirmBtnClasses ? this.props.confirmBtnClasses : 'sip-confirm-btn'}`}
                    onClick={this.props.confirm} color='primary' autoFocus={true}>
                    {this.props.confirmButton ||
                        'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    }
}


export default withStyles(styles)(GenericConfirmDialog);