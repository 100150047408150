import { LogoutUserActions, LogoutUserActionTypes } from './logoutUserActions';
import { LogoutState } from './../../models/logoutModel';

export const defaultLogoutUserState: LogoutState = {
    logoutLoading: false,
    logoutLoadingSuccess: false,
    logoutLoadingFailure: false
};

export const logoutUserReducer = (state: LogoutState = defaultLogoutUserState, action: LogoutUserActions):
    LogoutState => {
    switch (action.type) {
        case LogoutUserActionTypes.USER_LOGOUT_LOADING: return {
            ...state,
            logoutLoading: true,
            logoutLoadingSuccess: false,
            logoutLoadingFailure: false
        };
        case LogoutUserActionTypes.USER_LOGOUT_LOADING_SUCCESS: return {
            ...state,
            logoutLoading: false,
            logoutLoadingSuccess: true,
            logoutLoadingFailure: false
        };
        case LogoutUserActionTypes.USER_LOGOUT_LOADING_FAILURE: return {
            ...state,
            logoutLoading: false,
            logoutLoadingSuccess: false,
            logoutLoadingFailure: true
        };
        case LogoutUserActionTypes.CRLEAR_LOGOUT_STATE: return {
            ...state,
            logoutLoading: false,
            logoutLoadingSuccess: false,
            logoutLoadingFailure: false
        };
        default: return state;
    }
}