import { useQuery } from 'react-query';

import { reactQueryConfigOptions } from './../constants';
import { getOneTimeGoodsDonations } from './../service/apiService';


export const useFetchOneTimeGoodsDonations = (payload:any) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        isFetching,
        data
    } = useQuery(['oneTimeGoodsDonations', { ...payload, pageNum:payload?.page }], getOneTimeGoodsDonations,
    {...reactQueryConfigOptions, keepPreviousData:true});

    return {data, isLoading, isSuccess, isError, isFetching, error};
}
