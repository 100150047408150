import { Spinner } from "@walmart-web/livingdesign-components";
import { FC } from "react";

const WmSpinner: FC = () => {
  // Add spinner in center while data is loading
  return (
    <>
        <div className='flex-container'>
          <div className='row'>
            <div className='flex-item'>
              <Spinner
                color='gray'
                size='large'
                className='emergency-donation-loader'
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default WmSpinner;
