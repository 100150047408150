import { TraslationDataActions, TraslationActionTypes } from './translationActions';
import { TranslationState } from '../../models/globalDonationState';

export const defaultTranslationState: TranslationState = {
    translationData: {},
    translationLoading: false,
    translationLoadingFailure: false,
    translationLoadingSuccess: false,
};

export const translationDataReducer = (state: TranslationState = defaultTranslationState, action: TraslationDataActions):
    TranslationState => {
    switch (action.type) {
        case TraslationActionTypes.TRANSLATION_DATA_LOAD: return {
            ...state,
            translationData: true,
            translationLoadingSuccess: false,
            translationLoadingFailure: false,
        };
        case TraslationActionTypes.TRANSLATION_LOAD_SUCCESS: return {
            ...state,
            translationData: action.data,
            translationLoading: false,
            translationLoadingFailure: false,
            translationLoadingSuccess: true,
        };
        case TraslationActionTypes.TRANSLATION_LOAD_FAILURE: return {
            ...state,
            translationLoading: false,
            translationLoadingFailure: true,
            translationLoadingSuccess: false,
        };
        default: return state;
    }
}