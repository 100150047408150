import '../../../styles/formStyles.css';

import { Button, withStyles } from '@material-ui/core';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { saveFormData } from 'dynamic-form-components/lib/state/form/formDataActions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import SuccessAlertDailog from 'src/components/dialogComponents/SuccessAlertDailog';
import { cityStateMappingDataLoad } from 'src/state/OrgProfile/orgProfileActions';

import { GlobalDonationState } from '../../../models/globalDonationState';
import styles from '../../menu/ThemeStyle';
import { contextPath, donationSuccessTxt } from './../../../constants';
import { downloadFileData } from './../../../state/downloadFile/downloadFileDataActions';
import { downloadReportData } from './../../../state/report/reportDataActions';
import LoaderComponent from './../../LoaderComponent';

const config = require(`./../../../config.${process.env.NODE_ENV}.json`);


declare global {
    interface Window {
        provinceDropDownList: any
    }
}

export interface OwnProps {
    formAlias: string;
    classes: any;
    appi18n: any;
    content: any;
    isNewRequest: boolean;
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    selectedDonationData?: any;
    loggedInUserDetail: any;
    formState: any;
    cityStateMappingLoading: boolean;
    cityStateMapping: any;
}

export interface DispatchProps {
    submitDonation: (data: any) => void;
    printPdf: (data: any) => void;
    downloadProjectPdf: (data: any) => void;
    getCityStateMapping: (data: any) => void;
}
const appi18n: any = {
    projectIdTxt: {
        en: 'Project#:',
        es: 'Proyecto #:',
        fr: 'Numéro de projet:'
    },
    printPdfbtnTxt: {
        en: 'Print Pdf',
        es: 'Imprimir PDF',
        fr: 'Imprimer le PDF'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Retour'
    },
    projectLocationHeaders: {
        'geographicArea': {
            en: 'Geographic Area',
            fr: 'Zone géographique',
        },
        'geographicAreaOther': {
            en: 'If "Other" in Geographic Area, then specify below.',
            fr: 'Si "Autre" dans la zone géographique, précisez ci-dessous',
        },
        'province': {
            en: 'Province',
            fr: 'Province',
        },
        'provinceOther': {
            en: 'If "Other" in Province, then specify below.',
            fr: 'Si «Autre» dans la province, précisez ci-dessous',
        },
        'city': {
            en: 'City',
            fr: 'Ville',
        },
        'cityOther': {
            en: 'If "Other" in City, then specify below.',
            fr: 'Si «Autre» dans Ville, précisez ci-dessous.',
        },
        'supportedCommunity': {
            en: 'Supported Community',
            fr: 'Communauté soutenue',
        }
    },
    removeBtnText: {
        en: 'Remove',
        fr: 'Retirer',
    }
}
class CommunityGrant extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {

    public state = {
        formAlias: 'communityGrantExternal',
        donationType: 'COMMUNITY_GRANT'
    };

    public options: any;
    public submissionData: any;
    public UNSAFE_componentWillMount() {
        const countryCodeFromLocalStorage = localStorage.getItem('countryCode') || ''
        const countryCode = (this.props.loggedInUserDetail && this.props.loggedInUserDetail.countryCode)
            ? this.props.loggedInUserDetail.countryCode
            : ((countryCodeFromLocalStorage) ? countryCodeFromLocalStorage : '');
        if (countryCode) {
            this.props.getCityStateMapping({
                countryCode
            })
        }
        if (this.props.isNewRequest) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
            this.submissionData = {
                data: {
                    hideSubmitBtn: false,
                    hidePDFBtn: true,
                    isNewRequest: true,
                    orgName: this.props.loggedInUserDetail ? this.props.loggedInUserDetail.orgLegalName : '',
                    orgTaxId: this.props.loggedInUserDetail ? this.props.loggedInUserDetail.taxId : ''
                }
            }
        } else {
            this.options = {
                ...this.options,
                noAlerts: true,
                readOnly: true,
                language: this.props.selectedLang
            }
            const selectedData = (this.props.selectedDonationData.communityGrant) ?
                JSON.parse(JSON.stringify(this.props.selectedDonationData.communityGrant)) : {};
            this.submissionData = {
                data: {
                    ...selectedData,
                    isNewRequest: false,
                    hideSubmitBtn: true,
                    hidePDFBtn: false,
                }
            }
        }
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.cityStateMapping && newProps.cityStateMapping.length > 0) {
            const provinceDropDownList: any = [];
            newProps.cityStateMapping.forEach((item: any) => {
                if (item.label) {
                    provinceDropDownList.push(item.label)
                }
            })
            window.provinceDropDownList = provinceDropDownList;
        }
        if (this.props.loggedInUserDetail !== newProps.loggedInUserDetail) {
            if (newProps.loggedInUserDetail.countryCode) {
                this.props.getCityStateMapping({
                    countryCode: newProps.loggedInUserDetail.countryCode
                })
            }
        }
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang
            }
        }
        this.translateProjectLocationHeaders();
    }
    private onFormChange = (_event: any) => {
        this.translateProjectLocationHeaders();
    }
    private onDonationSubmit = (submission: any) => {

        const locations: any = [];
        const org = this.props.loggedInUserDetail
        submission.data.projectLocation.projectLocationDetails.forEach((location: any) => {
            locations.push({
                ...location,
                state: location.state.label
            })
        })
        this.props.submitDonation(
            {
                formAlias: this.state.formAlias,
                formData: {
                    data: {
                        donationDetail: {
                            ...submission.data,
                            projectLocation: {
                                projectLocationDetails: locations
                            },
                            facilityNo: submission.data.facility.facilityNo
                        },
                        organization: {
                            // id: org.id,
                            orgLegalName: org.orgLegalName,
                            taxId: org.taxId,
                            dnbStatus: org.dnbStatus,
                            dnbTrackingStatus: org.dnbTrackingStatus,
                            primaryOrgUser: org.primaryOrgUser,
                            additionalOrgUserEmails: org.additionalOrgUserEmails,
                            countryCode: org.countryCode
                        },
                        donationType: this.state.donationType
                    },
                },
                // updated endpoint to /ngo as part of spring security changes 
                submissionUrl: `${config.appBaseUrl}/community-grant/ngo`
            }
        );
    }

    private handleClick = () => {
        this.props.history.push(`${contextPath}/dashboard/community-grant?type=community-grants`)
    }

    private onCustomEvent = (event: any) => {
        const locations: any = [];
        event.data.projectLocation.projectLocationDetails.forEach((location: any) => {
            locations.push({
                ...location,
                state: location.state.label
            })
        })
        const data = {
            donationDetail: {
                ...event.data,
                projectLocation: {
                    projectLocationDetails: locations
                },
                facilityNo: event.data.facility.facilityNo,
            },
            donationType: this.state.donationType
        }
        this.props.printPdf(data);
    }
    private downloadProjectPdf = (_event: any) => {
        const payload = {
            donation: {
                ...this.submissionData.data,
            },
            donationType: this.state.donationType,
            langCode: this.props.selectedLang
        }
        this.props.downloadProjectPdf(payload);
    }

    private translateProjectLocationHeaders = () => {
        const projectLocationHeaders = document.querySelectorAll('.project-location-columns')
        // @ts-ignore
        if (projectLocationHeaders !== null) {
            // @ts-ignore
            projectLocationHeaders.forEach((item: any, _index: number) => {
                item.innerHTML = appi18n.projectLocationHeaders[item.id][this.props.selectedLang];
            })
        }
        const removeBtns = document.querySelectorAll('.removeRow');
        if (removeBtns !== null && !this.props.isNewRequest) {
            // @ts-ignore
            removeBtns.forEach((btn: any, _index: number) => {
                if (btn) {
                    btn.style.display = 'none';
                }
            })
        } else if (removeBtns !== null && this.props.isNewRequest) {
            removeBtns.forEach((btn: any, _index: number) => {
                btn.innerHTML = appi18n.removeBtnText[this.props.selectedLang];
            })
        }
        this.removeEventListener();
        this.addEventListener();
    }
    public componentDidMount() {
        this.addEventListener();
    }

    public componentWillUnmount() {
        this.removeEventListener();
    }

    private addEventListener = () => {
        // @ts-ignore
        const savebtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-saveRow\']');
        if (savebtn) {
            savebtn.addEventListener('click', this.translateProjectLocationHeaders);
        }
        // @ts-ignore
        const caancelBtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-cancelRow\']')
        if (caancelBtn) {
            caancelBtn.addEventListener('click', this.translateProjectLocationHeaders);
        }
        const addMoreLocationBtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-addRow\']');
        if (addMoreLocationBtn) {
            addMoreLocationBtn.addEventListener('click', this.translateProjectLocationHeaders);
        }

        const projectLocationsection = document.querySelector('.community-grant .gc-project-locations');
        if (projectLocationsection) {
            projectLocationsection.addEventListener('click', this.translateProjectLocationHeaders);
        }
    }

    private removeEventListener = () => {
        // @ts-ignore
        const savebtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-saveRow\']');
        if (savebtn) {
            savebtn.removeEventListener('click', this.translateProjectLocationHeaders);
        }
        // @ts-ignore
        const caancelBtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-cancelRow\']')
        if (caancelBtn) {
            caancelBtn.removeEventListener('click', this.translateProjectLocationHeaders);
        }
        const addMoreLocationBtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-addRow\']');
        if (addMoreLocationBtn) {
            addMoreLocationBtn.removeEventListener('click', this.translateProjectLocationHeaders);
        }
        const projectLocationsection = document.querySelector('.community-grant .gc-project-locations');
        if (projectLocationsection) {
            projectLocationsection.removeEventListener('click', this.translateProjectLocationHeaders);
        }
    }
    public render() {
        const { formState, classes } = this.props;
        const addMoreLocationBtn = document.querySelector('button[ref=\'editgrid-projectLocationDetails-addRow\']');
        // @ts-ignore
        if (addMoreLocationBtn !== null && !formState.formSaveSuccess) {
            const geoArea = document.querySelector('.formio-component-geoArea');
            if (!geoArea) {
                // @ts-ignore
                addMoreLocationBtn.click();
            }
        }
        return (<>
            <Button className={`back-btn ${this.props.classes.buttonStyles}`}
                variant='contained' color='primary'
                onClick={this.handleClick}>
                {appi18n.backBtnLabel[this.props.selectedLang]}
            </Button>
            {
                (this.options.readOnly && this.submissionData.data && this.submissionData.data.id) ?
                    <Button className={`print-pdf-btn ${this.props.classes.buttonStyles}`}
                        variant='contained' color='primary'
                        onClick={this.downloadProjectPdf}>
                        {appi18n.printPdfbtnTxt[this.props.selectedLang]}
                    </Button> : ''

            }
            {(this.options.readOnly && this.submissionData.data && this.submissionData.data.id) ?
                <>
                    <div className='project-id-container'>
                        <span className='project-id-label'>{appi18n.projectIdTxt[this.props.selectedLang]}</span>
                        <span className='project-id-text'> {this.submissionData.data.id}</span>
                    </div>
                </> : ''
            }
            <div className='community-grant'>
                <FormComponent
                    key={this.state.formAlias}
                    formAlias={this.state.formAlias}
                    options={this.options}
                    submission={this.submissionData}
                    onSubmit={this.onDonationSubmit}
                    submissionUrl={''}
                    handleCustomEvent={this.onCustomEvent}
                    handleOnFormChange={this.onFormChange}
                />
            </div>
            <SuccessAlertDailog
                showDialog={this.props.formState.formSaveSuccess}
                ok={this.handleClick}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={donationSuccessTxt.content[this.props.selectedLang]}
            />
            {this.props.formState.formSaveLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={formState.formSaveFailure} classes={classes}
                message={this.props.formState.formActionMessage.errorMessage ||
                    `Failed to create donation request, please try again!`} />
        </>)
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    selectedDonationData: state.selectedDataState.addDataToStore,
    loggedInUserDetail: state.loggedInUserState.userDetail,
    formState: state.formState,
    cityStateMappingLoading: state.orgProfile.cityStateMappingLoading,
    cityStateMapping: state.orgProfile.cityStateMapping
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    submitDonation: (data: any) => dispatch(saveFormData(data)),
    printPdf: (data: any) => dispatch(downloadReportData(data)),
    downloadProjectPdf: (data: any) => dispatch(downloadFileData(data)),
    getCityStateMapping: (data: any) => dispatch(cityStateMappingDataLoad(data))
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunityGrant)));
