import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { wordLengthValidation } from '../utils';
import { getFormattedDate, getTodaysDate } from '../../common/DateUtils';
import { Heading, InputBox } from '../../common/index';
import { stringFormat } from '../../common/utils';
import '../../../styles/oneTimeGoodDonations.css';

export interface OwnProps {
    classes: any;
}

const EmployeeDonationDetails = (props: any) => {
    const { t } = useTranslation();
    const dateValue = getTodaysDate();
    useEffect(() => {
        props.setValue('name', props?.data?.certifications[0]?.name ?
            props.data.certifications[0].name : '');
        props.setValue('title', props?.data?.certifications[0]?.title ?
            props.data.certifications[0].title : '');
        props.setValue('signature', props?.data?.certifications[0]?.signature ?
            props.data.certifications[0].signature : '');
        props.setValue('date', props?.data?.certifications[0]?.date ?
            getFormattedDate(props.data.certifications[0].date) : dateValue);
    }, [props.data]);

    const wordLengthValidate = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 100);
        return wordLengthValidation(value, message, 100)
    };

    const wordLengthValidate600 = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 600);
        return wordLengthValidation(value, message, 600)
    };

    const requiredFieldValidation = () => props?.readOnly ? false : t('common.validationMsg.requiredField') as string

    return (
        <>
            <div className={'donation_data'}>
                <Heading
                    id={`cartonDetails-heading`}
                    className={`h16_bold_title cartonDetails-heading mr-bt`}
                    level={'h6'}
                >{t('oneTimeGoodsDonation.employeeData.header')}</Heading>
                <Heading level={'h6'}>
                    <Trans i18nKey='oneTimeGoodsDonation.ack.companyEmplyeeCertification' />
                </Heading>
                <div className='row'>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_title inputBox'}
                            className={'authorization_title inputBox'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.heading.title')}
                            fieldName={'title'}
                            defaultValue={''}
                            required={true}
                            disabled={props?.data?.certifications[0]?.title ? true : false}
                            InputProps={{
                                readOnly: props?.data?.certifications[0]?.title ? true : false,
                                classes: {
                                    disabled: props?.data?.certifications[0]?.title ? props?.classes.disabled : ''
                                }
                            }}
                            rules={{
                                required: requiredFieldValidation(),
                                validate: wordLengthValidate
                            }}
                            errorMsg={props.errors?.certificateSignee?.userTitle?.message}
                        />
                    </div>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_employee_name'}
                            className={'authorization_employee_name'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.heading.employeeName')}
                            fieldName={'name'}
                            defaultValue={''}
                            required={true}
                            disabled={props?.data?.certifications[0]?.name ? true : false}
                            InputProps={{
                                readOnly: props?.data?.certifications[0]?.name ? true : false,
                                classes: {
                                    disabled: props?.data?.certifications[0]?.name ? props?.classes.disabled : ''
                                }
                            }}
                            rules={{
                                required: requiredFieldValidation(),
                                validate: wordLengthValidate
                            }}
                            errorMsg={props.errors?.certificateSignee?.employer?.message}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_signature'}
                            className={'authorization_signature'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.employeeData.signature')}
                            fieldName={'signature'}
                            defaultValue={''}
                            required={true}
                            disabled={props?.data?.certifications[0]?.signature ? true : false}
                            InputProps={{
                                readOnly: props?.data?.certifications[0]?.signature ? true : false,
                                classes: {
                                    disabled: props?.data?.certifications[0]?.signature ? props?.classes?.disabled : ''
                                }
                            }}
                            rules={{
                                required: requiredFieldValidation(),
                                validate: wordLengthValidate600
                            }}
                            errorMsg={props.errors?.certificateSignee?.signature?.message}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_date'}
                            className={'authorization_date'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.employeeData.date')}
                            fieldName={'date'}
                            disabled={true}
                            required={true}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    disabled: props?.classes?.disabled
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDonationDetails;
