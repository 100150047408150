import { createStyles, makeStyles, Table, TableCell, TableCellProps, TableHead, TableRow, Theme } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessageComponent } from 'src/components/common/formComponents/ErrorMessage';
import { formatCurrency } from 'src/components/common/utils';

import { IScheduleOfActivitiesFields } from './projectDetailsDefaults';

interface IProjectScheduleOfActivitiesTotalsProps {
    watchedFields: IScheduleOfActivitiesFields[],
    totalFieldName?: string,
    getTranslation: (key: string) => string
}

// @ts-ignore
export const TotalTableStyles = makeStyles((theme: Theme) => createStyles({

    cell: {
        border: 'none',
    },
    totalCellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
    },
    tableRoot: {
        backgroundColor: '#f2f2f2',
        borderRadius: '4px',
        marginTop: '15px'
    },
    errorMessage: {
        fontSize: '14px',
        color: 'red',
        marginTop: '15px'
    }
}));
export const ProjectScheduleOfActivitiesTotals: FC<IProjectScheduleOfActivitiesTotalsProps> =
    ({ watchedFields, getTranslation }) => {
        const classes = TotalTableStyles();
        const [fieldsTotal, setFieldsTotal] = useState<any>();
        const cellProps: TableCellProps = {
            variant: 'head',
            classes: { head: classes.totalCellHead, root: classes.cell }
        }
        const { register, errors, setValue } = useFormContext();
        const totalCallBack = useCallback(() => {
            if (watchedFields?.length > 0) {
                const fields = watchedFields?.reduce((acc, current) => {
                    // @ts-ignore
                    // tslint:disable-next-line: radix
                    acc.amount = acc.amount + parseInt(current.amount || 0);
                    return acc;
                }, { amount: 0 });
                setValue('justificationTotal', fields.amount, { shouldValidate: true });
                setFieldsTotal(fields);
            }
        }, [watchedFields]);


        useEffect(() => {
            totalCallBack();

        }, [totalCallBack])

        return (<div>
            <Table aria-label={'Totals Row'} size='small' classes={{ root: classes.tableRoot }}>
                <TableHead>
                    <TableRow>
                        <TableCell {...cellProps} style={{ width: '15%' }}>
                            {getTranslation('total')}
                        </TableCell>
                        <TableCell style={{ width: '35%' }} />
                        <TableCell {...cellProps} style={{ width: '15%' }}>
                            {formatCurrency(fieldsTotal?.amount)}
                        </TableCell>
                        <TableCell style={{ width: '15%' }} />
                        <TableCell style={{ width: '15%' }} />
                    </TableRow>
                </TableHead>
            </Table>

            <input type='hidden' ref={register} name={'justificationTotal'} />
            <div style={{ paddingTop: '10px' }}>
                <ErrorMessageComponent errors={errors} fieldName={'justificationTotal'} />
            </div>
        </div>);
    }

