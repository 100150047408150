import { FC, useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    TextField,
    Divider,
    createMuiTheme,
    MuiThemeProvider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import LoaderComponent from '../LoaderComponent';
import GlobalDonationDataService from '../../service';
import { isValidateFacilityNo } from './utils';
import { gridTranslations } from '../../static/GridTranslations';
import { useLazyEffect } from './useLazyEffect';
const config = require(`./../../config.${process.env.NODE_ENV}.json`);

const appi18n: any = {
    ...gridTranslations,
    noOptions: {
        en: 'No options',
        es: 'Sin opciones',
        fr: 'Aucune option',
        ja: 'オプションなし',
        zh: '无选项'
    },
    gridTitle: {
        en: 'Donation Requests',
        es: 'Solicitudes de donación',
        fr: 'Demandes de dons'
    },
    createNewBtnLable: {
        en: 'Create New',
        es: 'Crear nueva',
        fr: 'Créer un nouveau'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Arrière'
    },
    errorMessage: {
        en: 'Please enter a store number or select province',
        es: 'Ingrese un número de tienda o seleccione una provincia',
        fr: 'Veuillez entrer un numéro de magasin ou sélectionner la province',
        ja: '店舗番号を入力するか、州を選択してください',
        zh: '請輸入商店編號或選擇省'
    },
    labelCity: {
        en: 'Select City',
        es: 'Ciudad selecta',
        fr: 'Sélectionnez une ville',
        ja: '都市を選びなさい',
        zh: '选择城市'
    },
    storeNumberPlaceholder: {
        en: 'Enter Store Number',
        es: 'Ingrese el número de la tienda',
        fr: 'Entrez le numéro de magasin',
        ja: '店舗番号を入力してください',
        zh: '輸入商店編號'
    },
    dcNumberPlaceholder: {
        en: 'Enter DC Number',
        es: 'Ingrese el número de la centro de distribución',
        fr: 'Entrez le numéro de centre de distribution',
        ja: '店舗番号を入力してください',
        zh: '輸入商店編號'
    },
    homeofficeNumberPlaceholder: {
        en: 'Enter Home Office Number',
        es: 'Ingrese el número de la Oficina en casa',
        fr: 'Entrez le numéro de bureau à domicile',
        ja: '店舗番号を入力してください',
        zh: '輸入商店編號'
    },
    labelProvince: {
        en: 'Select Province',
        es: 'Seleccionar provincia',
        fr: 'Sélectionnez la province',
        ja: 'セレクト省',
        zh: '选择省'
    },
    labelFacilityNo: {
        en: 'Facility#',
        es: 'Instalaciones #',
        fr: 'Établissement #',
        ja: '施設 ＃',
        zh: '设施＃'
    },
    modalTitle: {
        en: 'Find Facility',
        es: 'Encontrar instalación',
        fr: 'Trouver une installation',
        ja: '施設を探します',
        zh: '查找设施'
    },
    searchResultText: {
        en: 'Search Results',
        es: 'Resultados de la búsqueda',
        fr: 'Résultats de recherche',
        ja: 'の検索結果',
        zh: '搜索结果'
    },
    searchBtnText: {
        en: 'Search',
        es: 'Buscar',
        fr: 'Chercher',
        ja: '探す',
        zh: '搜索'
    },
    resetBtnText: {
        en: 'Reset',
        es: 'Reiniciar',
        fr: 'Réinitialiser',
        ja: 'リセット',
        zh: '重启'
    },
    labelChooseStore: {
        en: 'I\'ll choose my store',
        es: 'Elegiré mi tienda',
        fr: 'Je vais choisir mon magasin',
        ja: '私は自分の店を選択します',
        zh: '我会选择我的商店'
    },
    labelChangeStore: {
        en: 'Change Store',
        es: 'Cambiar tienda',
        fr: 'Changer de magasin',
        ja: 'ストアの変更',
        zh: '換店'
    },
    facilityNoErr: {
        en: 'Please enter a valid store number',
        es: 'Ingrese un número de tienda válido',
        fr: 'Veuillez saisir un numéro de magasin valide',
        ja: '有効な店舗番号を入力してください',
        zh: '請輸入有效的商店編號'
    },
    labelAddress: {
        en: 'Street Address',
        es: 'Dirección',
        fr: 'Adresse de rue',
        ja: '住所',
        zh: '街道地址'
    },
    cityTxt: {
        en: 'City',
        es: 'Ciudad',
        fr: 'Ville',
        ja: '市',
        zh: '市'
    },
    provinceTxt: {
        en: 'Province',
        es: 'Provincia',
        fr: 'Province',
        ja: '州',
        zh: '省'
    },
    labelPostalCode: {
        en: 'Postal Code',
        es: 'Código postal',
        fr: 'code postal',
        ja: '郵便番号',
        zh: '邮政编码'
    }
}
const columns = [
    {
        'title': {
            en: 'Facility#',
            es: 'Nº Tienda',
            fr: 'Établissement #',
            ja: '施設 ＃',
            zh: '设施＃'
        },
        'field': 'facilityNo',
        'hidden': false,
        'sortable': true
    },
    {
        'title': {
            en: 'Province/State',
            es: 'Estado',
            fr: 'Province',
            ja: '州',
            zh: '省'
        },
        'field': 'state',
        'hidden': false,
        'sortable': true
    },
    {
        'title': {
            en: 'City',
            es: 'Comuna',
            fr: 'Ville',
            ja: '市',
            zh: '市'
        },
        'field': 'city',
        'hidden': false,
        'sortable': true
    },
    {
        'title': {
            en: 'Postal Code',
            es: 'Código postal',
            fr: 'code postal',
            ja: '郵便番号',
            zh: '邮政编码'
        },
        'field': 'postalCode',
        'hidden': false,
        'sortable': true
    },
    {
        'title': {
            en: 'Street Address',
            es: 'Dirección',
            fr: 'Adresse de rue',
            ja: '住所',
            zh: '街道地址'
        },
        'field': 'streetAddress',
        'hidden': false,
        'sortable': true
    },
    {
        'title': {
            'en': 'Facility Type',
            'fr': 'Type d\'établissement',
            'es': 'Formato',
            ja: '施設のタイプ',
            zh: '设施类型'
        },
        'field': 'facilityType',
        'hidden': false,
        'sortable': true
    }
]
const gridTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        }
    }
});

interface FacilitySearchProps {
    countryCode: string
    selectedLang: string
    showModal: boolean
    handleModalAction: (showModal: boolean) => void
    onFaciltySelection?: (data: any) => void
    color?: 'default' | 'primary' | 'secondary' | undefined
    size?: 'small' | 'medium' | undefined
    variant?: 'default' | 'outlined' | undefined
    sttyle?: any
    fetchType?: any;
}

const fetchTypeTranslation = (fetchType:string,selectedLang:any) => {
    switch(fetchType){
        case "dc":
            return appi18n.dcNumberPlaceholder[selectedLang];
        case "home_office":
            return appi18n.homeofficeNumberPlaceholder[selectedLang];
        default:
            return appi18n.storeNumberPlaceholder[selectedLang];
    }
}

export const FacilitySearchComponent: FC<FacilitySearchProps> = (props) => {

    const [refresh, setRefresh] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [facilityNoErr, setFacilityNoErr] = useState<boolean>(false)
    const [provinceList, setProvinceList] = useState<any>([])
    const [cityList, setCityList] = useState()
    const [facilityNo, setFacilityNo] = useState<any>()
    const [province, setProvince] = useState<any>({})
    const [city, setCity] = useState<string>()
    const [filters, setFilters] = useState<any>({})

    useEffect(() => {
        if (props?.countryCode === 'CL') {
            columns[1].field = 'county'
            columns[1].title.es = 'Región'
        }
    }, [])


    // Automatically search for location on typing
    useLazyEffect(() => {
        findFacility(null);
    }, [city,province,facilityNo], 500)

    useEffect(() => {
        setLoading(true)
        GlobalDonationDataService.getConfigurationsData({
            key: 'provinceFacilitySearch',
            level: 'market',
            levelValue: 'default',
            countryCode: props?.countryCode,
            checkDefaultLevels: 'false',
            pageNum: 1,
            pageSize: 100
        }).then((res: any) => {
            setLoading(false)
            setProvinceList(res?.provinceList || [])
        }).catch((error: any) => {
            /* istanbul ignore next */
            setLoading(false)
            setProvinceList([])
            setErrorMessage(error?.response?.data)
        })
        resetForm()
    }, [props?.countryCode])

    /* istanbul ignore next */
    const handleProvinceChange = (_event: any, selectedProvince: any) => {
        setProvince({ selectedProvince, errorMessage: '' })
        setCityList(selectedProvince?.cityList || [])
        setCity('')
    }
    /* istanbul ignore next */
    const handleFacilityChange = (event: any) => {
        if (!isValidateFacilityNo(event.target.value)) {
            setFacilityNoErr(true)
        }
        setFacilityNo(event.target.value)
    }
    /* istanbul ignore next */
    const handleCityChange = (_event: any, selectedCity: any) => {
        setCity(selectedCity)
    }
    /* istanbul ignore next */
    const findFacility = (_event: any) => {
        if (!facilityNo && !province?.selectedProvince?.value && _event!==null) {
            setErrorMessage(appi18n.errorMessage[props.selectedLang])
            return;
        }

        setFilters({
            city: city === null ? '' : city,
            state: province?.selectedProvince?.value ? province?.selectedProvince?.value : '',
            county: province?.selectedProvince?.value ? province?.selectedProvince?.value : '',
            number: facilityNo,
            countryCode: props?.countryCode
        })
        setRefresh(!refresh)
    }


    const resetForm = (_event: any = {}) => {
        setCity('')
        setProvince({ selectedProvince: null, errorMessage: '' })
        setFacilityNo('')
        setRefresh(!refresh)
        setFilters({})
    }

    /* istanbul ignore next */
    const handleRowSelection = (_event: any, rowData: any, _togglePanel: any) => {
        props.handleModalAction(false)
        // @ts-ignore
        return props?.onFaciltySelection(rowData)
    }
    const getSearchResults = (query: any) => {
        const filter = {...filters}
        const payload: any = {
            city: filter?.city === null || filter?.city === undefined ? '' : filter?.city,
            state: (filter?.state === null || filter?.state === undefined) ? '' : filter?.state,
            number: filter?.number === null || filter?.number === undefined ? '' : filter?.number,
            countryCode: filter?.countryCode ? filter.countryCode : props?.countryCode ? props.countryCode : '',
            pageNum: query.page + 1,
            numItemsPerPage: query.pageSize
        }
        if(props.fetchType){
            payload["fetchType"] = props.fetchType;
        }
        if (props?.countryCode === 'CL') {
            delete payload.state
            payload.county = (filter?.state === null || filter?.state === undefined) ? '' : filter?.state
        }
        /* istanbul ignore next */
        return GlobalDonationDataService.getFacilityDetails(payload).catch((e: any) => {
            if (e.response && e.response.status === 401) {
                window.location.href = config.pingfedUrl;
            }
        })
    }

    return (<>
        <div>
            <Dialog disableEnforceFocus={true}
                title={appi18n.modalTitle[props.selectedLang]}
                fullWidth={true}
                maxWidth={'md'}
                open={props.showModal}>
                <DialogTitle id='facility-search-dialog' className='facilty-dailog-title'>
                    {appi18n.modalTitle[props.selectedLang]}
                    <IconButton aria-label='close' className='pull-right' id='close-dailog'
                        onClick={() => props.handleModalAction(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div className='formio-errors'>
                        <div className='form-text error'>
                            {errorMessage}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <form>
                                <div className='form-group textFieldStyle'>
                                    <label>{appi18n.labelFacilityNo[props.selectedLang]}</label>
                                    <input type='text'
                                        className={`form-control ${facilityNoErr ? 'has-error is-invalid' : ''}`}
                                        name='facilityNo'
                                        id='facilityNo'
                                        value={facilityNo}
                                        placeholder={fetchTypeTranslation(props.fetchType,props.selectedLang)}
                                        
                                        onChange={handleFacilityChange} />
                                </div>
                                <div className='form-group selectFieldStyle dropdown'>
                                    <Autocomplete
                                        size={'small'}
                                        id='choose-Province'
                                        options={provinceList || []}
                                        noOptionsText={appi18n.noOptions[props.selectedLang]}
                                        // @ts-ignore
                                        value={province?.selectedProvince}
                                        // @ts-ignore
                                        onChange={handleProvinceChange}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        getOptionSelected={(option: any, value: any) => option.value === value.value}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        getOptionLabel={(option: any) => option.label}
                                        style={{ width: '100%' }}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        renderInput={(params: any) => <TextField {...params}
                                            label={appi18n.labelProvince[props.selectedLang]} variant='outlined' />}
                                    />
                                </div>
                                <div className='form-group selectFieldStyle dropdown'>
                                    <Autocomplete
                                        size={'small'}
                                        id='choose-city'
                                        value={city}
                                        options={cityList || []}
                                        noOptionsText={appi18n.noOptions[props.selectedLang]}
                                        // @ts-ignore
                                        onChange={handleCityChange}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        getOptionSelected={(option: any, value: any) => option === value}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        getOptionLabel={(option: any) => option}
                                        style={{ width: '100%' }}
                                        // tslint:disable-next-line: jsx-no-lambda
                                        renderInput={(params: any) => <TextField {...params}
                                            label={appi18n.labelCity[props.selectedLang]} variant='outlined' />}
                                    />
                                </div>

                                <div className='form-group row donation-request-btn button'>
                                    <div className='col-md-12'>
                                    </div>
                                </div>
                            </form>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <MuiThemeProvider theme={gridTheme}>
                                        <SubmissionGridComponent options={{ exportButton: false }}
                                            title={appi18n.searchResultText[props.selectedLang]} formAlias={''}
                                            key={refresh}
                                            filters={filters}
                                            remoteDataFunc={getSearchResults}
                                            columns={columns}
                                            selectedLanguage={props.selectedLang}
                                            i18n={appi18n}
                                            onRowSelect={handleRowSelection}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? <LoaderComponent fullScreen={true} /> : <></>}
                </DialogContent>
            </Dialog>
            {loading ? <LoaderComponent fullScreen={true} /> : <></>}
        </div>
    </>)
}
