

import { Box } from '@material-ui/core';
import { FC, useState } from 'react';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { contextPath } from '../../../../constants';
import { AccordionCompo } from '../../../common';
import { AppendixBRequestDTO } from '../../../../models/iOneTimeFoodDTO';
import FooterButton from '../../../common/FooterButton';
import GenericConfirmDialog from '../../../dialogComponents/GenericConfirmDialog';
import SuccessAlertDailog from '../../../dialogComponents/SuccessAlertDailog'
import LoaderComponent from '../../../LoaderComponent';
import InputConfirmDialog from 'src/components/dialogComponents/InputConfirmDialog';
import { LoadingSkeleton } from '../../moreDetailsSections/common/LoadingSkeleton';
import { ReviewSectionComponent } from '../../oneTimeGoods/components/ReviewSectionComponent';
import { useStyles } from '../../utils';
import { HighLightedBox } from '../common/HighLightedBox';
import AppendixBReducedForm from '../Create/AppendixBReducedForm';
// import AppendixBForm from '../Create/AppendixBForm';

interface OwnProps {
  appendixB: AppendixBRequestDTO
  countryCode: string,
  methods: any,
  submitDonation: any,
  showSubmit: boolean,
  isLoading: boolean,
  isError: boolean,
  errorMessage: string,
  isSuccess: boolean
  successMessage: any,
  isReview?: boolean,
  isFetching: boolean
  disabledReview?: any
  actionOptions?: any
}
let requestData: AppendixBRequestDTO | null;

const FoodGEForm: FC<OwnProps> = ({
  appendixB,
  countryCode,
  methods,
  submitDonation,
  showSubmit,
  isLoading,
  errorMessage,
  isError,
  isSuccess,
  successMessage,
  isReview = false,
  isFetching,
  disabledReview = false,
  actionOptions = false
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigateTo = useNavigate()

  const [showConfirm, setConfirm] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false);


  const onSubmit = (formData: AppendixBRequestDTO) => {
    setConfirm(true);
    requestData = formData
  }
  const onCancel = () => {
    if (showSubmit) {
      setShowCancelDialog(true)
    } else {
      onBack();
    }
  }
  const onBack = () => {
    navigateTo(`${contextPath}/dashboard/one-time-food?type=one-time-food`)
  }

  const cancelSubmit = () => {
    requestData = null;
    setConfirm(false);
  }

  const confirmSubmit = () => {
    setConfirm(false);
    submitDonation(requestData)
  }

  const handleConfirmActionDialog = async (comments: string) => {
    const payload = {
      ...requestData,
      comments
    }
    setConfirm(false);
    submitDonation(payload)
  }

  return <>
    {isLoading && <LoaderComponent fullScreen={true} />}
    {isFetching && <LoadingSkeleton />}
    {
      appendixB && !isFetching && <FormProvider {...methods}>
        <form data-testid='ngoForm'
          autoComplete='off'
          noValidate={true}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <AccordionCompo
            defaultExpanded={true}
            accordionHeader='common.heading.appendixB'
            accordionDetails={
              <AppendixBReducedForm
                apendixBDataDefault={appendixB}
                appendixBformData={undefined}
                countryCode={countryCode}
                maxAllowedBudget={Infinity}
                formDisabled={!showSubmit}
                disabledSections={[]}
                requestSourceApp={'external'}
              />}
          />
          {showSubmit && isReview &&
            <HighLightedBox>
              <ReviewSectionComponent
                formErrors={methods.errors} control={methods.control}
                fieldName={'action'} required={true} rules={{ required: t('common.dropDown.reviewDropDownLabel') }}
                defaultValue={''}
                classes={classes}
                disabled={disabledReview}
                actionOptions={actionOptions}
              />
            </HighLightedBox>
          }
          <Box display='flex' m={2}>
            <FooterButton variant='outlined'
              // @ts-ignore
              onClick={onCancel}>{t('common.buttonLabels.cancel')}</FooterButton>
            <Box component='span' mr={2} />
            {showSubmit &&
              <FooterButton
                color='primary' variant='contained' type='submit'>
                {t('common.buttonLabels.submitBtn')}
              </FooterButton>}
          </Box>
        </form>
        <ErrorSnackbarComponent
          isError={isError}
          // @ts-ignore
          message={errorMessage}
        />
        <SuccessAlertDailog
          // @ts-ignore
          showDialog={isSuccess}
          maxWidth={'lg'}
          ok={onBack}
          title={t('common.successTitle.donationSuccess')}
          message={successMessage}
        />
        <GenericConfirmDialog
          id={'dgsa_cancel_popup'}
          showDialog={showCancelDialog}
          cancel={() => setShowCancelDialog(false)}
          confirm={onBack}
          title={t('monetaryDonation.dialogMsgs.warning')}
          message={t('common.popUpMsg.cancelDonationRequest.msg')}
          cancelButton={t('common.buttonLabels.no')}
          confirmButton={t('common.buttonLabels.yes')}
        />
        {!isReview ? <GenericConfirmDialog
          id={'dgsa_cnfrm_popup'}
          showDialog={showConfirm}
          cancel={cancelSubmit}
          confirm={confirmSubmit}
          title={t('common.buttonLabels.submitBtn')}
          message={t('common.popUpMsg.submitNgoDonationRDAMsg')}
          cancelButton={t('common.buttonLabels.cancel')}
          confirmButton={t('common.buttonLabels.confirm')}
        /> :
          <InputConfirmDialog
            id={'ngo-form-inputConfirmDialog'}
            title={t('status.additionalInformation')}
            showDialog={showConfirm}
            onClose={cancelSubmit}
            onSubmit={handleConfirmActionDialog}
          />}
      </FormProvider>
    }
  </>
}


export default FoodGEForm;
