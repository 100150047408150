import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { OrganizationTypes } from 'src/components/common/utils';
import IOrganizationInfo from 'src/models/iOrganizationInfo';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { clearSelectedData } from '../../../state/selectedData/selectedDataActions';
import { getAppendixBDataFromOrgData } from './common/helper';
import GEReviewDonationOfFood from './GEReviewDonationOfFood';
import NGOReviewDonationOfFood from './NGOReviewDonationOfFood'

interface StateProps {
  selectedLang: any;
  selectedDonationData: any;
}

interface DispatchProps {
  clearSelectedData: (data: any) => void;
}

interface OwnProps {
  orgData: IOrganizationInfo
}

class ReviewDonationOfFood extends React.PureComponent<OwnProps & StateProps & DispatchProps>  {

  public render() {
    const selectedData = (this.props.selectedDonationData?.ONE_TIME_FOOD) ?
      JSON.parse(JSON.stringify(this.props.selectedDonationData?.ONE_TIME_FOOD)) : {};

    return <>{selectedData.id &&
      selectedData?.organization?.organizationType?.keyText === OrganizationTypes.NGO ?
      <NGOReviewDonationOfFood
        donationId={selectedData.id}
        countryCode={this.props?.orgData?.countryCode}
        selectedLang={this.props.selectedLang}
        appendixB={getAppendixBDataFromOrgData(this.props?.orgData, OrganizationTypes.NGO)}
        storeNumber={selectedData.storeNumber}
      />
      :
      <GEReviewDonationOfFood
        donationId={selectedData.id}
        countryCode={this.props?.orgData?.countryCode}
        selectedLang={this.props.selectedLang}
        appendixB={getAppendixBDataFromOrgData(this.props?.orgData, OrganizationTypes.GE)}
        storeNumber={selectedData.storeNumber}
      />
    }
    </>
  }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  selectedLang: state.selectedLang,
  selectedDonationData: state.selectedDataState.addDataToStore,
});
/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  clearSelectedData: (data) => dispatch(clearSelectedData(data)),
});

export default withTranslation()
  (connect(mapStateToProps, mapDispatchToProps)(ReviewDonationOfFood));
