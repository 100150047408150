import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import '../../../../styles/formStyles.css';
import { getUpdateRequestPayload, useStyles } from '../../utils';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AccordionCompo, AlertBox, Button, Heading } from '../../../common';
import { useFetchOneTimeGoodsDonationDetailsById, useUpdateOneTimeGoodsDonation } from '../../../../custom-hooks';
import { getFieldDisabledAttributes, getTransformedReviewActions, isAckUploadEnabled, isReviewSectionEnabled, useScrollToError } from '../../../common/utils';
import { AppendixBForm } from '../../../common/formComponents/AppendixBForm';
import { ReviewSectionComponent } from './ReviewSectionComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import InputConfirmDialog from '../../../dialogComponents/InputConfirmDialog';
import SuccessAlertDailog from '../../../dialogComponents/SuccessAlertDailog';
import { commonFormTranslations, DonationStatus } from '../../../../constants';
import { CartonDetails } from '../cartonDetails';
import EmployeeDonationDetails from '../EmployeeDonationDetails';
import { AboutOrganization } from '../organisationDetails';
import RecipientDonationDetails from '../RecipientDonationDetails';
import LoaderComponent from '../../../../components/LoaderComponent';
import { formErrorEleClass } from '../../../../styles/styleUtil';
import { useNavigate } from 'react-router';


export const NgoReviewFromComponent = (props: any) => {
    const { t } = useTranslation();
    const [showActionDailog, setShowActionDailog] = useState(false);
    const [formData, setFormData] = useState<any>();
    const [donationData, setData] = useState<any>();
    const queryClient = useQueryClient()
    const navigateTo = useNavigate();

    // Fetch donation details by id
    const { response, isError, isSuccess, isLoading } = useFetchOneTimeGoodsDonationDetailsById(props.donationId)

    // for updating donation details
    const {
        mutateAsync,
        isUpdateLoading,
        isUpdateError,
        isUpdateSuccess,
        updateError
    } = useUpdateOneTimeGoodsDonation();

    // If donationId is empty redirect to dashboard
    useEffect(() => {
        if (!props?.donationId) {
            redirectToDashboard()
        }
    }, [props.donationId])
    useEffect(() => {
        const isWaitingForAppendixB = response?.goodsOneTimeDonation?.currentStatusCode === DonationStatus.WaitingforAppendixB
        if (isWaitingForAppendixB) {
            response.goodsOneTimeDonation.appendixB.organization = props?.organization
        }
        if (isSuccess) {
            setData({
                ...response,
                ...response?.goodsOneTimeDonation,
                ...response?.goodsOneTimeDonation?.organization,
                ...response?.goodsOneTimeDonation?.donationDetail,
                appendixB: {
                    organization:
                        isWaitingForAppendixB
                            ? props?.organization
                            : response?.goodsOneTimeDonation?.appendixB?.organization,
                    donationDetail:
                        isWaitingForAppendixB
                            ? {}
                            : response?.goodsOneTimeDonation?.appendixB?.donationDetail
                }
            })
        }
    }, [isSuccess])

    const onSubmit = (data: any) => {
        setShowActionDailog(true);
        const payload = { ...data };
        setFormData(payload);
    };

    const closeActionDailog = () => {
        setShowActionDailog(false);
    }

    const handleConfirmActionDialog = async (comments: any) => {

        const form = new FormData();
        setShowActionDailog(false);
        const donationAttachments = formData?.donationAttachments ? [...formData.donationAttachments] : [];
        const organizationAttachments = formData?.organizationAttachments ? [...formData.organizationAttachments] : [];
        if (Array.isArray(donationAttachments)) {
            donationAttachments.forEach((attachment: any) => {
                form.append('donationAttachments', attachment);
            });
        }
        if (Array.isArray(organizationAttachments)) {
            organizationAttachments.forEach((attachment: any) => {
                form.append('organizationAttachments', attachment);
            });
        }
        delete formData?.organizationAttachments
        delete formData?.donationAttachments
        const payload = getUpdateRequestPayload(formData, response, comments, formData?.action?.value)
        form.append('donationWaasTask', JSON.stringify(payload));
        form.append('modifyOrg', props?.modifyOrg)
        await mutateAsync(form);
        queryClient.invalidateQueries('fetchOneTimeGoodsDonationDetailsById')
    }

    const redirectToDashboard = () => {
      navigateTo(props?.redirectUrl)
    }
    const { control, setValue, errors } = useForm();

    const defaultBoarMemberData = [{
        userName: '',
        userTitle: '',
        birthYear: null,
        cityOfResidence: '',
        stateOfResidence: '',
        countryOfResidence: ''
    }]

    const boardMembers = donationData?.appendixB?.organization?.boardMembers || []
    const executiveOfficers = donationData?.appendixB?.organization?.executiveOfficers || []
    const keyPersons = donationData?.appendixB?.organization?.keyPersons || []

    const formSubmitMethods = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        defaultValues: {
            langCode: props.selectedLang,
            ...donationData?.appendixB,
            certificateSignee: { ...donationData?.appendixB?.organization?.certificateSignee },
            consentSignee: { ...donationData?.appendixB?.organization?.consentSignee },
            certificateSignedDate: donationData?.appendixB?.organization?.certificateSignedDate,
            boardMembers: boardMembers?.length > 0 ? boardMembers : defaultBoarMemberData,
            executiveOfficers: executiveOfficers?.length > 0 ? executiveOfficers : defaultBoarMemberData,
            keyPersons: keyPersons?.length > 0 ? keyPersons : defaultBoarMemberData,
        }
    });

    useScrollToError(formSubmitMethods?.errors, formErrorEleClass)
    const classes = useStyles();
    const isFileUploadEnabled = isAckUploadEnabled(donationData?.currentStatusCode);
    const fieldDisabledSection = getFieldDisabledAttributes(donationData?.currentStatusCode);
    const showReviewSection = isReviewSectionEnabled(donationData?.currentStatusCode);

    return (<>
        <div style={{ marginBottom: '30px' }} className={'bodyStyles ngoFormWrapper'}>
            {donationData?.comments &&
                <AlertBox
                    rowStyles={{ marginBottom: '30px' }}
                    severity={'warning'}
                    message={donationData?.comments}
                    colGrid={'col-md-12'}
                />
            }
            <FormProvider  {...formSubmitMethods}>
                <form
                    onSubmit={formSubmitMethods.handleSubmit(onSubmit)}
                    noValidate={true}
                    autoComplete='off'
                >
                    {donationData?.storeNumber &&
                        <Heading
                            key={`form-heading-data-${donationData?.storeNumber}`}
                            id={'review-store-number'}
                            className={'h14_title'}
                            level={'h6'}
                        >
                            {t('common.labels.storeNumber')}
                            <span>{donationData?.storeNumber}</span>
                        </Heading>
                    }
                    {(donationData?.goodsOneTimeDonation?.additionalDetails?.appendixBIsFilled ||
                        donationData?.goodsOneTimeDonation?.currentStatusCode === 'WAITING_FOR_APPENDIX_B') &&
                        <AccordionCompo
                            className={'ngo-reviewform-appendixB'}
                            defaultExpanded={true}
                            accordionHeader={'common.heading.appendixB'}
                            accordionDetails={<AppendixBForm
                                selectedLang={props.selectedLang}
                                isReview={false}
                                appendixBOrgDetailsDisabled={fieldDisabledSection}
                                appendixBQuestionaireDisabled={fieldDisabledSection}
                                appendixBDonationDetailsDisabled={fieldDisabledSection}
                                orgMemberTypeComponentDisabled={fieldDisabledSection}
                                appendixBOrgMemberQuestionnaireDisabled={fieldDisabledSection}
                                appendixBCertificationDisabled={fieldDisabledSection}
                                appendixBConsentDisabled={fieldDisabledSection}
                                appendixBPrimaryUserDetailsDisabled={fieldDisabledSection}
                                classes={classes}
                                setOrgmebersData={true}
                                data={donationData?.appendixB}
                            />}
                        />}
                    {donationData?.goodsOneTimeDonation?.additionalDetails?.appendixCIsFilled &&
                        <AccordionCompo
                            className={'ngo-reviewform-appendixB'}
                            defaultExpanded={true}
                            accordionHeader={'common.heading.appendixC'}
                            accordionDetails={
                                <>
                                    <AboutOrganization
                                        control={control}
                                        errors={errors}
                                        selectedLang={props?.selectedLang}
                                        classes={classes}
                                        setValue={setValue}
                                        data={donationData} />
                                    <CartonDetails
                                        cartonDetails={donationData?.carton}
                                        countryCode={donationData?.goodsOneTimeDonation?.countryCode}
                                    />
                                    <EmployeeDonationDetails
                                        control={control}
                                        errors={errors}
                                        selectedLang={props?.selectedLang}
                                        classes={classes}
                                        setValue={setValue}
                                        data={donationData} />
                                    <RecipientDonationDetails
                                        control={control}
                                        errors={errors}
                                        selectedLang={props?.selectedLang}
                                        classes={classes}
                                        setValue={setValue}
                                        data={donationData}
                                        isFileUploadEnabled={isFileUploadEnabled}
                                        disabled={true}
                                    />
                                </>
                            }
                        />}
                    {donationData?.waaSTaskMessage?.formProperties?.length > 0 && showReviewSection &&
                        <ReviewSectionComponent
                            formErrors={formSubmitMethods.errors}
                            control={formSubmitMethods.control}
                            fieldName={'action'}
                            required={true}
                            rules={{
                                required: t('common.dropDown.reviewDropDownLabel')
                            }}
                            defaultValue={''}
                            actionOptions={
                                getTransformedReviewActions(donationData?.waaSTaskMessage?.formProperties)
                            }
                            classes={{
                                ...classes
                            }}
                        />
                    }
                    {donationData?.waaSTaskMessage?.formProperties?.length > 0 && showReviewSection &&
                        <div className='row' style={{ marginTop: '25px' }}>
                            <div className='col-md-5'>
                                <Button
                                    id={'ngoForm_submit'}
                                    className={'submit_button_styles ngo_next_button'}
                                    type={'submit'}
                                    title={t('common.buttonLabels.submitBtn')}
                                />
                            </div>
                        </div>
                    }
                </form>
            </FormProvider>
        </div>
        <InputConfirmDialog
            id={'ngo-form-inputConfirmDialog'}
            title={t('status.additionalInformation')}
            showDialog={showActionDailog}
            onClose={closeActionDailog}
            onSubmit={handleConfirmActionDialog}
        />
        <SuccessAlertDailog
            id={'successTitle-donationSuccess'}
            showDialog={isUpdateSuccess}
            maxWidth={'lg'}
            ok={() => redirectToDashboard()}
            title={t('common.successTitle.donationSuccess')}
            message={
                <Trans i18nKey='common.successMsg.donationUpdateMsg'>
                    Donation has been updated Successfully
                </Trans>
            }
        />
        <ErrorSnackbarComponent
            isError={isError}
            classes={props.classes}
            message={response?.errorMessage || commonFormTranslations.genericErrorMessage[props.selectedLang]}
        />
        <ErrorSnackbarComponent
            isError={isUpdateError}
            classes={props.classes}
            // @ts-ignore
            message={updateError?.errorMessage || commonFormTranslations.genericErrorMessage[props.selectedLang]}
        />
        {(isLoading || isUpdateLoading) && <LoaderComponent fullScreen={true} />}
    </>)
}
