import React from 'react';
import SideBar from './sideBar';
import { connect } from 'react-redux';
import { GlobalDonationState } from './../../../../../models/globalDonationState';
import Walmart_Spark from '../../../../../../src/components/spacedonation/commonComponents/assets/logos/Walmart_Spark.png'
import { navBarClose } from '../../../../../state/spaceDonation/navbar/navBarActions';

export interface Props {
    showSideBar?: any,
    navBarClose?: any
}

class SideBarComponent extends React.Component<Props> {
    public state = {
        showSideBar: false
    }

    public render() {
        return <div className={this.props.showSideBar ? 'sidebarparentactive' : 'sidebarparent'}>
            <div className={this.props.showSideBar ? 'sidebaractive' : 'sidebar'}>
                <SideBar {...this.props} />
                <img className='sidebar-logo' height='100px' width='100px'
                    src={Walmart_Spark} />
            </div></div>
    }
}

const mapStateToProps = (state: GlobalDonationState): any => ({
    showSideBar: state.navBarState.open
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): any => ({
    navBarClose: () => dispatch(navBarClose())
});


export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponent);
