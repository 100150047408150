import { Action } from 'redux';

export enum DonationApprovalActionTypes {
    DONATION_APPROVAL_LOAD = 'DONATION_APPROVAL_LOAD',
    DONATION_APPROVAL_SUCCESS = 'DONATION_APPROVAL_SUCCESS',
    DONATION_APPROVAL_FAILURE = 'DONATION_APPROVAL_FAILURE',
    OPEN_APPROVAL_RESP_DAILOG = 'OPEN_APPROVAL_RESP_DAILOG',
    CLOSE_APPROVAL_RESP_DAILOG = 'CLOSE_APPROVAL_RESP_DAILOG'
}

export interface DonationApprovalData extends Action {
    type: DonationApprovalActionTypes.DONATION_APPROVAL_LOAD;
    data: string;
}

export const donationApprovalData = (data: string): DonationApprovalData => ({
    type: DonationApprovalActionTypes.DONATION_APPROVAL_LOAD,
    data
});

export interface DonationApprovalSuccess extends Action {
    type: DonationApprovalActionTypes.DONATION_APPROVAL_SUCCESS;
    data: any;
}

export const donationApprovalSuccess = (data: any): DonationApprovalSuccess => ({
    type: DonationApprovalActionTypes.DONATION_APPROVAL_SUCCESS,
    data
});

export interface DonationApprovalFailure extends Action {
    type: DonationApprovalActionTypes.DONATION_APPROVAL_FAILURE;
    data?: any;
}

export const donationApprovalFailure = (data: any): DonationApprovalFailure => ({
    type: DonationApprovalActionTypes.DONATION_APPROVAL_FAILURE,
    data
});

export interface OpenDonationApprovalRespDailog extends Action {
    type: DonationApprovalActionTypes.OPEN_APPROVAL_RESP_DAILOG;
}

export const openDonationApprovalRespDailog = (): OpenDonationApprovalRespDailog => ({
    type: DonationApprovalActionTypes.OPEN_APPROVAL_RESP_DAILOG,
});


export interface CloseDonationApprovalRespDailog extends Action {
    type: DonationApprovalActionTypes.CLOSE_APPROVAL_RESP_DAILOG;
}

export const closeDonationApprovalRespDailog = (): CloseDonationApprovalRespDailog => ({
    type: DonationApprovalActionTypes.CLOSE_APPROVAL_RESP_DAILOG,
});



export type DonationApprovalActions = DonationApprovalData | DonationApprovalSuccess | DonationApprovalFailure |
    CloseDonationApprovalRespDailog | OpenDonationApprovalRespDailog;