import React, { useContext, useEffect, useState,useRef } from 'react';
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import './ngoEvent.css';
import { ChevronRight } from '../../commonComponents/icons/icon';
import { Card, ButtonGroup } from '@walmart-web/livingdesign-components';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import SearchBar from '../../commonComponents/appComponents/storeSearch/StoreSearch';
import { hoSearchContext } from '../../commonComponents/appComponents/drawer/drawer';
import { useLocation, useNavigate } from 'react-router';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { contextPath, reactQueryConfigOptions } from 'src/constants';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import moment from 'moment';
import { Plus } from '@livingdesign/icons';
import { EventInfo } from './EventInfo/EventInfo';
import { IconButton, Tooltip } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import NgoDownloadReport from './NgoDownloadReport/ngoDownloadReport';
import CampaignApprovalModal from './campaign-footer/CampaignApprovalModal';
import {AlertInfo} from './CalendarViewNgo'
import { useHistory } from 'src/polyfils/customRouter';

const service: Service = SpaceDonationService;

export interface NgoEventProp {
  location?: {
    state: {
      data?: any;
    };
  };
}
export interface DisplayEventDetailsInfoProp {
  ncId?: any;
  orgId?: any;
}
const NgoEvent: React.FunctionComponent<NgoEventProp> = () => {
  const data = useLocation()?.state as DisplayEventDetailsInfoProp;
  const navigateTo = useNavigate();

  const handleClick = () => {
    navigateTo(`${contextPath}/space-donation/edit/store-select`, {
      state: { ncid: data?.ncId, orgid: data?.orgId }
    });
  }

  const handleRedirect = () => {
    navigateTo(`${contextPath}/space-donation/events`);
  };

  if (!(data?.ncId || data?.orgId)) {
    handleRedirect();
  }
  const [showApprove, setShowApprove] = useState(false);
  const [isDownloadSuccess,setIsDownloadSuccess] = useState(false);
  const [isDownloadFailure,setIsDownloadFailure] = useState(false);
  const hideAllAlerts = () => {
    setIsDownloadSuccess(false);
    setIsDownloadFailure(false);
};
  const handleApproveAction = (userAction: string) => {
    setShowApprove(false);
    if (userAction === 'approve') {
      setShowApprove(false);
    } else if (userAction === 'cancel') {
      setShowApprove(false);
    }
  }
  const { isLoading: isDetailsLoading, data: modaldetails } =
    useQuery([], service.getDownloadModalDetails, { ...reactQueryConfigOptions });
  return (
    <>
      <div className='linkContainer'>
        <LDButton
          data-testid='event-button'
          variant='tertiary'
          size='small'
          className='buttonLink'
          onClick={() => {
            navigateTo(`${contextPath}/space-donation/events/`);
          }}
        >
          Events /
        </LDButton>
        <span className='spanLink'>Home Office Event</span>
      </div>
      <div className='alert-section'>
          {isDownloadSuccess && (
            <AlertInfo
              level='success'
              message='Successfully Sent Request for Download'
              hideAllAlerts={hideAllAlerts}
            />
          )}
          {isDownloadFailure && (
            <AlertInfo
              level='error'
              message='Something went wrong while downloading'
              hideAllAlerts={hideAllAlerts}
            />
          )}
      </div>
      <div>
        <div className='ngo-nc-event-header-wrap'>
          <h4 className='event-details-header'>Home Office Event</h4>
          {showApprove && (
            <NgoDownloadReport
              userAction={handleApproveAction}
              isDetailsLoading={isDetailsLoading}
              modaldetails={modaldetails}
              ncId={data?.ncId}
              orgId={data?.orgId}
              setShowApprove={setShowApprove}
              setIsDownloadSuccess={setIsDownloadSuccess}
              setIsDownloadFailure={setIsDownloadFailure}
            />
          )}
          <ButtonGroup>
            <LDButton size='small' onClick={handleClick} leading={<Plus />}>Add Stores</LDButton>
              <LDButton
                variant='secondary'
                size='small'
                data-testid='download-modal'
                onClick={() => setShowApprove(true)}
              >
                <IconButton>
                  <CloudDownload />
                </IconButton>
                Download Report
              </LDButton>
          </ButtonGroup>
        </div>
        <DisplayEventDetails ncId={data?.ncId} orgId={data?.orgId} />
      </div>
    </>
  );
};
export interface DisplayEventDetailsInfoProp {
  details?: any;
  ncId?: any;
  orgId?: any;
}

export const DisplayEventDetails: React.FunctionComponent<
  DisplayEventDetailsInfoProp
> = ({ ncId, orgId }) => {
  const [, setStoreNbr] = useState();
  const [showApprove, setShowApprove] = React.useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = React.useState(false);
  const [isSaveError, setisSaveError] = React.useState(false);
  const cancelReq = useMutation(service.cancelRequestCampaign);
  return (
    <div className='event-details-display'>
      <div className='event-details-table-display'>
        <div className='event-details-table-display-info'>
          <DisplayStoreLevelInfo
            ncId={ncId}
            orgId={orgId}
            showApprove={showApprove}
            setShowApprove={setShowApprove}
            setStoreNbr={setStoreNbr}
            cancelReq={cancelReq}
            isSaveSuccess={isSaveSuccess}
            setIsSaveSuccess={setIsSaveSuccess}
            isSaveError={isSaveError}
            setisSaveError={setisSaveError}
          />
        </div>
      </div>
    </div>
  );
};
const checkFilterList=(searchContextVal:any)=>{
 return searchContextVal?.length > 0 ? searchContextVal : []
}
export interface DisplayStoreLevelInfoProp {
  ncId: any;
  orgId: any;
  setStoreNbr?: any;
  showApprove:any;
  setShowApprove: (values: any) => void;
  isSaveSuccess: boolean;
  setIsSaveSuccess:(values: any) => void;
  isSaveError: boolean;
  setisSaveError:(values: any) => void;
  cancelReq:any;
}

export const DisplayStoreLevelInfo: React.FunctionComponent<
  DisplayStoreLevelInfoProp
> = ({ ncId, orgId, setStoreNbr,showApprove,setShowApprove,cancelReq,setisSaveError,isSaveError,setIsSaveSuccess,isSaveSuccess }) => {
  const eventsData = useMutation(service.fetchNgoEvents);
  const [pageNumber, setPageNumber] = useState(1);
  const [status, setStatus] = useState('APPROVED');
  const { searchContextVal } = useContext(hoSearchContext);
  const previousSearchValue = useRef(searchContextVal);
  const reqPayload = {
    orgId,
    ncId,
    pageNum: pageNumber,
    pageSize: 10,
    status,
    filterList: checkFilterList(searchContextVal),
  };
  const history = useHistory();
  useEffect(() => {
    eventsData?.mutate(reqPayload);
  }, [status, pageNumber]);
  useEffect(() => {
      const previousSearchValueSorted = previousSearchValue.current
            ?.slice()
            .sort();
          if (
            !(
              searchContextVal.length === previousSearchValueSorted.length &&
              searchContextVal
                .slice()
                .sort()
                .every(
                  (value: any, index: any) =>
                    value === previousSearchValueSorted[index]
                )
            )
          ) {
            previousSearchValue.current = searchContextVal;
            eventsData?.mutate(reqPayload);
          }
    }, [searchContextVal]);
  useEffect(() => {
    if (cancelReq?.isSuccess) {
      hideAllAlerts();
      setIsSaveSuccess(true);
      setTimeout(() => {
        history.push({
          pathname: `${contextPath}/space-donation/events/`,
        });
        return null;
      }, 2000);
    }
  }, [cancelReq?.isSuccess]);
  useEffect(() => {
    if (cancelReq.isError === true) {
      hideAllAlerts();
      setisSaveError(true);
    }
  }, [cancelReq?.isError]);

  const handleApproveAction = (userAction: string) => {
    setShowApprove(false);
    if (userAction === 'approve') {
      cancelReq.mutate({ ncId, orgId });
    } else if (userAction === 'cancel') {
      setShowApprove(false);
    }
  };
   const hideAllAlerts = () => {
      setIsSaveSuccess(false);
      setisSaveError(false);
  };
  return (
    <>
      {(eventsData?.isLoading || cancelReq?.isLoading) && <LoaderComponent />}
      <div className='alert-section'>
        {isSaveSuccess && (
          <AlertInfo
            level='success'
            message='Successfully Cancelled'
            hideAllAlerts={hideAllAlerts}
          />
        )}
        {isSaveError && (
          <AlertInfo
            level='error'
            message='Something went wrong while cancelling the request'
            hideAllAlerts={hideAllAlerts}
          />
        )}
      </div>
      <Card className='event-ngo-detail-table-card'>
        <div className='event-detail-table-card-header'>
          <div className='event-details-information-header-section'>
            <span className='event-details-information-header-title'>
              Store response
            </span>
          </div>
        </div>
        <StoreLevelInfo
          data={eventsData?.data}
          setPageNumber={setPageNumber}
          setStatus={setStatus}
          status={status}
          ncId={ncId}
          orgId={orgId}
          setStoreNbr={setStoreNbr}
        />
        <LDButton
        variant='tertiary'
        size='small'
        className='button-cancel'
        data-testid='button-cancel-request'
        onClick={() => {
          setShowApprove(true);
        }}
      >
        Cancel request
      </LDButton>
      {showApprove && (
        <CampaignApprovalModal
          userAction={handleApproveAction}
          bodyText={'Are you sure want to cancel the entire campaign?'}
        />
      )}
      </Card>
    </>
  );
};

export interface StoreLevelInfoProp {
  data: any[];
  setPageNumber: any;
  setStatus: any;
  status: any;
  ncId: any;
  orgId: any;
  setStoreNbr?: any;
}

export const StoreLevelInfo: React.FunctionComponent<StoreLevelInfoProp> = ({
  data,
  setPageNumber,
  setStatus,
  status,
  ncId,
  orgId,
  setStoreNbr,
}) => {
  const { data: counts, isLoading } = useQuery(
    [
      'fetchNgoEventStatusCount',
      {
        ncId,
        orgId,
      },
    ],
    service.fetchNgoEventStatusCount,
    { ...reactQueryConfigOptions }
  );
  const approvedLabel = (
    <span className='event-request-storeinfo-label'>
      <span>Approved</span>
      <Badge className='event-details-storeinfo-badge'>
        {isLoading ? '...' : counts?.approved}
      </Badge>
    </span>
  );
  const pendingdLabel = (
    <span className='event-request-storeinfo-label'>
      <span>Pending</span>
      <Badge className='event-details-storeinfo-badge'>
        {isLoading ? '...' : counts?.pending}
      </Badge>
    </span>
  );
  const optOutLabel = (
    <span className='event-request-storeinfo-label'>
      <span>Opt Out</span>
      <Badge className='event-details-storeinfo-badge'>
        {isLoading ? '...' : counts?.optedOut}
      </Badge>
    </span>
  );

  const [value, setValue] = useState('1');
  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  const handleTabChange = (newStatus: any) => {
    if (newStatus !== status) {
      setStatus(newStatus);
      setPageNumber(1);
    }
  };

  return (
    <div className='nc-event-search-bar'>
      <Box>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange}>
              <Tab
                value='1'
                label={approvedLabel}
                onClick={() => handleTabChange('APPROVED')}
              />
              <Tab
                value='2'
                label={pendingdLabel}
                onClick={() => handleTabChange('SUBMITTED')}
              />

              <Tab
                value='3'
                label={optOutLabel}
                onClick={() => handleTabChange('REJECTED')}
              />
            </TabList>
          </Box>
          <LDDivider />
          <SearchBar multiple={true} disableCloseOnSelect={false} />
          <TabPanel value='1'>
            <StoreLevelApprovedInfo
              data={data}
              setPageNumber={setPageNumber}
              ncId={ncId}
              orgId={orgId}
              status={'APPROVED'}
            />
          </TabPanel>
          <TabPanel value='2'>
            <StoreLevelApprovedInfo
              setStoreNbr={setStoreNbr}
              data={data}
              setPageNumber={setPageNumber}
              ncId={ncId}
              orgId={orgId}
              status={'SUBMITTED'}
            />
          </TabPanel>
          <TabPanel value='3'>
            <StoreLevelApprovedInfo
              data={data}
              setPageNumber={setPageNumber}
              ncId={ncId}
              orgId={orgId}
              status={'REJECTED'}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export interface StoreLevelTableInfoProp {
  data: any;
  setPageNumber: any;
  setModal?: any;
  setStoreNbr?: any;
}
const ApprovedListActions = ({ ncId, orgId, row, status }: any) => {
  const navigateTo = useNavigate();
  return (
    <>
      <ChevronRight
        className='event-info-details-button-icon'
        onClick={() => {
          navigateTo(`${contextPath}/space-donation/events/ngo-detail/calendar`, {
            state: { data: row, ncId, orgId, status }
          })
        }}
      />
    </>
  );
};

export interface StoreLevelTableInfoProp {
  data: any;
  setPageNumber: any;
  ncId?: any;
  orgId?: any;
  status: any;
}

export const StoreLevelApprovedInfo: React.FunctionComponent<
  StoreLevelTableInfoProp
> = ({ data, setPageNumber, ncId, orgId, status }) => {
  const format = 'MMM DD, YYYY';

  const columns = [
    {
      name: 'Store #',
      selector: (row: any) => row?.storeNbr,
      sortable: true,
    },
    {
      name:<div style={{minWidth:'30px'}}>Store Format</div>,
      selector: (row: any) => row?.storeInfo?.storeFormat,
      sortable: true,
      cell: (row: any) =><Tooltip title={row?.storeInfo?.storeFormat} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.storeInfo?.storeFormat}</span></Tooltip>,
    },
    {
      name: 'Address',
      selector: (row: any) => row?.storeInfo?.address,
      sortable: true,
      cell: (row: any) =><Tooltip title={row?.storeInfo?.address} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.storeInfo?.address}</span></Tooltip>,
    },
    {
      name: 'City',
      selector: (row: any) => row?.storeInfo?.city,
      sortable: true,
      cell: (row: any) =><Tooltip title={row?.storeInfo?.city} placement='right'>
            <span className='ngo-nc-address-span-tooltip'>{row?.storeInfo?.city}</span></Tooltip>,
    },
    {
      name: 'State',
      selector: (row: any) => row?.storeInfo?.state,
      sortable: true,
    },
    {
      name: 'ZIP',
      selector: (row: any) => row?.storeInfo?.postalCode,
      sortable: true,
    },
    ...(status === 'APPROVED'
      ? [
        {
          name:<div>Approved on</div>,
          selector: (row: any) =>
          row?.approvedDate ?  moment(row?.approvedDate).format(format) : '',
          sortable: true,
        },
      ]
      : []),
    ...(status === 'APPROVED'
      ? [
        {
          name:<div>Requested days</div>,
          selector: (row: any) =>
            row?.dayStats?.approved + '/' + row?.dayStats?.total,
          sortable: true,
        },
      ]
      : []),

    {
      name: 'Action',
      cell: (row: any) => (
        <ApprovedListActions
          row={row}
          status={status}
          ncId={ncId}
          orgId={orgId}
        />
      ),
      sortable: false,
    },
  ];
  return (
    <div>
      <DataTable
        columns={columns}
        data={data?.data}
        pagination={true}
        paginationServer={true}
        onChangePage={setPageNumber}
        paginationPerPage={10}
        paginationTotalRows={data?.totalNoOfRecords}
        paginationRowsPerPageOptions={[10]}
      />
      <EventInfo ncId={ncId} orgId={orgId} />
    </div>
  );
};
export default NgoEvent;
