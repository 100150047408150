import React, { useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Callout, Checkbox } from '@walmart-web/livingdesign-components';

import startOfDay from 'date-fns/startOfDay';
import moment from 'moment';
import './ReserveDateTime.css';
import LDModal from '../../commonComponents/uiComponents/LDModal';
import {
  LocalizationProvider,
  PickersDay,
  StaticDatePicker
} from '@mui/x-date-pickers';
import Box from '@material-ui/core/Box';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Tab from '@material-ui/core/Tab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import DataTable from 'react-data-table-component';
import { Tooltip } from '@material-ui/core';
import { checkConsecutiveDays, useTimeoutState } from '../ngoEvent/MultiDatePickerForSelect';
const DATE_FORMAT_SHORT = 'MMM DD';
const DATE_FORMAT_LONNG = 'MM-DD-yyyy';

export const findIndexDate = (dates: any, date: any) => {
  const dateTime = date.getTime();
  return dates.findIndex((item: any) => item.getTime() === dateTime);
};

export const findDate = (dates: any, date: any) => {
  return dates.find((item: any) => moment(item).isSame(moment(date), 'day'));
};

export const getEventSlotIds = (eventSlots: any[]) => eventSlots.map((item: any) => item.slotId);

export const getEventSlotObj = (eventSlots: any[], slotId: number) => eventSlots.find((item: any) => item.slotId === slotId);

export const getSelectedSlotsList = (eventSlots: any[], selected: any[]) =>
  selected.map((slotId: number) => getEventSlotObj(eventSlots, slotId));

type LegendTypes = 'selected' | 'available' | 'notAvailable';
interface DateEventSlotsProps {
  eventSlots: any[];
  values: Date[];
  selectedSlots: any;
  customSlot: boolean;
  readOnly?: boolean;
  handleSlotSelect: (slotId: number, currentDate?: any) => void;
}

interface ReserveDateTimeProps {
  isOpen: boolean;
  eventSlots: any[];
  minDate: Date;
  maxDate: Date;
  selectedStores: any[];
  timeSelectedStores: any[];
  maxAllowedDays?: any;
  disabled?: boolean;
  onClose: () => void;
  onConfirmSelection: (selection: any) => void;
  defaultCalendarMonth?: Date;
  consecutiveDaysRestriction?: boolean;
  restrictedDays?: number
}

const Legend: React.FC<{ label: string, type: LegendTypes }> = ({ label, type }) => {
  const styles = {
    selected: 'selected-dates ml-1',
    available: 'available-dates',
    notAvailable: 'not-available-dates'
  }[type];

  return (
    <span className='d-flex align-items-center mx-1'>
      <Badge UNSAFE_className={styles}>{type === 'selected' ? '' : '#'}</Badge>
      <span style={{ fontSize: 12, margin: '0 6px' }}>{label}</span>
    </span>
  )
}

export const DateEventSlots: React.FC<DateEventSlotsProps> = ({
  eventSlots, values, selectedSlots, customSlot, readOnly, handleSlotSelect
}) => {
  const renderSlots = (currentDate?: any) => (
    <div className='reserve-date-time-slots-container'>
      {eventSlots.map((slot: any) => {
        const key = currentDate ? currentDate : 'all';
        const slots = selectedSlots[key] || [];
        const isSelected = slots?.includes(slot.slotId);
        let isSlotDisabled = false;
        if (slots.includes(0) && slot.slotId !== 0) {
          isSlotDisabled = true;
        } else if (!slots.includes(0) && slots.length > 0 && slot.slotId === 0) {
          isSlotDisabled = true;
        }
        let className = '';
        if (isSelected) {
          className += 'reserve-date-time-slot-selected '
        }
        if (readOnly) {
          className += 'reserve-date-time-slot-readOnly '
        }
        if (isSlotDisabled) {
          className += 'reserve-date-time-slot-disabled '
        }
        return (
          <span
            key={slot.slotId}
            data-testid={`slot-${slot.slotId}`}
            className={`reserve-date-time-slot ${className}`}
            onClick={() => (isSlotDisabled || readOnly) ? undefined : handleSlotSelect(slot.slotId, currentDate)}
          >
            {slot.label}
          </span>
        );
      })}
    </div>
  );

  if (customSlot) {
    return (
      <div className='reserve-date-time-custom-slot-container'>
        {values.map((item: any, index: number) => (
          <div key={index} className='py-2'>
            <div style={{ fontSize: 16, fontWeight: 'bold' }}>{moment(item).format(DATE_FORMAT_SHORT)}*</div>
            {renderSlots(item)}
          </div>
        ))}
      </div>
    )
  }
  return <>{renderSlots()}</>;
}

const ReserveDateTime: React.FC<ReserveDateTimeProps> = ({
  isOpen,
  onClose,
  disabled,
  eventSlots,
  minDate,
  maxDate,
  selectedStores,
  timeSelectedStores,
  onConfirmSelection,
  maxAllowedDays,
  defaultCalendarMonth,
  consecutiveDaysRestriction = false,
  restrictedDays = 0
}) => {
  const [values, setValues] = React.useState<Date[]>([]);
  const [customSlot, setCustomSlot] = React.useState(false);
  const [selectedSlots, setSelectedSlots] = React.useState<any>({});
  const remainingDays = maxAllowedDays - values.length;
  const ref = React.useRef(null);
  const [isCallOutOpen, setIsCallOutOpen] = React.useState(false);
  const [showConsecutiveDaysAlert, setShowConsecutiveDaysAlert] = useTimeoutState();

  React.useEffect(() => {
    if (remainingDays === 0 && !disabled) {
      setIsCallOutOpen(true);
    } else {
      setIsCallOutOpen(false);
    }
  }, [remainingDays])

  React.useEffect(() => {
    if (isOpen && !timeSelectedStores.length) {
      setSelectedSlots({});
    }
  }, [customSlot, isOpen, timeSelectedStores]);

  React.useEffect(() => {
    if (isOpen) {
      if (timeSelectedStores.length || (selectedStores.length === 1 && selectedStores[0].proposedEventDates.length > 0)) {
        const { proposedEventDates } = timeSelectedStores[0] || selectedStores[0];
        const preSelectedDays: any = [];
        const preSelectedSlot = proposedEventDates?.reduce((acc: any, item: any) => {
          const datesSelected = new Date(item.eventDateString)
          preSelectedDays.push(datesSelected);
          const key = new Date(item.eventDateString).toString();
          acc[key] = getEventSlotIds(item.eventSlots);
          return acc;
        }, {});
        const slots = Object.values(preSelectedSlot) as any;
        const isCustomSlot = !slots.every((v: any) => v.join(',') === slots[0].join(','));
        setValues(preSelectedDays)
        setCustomSlot(isCustomSlot);
        setSelectedSlots(isCustomSlot ? preSelectedSlot : { all: getEventSlotIds(proposedEventDates[0].eventSlots) });
      }
    }
  }, [isOpen, selectedStores, timeSelectedStores]);

  const handleSlotSelect = (slotId: number, currentDate?: any) => {
    const key = currentDate ? currentDate : 'all';
    setSelectedSlots((prev: any) => {
      if (customSlot) {
        delete prev.all;
      }
      prev[key] = prev[key] || [];

      let slots = prev[key];
      slots = slots.includes(slotId) ? slots.filter((id: number) => id !== slotId) : [...slots, slotId];
      slots.sort((a: any, b: any) => a - b);

      if (slots.length) {
        prev[key] = [...slots];
      } else {
        delete prev[key];
      }
      return { ...prev };
    });
  }

  const onConfirm = () => {
    let dateAndSlotList = [];
    if (customSlot) {
      dateAndSlotList = Object.entries(selectedSlots).map(([key, value]: any) => {
        return { eventDateString: moment(key).format(DATE_FORMAT_LONNG), eventSlots: getSelectedSlotsList(eventSlots, value) };
      });
    } else {
      dateAndSlotList = values.map((item: any) => {
        return { eventDateString: moment(item).format(DATE_FORMAT_LONNG), eventSlots: getSelectedSlotsList(eventSlots, selectedSlots.all) }
      });
    }
    onConfirmSelection(dateAndSlotList);
    onCloseHandler();
  }
  const onCloseHandler = () => {
    onClose();
    setValues([])
    setValue('1')
    setSelectedSlots([])
  }
  const renderPickerDay = (
    day: any,
    _selectedDate: any,
    pickersDayProps: any
  ) => {
    const dayInCurrentMonth = !pickersDayProps.outsideCurrentMonth;
    const selected = findDate(values, day);
    const isPast =
      moment(day).isBefore(moment(minDate)) ||
      moment(day).isBefore(moment());
    const isFuture = moment(day).isAfter(moment(maxDate));

    return (
      <div className={selected && dayInCurrentMonth ? 'selected-button' : ''}>
        <PickersDay disabled={isPast || isFuture} {...pickersDayProps} >
          {dayInCurrentMonth ? <span>{day.getDate()}</span> : <></>}
        </PickersDay>
      </div>
    );
  };
  const selectedStoresTabData = timeSelectedStores.length > 0 ? timeSelectedStores : selectedStores
  const selectedStoreLength = timeSelectedStores.length > 0 ? timeSelectedStores.length : selectedStores.length
  const selectedSlotsCount = Object.values(selectedSlots).length;
  const isConfirmDisabled = customSlot ? selectedSlotsCount !== values.length : !(values.length && selectedSlotsCount);
  const [tabValue, setValue] = useState('1');
  const dateAndTimeLabel = 'Date and time';
  const selectedStoreLabel = <span className='event-request-storeinfo-label'>
    <span>Selected stores</span>
    <Badge UNSAFE_className='ngo-nc-badge'>{selectedStoreLength}</Badge>
  </span>
  const handleTabChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };
  return (
    <LDModal
      className='reserve-date-time-modal'
      title='Select Date and time'
      size='medium'
      isOpen={isOpen}
      onClose={onCloseHandler}
      closeButtonProps={{ style: { padding: 10 } }}
      actions={disabled
        ? <></>
        : (
          <ButtonGroup>
            <Button variant='tertiary' data-testid='cancel-btn' onClick={onCloseHandler}>Cancel</Button>
            <Button variant='primary' data-testid='confirm-btn' onClick={onConfirm} disabled={isConfirmDisabled}>Confirm</Button>
          </ButtonGroup>
        )
      }
    >
      <div className='reserve-date-time-modal-box'>
        <Box>
          <TabContext value={tabValue}>
            <Box>
              <TabList onChange={handleTabChange}>
                <Tab value='1'
                  label={dateAndTimeLabel} />
                <Tab value='2'
                  label={selectedStoreLabel} />
              </TabList>
            </Box>
            <LDDivider />
            <TabPanel value='1'>
              {showConsecutiveDaysAlert && (
                <div className='my-2'>
                  <Alert variant="warning">
                    {`Not allowed to select more than ${restrictedDays} consecutive days`}
                  </Alert>
                </div>
              )}
              <div style={{ margin: '30px 0' }} className='reserve-date-time-picker '>
                <div>
                  <div className='reserve-date-time-subtitle reserve-date-time-subtitle-bold'>Select dates</div>
                  <div className='reserve-date-time-subtitle'>
                    <li>Total selected <strong>{values.length}</strong> days</li>
                    <li>
                      <Callout
                        a11yContentLabel='Target information'
                        content='You have reached maximum allowed days for selection'
                        isOpen={isCallOutOpen}
                        onClose={() => setIsCallOutOpen(false)}
                        position='right'
                        UNSAFE_className={'callOutMaxAllowedDays'}
                        targetRef={ref}
                      >
                        <span ref={ref}>Remaining <strong>{remainingDays}</strong> day</span>
                      </Callout>
                    </li>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDatePicker
                      displayStaticWrapperAs='desktop'
                      label='Week picker'
                      value={values}
                      onChange={(newValue: any) => {
                        let selectedArray = [...values, newValue];
                        const isConsecutive = consecutiveDaysRestriction && checkConsecutiveDays(selectedArray, newValue, restrictedDays);
                        if (isConsecutive) {
                          setShowConsecutiveDaysAlert(true);
                          return;
                        }
                        if (
                          newValue &&
                          startOfDay(newValue) <= maxDate &&
                          startOfDay(newValue) >= startOfDay(minDate) &&
                          startOfDay(newValue) >= startOfDay(new Date())
                        ) {

                          setValues((previousValues) => {
                            const array = [...previousValues];
                            const date = startOfDay(newValue);
                            const index = findIndexDate(array, date);
                            if (index >= 0) {
                              array.splice(index, 1);
                              setSelectedSlots((prev: any) => {
                                const updatedSlots = { ...prev };
                                delete updatedSlots[newValue];
                                return updatedSlots;
                              });
                            } else {
                              if (remainingDays !== 0) {
                                array.push(date);
                              }
                            }
                            return array.sort(
                              (a, b) => a.valueOf() - b.valueOf()
                            );
                          });
                        }
                      }}
                      renderDay={renderPickerDay}
                      renderInput={(params) => <TextField {...params} />}
                      views={['day']}
                      disableHighlightToday={true}
                      readOnly={disabled}
                      maxDate={maxDate}
                      minDate={minDate}
                      defaultCalendarMonth={defaultCalendarMonth}
                    />
                    <div className='reserve-date-time-legend-container'>
                      <Legend label='Selected' type='selected' />
                      <Legend label='Available' type='available' />
                      <Legend label='Not available' type='notAvailable' />
                    </div>
                  </LocalizationProvider>

                </div>

                <div className='ml-4'>
                  <div className='reserve-date-time-title'>Time slots</div>
                  <div className='reserve-date-time-subtitle'>Please select your preferred time slots below.</div>
                  <div className='reserve-date-time-subtitle'>*Required</div>
                  <div className='d-flex align-items-center py-3'>
                    <Checkbox
                      name='slotSelectionType'
                      checked={!customSlot}
                      label='Apply the same time slot'
                      className='pr-3'
                      disabled={disabled}
                      onChange={() => setCustomSlot(prev => !prev)}
                    />
                  </div>
                  {!customSlot && <div className='reserve-date-time-subtitle reserve-date-time-subtitle-bold'>All dates selected*</div>}
                  <DateEventSlots {...{ eventSlots, values, selectedSlots, customSlot, handleSlotSelect, readOnly: disabled }} />
                </div>
              </div>
            </TabPanel>
            <TabPanel value='2'>
              <DataTable
                className='ngo-nc-store-detail-dataTable'
                columns={getColumns()}
                data={selectedStoresTabData}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </LDModal>
  );
};

export default ReserveDateTime;

export const getColumns = () => [
  {
    name: 'Store #',
    selector: (row: any) => row?.storeNbr,
    sortable: true,
  },
  {
    name: 'Address',
    cell: (row: any) => <Tooltip title={row?.addressLine1} placement='right'>
      <span className='ngo-nc-address-span-tooltip'> {row?.addressLine1}</span></Tooltip>,
    sortable: true,
  },
  {
    name: 'City',
    cell: (row: any) => <Tooltip title={row?.city} placement='right'>
      <span className='ngo-nc-address-span-tooltip'>{row?.city}</span></Tooltip>,
    sortable: true,
  },
  {
    name: 'State',
    selector: (row: any) => row?.state,
    sortable: true,
  },
  {
    name: 'ZIP',
    selector: (row: any) => row?.postalCode,
    sortable: true,
  }
]
