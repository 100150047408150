
import {
  Location,
  useLocation,
  useNavigate
} from 'react-router-dom';

interface ICustomHistory {
  goBack: () => void,
  push: (payload: any) => void,
}


export interface RouteComponentProps {
    history: ICustomHistory;
    location: Location;
}
function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const history = useHistory();

    return (
      <Component
        {...props}
        history={history}
        location = {location}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const useHistory = (): ICustomHistory => {
  const navigateTo = useNavigate()
  const history = {
    push: (payload: any) => {
      if(typeof payload === 'string') {
        navigateTo(payload)
      } else {
        navigateTo(payload.pathname, {
          ...payload
        })
      }
    },
    goBack: () => {
      navigateTo(-1)
    }
  }

  return history;
}
export default withRouter;
