import * as React from 'react';
// @ts-ignore
import AppBar from '@material-ui/core/AppBar';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import Toolbar from '@material-ui/core/Toolbar';
import {
    withStyles,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio, Theme, Typography,
} from '@material-ui/core';
import styles from '../menu/ThemeStyle';
import './FoodSurplusConfirmation.css';
import { WalmartIcon } from 'dynamic-form-components/lib/components/icon/WalmartLogo';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../models/globalDonationState';
import { foodSurplusConfirmation } from '../../state/foodSurplusConfirmation/foodSurplusConfirmationAction';
import ConfirmDialog from './../dialogComponents/confirmDialog';
import LoaderComponent from '../LoaderComponent';
import tracker from '../../adobe-analytics/tracker';
import analytics from '../../adobe-analytics/analytics';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
export interface OwnProps {
    classes: any;
    theme: Theme;
}
export interface StateProps {
    selectedLang?: any;
    foodSurplus?: any;
    foodSurplusConfirmationLoading?: any;
}
export interface DispatchProps {
    foodSurplusConfirmation: (data: any) => void;
}
const appi18n: any = {
    title: {
        en: 'Food Surplus Donation Request Confirmation',
        es: 'Food Surplus Donation Request Confirmation es',
        fr: 'Food Surplus Donation Request Confirmation fr'
    },
    succesText: {
        en: 'Successfully Saved Response',
        es: 'Successfully Saved Response es',
        fr: 'Successfully Saved Response fr'
    },
    errorText: {
        en: 'Response Not Accepted As Donation Is No Longer Available',
        es: 'Response Not Accepted As Donation Is No Longer Available es',
        fr: 'Response Not Accepted As Donation Is No Longer Available fr'
    },
    errorResponse: {
        en: 'Something went wrong. Please try again later',
        es: 'Something went wrong. Please try again later es',
        fr: 'Something went wrong. Please try again later fr'
    },
    donationAccepted: {
        en: 'Donations Accepted?',
        es: 'Donations Accepted? es',
        fr: 'Donations Accepted? fr'
    },
    actionMessageConfirm: {
        en: 'Please confirm the action, Acceptance of carton?',
        es: 'Please confirm the action, Acceptance of carton? es',
        fr: 'Please confirm the action, Acceptance of carton? fr'
    },
    actionMessageCancel: {
        en: 'Please confirm the action, Rejection of carton?',
        es: 'Please confirm the action, Rejection of carton? es',
        fr: 'Please confirm the action, Rejection of carton? fr'
    },
    actionTitle: {
        en: 'Confirm Action',
        es: 'Confirm Action es',
        fr: 'Confirm Action fr'
    }
};
class FoodSurplusConfirmationScreen extends React.Component<OwnProps & StateProps & DispatchProps> {
    public languages: any = [
        {
            value: 'en',
            label: 'English'
        },
        {
            value: 'es',
            label: 'Español'
        },
        {
            value: 'fr',
            label: 'French'
        }
    ]
    public state = {
        countryCode: '',
        acceptParam: '',
        locale: '',
        keyparam: '',
        responseText: '',
        foodSurplusStatus: this.props.foodSurplus || '',
        buttonClicked: false,
        showConfirmDialog: false
    };
    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.foodSurplus.foodSurplusPage);
    }
    public componentWillMount() {
        const keyparam = window.location.pathname.split('response')[1].split('/')[1];
        const locale = new URL(window.location.href).searchParams.get('lang');
        const acceptParam = new URL(window.location.href).searchParams.get('accept');
        const countryCode = new URL(window.location.href).searchParams.get('countryCode');
        this.setState({ keyparam, locale, acceptParam, countryCode });
    }
    public componentWillReceiveProps(newProps: StateProps) {
        this.setState({ foodSurplusStatus: newProps.foodSurplus.status });
        if (newProps.foodSurplus.status === 200) {
            this.setState({ responseText: appi18n.succesText[newProps.selectedLang] });
        } else if (newProps.foodSurplus.status === 400) {
            this.setState({ responseText: appi18n.errorText[newProps.selectedLang] })
        } else if (newProps.foodSurplus.status === 500) {
            this.setState({ responseText: appi18n.errorResponse[newProps.selectedLang] })
        }
    }
    private handleSubmit = (event: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.FOOD_SURPLUS_SUBMIT_BUTTON.name,
            location: ANALYTICS.foodSurplus.foodSurplusPage
        });
        this.setState({ showConfirmDialog: true })
        event.preventDefault();
    };
    private checkUKMarket() {
        return this.state.countryCode === 'GB';
    }
    private handleCancelAction = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.FOOD_SURPLUS_GOBACK_BUTTON.name,
            location: ANALYTICS.foodSurplus.foodSurplusPage
        });
        this.setState({ showConfirmDialog: false })
    }
    private handleConfirmAction = (event: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.FOOD_SURPLUS_CONFIRMATION_BUTTON.name,
            location: ANALYTICS.foodSurplus.foodSurplusPage
        });
        this.setState({ showConfirmDialog: false, buttonClicked: true })
        this.props.foodSurplusConfirmation({
            key: this.state.keyparam,
            accepted: this.state.acceptParam,
            lang: this.state.locale,
            countryCode: this.state.countryCode
        });
        event.preventDefault();
    }
    public render() {
        const { classes } = this.props;
        const radioDefaultValue = this.state.acceptParam ? this.state.acceptParam === 'true' ? 'yes' : 'no' : undefined;
        return (<>
            <main className={`${classes.contentConfirmationScreen}`}>
                <AppBar position='fixed' className={this.checkUKMarket() ? classes.appBarConfirmationScreen : ''}>
                    <Toolbar>
                        <div className={`${classes.toolbar} ${this.checkUKMarket() ? classes.logoConfirmationScreen : classes.logo}`}>
                            <Typography color='inherit' variant='h6'>
                                <WalmartIcon /> {this.checkUKMarket() ?
                                    <img alt='ASDA' src={require('./../../resources/asda_green.svg')} /> : ''}{'Global Donation Management'}
                            </Typography>
                        </div>
                        <div className={classes.grow} />
                    </Toolbar>
                </AppBar>
                <div className='row'>
                    <div className='col-md-5 confirmation-screen'>
                        <div className='content-confirmation-screen'>
                            <Typography color='inherit' variant='h6'>
                                {appi18n.title[this.props.selectedLang]}
                            </Typography>
                            <div className='confirmation-screen-main'>
                                <FormControl component='fieldset'>
                                    <FormLabel component='legend' required={true}>
                                        {appi18n.donationAccepted[this.props.selectedLang]}</FormLabel>
                                    <RadioGroup value={radioDefaultValue} >
                                        <FormControlLabel value='yes' disabled={!!radioDefaultValue} control={<Radio />} label='Yes' />
                                        <FormControlLabel value='no' disabled={!!radioDefaultValue} control={<Radio />} label='No' />
                                    </RadioGroup>
                                </FormControl>
                                <div className='App'>
                                    <form onSubmit={(evt: any) => this.handleSubmit(evt)}>

                                        <div className='confirmation-input'>

                                            <div><input className={`btn confirmation-submit ${this.state.buttonClicked ?
                                                'button-disable' : ''}`} type='submit' /></div>
                                            <div className={`confirmation-error ${this.state.foodSurplusStatus === 200 ?
                                                'confirmation-error-green' : ''}`}>{this.state.responseText}</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div></div></div></main>
            {this.props.foodSurplusConfirmationLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ConfirmDialog showDialog={this.state.showConfirmDialog}
                isAsdaTheme={true}
                title={appi18n.actionTitle[this.props.selectedLang]}
                message={this.state.acceptParam === 'true' ? appi18n.actionMessageConfirm[this.props.selectedLang]
                    : appi18n.actionMessageCancel[this.props.selectedLang]} cancel={this.handleCancelAction}
                // @ts-ignore
                confirm={this.handleConfirmAction}
            />
            <ErrorSnackbarComponent isError={this.state.foodSurplusStatus === 400 || this.state.foodSurplusStatus === 500} classes={classes}
                message={this.state.responseText} />
            <SuccessSnackBarComponent isSuccess={this.state.foodSurplusStatus === 200}
                message={this.state.responseText} />
        </>);
    }
}
const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    foodSurplus: state.foodSurplusConfirmationState.foodSurplusConfirmation,
    foodSurplusConfirmationLoading: state.foodSurplusConfirmationState.foodSurplusConfirmationLoading,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    foodSurplusConfirmation: (data) => dispatch(foodSurplusConfirmation(data))
});
export default (withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FoodSurplusConfirmationScreen)));
