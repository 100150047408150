import { SidebarActions, SidebarActionTypes } from './sidebarActions';
import { SidebarState } from '../../models/sidebarState';

export const defaultSidebarState: SidebarState = {
    sidebarData: {},
    sidebarloading: false,
    sidebarLoadingSuccess: false,
    sidebarLoadingFailure: false
};

export const sidebarReducer = (state: SidebarState = defaultSidebarState, action: SidebarActions):
SidebarState => {
    switch (action.type) {
        case SidebarActionTypes.SIDEBAR_DATA_LOAD: return {
            ...state,
            sidebarloading: true,
            sidebarLoadingSuccess: false,
            sidebarLoadingFailure: false,
        };
        case SidebarActionTypes.SIDEBAR_LOAD_SUCCESS: return {
            ...state,
            sidebarData: action.data,
            sidebarloading: false,
            sidebarLoadingFailure: false,
            sidebarLoadingSuccess: true,
        };
        case SidebarActionTypes.SIDEBAR_LOAD_FAILURE: return {
            ...state,
            sidebarloading: false,
            sidebarLoadingSuccess: false,
            sidebarLoadingFailure: true,
        };
        default: return state;
    }
}