//@ts-nocheck
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import '../../../styles/formStyles.css';
import '../../../styles/oneTimeGoodDonations.css';
import { FormBlockHeading, getFormattedAppendixBData, useStyles, sourceApp } from '../utils';
import GenericConfirmDialog from '../../dialogComponents/GenericConfirmDialog';
import { Button, Heading } from '../../common/index';
import LoaderComponent from '../../LoaderComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { commonFormTranslations } from '../../../constants';
import { useSaveOneTimeGoodsDonation } from '../../../custom-hooks/useSaveOneTimeGoodsDonation';
import SuccessAlertDailog from '../../dialogComponents/SuccessAlertDailog';
import { AppendixBForm } from '../../common/formComponents/AppendixBForm';
import { Link } from '@material-ui/core';
import { FacilitySearchComponent } from '../../common/FacilitySearchComponent';
import { useQueryClient } from 'react-query';
import { countryCodeToLanguageCode } from '../../../constants';
import { GEAppendixBForm } from '../oneTimeGoods/GEAppendixBFormComponent/GEAppendixBForm'
import { formErrorEleClass } from '../../../styles/styleUtil';
import { useScrollToError } from '../../../components/common/utils';
import tracker from '../../../adobe-analytics/tracker';
import analytics from '../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';


export const NgoCreateDonationForm = (props: any) => {
    const { t } = useTranslation();
    const [showDailog, setShowDailog] = useState(false);
    const [showCancelDailog, setShowCancelDailog] = useState(false);
    const [formData, setFormData] = useState<any>();
    const [showStoreSearchDialog, setShowStoreSearchDialog] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState({
        facilityNo: '',
        postalCode: '',
        state: '',
        streetAddress: '',
        city: '',
        facilityType: ''
    })
    const queryClient = useQueryClient()

    const handleFacilityChange = (faciltyDetails: any) => {
        setSelectedFacility({
            ...faciltyDetails,
            loading: true
        })
        setShowStoreSearchDialog(false)
    }

    const handleModalAction = (show: boolean) => {
        setShowStoreSearchDialog(show)
    }

    const submitBtnText = t('common.buttonLabels.submitBtn')
    const errorMsg = t('common.validationMsg.storeNumberError')
    const { resopnse, mutateAsync, isError, isSuccess, isLoading, error } = useSaveOneTimeGoodsDonation();
    const donationId = isSuccess ? resopnse : '';

    const onSubmit = (data: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.SUBMIT_ONE_TIME_DONATION.name,
            location: ANALYTICS.oneTimeGoodsDonation.dashboardPage
        });
        setShowDailog(true);
        const payload = { ...data };
        setFormData(payload);
    };

    const closeDailog = () => {
        setShowDailog(false);
    }
    const donationCountryCodeFromAPI = props?.appendixB?.organization?.countryCode;
    const preferredLanguage = countryCodeToLanguageCode[donationCountryCodeFromAPI];

    const handleConfirmDialog = async () => {
        const form = new FormData();
        setShowDailog(false);
        const storeNumber = selectedFacility?.facilityNo;
        const countryCode = props?.appendixB?.organization?.countryCode;
        const appendixB = props?.appendixB?.organization;

        const defaultFiles: File[] = []
        const donationAttachments = formData?.donationAttachments ? [...formData.donationAttachments] : defaultFiles;
        const organizationAttachments = formData?.organizationAttachments ? [...formData.organizationAttachments] : defaultFiles;
        if (Array.isArray(donationAttachments)) {
            donationAttachments.forEach((attachment: any) => {
                form.append('donationAttachments', attachment);
            });
        }
        if (Array.isArray(organizationAttachments)) {
            organizationAttachments.forEach((attachment: any) => {
                form.append('organizationAttachments', attachment);
            });
        }
        delete formData?.organizationAttachments
        delete formData?.donationAttachments
        const payload = getFormattedAppendixBData(formData, appendixB, preferredLanguage?.toLowerCase())
        /* As part of security fixes, removing the organization id from the request payload and
          retrieving it from the context on the backend
        */
        delete payload?.orgId
        delete payload?.organization?.id
        form.append('appendixB', JSON.stringify(payload));
        form.append('externalDonationRequestDTO', JSON.stringify({ storeNumber, countryCode, sourceApp }));
        await mutateAsync(form);
        queryClient.invalidateQueries('fetchDonationDetails')
    }
    const defaultBoarMemberData = [{
        userName: '',
        userTitle: '',
        birthYear: null,
        cityOfResidence: '',
        stateOfResidence: '',
        countryOfResidence: ''
    }]

    const boardMembers = props?.appendixB?.organization?.boardMembers || []
    const executiveOfficers = props?.appendixB?.organization?.executiveOfficers || []
    const keyPersons = props?.appendixB?.organization?.keyPersons || []

    const appendixbMethods = useForm({
        mode: 'all',
        defaultValues: {
            langCode: props.selectedLang,
            ...props?.appendixB?.organization,
            boardMembers: boardMembers?.length > 0 ? boardMembers : defaultBoarMemberData,
            executiveOfficers: executiveOfficers?.length > 0 ? executiveOfficers : defaultBoarMemberData,
            keyPersons: keyPersons?.length > 0 ? keyPersons : defaultBoarMemberData,
            certificateSignee: {},
            consentSignee: {},
        }
    })

    useScrollToError(appendixbMethods?.errors, formErrorEleClass)
    const redirectToDashboard = () => props?.history?.push(props?.redirectUrl)
    const classes = useStyles();

    return (<>
        <div style={{ marginBottom: '20px', padding: 'initial' }} className={'bodyStyles createDonationForm'}>
            {props?.appendixB?.organization?.countryCode &&
                <>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-xs-12' style={{ marginTop: '20px', marginBottom: '10px' }}>
                            {selectedFacility?.facilityNo &&
                                <Heading
                                    key={`form-heading-data-${selectedFacility?.facilityNo}`}
                                    id={'create-store-number'}
                                    className={'h14_title'}
                                    level={'h6'}
                                >
                                    {t('oneTimeGoodsDonation.store.number')}
                                    <span>
                                        {selectedFacility?.facilityNo}
                                    </span>&nbsp;(<span
                                        style={{
                                            marginLeft: '0px',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            fontSize: 'inherit',
                                            fontWeight: 'normal',
                                            color: '#0071ce'
                                        }}
                                        onClick={() => { handleModalAction(true); }}>
                                        {t('common.labels.changeFacilityText')}
                                    </span>)
                                </Heading>}
                            {!selectedFacility?.facilityNo &&
                                <><div style={{ marginBottom: '20px' }}>
                                    <Link
                                        color='inherit'
                                        component='button'
                                        variant='inherit'
                                        onClick={() => {
                                            handleModalAction(true);
                                        }}
                                        style={{ fontSize: '16px', textDecoration: 'underline' }}
                                    >
                                        {t('common.labels.selectFacilityText')}
                                    </Link>
                                </div></>
                            }
                        </div>
                    </div>
                </>
            }
            {<div>
                <FormBlockHeading heading={t('common.heading.appendixB')} />
                <FormProvider {...appendixbMethods}>
                    <form onSubmit={appendixbMethods.handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                        <input type='hidden'
                            name='facilityNo'
                            value={selectedFacility?.facilityNo}
                            ref={appendixbMethods.register({ required: errorMsg })}
                        />
                        <div>

                            { // @ts-ignore
                                (appendixbMethods?.errors?.facilityNo?.message) &&
                                <span className='reg-form-error-container' style={{ color: 'red' }}>
                                    {
                                        // @ts-ignore
                                        appendixbMethods?.errors?.facilityNo?.message}
                                </span>
                            }
                        </div>
                        {props?.appendixB?.organization?.organizationType?.keyText === 'org_type_ngo' &&
                            <AppendixBForm
                                selectedLang={props?.selectedLang}
                                appendixBPrimaryUserDetailsDisabled={false}
                                appendixBOrgDetailsDisabled={false}
                                isReview={false}
                                classes={classes}
                                data={props?.appendixB} />}
                        {props?.appendixB?.organization?.organizationType?.keyText === 'org_type_governmental' &&
                            <GEAppendixBForm
                                selectedLang={props?.selectedLang}
                                appendixBPrimaryUserDetailsDisabled={false}
                                appendixBOrgDetailsDisabled={false}
                                isReview={false}
                                classes={classes}
                                data={props?.appendixB} />}
                        <div className='row' style={{ marginTop: '25px' }}>
                            <div className='col-md-5'>
                                <Button
                                    className={'cancel_button_styles mr-20 ngo_store_goBackBtn'}
                                    type='button'
                                    title={t('common.buttonLabels.cancel')}
                                    onClick={() => setShowCancelDailog(true)}
                                    style={{ marginRight: '20px' }} />
                                <Button
                                    id={'ngoForm_submit'}
                                    className={'submit_button_styles ngo_next_button'}
                                    type={'submit'}
                                    title={t('common.buttonLabels.submitBtn')} />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>}
        </div>

        {showStoreSearchDialog && <FacilitySearchComponent
            countryCode={props?.appendixB?.organization?.countryCode}
            selectedLang={props.selectedLang}
            onFaciltySelection={handleFacilityChange}
            handleModalAction={handleModalAction}
            showModal={showStoreSearchDialog}
        />}
        <GenericConfirmDialog
            id={'dgsa_submit_popup'}
            showDialog={showDailog}
            cancel={closeDailog}
            confirm={handleConfirmDialog}
            title={submitBtnText}
            message={t('common.popUpMsg.submitNgoDonationMsg')}
            cancelButton={t('common.buttonLabels.cancel')}
            confirmButton={t('common.buttonLabels.confirm')} />
        <GenericConfirmDialog
            id={'dgsa_cancel_popup'}
            showDialog={showCancelDailog}
            cancel={() => setShowCancelDailog(false)}
            confirm={() => redirectToDashboard()}
            title={t('common.buttonLabels.cancel')}
            message={t('common.popUpMsg.cancelDonationRequest.msg')}
            cancelButton={t('common.buttonLabels.no')}
            confirmButton={t('common.buttonLabels.yes')} />
        <SuccessAlertDailog
            showDialog={isSuccess}
            maxWidth={'lg'}
            ok={() => redirectToDashboard()}
            title={t('common.successTitle.donationSuccess')}
            message={<Trans i18nKey='common.successMsg.donationCreateMsg'>
                Donation <strong>{{ donationId }}
                </strong> has been created Successfully
            </Trans>}
        />
        <ErrorSnackbarComponent
            isError={isError}
            classes={props.classes}
            // @ts-ignore
            message={error?.errorMessage || commonFormTranslations.genericErrorMessage[props.selectedLang]} />
        {(isLoading) && <LoaderComponent fullScreen={true} />}
    </>)
}
