import Axios from 'axios';
import { baseSubContextUrl } from '../components/donations/CommonComponents/commonApi';
import {  DONATION_TYPE_VALUES } from '../constants';

export const isGenericType = (dType: any) => {
    return dType === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL || 
            dType === DONATION_TYPE_VALUES.SERVICE_DONATION;
}

export const getSubContextAndSetDonationTypeHeaders = (type: string) => {
    let URL = `emergency-donation/dashboard`;
    if (type && isGenericType(type)) {
        URL = `${baseSubContextUrl}/dashboard`;
    }
    return URL;
}

export const setDonationTypeHeader = () => {
    const { pathname } = window.location;
    let donationType = "";
    if (pathname.indexOf("emergency-donation") >= 0) {
        donationType = DONATION_TYPE_VALUES.EMERGENCY_DONATION;
    }
    else if (pathname.indexOf("space-international") >= 0) {
        donationType = DONATION_TYPE_VALUES.SPACE_INTERNATIONAL;
    }
    else if (pathname.indexOf("service-donation") >= 0) {
        donationType = DONATION_TYPE_VALUES.SERVICE_DONATION;
    }
    return donationType;
}

export const setDonationIdHeaderDynamically = (donationId: string) => {
    const { pathname } = window.location;
    if (pathname.indexOf("view/emergency-donation") >= 0 ||
        pathname.indexOf("view/space-international") >= 0) {
        Axios.defaults.headers.common["DONATION-ID"] = donationId;
    }
}

export const setDonationIdHeaderonEveryRequest = () => {
    let old =  Axios.defaults.headers.common["DONATION-ID"];
     const { pathname } = window.location;
    if (pathname.indexOf("view/emergency-donation") >= 0 ||
        pathname.indexOf("view/space-international") >= 0) {
        Axios.defaults.headers.common["DONATION-ID"] = old;

    } else {
        delete Axios.defaults.headers.common["DONATION-ID"];
    }
}

