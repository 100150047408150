import * as React from 'react';
import ProgressTracker from '../../uiComponents/LDProgress';
import ProgressTrackerItem from '../../uiComponents/LDProgressItem';
import './styles.css';

export interface OnboardingTrackerProps {
    progressTrackerItems: any[],
    activeIndex: number,
    variant: string
}

const OnboardingTracker: React.SFC<OnboardingTrackerProps> = (props) => {
    return <div className='onboarding-tracker'><ProgressTracker
        className='onboarding-tracker-item' activeIndex={props.activeIndex} variant={props.variant}>
        {props.progressTrackerItems.map((key) => (
            <ProgressTrackerItem className='onboarding-tracker-item'>
                {key.toLowerCase().replace(/(\b[a-z](?!\s))/g, (x: string) => x.toUpperCase())}</ProgressTrackerItem>))}
    </ProgressTracker></div>
}

export default OnboardingTracker;
