import React, { useEffect, useState, useCallback } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import Card from '../../commonComponents/uiComponents/LDCard';
import Divider from '../../commonComponents/uiComponents/LDDivider';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import TextField from '../../commonComponents/uiComponents/LDTextField';
import Button from '../../commonComponents/uiComponents/LDButton';
import { ChevronRight, Search } from '../../commonComponents/icons/icon';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './styles.css';
import { scroller as scroll } from 'react-scroll';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useQuery , useMutation} from 'react-query';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import analytics from '../../../../adobe-analytics/analytics';
import spacetracker from '../../../../adobe-analytics-space/space-tracker';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';

// const textTrimmer = (text:string, length:number) => <div className=''>{text.substring(0, length)}</div>

export interface StoreDetails {
    storeNbr: any,
    storeName: any,
    state: any,
    city: any,
    postalCode: any,
    latitude: any,
    longitude: any,
    addressLine1: any
}

export interface MapComponentProps {
    setStore: any,
    storeSelected: any,
    isMobile:boolean,
    storeDetails: StoreDetails[],
    multipleSelect?: boolean
}

export interface Props {
    nextPage?: any,
    prevPage?: any,
    setSelectedForm: any,
    setSelectedStore: any,
    selectedStore?: any,
    searchString?: any,
    setSearchString?: any
    data?:any[],
    isFetching?:any,
    userContext?: any,
    prevSearchString?: any,
    setPrevSearchString?: any
}

export interface StoreListProps {
    nextPage?: any,
    prevPage?: any,
    setSelectedForm: any,
    setSelectedStore: any,
    setStore: any,
    storeSelected: any
}

export interface StoreListComponentProps {
    setSelectedStore: any,
    setSelectedForm: any,
    nextPage: any,
    storeSelected: any,
    setStore: any,
    storeDetails: StoreDetails[]
}

export interface StoreSelectionWebComponentProps {
    storeSelected: any,
    setStore: any,
    storeDetails: any
    className?:string,
    setStoreData?: any
}


const selectedIcon = L.icon({
    iconUrl: require('src/components/spacedonation/commonComponents/assets/images/store-selected.png'),
    iconSize: [32, 42]
});

const notSelectedIcon = L.icon({
    iconUrl: require('src/components/spacedonation/commonComponents/assets/images/store-unselected.png'),
    iconSize: [30, 40]
});

const service: Service = SpaceDonationService;

const scrollTo = (id: any) => scroll.scrollTo(id, {
    smooth: 'linear',
    duration: 1,
    delay: 1,
    containerId: 'storelist'
});

const hasStoreDetailsData = (sd:[]) => sd && sd.length;

const isMobileResolution = () => window.innerWidth <= 425;
const trimZipCode = (s:any) => s?.replaceAll('-')?.substring(0,5);

const SpaceDonationStoreChooser: React.SFC<Props> = (props) => {
    const userDetails = props.userContext;
    const zipCode = props.prevSearchString ? props.prevSearchString : (userDetails?.organization?.orgZip);
    const [searchString, setSearchString] = useState(trimZipCode(zipCode));
    const { status, data, isFetching } = useQuery(['fetchFocusAreas', searchString],
        service.fetchStores, { refetchOnWindowFocus: false });
    console.log(status + data);
    return <>
        {isFetching && <LoaderComponent />}
        {!isFetching && <StoreChooser {...props}
        data={data}
        searchString={searchString}
        setSearchString={setSearchString} />}
    </>
}


const StoreChooser: React.SFC<Props> = (props) => {
    const [storeSelected, setStore] = useState(props.selectedStore ? props.selectedStore.storeNbr : -1);
    const [storeData,setStoreData] = useState(props.data);
    return <>{!isMobileResolution() && <StoreSelectionWebComponent className='space-donation-store-chooser-web'
        {...props}
        setStore={setStore}
        storeDetails={storeData}
        setStoreData={setStoreData}
        storeSelected={storeSelected} />}

        {isMobileResolution() && <StoreSelectionMobileComponent className='space-donation-store-chooser-mobile'
            {...props}
            setStore={setStore}
            storeDetails={storeData}
            setStoreData={setStoreData}
            storeSelected={storeSelected}
        />}
    </>
}
export default SpaceDonationStoreChooser;






const StoreSelectionWebComponent: React.SFC<StoreSelectionWebComponentProps> = (props) => {
    return <Card className='space-donation-store-chooser-container'>
        <div id='storelist' className='space-donation-store-chooser-list'>
            <StoreList {...props} />
        </div>
        <div className='space-donation-store-chooser-map'>
            <MapComponent isMobile={false} {...props} />
        </div>
    </Card>
}


const StoreSelectionMobileComponent = (props: any) => {
    const [mapView,setMapView] = useState(true);
    return <><div className='space-donation-store-chooser-container-mobile'>
        <SearchComponent {...props}/>
        {Boolean(mapView && hasStoreDetailsData(props.storeDetails)) && <MapComponent isMobile={true} {...props} />}
        {Boolean(mapView && hasStoreDetailsData(props.storeDetails)) && <StoreListShortComponent {...props} />}
        {Boolean(!mapView && hasStoreDetailsData(props.storeDetails)) && <StoreListMobileComponent {...props} />}
        <div className='phone-buffer-section'/>
    </div>
        { Boolean(hasStoreDetailsData(props.storeDetails))&& <div className='mobile-bottom-section'>
            <span className='view-all' onClick={() => setMapView(!mapView)}>View {mapView ? 'List' : 'Map'}</span>
            <span className='store-count'>{props.storeDetails.length - 1} stores</span>
        </div>}
        {!hasStoreDetailsData(props.storeDetails) && <StoreListNoDataComponent />}
    </>
}


const StoreList = (props: any) => {
    return <Card className='store-card-container'>
        <div className='store-card-search-box'><CardItem>
            <SearchComponent {...props}/>
        </CardItem>
        </div>
        <Divider />
        <div className='store-card-list-container'>
            <div className='store-list'>{props.storeDetails && props.storeDetails.length >= 0 && <StoreListComponent {...props} />}
                {props.storeDetails && props.storeDetails.length <= 0 && <StoreListNoDataComponent />}</div>
        </div>
        {<div><div className='cont-button'>
            <Button variant='primary' size='small'
                    disabled={!props?.selectedStore?.storeNbr}
                    onClick={() => {
                        analytics.trackEventInfo({
                            type: spacetracker.EVENTS.CLICK,
                            actionOn: spacetracker.ACTION_ON.CONTINUE_NEW_SPACE_REQUESTS.name,
                            location: SPACEANALYTICS.createSpaceLandingExternalPage.name
                        });
                        props.setSelectedForm(props.nextPage);
                    }}>
                Continue
            </Button></div></div>}
    </Card>
}


export const MapComponent: React.SFC<MapComponentProps> = (props) => {
    const bounds: any = [];
    props.storeDetails.forEach(x => bounds.push([x.latitude, x.longitude]));
    return <>{props.storeDetails && props.storeDetails.length ? <Map bounds={bounds}
        center={
            [props.storeDetails.map(x => x.latitude).reduce((a, b) => a + b) / props.storeDetails.length,
            props.storeDetails.map(x => x.longitude).reduce((a, b) => a + b) / props.storeDetails.length]
        }
        className='map'
    >
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {props.storeDetails.map(store => {
            return <Marker key={store.storeNbr}
                onClick={() => {
                    props.setStore(store.storeNbr);
                    if (!props.isMobile) { scrollTo(`${store.storeNbr}`); }
                }
                }
                icon={props.storeSelected === store.storeNbr ? selectedIcon : notSelectedIcon}
                position={[store.latitude, store.longitude]} />
        })}
        {props.multipleSelect && props.storeDetails.map(store => {
            return <Marker key={store.storeNbr}
                icon={notSelectedIcon}
                position={[store.latitude, store.longitude]} />
        })}
    </Map> : props.multipleSelect?
            <Map zoom={4} center={[35.42091012590866, -98.57246517156102]} className='map'>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
            </Map>
            :<div className='no-map-data'>No Map Data</div>}
    </>
}


const SearchComponent = (props: any) => {
    const [searchString, setSarchString] = useState(props.searchString);
    const mutation = useMutation(key => service.fetchStores({ queryKey: [0, key] }));
    const handleUserKeyPress = useCallback((event: any) => {
        const { keyCode } = event;
        if(keyCode === 13){
            event.preventDefault();
            document?.getElementById('store-search-btn')?.click();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', handleUserKeyPress);
        return () => {
            window.removeEventListener('keyup', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);
    useEffect(() => {
        if (mutation.isSuccess) { props.setStoreData(mutation.data); }
    }, [mutation]);
    return <>{mutation.isLoading && <LoaderComponent />}
        <div className='store-card-search-box-grid'>
            <TextField
                id='store-search'
                label='Search By State/City/Zip'
                leadingIcon={<Search size='small' />}
                value={searchString}
                onChange={(e: any) => setSarchString(e.target.value)}
                type='text'
            />
            <div className='search-button'>
                <Button
                    id='store-search-btn'
                    variant='primary'
                    onClick={() => { mutation.mutate(searchString); props.setPrevSearchString(searchString); props.setSelectedStore({})}}
                >Search</Button>
            </div>
        </div></>
}

const StoreListComponent: React.SFC<StoreListComponentProps> = (props) => {
    return <>{props.storeDetails.map(store => {
        return <><div id={`${store.storeNbr}`}
            className={`store-card ${props.storeSelected === store.storeNbr ? 'store-selected' : ''}`}
            onClick={() => { props.setStore(store.storeNbr); props.setSelectedStore(store); }}>
            <CardItem className='store-card-details-container' key={store.storeNbr}>
                <div className='store-card-header'>{store.city}</div>
                <div className='store-card-details'>{store.storeName} {store.storeNbr}</div>
                <div className='store-card-store-address'>{store.addressLine1}</div>
            </CardItem>
        </div>
            <Divider />
        </>
    }
    )}</>
}

const StoreListMobileComponent: React.SFC<StoreListComponentProps> = (props) => {
    return <>{props.storeDetails.map(store => {
        return <><div id={`${store.storeNbr}`}
            className={`store-card ${props.storeSelected === store.storeNbr ? 'store-selected' : ''}`}
            onClick={() => { props.setStore(store.storeNbr); props.setSelectedStore(store); props.setSelectedForm(props.nextPage);}}>
            <CardItem className='store-card-details-container' key={store.storeNbr}>
                <div className='store-card-header'>{store.city}</div>
                <div className='store-card-details'>{store.storeName} {store.storeNbr}</div>
                <div className='store-card-store-address'>{store.addressLine1}</div>
            </CardItem>
        </div>
            <Divider />
        </>
    }
    )}</>
}

const StoreListNoDataComponent = () => {
    return <div className='no-list-data'><span className='no-list-data-text'>No Data</span></div>
}

const StoreListShortComponent: React.SFC<StoreListComponentProps> = (props) => {
    const store = props.storeDetails[0];
    return <div className='store-list-short-component'>
        <div id={`${store.storeNbr}`}
            className={`store-card ${props.storeSelected === store.storeNbr ? 'store-selected' : ''}`}
            onClick={() => { props.setStore(store.storeNbr) }}>
            <CardItem className='store-card-details-container' key={store.storeNbr}>
                <div className='store-card-header'>{store.city}</div>
                <div className='store-card-details'>{store.storeName} {store.storeNbr}</div>
                <div className='store-card-store-address'>{store.addressLine1}</div>
            </CardItem>
            <div className='store-card-arrow'
                onClick={() => {
                    props.setSelectedStore(store);
                    props.setSelectedForm(props.nextPage);
                }}>
                <ChevronRight />
            </div>
        </div>
        <Divider />
    </div>
}
