import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import { SubmissionState } from 'dynamic-form-components/lib/models/ApplicationState';
import { GetColumnConfigPayload } from 'dynamic-form-components/lib/models/SubmissionModel';
import ApplicationDataService from 'dynamic-form-components/lib/service';
import { saveFormDataFailure } from 'dynamic-form-components/lib/state/form/formDataActions';
import { getColumnConfig } from 'dynamic-form-components/lib/state/submission/submissionDataActions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { gridTranslations } from 'src/static/GridTranslations';

import { contextPath, landigPageUrl } from '../../../constants';
import GlobalDonationService, { Service } from '../../../service';
import { loggedInUserData } from '../../../state/loggedInUser/loggedInUserActions';
import { getFormattedDate } from '../../common/DateUtils';
import { donationsStatusText } from './../../../constants';
import { GlobalDonationState, Privileges, UiHubProps } from './../../../models/globalDonationState';
import { selectedData, setSelectedDataLoad } from './../../../state/selectedData/selectedDataActions';
import { submissionData } from './../../../state/submission/submissionDataActions';

export let service: Service = GlobalDonationService;
const config = require(`./../../../config.${process.env.NODE_ENV}.json`);
const { getLoggedInUserDetail } = service;
export interface OwnProps {
    classes?: any;
    appi18n: any;
    formLink: string;
    remoteDataUri?: string;
    privileges: Privileges;
    uiHubProps: UiHubProps;
    showViewDiagramDialog?: boolean;
    loggedInUserDetail: any;
}

export interface DispatchProps {
    getSubmissions: (data: any) => void;
    addSelectedDataToStore: (data: any) => void;
    getColumnConfig: (data: any) => void;
    updateFormSuccessStatus: () => void;
    setSelectedDataLoad: (data: any) => void;
    getLoggedInUserDetail: () => void;
}

export interface StateProps {
    columns: any[];
    selectedLang: string;
    submissionState: SubmissionState;
    loggedInUserDetail: any;
}

const styles = {
    buttonStyles: {
        marginBottom: 20
    }
}

const appi18n: any = {
    ...gridTranslations,
    gridTitle: {
        en: 'List of Requests',
        es: 'Lista de solicitudes',
        fr: 'Liste des demandes',
        ja: 'リクエスト一覧',
        zh: '请求清单'
    },
    createNewBtnLable: {
        en: 'Create New',
        es: 'Crear nueva',
        fr: 'Créer un nouveau',
        ja: '新しく作る',
        zh: '创建新的'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Arrière',
        ja: 'バック',
        zh: '背部'
    },
    projectPdfLink: {
        en: 'Project Pdf',
        es: 'Proyecto Pdf',
        fr: 'Projet Pdf',
        ja: 'プロジェクトPDF',
        zh: '项目PDF'
    },
    donationRequests: {
        en: 'Donation Requests',
        es: 'Solicitudes de Donaciones',
        fr: 'Demandes de dons',
        ja: '寄付のリクエスト',
        zh: '捐款要求'
    }
}
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        },
    },overrides: {
        MuiPaper: {
            root: {
                width: 'auto !important'
            }
        },
        MuiSelect: {
            selectMenu: {
                minWidth: '150px',
                whiteSpace: 'pre-wrap'
            }
        },
        MuiInputBase: {
            input: {
                minWidth: '80px'
            }
        }
    }
});
class UnmannedCollectionGrid extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        submissionForEdit: {},
        formAlias: 'unmannedCollection',
        listAlias: 'unmannedCollectionExt',
        donationType: 'UNMANNED_COLLECTION',
        columns:[],
        options:{}
    }

    private type: string | null = 'unmanned-collection';
    private formOptions: any;
    private donationsStatusText = donationsStatusText[this.state.donationType];

    public UNSAFE_componentWillMount() {
        const payload = {
            value: {},
            key: 'unmannedCollection'
        }
        this.props.addSelectedDataToStore(payload);
        this.props.getColumnConfig({
            listAlias: this.state.listAlias,
        });
        this.props.updateFormSuccessStatus();
        this.setState({
            options: {
                exportButton: false,
                debounceInterval: 400,
                minBodyHeight: '300px',
                maxBodyHeight: '721px',
                headerStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                    color: '#041f41',
                },
                rowStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                },
                filtering: true
            }
        })
    }
    public UNSAFE_componentWillReceiveProps(newProps: StateProps) {
        this.formOptions = {
            noAlerts: true,
            language: newProps.selectedLang
        }
        const columns: any = [];
        if ((newProps.columns !== this.props.columns && newProps.columns.length > 0) ||
            (newProps.selectedLang !== this.props.selectedLang)) {
            newProps.columns.map((column: any) => {
                let customCoulmns: any = {};
                if (column.field === 'donation.id') {
                    customCoulmns = {
                        ...column,
                        render: (rowData: any) => {
                            return <button className='btn-link'
                                onClick={() => this.viewDonationDetails(rowData)}>{rowData.donation.id}</button>
                        }
                    }
                }
                if (column.field === 'donation.currentStatusCode') {
                    customCoulmns = {
                        ...column,
                        'lookup': this.donationsStatusText[newProps.selectedLang],
                        filtering: true,
                    }
                }
                if (column.field === 'donation.audit.createdDtm') {
                    customCoulmns = {
                        ...column,
                        render: (rowData: any) => {
                            return getFormattedDate(rowData.donation.audit.createdDtm)
                        }
                    }
                }
                if (column.field === 'donation.audit.submittedDtm') {
                    customCoulmns = {
                        ...column,
                        render: (rowData: any) => {
                            return getFormattedDate(rowData.donation.audit.submittedDtm)
                        }
                    }
                }
                columns.push({
                    ...column,
                    ...customCoulmns
                })
            })
            this.setState({columns})
        }
    }
    public render() {

        let createBtn: any;
        createBtn = <Button className={`back-btn ${this.props.classes.buttonStyles}`}
            variant='contained' color='primary'
            onClick={this.handleCreateDonation}>
            {appi18n.createNewBtnLable[this.props.selectedLang]}
        </Button>

        // const remoteDataUrl = `${config.appBaseUrl}/donations/requests`;
        return <>
            {createBtn}
            <MuiThemeProvider theme={theme}>
                <SubmissionGridComponent
                    title={appi18n.donationRequests[this.props.selectedLang]}
                    formAlias={this.state.formAlias}
                    listAlias={this.state.listAlias}
                    // remoteDataUrl={remoteDataUrl}
                    columns={this.state.columns}
                    i18n={appi18n}
                    privileges={this.props.privileges}
                    formOptions={this.formOptions}
                    options={this.state.options}
                    selectedLanguage={this.props.selectedLang}
                remoteDataFunc={this.getData}
                />
            </MuiThemeProvider>
        </>
    };
    private getData = (query: any) => {
        const filters: any = {};
        let filtersRgex: any = {}
        let regexQuery: any = '';
        const sortingQuery: any = {};
        const columnQuery: any = {};

        query.filters.forEach((filter: any) => {
                if (!filter.value || (filter.value && filter.value.length === 0)) {
                    return;
                }
                let fieldName = filter.column.field.replace('donation.', '');
                fieldName = fieldName === 'id' ? '_id' : fieldName
                filters[fieldName] = filter.value
                let columnValue:any = '';
                if (fieldName === 'currentStatusCode') {
                    columnQuery[fieldName] = filter.value
                } else {
                    if (fieldName === '_id') {
                        // tslint:disable-next-line: radix
                        columnValue = parseInt(filter.value);
                        columnQuery[fieldName] = columnValue;
                    } else {
                        columnValue = filter.value;
                        columnQuery[fieldName] = columnValue;
                    }
                }
                filtersRgex = columnQuery
        });

        if (query.orderBy) {
            // @ts-ignore
            const field = query.orderBy.field.replace('donation.', '');
            sortingQuery[field === 'id' ? '_id' : field] = query.orderDirection === 'asc' ? 1 : -1
        } else {
            if (!sortingQuery._id) {
                sortingQuery._id = query.orderDirection === 'asc' ? 1 : -1
            }
        }
        if (query.search) {
            let numericSearchTxt: any = '';
            // tslint:disable-next-line: radix
            numericSearchTxt = parseInt(query.search);
            numericSearchTxt = numericSearchTxt !== 'NaN' ? numericSearchTxt : query.search;
            regexQuery = {
                    '_id': numericSearchTxt
            }
        }
        if (this.props.loggedInUserDetail && Object.keys(this.props.loggedInUserDetail).length === 0) {
            // @ts-ignore
            return getLoggedInUserDetail().then((resp) => {
                const searchQuery = {

                    donationType: this.state.donationType,
                                ...regexQuery,
                                // ...filters,
                                ...filtersRgex
                }
                const url = `${config.appBaseUrl}/donations/dashboard/UnmannedCollections?pageSize=${query.pageSize}&pageNum=${query.page + 1}&queryId=donation.externalUnmanned&orderBy=audit.createdDtm&orderDir=desc`;
                return ApplicationDataService.getData(url, searchQuery).catch(e => {
                    if (e.response && e.response.status === 401) {
                        window.location.href = window.location.origin + landigPageUrl;
                    }
                })
            })
        } else {
            const searchQuery = {
                            donationType: this.state.donationType,
                            ...regexQuery,
                            // ...filters,
                            ...filtersRgex
            }
            const url = `${config.appBaseUrl}/donations/dashboard/UnmannedCollections?pageSize=${query.pageSize}&pageNum=${query.page + 1}&queryId=donation.externalUnmanned&orderBy=audit.createdDtm&orderDir=desc`;
            return ApplicationDataService.getData(url, searchQuery).catch(e => {
                if (e.response && e.response.status === 401) {
                    window.location.href = window.location.origin + landigPageUrl;
                }
            })
        }
    }
    private viewDonationDetails = (rowData: any) => {
        const payload = {
            value: {
                ...rowData,
                id: rowData.donation.id,
                currentStatusCode: rowData.donation.currentStatusCode,
                readOnly: true,
            },
            key: 'unmannedCollection'
        }
        this.props.addSelectedDataToStore(payload);
        this.props.history.push(`${contextPath}/donation/view/unmanned-collection?type=${this.type}`);
    }
    private handleCreateDonation = () => {
        this.props.setSelectedDataLoad({});
        this.props.history.push(`${contextPath}/donation/unmanned-collection?type=${this.type}`);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    columns: state.submissionState.columnConfig,
    selectedLang: state.selectedLang,
    submissionState: state.submissionState,
    loggedInUserDetail:state.loggedInUserState.userDetail,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    getSubmissions: (data) => dispatch(submissionData(data)),
    addSelectedDataToStore: (data: any) => dispatch(selectedData(data)),
    setSelectedDataLoad: (data: any) => dispatch(setSelectedDataLoad(data)),
    getColumnConfig: (data: GetColumnConfigPayload) => dispatch(getColumnConfig(data)),
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    updateFormSuccessStatus: () => dispatch(saveFormDataFailure(''))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UnmannedCollectionGrid)));
