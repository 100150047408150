
import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  getAboutOrgConfig,
  getPrimaryContactConfig,
  getDonationDetails,
  getCertificationConfig
} from '../configs/appendixBGEConfig';
import FormSection from '../../../common/FormSection/FormSection'
import { AppendixBProps, AppendixBSections } from './AppendixBForm';

const AppendixBReducedForm: FC<AppendixBProps> = ({
  apendixBDataDefault,
  appendixBformData,
  disabledSections,
  formDisabled = false,
  hideConcentFile = false,
  countryCode }) => {

  const { t } = useTranslation();
  return <>

    <FormSection
      heading={t('oneTimeGoodsDonation.heading.orgDetails')}
      configs={
        getAboutOrgConfig(formDisabled || disabledSections.includes(AppendixBSections.ORG), countryCode, apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('orgMngmtTbl.cols.primaryContact')}
      configs={
        getPrimaryContactConfig((formDisabled || disabledSections.includes(AppendixBSections.PRIMARY_USER)), apendixBDataDefault)
      }
    />
    <FormSection
      heading={t('oneTimeGoodsDonation.heading.donationDetails')}
      configs={
        getDonationDetails(formDisabled || disabledSections.includes(AppendixBSections.DONATION),
          true, countryCode, appendixBformData ?? apendixBDataDefault, apendixBDataDefault)
      }
    />

    <FormSection
      heading={t('appendixBCertification.certification')}
      configs={
        getCertificationConfig(formDisabled || disabledSections.includes(AppendixBSections.CERTIFICATION),
          countryCode, appendixBformData ?? apendixBDataDefault, apendixBDataDefault, hideConcentFile ?? false)
      }
    />
  </>
}

export default memo(AppendixBReducedForm);
