import * as React from 'react';
import IdleTimer from 'react-idle-timer'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Theme, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { contextPath } from 'src/constants';

const styles: any = (_theme: Theme) => ({
    buttonStyles: {
        marginBottom: 10,
        color: '#fff !important',
        background: '#007bff !important',
        borderRadius: '20px',
        textAlign: 'center',
        textTransform: 'none',
        minWidth:'80px'
    },
    buttonContainer:{
        justifyContent: 'center !important',
    }
});

const appi18n: any = {
    buttonText:{
        en:'Ok',
        es:'Okay',
        fr: 'D\'accord',
        ja:'OK',
        zh:'好'
    },
    alertMsg: {
        en: 'Application is timed out. Please login again',
        es: 'Se agotó el tiempo de espera de la aplicación. Por favor ingrese nuevamente',
        fr: `L'application a expiré. Veuillez vous reconnecter`,
        js: 'アプリケーションがタイムアウトしました。もう一度ログインしてください',
        zh: '应用程序超时。请重新登录'
    },
    alertTitle: {
        en: 'Timed Out',
        es: 'Caducado',
        fr: 'Fin du temps',
        js: 'タイムアウトしました',
        zh: '时间到'
    }
}

export interface OwnProps {
    selectedLang: string;
    classes: any;
}

class Timer extends React.PureComponent<OwnProps> {
    public state = {
        isTimedOut: false
    }
    public idleTimer : any;

    public render() {
        const { classes } = this.props;
        return (
            <>
            <Dialog
                disableEnforceFocus={true}
                disableBackdropClick={true}
                open={this.state.isTimedOut}
                onClose={this.refreshPage}
                aria-labelledby='confirm-dialog'
                aria-describedby='confirm-dialog'
            >
                <DialogTitle id='confirm-dialog'>{appi18n.alertTitle[this.props.selectedLang]}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='confirm-dialog-description'>
                        {appi18n.alertMsg[this.props.selectedLang]}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                className={classes.buttonContainer}>
                    <Button
                        className={classes.buttonStyles}
                        onClick={this.refreshPage} color='primary' autoFocus={true}>
                        {appi18n.buttonText[this.props.selectedLang]}
                    </Button>
                </DialogActions>
            </Dialog>
              <IdleTimer
                key='idleTimer'
                startOnMount={ true }
                ref={(ref: any) => { this.idleTimer = ref }}
                element={ document }
                onIdle={ this.onIdle }
                timeout={1500000}
              />
           </>
          );
    }

    private onIdle = () => {
        this.setState({ isTimedOut: true })
    }

    private refreshPage = () => {
        window.location.href = `${contextPath}/Public/landingPage`
    }
}

export default withStyles(styles)(Timer);