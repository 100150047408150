import { objectHasValue, resolvePath } from '../../../common/utils';
import { AppendixBRequestDTO } from '../../../../models/iOneTimeFoodDTO';
import { taxIdPlaceholderTxt } from '../../../../constants';
import instance from '../../../../i18n';
import { IFormConfig } from '../../../common/FormSection/FormSection'
import { getCurrencySymbol } from '../../utils';
import { Trans } from 'react-i18next';

const ifYes = 'oneTimeFoodDonation.ifYesExplain';
const datePlaceHolder = 'DD-MM-YYYY';
export const getAboutOrgConfig = (disabled = false, countryCode: string, appendixB: AppendixBRequestDTO): IFormConfig[]  => {

  return [
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'orglegalName',
        label: instance.t('orgRegForm.labels.orglegalName'),
        disabled: true,
        fieldName: 'organization.orgLegalName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'taxId',
        label: instance.t('orgRegForm.labels.taxId'),
        disabled: true,
        fieldName: 'organization.taxId',
        required: (countryCode !== 'CA'),
        type: 'text',
        placeholder: taxIdPlaceholderTxt[countryCode]
      }
    },
    {
      gridConfig: {
        md: 12,
        xs: 12,
        lg: 10
      },
      uiConfig: {
        id: 'streetAddress',
        label: instance.t('orgRegForm.labels.streetAddr'),
        disabled: disabled && objectHasValue('organization.address.addressLine1',appendixB),
        fieldName: 'organization.address.addressLine1',
        type: 'text',
      }
    },

    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'city',
        label: instance.t('orgRegForm.labels.city'),
        disabled: disabled && objectHasValue('organization.address.city',appendixB),
        fieldName: 'organization.address.city',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'province',
        label: instance.t('orgRegForm.labels.state'),
        disabled: disabled && objectHasValue('organization.address.province',appendixB),
        fieldName: 'organization.address.province',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'postalCode',
        label: instance.t('orgRegForm.labels.postalCode'),
        disabled: disabled && objectHasValue('organization.address.postalCode',appendixB),
        fieldName: 'organization.address.postalCode',
        type: 'text',
      }
    },{
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'country',
        label: instance.t('organizationProfileDetails.common.country'),
        disabled: disabled && objectHasValue('organization.address.country',appendixB),
        fieldName: 'organization.address.country',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'telephone',
        label: instance.t('orgRegForm.labels.telephone'),
        disabled: disabled && objectHasValue('organization.telephone',appendixB),
        fieldName: 'organization.telephone',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'website',
        label: instance.t('orgRegForm.labels.website'),
        disabled: disabled && objectHasValue('organization.website',appendixB),
        fieldName: 'organization.website',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'establishedDate',
        label: instance.t('orgRegForm.labels.establishedDate'),
        disabled: disabled && objectHasValue('organization.establishedDate',appendixB),
        placeholder: datePlaceHolder,
        fieldName: 'organization.establishedDate',
        type: 'text',
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'missionAndPurpose',
        label: instance.t('orgRegForm.labels.missionAndPurpose'),
        disabled: disabled && objectHasValue('organization.missionAndPurpose',appendixB),
        fieldName: 'organization.missionAndPurpose',
        type: 'text',
        multiline: true,
        rows: 4,
        required: true
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'geographicCoverage',
        label: instance.t('orgRegForm.labels.geographicCoverage'),
        fieldName: 'organization.geographicCoverage',
        disabled: disabled && objectHasValue('organization.geographicCoverage',appendixB),
        type: 'text',
        multiline: true,
        rows: 4,
        required: true
      }
    }
  ]
}

export const getPrimaryContactConfig = (disabled = false, appendixB: AppendixBRequestDTO): IFormConfig[]  => {

  return [
    {
      gridConfig: {
        md: 4,
        xs: 12,
        lg: 3
      },
      uiConfig: {
        id: 'primaryOrgUser.userTitle',
        label: instance.t('orgRegForm.labels.userTitle'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.userTitle',appendixB),
        fieldName: 'organization.primaryOrgUser.userTitle',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 8,
        xs: 12,
        lg: 7
      },
      uiConfig: {
        id: 'primaryOrgUser.userName',
        label: instance.t('orgRegForm.labels.primaryuser'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.userName',appendixB),
        fieldName: 'organization.primaryOrgUser.userName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'primaryuserEmail',
        label: instance.t('orgRegForm.labels.primaryuserEmail'),
        disabled: true,
        fieldName: 'organization.primaryOrgUser.userEmail',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'telephone',
        label: instance.t('orgRegForm.labels.telephone'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.userTelephone',appendixB),
        required: false,
        fieldName: 'organization.primaryOrgUser.userTelephone',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'primaryOrgUser.yearOfBirth',
        label: instance.t('orgRegForm.labels.yearOfBirth'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.birthYear',appendixB),
        fieldName: 'organization.primaryOrgUser.birthYear',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'country',
        label: instance.t('appendixBCertification.countryOfResidence'),
        disabled: disabled && objectHasValue('organization.primaryOrgUser.countryOfResidence',appendixB),
        fieldName: 'organization.primaryOrgUser.countryOfResidence',
        type: 'text',
        required: true
      }
    }
  ]
}


export const getQuestions = (disabled = false, appendixB: AppendixBRequestDTO): IFormConfig[]  => {

  return [
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'authorizedQuestion',
        question: instance.t('appendixBQuestionnaires.authorizedQuestion'),
        fieldName: 'organization.authorized',
        defaultValue: resolvePath('organization.authorized',appendixB, null),
        disabled,
        type: 'yesOrNoOption',
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'issueReceiptsQuestion',
        question: instance.t('appendixBQuestionnaires.issueReceiptsQuestion'),
        fieldName: 'organization.issueReceipts',
        defaultValue: resolvePath('organization.issueReceipts',appendixB, null),
        disabled,
        type: 'yesOrNoOption',
      },
    },
      {
        gridConfig: {
          xs: 12,
        },
        uiConfig: {
          id: 'issueReceiptText',
          fieldName: 'organization.issueReceiptText',
          label: instance.t('appendixBQuestionnaires.issueReceiptText'),
          variant: 'body1',
          type: 'textField'
        }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'govtEntityRelation',
        question: instance.t('appendixBQuestionnaires.govtEntityRelationshipQuestion'),
        label:instance.t('appendixBQuestionnaires.govtEntityRelationshipDescription'),
        fieldName: 'organization.govtEntityRelationDetails',
        optionName: 'organization.govtEntityRelation',
        defaultValue: resolvePath('organization.govtEntityRelation',appendixB, null),
        disabled,
        type: 'yesOrNoQuestion',
      },
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'govtOfficialRelationshipQuestion',
        question: instance.t('appendixBQuestionnaires.govtOfficialRelationshipQuestion'),
        label:instance.t('appendixBQuestionnaires.govtOfficialRelationshipDescription'),
        fieldName: 'organization.govtOfficialRelationDetails',
        optionName: 'organization.govtOfficialRelation',
        defaultValue: resolvePath('organization.govtOfficialRelation',appendixB, null),
        disabled,
        type: 'yesOrNoQuestion',
      }
    },{
        gridConfig: {
          xs: 12,
        },
        uiConfig: {
          id: 'politicalActivityQuestion',
          question: instance.t('appendixBQuestionnaires.politicalActivityQuestion'),
          label:instance.t('appendixBQuestionnaires.politicalActivityDescription'),
          optionName: 'organization.politicalActivity',
          fieldName: 'organization.politicalActivityDetails',
          defaultValue: resolvePath('organization.politicalActivity',appendixB, null),
          disabled,
          type: 'yesOrNoQuestion',
        },
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'briberyQuestion',
        question: instance.t('appendixBQuestionnaires.briberyQuestion'),
        label:instance.t('appendixBQuestionnaires.briberyDescription'),
        optionName: 'organization.corruptionOrBribery',
        fieldName: 'organization.corruptionOrBriberyDetails',
        defaultValue: resolvePath('organization.corruptionOrBribery',appendixB, null),
        disabled,
        type: 'yesOrNoQuestion',
      },
  },{
    gridConfig: {
      xs: 12,
    },
    uiConfig: {
      id: 'donationReceivedQuestion',
      question: instance.t('appendixBQuestionnaires.donationReceivedQuestion'),
      label:instance.t('appendixBQuestionnaires.donationReceivedDescription'),
      optionName: 'organization.pastDonation',
      fieldName: 'organization.pastDonationDetails',
      defaultValue: resolvePath('organization.pastDonation',appendixB, null),
      disabled,
      type: 'yesOrNoQuestion',
    },
},{
    gridConfig: {
      xs: 12,
    },
    uiConfig: {
      id: 'donationDeniedDescription',
      question: instance.t('appendixBQuestionnaires.donationDeniedQuestion'),
      label:instance.t('appendixBQuestionnaires.donationDeniedDescription'),
      optionName: 'organization.prevDeniedDonation',
      fieldName: 'organization.prevDeniedDonationDetails',
      defaultValue: resolvePath('organization.prevDeniedDonation',appendixB, null),
      disabled,
      type: 'yesOrNoQuestion',
    },
  },
  ]
}



export const getDonationDetails = (disabled = false,
  countryCode: string, appendixB: any, apendixBDataDefault: any): IFormConfig[]  => {

  return [
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'donationTitle',
        label: instance.t('oneTimeGoodsDonation.heading.donationTitle'),
        disabled,
        fieldName: 'donationTitle',
        type: 'text',
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'listOfCountriesActivityDone',
        question: instance.t('appendixBDonationLabels.listTheCountries'),
        label: instance.t('oneTimeFoodDonation.listOfCountries'),
        fieldName: 'listOfCountries',
        type: 'descriptionBox',
        disabled,
        required: true,
        qno: '1'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'donationOfFoodType',
        question: instance.t('oneTimeFoodDonation.donationOfFoodType'),
        label: instance.t('oneTimeFoodDonation.explain'),
        fieldName: 'description',
        type: 'descriptionBox',
        disabled,
        required: true,
        qno: '2'
      }
    },
    {
      gridConfig: {
        xs: 12,
        md: 10,
        container: true,
        spacing: 2
      },
      uiConfig: {
        id: 'requestAmount',
        label: `${instance.t('oneTimeGoodsDonation.labels.value')} (${getCurrencySymbol(countryCode)})`,
        question: instance.t('oneTimeFoodDonation.amountIf'),
        disabled,
        fieldName: 'amount',
        type: 'requestAmount',
        qno: '3'
      }
    },

    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'purposeOfDonation',
        question: instance.t('oneTimeFoodDonation.purposeOfDonation'),
        label: instance.t('oneTimeFoodDonation.explain'),
        fieldName: 'purpose',
        disabled,
        required: true,
        type: 'descriptionBox',
        qno: '4'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'oneTimeOrRecurringHeading',
        question: instance.t('formQuestion.oneTimeOrRecurringHeading'),
        yesLabel: instance.t('formQuestion.recurring'),
        noLabel: instance.t('formQuestion.oneTime'),
        label: instance.t('formQuestion.recurringDonationDetail'),
        fieldName: 'isRecurring.description',
        optionName: 'isRecurring.value',
        disabled,
        required: true,
        defaultValue : appendixB.isRecurring?.value,
        type: 'yesOrNoQuestion',
        qno: '5'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'govtSuggestionHeading',
        question: instance.t('formQuestion.govtSuggestionHeading'),
        label: instance.t('formQuestion.govtSuggestionInputHeading'),
        fieldName: 'govtSuggestion.description',
        optionName: 'govtSuggestion.value',
        disabled,
        required: true,
        defaultValue : appendixB.govtSuggestion?.value,
        type: 'yesOrNoQuestion',
        qno: '6'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'boardMEmbers',
        label:'common.orgMembers.boardMembers',
        disabled,
        fieldName:'organization.boardMembers',
        type: 'orgMember'
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'executiveOfficers',
        label:'common.orgMembers.executiveOfficers',
        disabled,
        fieldName:'organization.executiveOfficers',
        type: 'orgMember'
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'keyPersons',
        label:'common.orgMembers.keyPersons',
        fieldName:'organization.keyPersons',
        disabled,
        type: 'orgMember'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'areAnyIndividuals',
        question: instance.t('appendixBQuestionnaires.govtOrPoliticalOfficeQuestion'),
        label: instance.t(ifYes),
        fieldName: 'organization.govtOrPoliticalOfficeDetails',
        optionName: 'organization.govtOrPoliticalOffice',
        disabled,
        defaultValue: appendixB?.organization?.govtOrPoliticalOffice,
        type: 'yesOrNoQuestion',
        qno: '1'
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'doAnyIndividual',
        question: instance.t('appendixBQuestionnaires.govtOrPoliticalOfficeRelativeQuestion'),
        label: instance.t(ifYes),
        fieldName: 'organization.govtOrPoliticalOfficeRelativeDetails',
        optionName: 'organization.govtOrPoliticalOfficeRelative',
        disabled,
        defaultValue: appendixB?.organization?.govtOrPoliticalOfficeRelative,
        type: 'yesOrNoQuestion',
        qno: '2'
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'donationAttachments',
        fieldName: 'donationAttachments',
        label: instance.t('common.heading.donationDocumnets'),
        disabled,
        defaultValue: apendixBDataDefault?.uploadFiles ?? [],
        required: false,
        type: 'fileUpload'
      }
    }
  ]
}


export const getCertificationConfig = (disabled = false, apendixBDataDefault: any): IFormConfig[] => {

  return [
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'certificationCheckBox1',
        label: instance.t('appendixBCertification.checkbox1'),
        fieldName:'organization.certificationCheckBox1',
        type: 'permission',
        disabled,
        required: true,
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'certificationCheckBox2',
        label: instance.t('appendixBCertification.checkbox2'),
        labelInjected:  <Trans i18nKey='appendixBCertification.checkbox2'>
        Organization understands that the Company's Global Anti-Corruption Policy is available at <a target='_blank' href='https://walmartethics.com' rel='noreferrer'>https://walmartethics.com</a>
    </Trans>,
        fieldName:'organization.certificationCheckBox2',
        type: 'permission',
        disabled,
        required: true,
      }
    },
    {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'certificationCheckBox3',
        label: instance.t('appendixBCertification.checkbox3'),
        fieldName:'organization.certificationCheckBox3',
        type: 'permission',
        disabled,
        required: true,
      }
    },
    {
      gridConfig: {
        md: 12,
        xs: 12,
        lg: 10
      },
      uiConfig: {
        id: 'signature',
        label: instance.t('oneTimeGoodsDonation.heading.signature'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.signature',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.userTitle',
        label: instance.t('appendixBCertification.userTitle'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.userTitle',
        type: 'text',
      }
    },{
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.userName',
        label: instance.t('common.orgMembers.name'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.userName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.yearOfBirth',
        label: instance.t('appendixBCertification.yearOfBirth'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.birthYear',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignee.countryOfResidence',
        label: instance.t('appendixBCertification.countryOfResidence'),
        disabled,
        required: true,
        fieldName: 'organization.certificateSignee.countryOfResidence',
        type: 'country',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'employer',
        label: instance.t('appendixBCertification.employer'),
        disabled,
        fieldName: 'organization.certificateSignee.employer',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'certificateSignedDate',
        label: instance.t('appendixBCertification.todayDate'),
        disabled: true,
        fieldName: 'organization.certificateSignedDate',
        type: 'text',
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'certificationText',
        fieldName: 'certificationText',
        label: instance.t('appendixBCertification.certificationText'),
        variant: 'body1',
        type: 'textField'
      }
    },{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'organizationAttachments',
        fieldName: 'organizationAttachments',
        label: instance.t('common.heading.orgDocuments'),
        disabled,
        required: false,
        defaultValue: apendixBDataDefault?.orgUploadFiles ?? [],
        type: 'fileUpload'
      }
    }
  ]
}

export const getConcentConfig = (disabled = false, countryCode: string): IFormConfig[] => {
  const consentTextKey = countryCode === 'GB' ? 'appendixBCertification.consentTextUk' : 'appendixBCertification.consentTextNonUk';
  return [{
      gridConfig: {
        xs: 12,
      },
      uiConfig: {
        id: 'consentText',
        fieldName: 'consentText',
        label: consentTextKey,
        labelInjected: <Trans i18nKey={consentTextKey}>
        Consent text injected dynamically based on key
      </Trans>,
        variant: 'body1',
        type: 'textField'
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'recievedPrivacyNotice',
        label: instance.t('appendixBCertification.recievedPrivacyNotice'),
        labelInjected: <Trans i18nKey='appendixBCertification.consentCheckbox1'>
        I confirm I have reviewed Walmart Privacy Notice (<a target='_blank'
        href='https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice'>
          https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice</a>)
        </Trans>,
        fieldName:'organization.consentCheckBox1',
        type: 'permission',
        defaultValue: disabled,
        disabled,
        required: true,
      }
    }, {
      gridConfig: {
        xs: 12,
      },
      uiConfig:  {
        id: 'agreeApplicationConcent',
        label: instance.t('appendixBCertification.consentCheckbox2'),
        fieldName:'organization.consentCheckBox2',
        defaultValue: disabled,
        type: 'permission',
        disabled,
        required: true,
      }
    },    {
      gridConfig: {
        md: 12,
        xs: 12,
        lg: 10
      },
      uiConfig: {
        id: 'consentSignee.signature',
        label: instance.t('oneTimeGoodsDonation.heading.signature'),
        disabled,
        fieldName: 'organization.consentSignee.signature',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'consentSignee.userTitle',
        label: instance.t('appendixBCertification.userTitle'),
        disabled,
        fieldName: 'organization.consentSignee.userTitle',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'consentSignee.userName',
        label: instance.t('common.orgMembers.name'),
        disabled,
        fieldName: 'organization.consentSignee.userName',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'consentSignee.yearOfBirth',
        label: instance.t('appendixBCertification.yearOfBirth'),
        disabled,
        fieldName: 'organization.consentSignee.birthYear',
        type: 'text',
      }
    },
    {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'consentSignee.countryOfResidence',
        label: instance.t('appendixBCertification.countryOfResidence'),
        disabled,
        fieldName: 'organization.consentSignee.countryOfResidence',
        type: 'country',
      }
    }, {
      gridConfig: {
        md: 6,
        xs: 12,
        lg: 5
      },
      uiConfig: {
        id: 'consentSignedDate',
        label: instance.t('appendixBCertification.todayDate'),
        disabled: true,
        fieldName: 'organization.consentSignedDate',
        type: 'text',
      }
    }
  ]
}
