import { FormLabel, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessageComponent } from 'src/components/common/formComponents/ErrorMessage';
import { resolvePath } from '../../../common/utils';


const useStyles = makeStyles({
    selectRoot: {
        color: '#181818 !important',
    },
    selectDisabled: {
        backgroundColor: '#e9ecef'
    },
    selectIcon: {
        color: '#181818 !important'
    },
    selectRootLabel: {
        top: '-8px',
        left: '15px',
        zIndex: 5
    }
});

export interface IMXLanguage {
    en: string,
    es: string
    [key: string]: string
}
export interface IMDDropdownOption {
    keyText: string,
    language: IMXLanguage,
    active: boolean,
    audit: any
}
export interface IMDGroupDropdownOption {
    type: IMDDropdownOption,
    subType: IMDDropdownOption[]
}
interface KeyValueDropdownProps {
    id?: string,
    fieldName: string,
    required?: boolean,
    disabled?: boolean,
    control?: any,
    autoWidth?: boolean,
    inputLabel?: string,
    options: IDropdownOptions[],
    rules?: any,
    errorKeyName: string,
    defaultValue: string
    errors?: any
    valueKey?: string
    labelKey?: string
}

export interface IDropdownOptions {
    label: string
    value: string
}

const defaultProps: KeyValueDropdownProps = {
    fieldName: '',
    control: {},
    inputLabel: '',
    autoWidth: false,
    options: [],
    errors: {},
    defaultValue: '',
    errorKeyName: '',
    labelKey: 'label',
    valueKey: 'value'
}

const KeyValueDropdown: FC<KeyValueDropdownProps> = ((props: KeyValueDropdownProps) => {
    const classes = useStyles();
    const fieldError = resolvePath(props.errorKeyName, props.errors, '')?.message;

    return <Box data-testid='keyvalue-dropdown-container'>

        {props.options && props.options.length > 0 && <FormControl
            classes={{}}
            fullWidth={true}
            data-testid='keyvalue-dropdown-control'
            error={fieldError ? true : false}>
             <FormLabel
                required={props.required ?? false}
                classes={{ root: classes.selectRootLabel }}
                id='keyvaluedropdown-label'
            >
              {props.inputLabel}
          </FormLabel>
            <Controller
                control={props.control}
                name={props.fieldName}
                rules={{ ...props?.rules }}
                defaultValue={props.defaultValue}
                render={(field, _state) => <Select
                    classes={{ root: classes.selectRoot, icon: classes.selectIcon, disabled: classes.selectDisabled }}
                    defaultValue={props.defaultValue}
                    labelId='keyvaluedropdown-label'
                    id='keyvaluedropdown'
                    data-testid = {props.fieldName}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    inputRef={field.ref}
                    name={field.name}
                    displayEmpty={true}
                    variant='outlined'
                    required={props.required ?? false}
                    disabled={props.disabled ?? false}
                >
                    {props.options.map((itm, index) => (
                        <MenuItem
                            key={`dropDownChild-${index}`}
                            // @ts-ignore
                            value={itm[props.valueKey]}
                        >
                            {
                                // @ts-ignore
                                itm[props.labelKey]
                            }
                        </MenuItem>
                    ))}
                </Select>}
            />
            <ErrorMessageComponent errors={props.errors} fieldName={props.errorKeyName} />
        </FormControl>}
    </Box>
})

KeyValueDropdown.defaultProps = defaultProps
export default KeyValueDropdown;