import { createStyles, Grid, IconButton, Link, makeStyles, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SectionContainer } from '../../donationOfMoney/sectionsContainer';
import AddRow from '../common/AddRow';
import {InputTextField} from '../../../common/Input/InputTextField';
import {DefaultProjectObjectives} from './projectDetailsDefaults';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        containerRoot: {
            padding: '15px'
        },
        linkRoot: {
            textDecoration: 'underline'
        },
        gridRow: {
            borderBottom: '1px solid #e6e7e8'
        },
        cell: {
            padding: '0px 5px',
            verticalAlign: 'baseline'
        },
        headerRoot: {
            fontFamily: 'Bogle',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#000',
            '&:after': {
                content: '" * "'
            },
        },
        measureDeleteCell: {
            display: 'flex',
            alignItems: 'center'
        }
    }),
);

export interface IProjectObjectives {
    fieldName: string
}


export const ProjectObjectives: FC<IProjectObjectives> = (props) => {
    const { control, setValue, getValues } = useFormContext();
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    const projectObjectiveFields = useFieldArray({
        name: props.fieldName,
        control
    })
    const classes = useStyles();

    const handleAdd = () => {
        setValue(props.fieldName, [...getValues().observationSummary, DefaultProjectObjectives[0]])
    }

    const handleRemove = (index: number) => {
        projectObjectiveFields.remove(index)
    }
    const styles = { padding: '15px', backgroundColor: '#fbfbfb' }
    return (

        <SectionContainer title={getTranslation('projObjectiveHeading')}>
            <Paper elevation={3} variant='elevation' style={styles}>
                <Grid container={true} spacing={3} classes={{ root: classes.containerRoot }} >
                    <Grid container={true} item={true} className={classes.gridRow} >
                        <Grid item={true} xs={4} className={classes.cell} classes={{ root: classes.headerRoot }} >
                            {getTranslation('projObjective')}
                        </Grid>
                        <Grid item={true} xs={7} container={true} >
                            <Grid item={true} container={true} >
                                <Grid item={true} xs={6} className={classes.cell} classes={{ root: classes.headerRoot }} >
                                    {getTranslation('measureSucs')}
                                </Grid>
                                <Grid item={true} xs={6} className={classes.cell} classes={{ root: classes.headerRoot }}>
                                    {getTranslation('results')}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        projectObjectiveFields.fields.map((field, index) => {
                            return (<Grid item={true} container={true} xs={12} key={field.id} className={classes.gridRow} >
                                <Grid item={true} xs={4} className={classes.cell}     >
                                    <InputTextField
                                        fieldName={`${props.fieldName}[${index}].projectObservation`}
                                        id={`${index}`}
                                        defaultValue={field.projectObservation}
                                        errorKeyName={`${props.fieldName}.${index}.projectObservation`}
                                    />
                                </Grid>
                                <Grid item={true} xs={7}  >
                                    <MeasureResults control={control} measureIndex={index} fieldName={props.fieldName} />
                                </Grid>
                                <Grid item={true} xs={'auto'}  >
                                    <IconButton color='default' onClick={() => handleRemove(index)}
                                        disabled = {projectObjectiveFields?.fields?.length === 1}
                                        aria-label='delete row' component='span'>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </Grid>
                            </Grid>)
                        })
                    }
                </Grid>
                <AddRow
                    handler = {handleAdd}
                    key = 'addGeographicalCoverage'
                    t = {t}
                />
            </Paper>
        </SectionContainer>
    );
}

// @ts-ignore
const MeasureResults = ({ control, measureIndex, fieldName }) => {

    const measureFields = useFieldArray({
        control,
        name: `${fieldName}[${measureIndex}].measureResults`
    });
    const { t } = useTranslation();

    const handleAdd = () => {
        measureFields.append({
            measure: '',
            result: ''
        })
    }

    const classes = useStyles();
    return (<>{
        measureFields.fields.map((field, idx) => {
            const key = `${fieldName}[${measureIndex}].measureResults[${idx}]`;
            const errKey = `${fieldName}.${measureIndex}.measureResults.${idx}`
            return (<Grid item={true} container={true} key={field.id} >
                <Grid item={true} xs={6} className={classes.cell}  >
                    <InputTextField
                        fieldName={`${key}.measure`}
                        id={`${idx}`}
                        defaultValue={field.measure}
                        errorKeyName={`${errKey}.measure`}
                    />
                </Grid>
                <Grid item={true} xs={6} className={classes.cell} >
                    <InputTextField
                        fieldName={`${key}.result`}
                        id={`${idx}`}
                        defaultValue={field.result}
                        errorKeyName={`${errKey}.result`}
                    />
                </Grid>
            </Grid>)

        })
    }
        <Grid item={true} xs={'auto'}>
            <Link variant='inherit' href='#' classes={{ root: classes.linkRoot }} onClick={handleAdd}>
                {t('monetaryDonation.projectDetailsSection.addMeasureOfSuccess')}
            </Link>
        </Grid>
    </>);
}