import * as React from 'react';
import Card from '../../uiComponents/LDCard';
import CardItem from '../../uiComponents/LDCardItem';
import Divider from '../../uiComponents/LDDivider';
import Button from '../../uiComponents/LDButton';
import './styles.css'


export interface AccountInfoEditCardProps {
    header: any,
    buttonSection?: any,
    showEditButton?: boolean,
    buttonHandler?:any,
    children?: any
}

const AccountInfoEditCard: React.SFC<AccountInfoEditCardProps> = ({ header, buttonSection = null,
  children = null, showEditButton = false, ...props }) => {
    return <div className='info-card'><Card className='info-card-item'>
        <CardItem className='info-card-item info-card-header-grid'>
            <div className='info-card-header'>{header}</div>
            {showEditButton && <Button variant='tertiary' onClick={ () => {
                  if(props?.buttonHandler) {
                    props?.buttonHandler(true)
                  }
                }}>Edit</Button>}
        </CardItem>
        <Divider />
        <CardItem className='info-card-item'><div className='info-card-child'>{children}</div></CardItem>
        <CardItem className='info-card-item'><div className='info-card-footer'>{buttonSection}</div></CardItem>
    </Card></div>
}

export default AccountInfoEditCard;