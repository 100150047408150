import { registerUserActionTypes, registerUserActions } from './registerUserActions';
import { RegisterUser } from '../../models/registerUser';

export const defaultRegisterUser: RegisterUser = {
    registerUserData: {},
    registerUserLoading: false,
    registerUserFailure: false,
    registerUserSuccess: false,

    requestVerificationCode: {},
    requestVerificationCodeLoading: false,
    requestVerificationCodeFailure: false,
    requestVerificationCodeSuccess: false,

    verifyCode: {},
    verifyCodeLoading: false,
    verifyCodeFailure: false,
    verifyCodeSuccess: false,

    resetPassword: {},
    resetPasswordLoading: false,
    resetPasswordFailure: false,
    resetPasswordSuccess: false,

    selectArea: {},
    selectAreaLoading: false,
    selectAreaFailure: false,
    selectAreaSuccess: false,

    notificationMsg: 'Failed!',
};

export const registerUserDataReducer = (state: RegisterUser = defaultRegisterUser, action: registerUserActions):
    RegisterUser => {
    switch (action.type) {
        case registerUserActionTypes.REGISTER_USER: return {
            ...state,
            registerUserLoading: true,
            registerUserSuccess: false,
            registerUserFailure: false,
        };
        case registerUserActionTypes.REGISTER_USER_SUCCESS: return {
            ...state,
            registerUserData: action.data,
            registerUserLoading: false,
            registerUserFailure: false,
            registerUserSuccess: true,
        };
        case registerUserActionTypes.REGISTER_USER_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            registerUserLoading: false,
            registerUserFailure: true,
            registerUserSuccess: false,
        };

        case registerUserActionTypes.REQUEST_VERIFICATION_CODE: return {
            ...state,
            requestVerificationCodeLoading: true,
            requestVerificationCodeSuccess: false,
            requestVerificationCodeFailure: false,
        };
        case registerUserActionTypes.REQUEST_VERIFICATION_CODE_SUCCESS: return {
            ...state,
            requestVerificationCode: action.data.emailId,
            requestVerificationCodeLoading: false,
            requestVerificationCodeFailure: false,
            requestVerificationCodeSuccess: true,
        };
        case registerUserActionTypes.REQUEST_VERIFICATION_CODE_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            requestVerificationCodeLoading: false,
            requestVerificationCodeFailure: true,
            requestVerificationCodeSuccess: false,
        };

        case registerUserActionTypes.VERIFY_CODE: return {
            ...state,
            verifyCodeLoading: true,
            verifyCodeSuccess: false,
            verifyCodeFailure: false,
        };
        case registerUserActionTypes.VERIFY_CODE_SUCCESS: return {
            ...state,
            verifyCode: action.data.verificationCode,
            verifyCodeLoading: false,
            verifyCodeFailure: false,
            verifyCodeSuccess: true,
        };
        case registerUserActionTypes.VERIFY_CODE_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            verifyCodeLoading: false,
            verifyCodeFailure: true,
            verifyCodeSuccess: false,
        };

        case registerUserActionTypes.RESET_PASSWORD: return {
            ...state,
            resetPasswordLoading: true,
            resetPasswordSuccess: false,
            resetPasswordFailure: false,
        };
        case registerUserActionTypes.RESET_PASSWORD_SUCCESS: return {
            ...state,
            resetPassword: action.data,
            resetPasswordLoading: false,
            resetPasswordFailure: false,
            resetPasswordSuccess: true,
        };
        case registerUserActionTypes.RESET_PASSWORD_FAILURE: return {
            ...state,
            notificationMsg: action.data,
            resetPasswordLoading: false,
            resetPasswordFailure: true,
            resetPasswordSuccess: false,
        };

        case registerUserActionTypes.SELECT_AREA: return {
            ...state,
            selectAreaLoading: true,
            selectAreaSuccess: false,
            selectAreaFailure: false,
        };
        case registerUserActionTypes.SELECT_AREA_SUCCESS: return {
            ...state,
            selectArea: action.data,
            selectAreaLoading: false,
            selectAreaFailure: false,
            selectAreaSuccess: true,
        };
        case registerUserActionTypes.SELECT_AREA_FAILURE: return {
            ...state,
            selectAreaLoading: false,
            selectAreaFailure: true,
            selectAreaSuccess: false,
        };

        default: return state;
    }
}