import CreateDonationTemplate from '../../donations/CommonComponents/CreateDonationTemplate';
import DonationFeedbackTemplate from '../../donations/CommonComponents/DonationFeedbackTemplate';
import GenericDashBoardTemplate from '../../donations/CommonComponents/GenericDashBoardTemplate';
import ReviewDonationComponent from '../../donations/CommonComponents/ReviewDonationTemplate';
import { spacePageType } from '../../donations/gdmsSpaceDonation/SpaceInternationalDataFunctions';
import { servicePageType } from '../../donations/serviceDonation/serviceDonationDataFunctions';

import { DONATION_TYPE_VALUES } from '../../../constants';

const spaceRouteType = spacePageType;
const serviceRouteType = servicePageType;

const testRoutObj = [
    { routePattern: spaceRouteType, routeDisplayName: 'Space Internationl Donation', donationType: DONATION_TYPE_VALUES.SPACE_INTERNATIONAL },
    { routePattern: serviceRouteType, routeDisplayName: 'Service Donation', donationType: DONATION_TYPE_VALUES.SERVICE_DONATION }
]

const getDonationRoutes = () => {
    let finalRoutes: any = [];
    testRoutObj.map((routingObject: any) => {
        finalRoutes = [...finalRoutes, ...setDynamicRouting(routingObject)]
    });

    return finalRoutes;
}

const setDynamicRouting = (routingObject: any) => {
    return [
        {
            rotueName: 'dashBoard',
            displayName: `${routingObject.routeDisplayName} DashBaord`,
            routePath: `dashboard/${routingObject.routePattern}`,
            routeComponent: GenericDashBoardTemplate,
            donationCategory: routingObject.donationType
        },
        {
            rotueName: 'add',
            displayName: `Add ${routingObject.routeDisplayName} Request`,
            routePath: `add/${routingObject.routePattern}`,
            routeComponent: CreateDonationTemplate,
            donationCategory: routingObject.donationType
        },
        {
            rotueName: 'thankyou',
            displayName: `Thankyou ${routingObject.routeDisplayName}`,
            routePath: `thank-you/${routingObject.routePattern}`,
            routeComponent: DonationFeedbackTemplate,
            donationCategory: routingObject.donationType
        },
        {
            rotueName: 'view',
            displayName: `view ${routingObject.routeDisplayName}`,
            routePath: `view/${routingObject.routePattern}`,
            routeComponent: ReviewDonationComponent,
            donationCategory: routingObject.donationType
        }
    ];
}

export default getDonationRoutes;