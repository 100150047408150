import { useState } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, Theme, withStyles, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '../common';
import { useTranslation } from 'react-i18next';

const inputTextColor = 'var(--inputTextColor) !important';
const fontFamily = ' var(--appFont)';

const styles = (theme: Theme) => ({
    closeButton: {
        position: 'absolute' as 'absolute',
        right: 25,
        top: 10,
        color: theme.palette.grey[500],
    },
    paperFullWidth: {
        minWidth: '600px',
        minHeight: '300px'
    },
    dialogContentRoot: {
        paddingBottom: '70px',
    },
    inputRoot: {
        width: '100%',
        color: inputTextColor,
        fontFamily,
        '& textarea, input': {
            color: inputTextColor,
            backgroundColor: '#fff !important',
            fontFamily
        },
        '& textarea:disabled, input:disabled': {
            backgroundColor: '#e9ecef'
        },
        backgroundColor: '#fff !important',
        '& .MuiInputLabel-outlined': {
            backgroundColor: '#fff'
        }
    }
});

const InputConfirmDialog = ((props: any) => {
    const { classes } = props;
    const [inputData, setInputData] = useState('');
    /* istanbul ignore next */
    const onInputChange = (e: any) => setInputData(e.target.value);
    /* istanbul ignore next */
    const onSubmit = () => props.onSubmit(inputData);
    const { t } = useTranslation();
    return (
        <div id={props.id} className='inputConfirmDialog-wrapper'>
            <Dialog
                disableEnforceFocus={true}
                title={`Add ${props.title} to Submit.`}
                fullWidth={true}
                maxWidth={'md'}
                open={props.showDialog}
                classes={{
                    paperFullWidth: classes.paperFullWidth
                }}
            >
                <DialogTitle
                    id='input-confirm-dialog'
                >{props.title}
                    <IconButton aria-label='close' className={classes.closeButton} onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    classes={{
                        root: classes.dialogContentRoot
                    }}
                >
                    <TextField
                        placeholder={t('status.specifyAdditionalInformation')}
                        classes={{
                            root: classes.inputRoot
                        }}
                        className={classes.input}
                        onChange={onInputChange}
                        id='gnt-additional-comments'
                        label={t('status.specifyAdditionalInformation')}
                        multiline={true}
                        rows={5}
                        variant='outlined'
                    />
                    <Button
                        id={'ngoForm_submit'}
                        className={`
                        submit_button_styles ngo_next_button
                        ${(inputData.length === 0) ? 'submit_disabled' : ''}
                    `}
                        disabled={(inputData.length === 0) ? true : false}
                        type={'submit'}
                        title={t('common.buttonLabels.submitBtn')}
                        style={{ position: 'absolute', bottom: '20px', right: '24px' }}
                        onClick={onSubmit}
                    />
                </DialogContent>
            </Dialog>
        </div>);
});
export default withStyles(styles)(InputConfirmDialog);