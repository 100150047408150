import { Action } from 'redux';

export enum TraslationActionTypes {
    TRANSLATION_DATA_LOAD = 'TRANSLATION_DATA_LOAD',
    TRANSLATION_LOAD_SUCCESS = 'TRANSLATION_LOAD_SUCCESS',
    TRANSLATION_LOAD_FAILURE = 'TRANSLATION_LOAD_FAILURE'
}

export interface TraslationData extends Action {
    type: TraslationActionTypes.TRANSLATION_DATA_LOAD;
    data: any;
}

export const traslationData = (data: string): TraslationData => ({
    type: TraslationActionTypes.TRANSLATION_DATA_LOAD,
    data
});

export interface TraslationSuccess extends Action {
    type: TraslationActionTypes.TRANSLATION_LOAD_SUCCESS;
    data: any;
}

export const traslationSuccess = (data: any): TraslationSuccess => ({
    type: TraslationActionTypes.TRANSLATION_LOAD_SUCCESS,
    data
});

export interface TranslationFailure extends Action {
    type: TraslationActionTypes.TRANSLATION_LOAD_FAILURE;
    data?: any;
}

export const translationFailure = (data: string): TranslationFailure => ({
    type: TraslationActionTypes.TRANSLATION_LOAD_FAILURE,
    data
});

export type TraslationDataActions = TraslationData | TraslationSuccess | TranslationFailure;