import { IconButton, Paper, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputTextField } from 'src/components/common/Input/InputTextField';
import { FileUploader } from '../../../common/formComponents/FilesUploader';
import { SectionContainer } from '../../donationOfMoney/sectionsContainer';
import AddRow from '../common/AddRow';
import BiLingualDropdown, { BiLingualDropdownTypes } from '../common/biLingualDropdown';
import { CustomCell } from '../common/CustomCell';
import { CustomDivider } from '../common/CustomDivider';
import MoreDetailsTable from '../common/MoreDetailsTable';
import { ExpenseTotals } from './expenseTotalsTable';
import { DefaultOrganizationExpense, Sections } from './orgDetailsDefaults';

interface OrganizationExpensesProps {
    fieldName?: string,
    fileDownloader: (data: any) => void;
}


const columnHeader = [{ key: 'expType', width: '24%' }, { key: 'annExp', width: '24%' },
{ key: 'percentage', width: '24%' }, { key: 'actions', width: '6%' }];

export const OrganizationExpenses: FC<OrganizationExpensesProps> = ({ fileDownloader }) => {
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField')
    const { control, errors } = useFormContext();

    const currentFinancialYearFields = useFieldArray({
        name: Sections.currentExpense,
        control
    });
    const previousFinancialYearFields = useFieldArray({
        name: Sections.previousExpense,
        control
    });

    const currentYearWatcher = useWatch<any>({ control, name: Sections.currentExpense });
    const previousYearWatcher = useWatch<any>({ control, name: Sections.previousExpense });

    return (<>
        <SectionContainer title={getTranslation('currFin')}>
            <CustomTable fields={currentFinancialYearFields.fields}
                id={'currentFinancialYearTable'}
                fieldName={Sections.currentExpense}
                removeRowHandler={currentFinancialYearFields.remove}
                addRowHandler={currentFinancialYearFields.append}
                defaultValues={DefaultOrganizationExpense.currentFinancialYear[0]}
                totalFieldName={'totals.expenseCurrentFinancialYear'}
                ariaLabel={'Organization Expenses Current Financial Year Table'}
                getTranslation={getTranslation}
                requiredFieldMsg={requiredFieldMsg}
                watchedFields={currentYearWatcher}
                control={control}
                t={t}
                errors={errors} />
        </SectionContainer>
        <SectionContainer title={getTranslation('prevFin')}>
            <CustomTable fields={previousFinancialYearFields.fields}
                t={t}
                id={'previousFinancialYearTable'}
                fieldName={Sections.previousExpense}
                removeRowHandler={previousFinancialYearFields.remove}
                addRowHandler={previousFinancialYearFields.append}
                totalFieldName={'totals.expensePreviousFinancialYear'}
                ariaLabel={'Organisation Expenses Previous Financial Year Table'}
                getTranslation={getTranslation}
                requiredFieldMsg={requiredFieldMsg}
                watchedFields={previousYearWatcher}
                control={control}
                defaultValues={DefaultOrganizationExpense.previousFinancialYear[0]}
                errors={errors} />
            <FileUploader name={Sections.expenseAttachments} fileDownloader={fileDownloader} />
        </SectionContainer>
    </>
    );
}

interface CustomExpenseComponentProps {
    fields: any;
    fieldName: any;
    removeRowHandler: any; 
    addRowHandler: any; 
    t: any;
    id: any;
    defaultValues: any;
    ariaLabel?: any;
    getTranslation: any;
    requiredFieldMsg: any; 
    totalFieldName: any;
    watchedFields: any;
    control: any;
    errors: any;
}

const CustomExpenseComponent: FC<CustomExpenseComponentProps> = ({ fields, fieldName, removeRowHandler, addRowHandler, 
    t, id, defaultValues, getTranslation, requiredFieldMsg, totalFieldName, 
    watchedFields, control, errors }) => {
    
    const styles = { maxWidth: '870px', padding: '15px', backgroundColor: '#fbfbfb' }
    return (<>
        <Paper elevation={3} variant='elevation' style={styles}>
            <MoreDetailsTable
                getTranslation={getTranslation}
                header={columnHeader}>
                {fields.map((field: any, index: number) => {

                    const errorKey = `${fieldName}.${index}`;
                    return (<TableRow key={field.id} >
                        <CustomCell >
                            <BiLingualDropdown
                                fieldName={`${fieldName}[${index}].type`}
                                id={field.id}
                                optionKey={BiLingualDropdownTypes.EXPENSES_TYPE}
                                errorKeyName={`${errorKey}.type`}
                                errors={errors}
                                control={control}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].amount`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.amount}
                                errorKeyName={`${errorKey}.amount`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell  >
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].percentage`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.percentage}
                                errorKeyName={`${errorKey}.percentage`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <IconButton color='default' id={field.id} onClick={() => removeRowHandler(index)}
                                disabled={fields.length === 1}
                                data-testid='delete-row'
                                aria-label='delete row' component='span'>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </CustomCell>

                    </TableRow>)
                })}
            </MoreDetailsTable>
            <ExpenseTotals watchedFields={watchedFields} getTranslation={getTranslation} totalFieldName={totalFieldName} />
            <CustomDivider />
            <AddRow handler={() => addRowHandler(defaultValues)} key={id} t={t} />
        </Paper>
    </>
    );
}
const CustomTable = React.memo(CustomExpenseComponent);