
import { useMutation, useQuery } from "react-query";
import { reactQueryConfigOptions } from "../../../constants";
import {
    createNewDonation, getDonationDetailsById, getDonationsForDashboard,
    getRdaConfig, postDonationTasksByAction
} from "../../../service/apiService";

export const baseSubContextUrl = 'base-donation';

export const RdaCheck = (setIsLocationMandatory: any, setError: any) => {
    const mutation = useMutation({
        mutationFn: (payload: any) => {
            return getRdaConfig(payload);
        },
        onSuccess: (data: any) => {
            if (data.hasOwnProperty("errorMessage")) {
                setError(true);
            } else {
                setIsLocationMandatory(data);
            }
        },
    });
    return mutation;
};

export const GetDonationById = (donationId: number, subContext: string) => {
    const query = useQuery(
        ["fetchDonationByProjectId", { donationId, subContext }],
        getDonationDetailsById,
        { ...reactQueryConfigOptions, cacheTime: 0 }
    );

    return query;
};

export const useFetchSpaceDonationDashboard = (payload: any) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        isFetching,
        isRefetching,
        data
    } = useQuery(['getGdmsSpaceDashboard', { ...payload, pageNumber: payload?.page }], getDonationsForDashboard,
        { ...reactQueryConfigOptions, keepPreviousData: true, cacheTime: 0 });

    return { data, isLoading, isSuccess, isError, isFetching, isRefetching, error };
}

export const useCreateDonationApi = (history: any, setError: any, apiUrl: string, redirectUrl: string) => {
    const mutation = useMutation({
        mutationFn: (payload: any) => {
            return createNewDonation(payload, apiUrl);
        },
        onSuccess: (data: any) => {
            if (data.hasOwnProperty("errorMessage")) {
                setError(true);
            }
            else {
                history.push({
                    pathname: redirectUrl,
                    state: {
                        donationId: data,
                    },
                });
            }
        }
    });
    return mutation;
};

// For any dynamic actions (accept and cancel) donations("space-international/tasks")
export const useDonationAction = (invokeOnDonationActionApiCompleted: any, apiUrl: string) => {
    const mutation = useMutation({
        mutationFn: (payload: any) => {
            return postDonationTasksByAction(payload.form, apiUrl);
        },
        onSuccess: (data: any, variables: any) => {
            invokeOnDonationActionApiCompleted(data, variables, true);
        },
        onError: () => {
            invokeOnDonationActionApiCompleted("", "", false);
        },
    });

    return mutation;
};
