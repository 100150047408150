import { Action } from 'redux';

export enum OrgUserManagementActionTypes {
    CHANGE_SELECTED_USER = 'CHANGE_SELECTED_USER',
    SELECTED_USER_CHANGED = 'SELECTED_USER_CHANGED',
    ADD_ORG_USER = 'ADD_ORG_USER',
    ADD_ORG_USER_SUCCESS = 'ADD_ORG_USER_SUCCESS',
    ADD_ORG_USER_FAILURE = 'ADD_ORG_USER_FAILURE',
    EDIT_ORG_USER = 'EDIT_ORG_USER',
    EDIT_ORG_USER_SUCCESS = 'EDIT_ORG_USER_SUCCESS',
    EDIT_ORG_USER_FAILURE = 'EDIT_ORG_USER_FAILURE',
    REMOVE_ORG_USER = 'REMOVE_ORG_USER',
    REMOVE_ORG_USER_SUCCESS = 'REMOVE_ORG_USER_SUCCESS',
    REMOVE_ORG_USER_FAILURE = 'REMOVE_ORG_USER_FAILURE',
    SEND_TEMPORARY_PASSWORD = 'SEND_TEMPORARY_PASSWORD',
    SEND_TEMPORARY_PASSWORD_SUCCESS = 'SEND_TEMPORARY_PASSWORD_SUCCESS',
    SEND_TEMPORARY_PASSWORD_FAILURE = 'SEND_TEMPORARY_PASSWORD_FAILURE',
    SET_AS_PRIMARY_ORG_USER = 'SET_AS_PRIMARY_ORG_USER',
    SET_AS_PRIMARY_ORG_USER_SUCCESS = 'SET_AS_PRIMARY_ORG_USER_SUCCESS',
    SET_AS_PRIMARY_ORG_USER_FAILURE = 'SET_AS_PRIMARY_ORG_USER_FAILURE',
    CHECK_FIRST_TIME_LOGIN='CHECK_FIRST_TIME_LOGIN',
    CHECK_FIRST_TIME_LOGIN_SUCCESSFUL='CHECK_FIRST_TIME_LOGIN_SUCCESSFUL',
    SAVE_ORG_USER_PASSWORD= 'SAVE_ORG_USER_PASSWORD',
    SAVE_ORG_USER_PASSWORD_SUCCESSFUL= 'SAVE_ORG_USER_PASSWORD_SUCCESSFUL',
    SAVE_ORG_USER_PASSWORD_FAILURE='SAVE_ORG_USER_PASSWORD_FAILURE'
}

export interface ChangeSelectedUser extends Action {
    type: OrgUserManagementActionTypes.CHANGE_SELECTED_USER,
    data: any
}

export const changeSelectedUser = (data: any) : ChangeSelectedUser => ({
    type: OrgUserManagementActionTypes.CHANGE_SELECTED_USER,
    data
});

export interface SelectedUserChanged extends Action {
    type: OrgUserManagementActionTypes.SELECTED_USER_CHANGED,
    data: any
}

export const selectedUserChanged = (data: any) : SelectedUserChanged => ({
    type: OrgUserManagementActionTypes.SELECTED_USER_CHANGED,
    data
});

export interface AddOrgUser extends Action {
    type: OrgUserManagementActionTypes.ADD_ORG_USER,
    data: any
}

export const addOrgUser = (data: any) : AddOrgUser => ({
    type: OrgUserManagementActionTypes.ADD_ORG_USER,
    data
});

export interface AddOrgUserSuccess extends Action {
    type: OrgUserManagementActionTypes.ADD_ORG_USER_SUCCESS,
    data: any
}

export const addOrgUserSuccess = (data: any) : AddOrgUserSuccess => ({
    type: OrgUserManagementActionTypes.ADD_ORG_USER_SUCCESS,
    data
});

export interface AddOrgUserFailure extends Action {
    type: OrgUserManagementActionTypes.ADD_ORG_USER_FAILURE,
    data?: any;
}

export const addOrgUserFailure = (data: any) : AddOrgUserFailure => ({
    type: OrgUserManagementActionTypes.ADD_ORG_USER_FAILURE,
    data
});

export interface EditOrgUser extends Action {
    type: OrgUserManagementActionTypes.EDIT_ORG_USER,
    data: any
}

export const editOrgUser = (data: any) : EditOrgUser => ({
    type: OrgUserManagementActionTypes.EDIT_ORG_USER,
    data
});

export interface EditOrgUserSuccess extends Action {
    type: OrgUserManagementActionTypes.EDIT_ORG_USER_SUCCESS,
    data: any
}

export const editOrgUserSuccess = (data: any) : EditOrgUserSuccess => ({
    type: OrgUserManagementActionTypes.EDIT_ORG_USER_SUCCESS,
    data
});

export interface EditOrgUserFailure extends Action {
    type: OrgUserManagementActionTypes.EDIT_ORG_USER_FAILURE,
    data?: any;
}

export const editOrgUserFailure = (data: any) : EditOrgUserFailure => ({
    type: OrgUserManagementActionTypes.EDIT_ORG_USER_FAILURE,
    data
});

export interface RemoveOrgUser extends Action {
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER,
    data: any
}

export const removeOrgUser = (data: any) : RemoveOrgUser => ({
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER,
    data
});

export interface RemoveOrgUserSuccess extends Action {
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER_SUCCESS,
    data: any
}

export const removeOrgUserSuccess = (data: any) : RemoveOrgUserSuccess => ({
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER_SUCCESS,
    data
});

export interface RemoveOrgUserFailure extends Action {
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER_FAILURE,
    data?: any;
}

export const removeOrgUserFailure = (data: any) : RemoveOrgUserFailure => ({
    type: OrgUserManagementActionTypes.REMOVE_ORG_USER_FAILURE,
    data
});

export interface SendTemporaryPassword extends Action {
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD,
    data: any
}

export const sendTemporaryPassword = (data: any) : SendTemporaryPassword => ({
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD,
    data
});

export interface SendTemporaryPasswordSuccess extends Action {
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_SUCCESS,
    data: any
}

export const sendTemporaryPasswordSuccess = (data: any) : SendTemporaryPasswordSuccess => ({
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_SUCCESS,
    data
});

export interface SendTemporaryPasswordFailure extends Action {
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_FAILURE,
    data?: any;
}

export const sendTemporaryPasswordFailure = (data: any) : SendTemporaryPasswordFailure => ({
    type: OrgUserManagementActionTypes.SEND_TEMPORARY_PASSWORD_FAILURE,
    data
});

export interface SetAsPrimaryOrgUser extends Action {
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER,
    data: any
}

export const setAsPrimaryOrgUser = (data: any) : SetAsPrimaryOrgUser => ({
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER,
    data
});

export interface SetAsPrimaryOrgUserSuccess extends Action {
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_SUCCESS,
    data: any
}

export const setAsPrimaryOrgUserSuccess = (data: any) : SetAsPrimaryOrgUserSuccess => ({
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_SUCCESS,
    data
});

export interface SetAsPrimaryOrgUserFailure extends Action {
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_FAILURE,
    data?: any;
}

export const setAsPrimaryOrgUserFailure = (data: any) : SetAsPrimaryOrgUserFailure => ({
    type: OrgUserManagementActionTypes.SET_AS_PRIMARY_ORG_USER_FAILURE,
    data
});

export interface CheckFirstTimeLogIn extends Action {
    type: OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN
}

export const checkFirstTimeLogIn = () : CheckFirstTimeLogIn => ({
    type: OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN
});

export interface CheckFirstTimeLogInSuccessful extends Action {
    type: OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN_SUCCESSFUL;
    data: any;
}

export const checkFirstTimeLogInSuccessful = (data: any) : CheckFirstTimeLogInSuccessful => ({
    type: OrgUserManagementActionTypes.CHECK_FIRST_TIME_LOGIN_SUCCESSFUL,
    data
});


export interface SaveOrgUserPassword extends Action {
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD,
    data: any
}

export const saveOrgUserPassword = (data: any) : SaveOrgUserPassword => ({
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD,
    data
});

export interface SaveOrgUserPasswordSuccessful extends Action {
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_SUCCESSFUL,
    data: any
}

export const saveOrgUserPasswordSuccessful = (data: any) : SaveOrgUserPasswordSuccessful => ({
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_SUCCESSFUL,
    data
});

export interface SaveOrgUserPasswordFailure extends Action {
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_FAILURE,
    data: any
}

export const saveOrgUserPasswordFailure = (data: any) : SaveOrgUserPasswordFailure => ({
    type: OrgUserManagementActionTypes.SAVE_ORG_USER_PASSWORD_FAILURE,
    data
});


export type OrgUserManagementActions = AddOrgUser | AddOrgUserSuccess | AddOrgUserFailure
| EditOrgUser | EditOrgUserSuccess | EditOrgUserFailure
| RemoveOrgUser | RemoveOrgUserSuccess | RemoveOrgUserFailure
| SendTemporaryPassword | SendTemporaryPasswordSuccess | SendTemporaryPasswordFailure
| SetAsPrimaryOrgUser | SetAsPrimaryOrgUserSuccess | SetAsPrimaryOrgUserFailure
| ChangeSelectedUser | SelectedUserChanged | CheckFirstTimeLogIn | CheckFirstTimeLogInSuccessful
| SaveOrgUserPasswordFailure | SaveOrgUserPasswordSuccessful | SaveOrgUserPassword;
