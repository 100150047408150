import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Divider, Grid, Paper } from '@material-ui/core';
import { Button } from 'primereact/button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useForm } from 'react-hook-form';
import KeyvalueDropdown from '../common/Dropdown/keyValueDropdown';
import { useTranslation } from 'react-i18next';
import { exportToExcel } from './../../service/apiService';
import { makeStyles } from '@material-ui/core/styles';
import {
    getYearValues,
    getMonthValues
} from 'src/components/common/utils';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    buttonStyles: {
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#0071ce',
        textTransform: 'uppercase',
        marginRight: '10px',
        color: '#fff',
        border: 'solid 1px',
        fontSize: '14px',
        fontWeight: 'bold',
        width: 'auto',
    }
}));


interface ExtractReportProps {
    lang: string
    showModal: boolean
    donationType: string
    handleModalAction: (showModal: boolean) => void
    heading: string
}
export const ExtractDialogComponent: FC<ExtractReportProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<any>();
    const [errorMsg, setErrorMsg] = useState(false);

    const {
        errors,
        control,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            fromMonth: 1,
            toMonth: 12
        }
    });
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField');


    const submit = (data: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.DOWNLOAD_EXCEL_REPORT.name,
            location: ANALYTICS.socialImpact.dashboardPage
        });
        const payload = {
            ...data,
            'donationType': props.donationType
        }

        exportToExcel({
            payload: { ...payload }, endpoint: '/social-impact-reports/extract-excel-report',
            fileName: 'socialImpactReport'

        }).then((response: any) => {
            if (response.errorMessage === '') {
                setErrorMsg(true)
                setErrorMessage(response?.data)

            }
        })
    }

    return (<><Dialog
        fullWidth={true}
        maxWidth={'sm'}
        title={(props.heading)}
        disableEnforceFocus={true}
        open={props.showModal}
        aria-labelledby='ddq-dialog'
        aria-describedby='ddq-dialog'>
        <DialogTitle id='Extract-report-dialog' className='Extract-report-title'>
            {(props.heading)}
            <IconButton aria-label='close' className='pull-right' id='close-dailog'
                onClick={() => props.handleModalAction(false)}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
            <div className={classes.root}>
                <form onSubmit={handleSubmit(submit)} id={'Extract-Report-form'}
                    noValidate={true} autoComplete='off'>
                    <div className='form-text error'>
                        {errorMessage}
                    </div>
                    <Grid container={true} spacing={3}>
                        ` <Grid xs={6} sm={3} >
                            <Paper className={classes.paper}>
                                <div className='Extract-Dialog-container'>
                                    <p className='extract-header' />
                                    <form noValidate={true} autoComplete='off'>
                                        <div className='extract-filters clearfix'>
                                            <div className='filter'>
                                                <KeyvalueDropdown
                                                    id={'year'}
                                                    fieldName={`year`}
                                                    defaultValue={(new Date().getFullYear()).toString()}
                                                    inputLabel={t('extractDialog.year')}
                                                    control={control}
                                                    options={getYearValues(2014)}
                                                    errors={errors}
                                                    errorKeyName={'year'}
                                                    required={true}
                                                    rules={{
                                                        required: requiredFieldMsg
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid xs={6} sm={4}>
                            <Paper className={classes.paper}>
                                <div className='Extract-Dialog-container'>
                                    <p className='extract-header' />
                                    <form noValidate={true} autoComplete='off'>
                                        <div className='extract-filters clearfix'>
                                            <div className='filter'>
                                                <KeyvalueDropdown
                                                    id={'fromMonth'}
                                                    fieldName={`fromMonth`}
                                                    inputLabel={t('extractDialog.startMonthRange')}
                                                    defaultValue={1}
                                                    control={control}
                                                    options={getMonthValues(props.lang)}
                                                    errors={errors}
                                                    errorKeyName={'fromMonth'}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid xs={6} sm={4}>
                            <Paper className={classes.paper}>
                                <div className='Extract-Dialog-container'>
                                    <p className='extract-header' />
                                    <form noValidate={true} autoComplete='off'>
                                        <div className='extract-filters clearfix'>
                                            <div className='filter'>
                                                <KeyvalueDropdown
                                                    id={'toMonth'}
                                                    fieldName={`toMonth`}
                                                    defaultValue={12}
                                                    inputLabel={t('extractDialog.endMonthRange')}
                                                    control={control}
                                                    options={getMonthValues(props.lang)}
                                                    errors={errors}
                                                    errorKeyName={'toMonth'}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={3}>
                        <Grid container={true}
                            item={true}
                            direction='row'
                            justifyContent='center'
                            alignItems='center'>
                            <Button label={t('extractDialog.Download')} className={classes.buttonStyles}
                                type='submit' data-testid='submit-btn' />
                        </Grid>
                    </Grid>
                </form>
            </div>
        </DialogContent>
    </Dialog >
        <ErrorSnackbarComponent isError={errorMsg ? true : false}
            message={t('extractDialog.errorMessage')}
        />
    </>)
}
