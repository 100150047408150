import * as React from 'react';
import { showAppLogo, hideAppLogo } from '../../../../../state/spaceDonation/AppLogo/AppLogoActions';

import { connect } from 'react-redux';


export interface Props {
    children: any,
    showAppLogo: any,
    hideAppLogo: any
}

class AppLogoHandler extends React.Component<Props> {
    public componentWillMount() {
        this.props.hideAppLogo();
    }
    public componentWillUnmount() {
        this.props.showAppLogo();
    }

    public render() {
        return <div>{this.props.children}</div>
    }
}


const mapDispatchToProps = (dispatch: React.Dispatch<any>): any => ({
    hideAppLogo: () => dispatch(hideAppLogo()),
    showAppLogo: () => dispatch(showAppLogo())
});

export default connect(null, mapDispatchToProps)(AppLogoHandler);