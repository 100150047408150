import * as React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../models/globalDonationState';

const styles: any = (_theme: any) => ({
    selectRoot: {
        color: 'var(--inputTextColor) !important',
        fontFamily: ' var(--appFont)',
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: 'var(--inputTextColor) !important'
    },
    selectFormControl: {
        color: '#495057 !important',
        minWidth: '200px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontWeight:'bold'
    },
    selectLabel: {
        color: '#495057 !important',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor: '#fff'
    },
    menuItemRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)'
    },
    menuItemselected: {
        color: 'var(--inputTextColor);'
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonGroup: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonStyles: {
        marginBottom: 10,
        color: '#fff !important',
        background: '#007bff !important',
        borderRadius: '20px',
        textAlign: 'center',
        textTransform: 'none',
        minWidth:'80px'
    },
    buttonContainer:{
        justifyContent: 'center !important',
    },
    closeBtn: {
        fontSize: '18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color: 'red'
        }
    }
})

interface OwnProps {
    showDialog: boolean;
    continue: () => void;
    cancel: () => void;
    message: string;
    isAsdaTheme?:boolean;
}

export interface StateProps {
    selectedLang: string;
    orgUserManagementState: any;
}

const appi18n: any = {
    cancelButtonText:{
        en:'Cancel',
        es:'Cancelar',
        fr: 'Annuler',
        ja:'キャンセル',
        zh:'取消'
    },
    yesButtonText:{
        en:'Yes',
        es:'Sí',
        fr: 'Oui',
        ja:'はい',
        zh:'是的'
    }
}

class OrgUserOtherActionsConfirmDialog extends React.PureComponent<OwnProps & StateProps & any>{

    public render() {
        const { classes } = this.props;
        return <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                disableEnforceFocus={true}
                disableBackdropClick={true}
                open={this.props.showDialog}
                onClose={this.props.cancel}
                aria-labelledby='org-user-action-confirm-dialog'
                aria-describedby='org-user-action-confirm-dialog'>
                    <DialogTitle id='org-user-action-confirm-dialog'>
                        <div>
                            <b>{this.props.title}</b>
                        </div>
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText id='org-user-action-confirm-dialog-description' style={{ paddingBottom: '5%' }}>
                            {this.props.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.buttonContainer}>
                    <Button
                        className={`${this.props.isAsdaTheme ? 'confirm-btn-asda' : classes.buttonStyles}`}
                        onClick={() => this.props.continue(this.props.orgUserData)} color='primary' autoFocus={true}>
                        {appi18n.yesButtonText[this.props.selectedLang]}
                    </Button>
                    <Button
                        className={`${this.props.isAsdaTheme ? 'confirm-btn-asda' : classes.buttonStyles}`}
                        onClick={this.props.cancel} color='primary' autoFocus={true}>
                        {appi18n.cancelButtonText[this.props.selectedLang]}
                    </Button>
                </DialogActions>
            </Dialog>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    orgUserManagementState: state.orgUserManagementState
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)((OrgUserOtherActionsConfirmDialog)));