
import IAppendixCDonationInfo from '../../../models/iAppendixCDonationInfo';
import { objectHasValue, resolvePath } from '../../../components/common/utils';
import instance from '../../../i18n';
import { IFormConfig } from '../../../components/common/FormSection/FormSection';

const dateFormat = 'DD-MM-YYYY';

export const getDonationRecipientSection = (disabled = false, appenidxC: IAppendixCDonationInfo)
    : IFormConfig[] => {

    return [
        {
            gridConfig: {
                md: 6,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'orglegalName',
                label: instance.t('appendices.c.donationRecipient.orglegalName'),
                disabled: disabled && objectHasValue('orgLegalName', appenidxC),
                fieldName: 'orgLegalName',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 10,
                sm: 10,
                lg: 10
            },
            uiConfig: {
                id: 'businessAddress',
                label: instance.t('appendices.c.donationRecipient.businessAddress'),
                disabled: disabled && objectHasValue('address.addressLine1', appenidxC),
                fieldName: 'address.addressLine1',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'postalCode',
                label: instance.t('appendices.c.donationRecipient.postalCode'),
                disabled: disabled && objectHasValue('address.postalCode', appenidxC),
                fieldName: 'address.postalCode',
                type: 'text',
                required: false
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'country',
                label: instance.t('appendices.c.donationRecipient.countryOrRegion'),
                disabled: disabled && objectHasValue('address.country', appenidxC),
                fieldName: 'address.country',
                type: 'text',
                required: true
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'telephone',
                label: instance.t('appendices.c.donationRecipient.enityTelphoneNo'),
                disabled: disabled && objectHasValue('telephone', appenidxC),
                fieldName: 'telephone',
                type: 'text',
                required: true
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'donationReceivedDate',
                label: instance.t('appendices.c.donationRecipient.donationRecievedDate'),
                fieldName: 'donationReceivedDate',
                type: 'text',
                required: true,
                disabled: disabled && objectHasValue('donationReceivedDate', appenidxC),
                placeholder:dateFormat
            }
        },
        {
            gridConfig: {
                md: 10,
                sm: 10,
                lg: 10
            },
            uiConfig: {
                id: 'reasonOfDonation',
                label: instance.t('appendices.c.donationRecipient.donationDescription'),
                fieldName: 'reasonOfDonation',
                type: 'text',
                multiline: true,
                disabled: disabled && objectHasValue('reasonOfDonation', appenidxC),
                rows: 4,
                required: true
            }
        }
    ]
}

export const getRecipientCertificationSection = (disabled = false, appenidxC: IAppendixCDonationInfo)
    : IFormConfig[] => {

    return [
        {
            gridConfig: {
                sm: 10,
            },
            uiConfig: {
                id: 'recipientCertificationText',
                fieldName: 'certificationText',
                label: 'appendices.c.recipient.certificationText',
                labelInjected: true,
                variant: 'body1',
                type: 'textField'
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'recepientInfoName',
                label: instance.t('appendices.c.recipient.name'),
                disabled: disabled && objectHasValue('recipientInfo.name', appenidxC),
                fieldName: 'recipientInfo.name',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'recepientInfoSignature',
                label: instance.t('appendices.c.recipient.signature'),
                disabled: disabled && objectHasValue('recipientInfo.signature', appenidxC),
                fieldName: 'recipientInfo.signature',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'recepientInfoDate',
                label: instance.t('appendices.c.recipient.date'),
                disabled: objectHasValue('recipientInfo.date',
                appenidxC),
                fieldName: 'recipientInfo.date',
                type: 'text',
                placeholder:dateFormat
            }
        },
    ]
}

export const getCompanyEmpCertificationSection = (disabled = false,
    appenidxC: IAppendixCDonationInfo, showUploadedFile?:true)
    : IFormConfig[] => {

    const uiConfig:IFormConfig[] = [
        {
            gridConfig: {
                sm: 10,
            },
            uiConfig: {
                id: 'companyEmployeeCertificationText',
                fieldName: 'certificationText',
                label: 'appendices.c.companyEmployee.certificationText',
                labelInjected: true,
                variant: 'body1',
                type: 'textField'
            }
        },
        {
            gridConfig: {
                md: 8,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'employeeName',
                label: instance.t('appendices.c.companyEmployee.name'),
                disabled: disabled && objectHasValue('companyEmployee.name', appenidxC),
                fieldName: 'companyEmployee.name',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'employeeTitle',
                label: instance.t('appendices.c.companyEmployee.title'),
                disabled: disabled && objectHasValue('companyEmployee.title', appenidxC),
                fieldName: 'companyEmployee.title',
                type: 'text',
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'employeeDatedate',
                label: instance.t('appendices.c.companyEmployee.date'),
                disabled: disabled && objectHasValue('companyEmployee.date',appenidxC),
                fieldName: 'companyEmployee.date',
                type: 'text',
                placeholder:dateFormat
            }
        },
        {
            gridConfig: {
                md: 4,
                sm: 12,
                lg: 5
            },
            uiConfig: {
                id: 'employeeSignature',
                label: instance.t('appendices.c.companyEmployee.signature'),
                disabled: disabled && objectHasValue('companyEmployee.signature',appenidxC),
                fieldName: 'companyEmployee.signature',
                type: 'text',
            }
        },
    ]
    if (showUploadedFile) {
        const fileInfo = resolvePath('fileInfo',appenidxC, '')
        uiConfig.push(
            {
                gridConfig: {
                    sm: 8,
                    md:6
                },
                uiConfig: {
                  id: 'consentFiles',
                  fieldName: 'consentDocuments',
                  label: instance.t('common.heading.consentDocuments'),
                    required: true,
                  disabled:true,
                  type: 'fileUpload',
                  defaultValue:fileInfo?.fileName ? [{...fileInfo}] : []
                }
              }
        )
    }
    return uiConfig
}
