import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    makeStyles,
    Snackbar,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { Cancel, CheckCircle, CloudDownload, CloudUpload, Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GenericConfirmDialog from 'src/components/dialogComponents/GenericConfirmDialog';
import { IMXLanguage } from 'src/components/common/Dropdown/keyValueDropdown';
import GlobalDonationDataService from 'src/service';

interface AttachmentsProps {
    files: Map<string, IFileSnapshot>
    setTabFullFilled: (status: boolean) => void,
    donationId: string
    lang: string,
    setLasModified: (lastModified: Date) => void,
}

export interface IFileSnapshot {
    code: string;
    mandatory: boolean;
    label: ILanguageOptions;
    fileDetails: IFileDetails;
}

export interface IFileDataResponse {
    uploadedFiles: Map<string, IFileSnapshot>;
    lastModifiedOn: Date | null;
}

interface ILanguageOptions {
    keyText: string,
    language: IMXLanguage
}

export interface IFileDetails {
    container: string | null;
    fileAdditionalDetails: string | null
    fileId: string | null;
    fileName: string | null;
    uploadDir: string | null;
    uploadedOn?: Date
}

const useStyles = makeStyles({
    attachmentsContainer: {
        margin: '12px 14px 6px'
    },
    tittle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    tittleDescription: {
        fontSize: '14px',
        color: '#6d7278',
    },

    tableHead: {
        fontWeight: 'bolder',
        border: '0 !important',
        padding: '16px 5px 16px 0 !important'
    },
    tableBody: {
        padding: '7px 0 !important',
        verticalAlign: 'middle !important',
        borderBottom: 0
    },
    tableContainer: {
        margin: '15px 0',
        padding: '6px 20px 0px',
        borderRadius: '6px',
        boxShadow: '0 1px 1px 1px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fbfbfb;',
        width: 'auto'
    },
    actionButton: {
        padding: '5px',
        '&:disabled': {
            opacity: '.6',
        }
    },
    yesButton: {
        '&:hover': {
            color: '#041E42'
        }
    },
    noButton: {
        '&:hover': {
            color: '#041E42'
        }
    }
});

const maxFileSize = '10 mb';
const supportedFileTypes = ['pdf','xlx'];
const supportedFileExtensions = ['.xls', '.xlsx', '.pdf'];


const validateFileSize = (file: File): boolean=> {
    return file.size <= 10**7;
}

let errorMessage = '';

const AttachmentsTableComponent: FC<AttachmentsProps> = ({files, setTabFullFilled, donationId,lang, setLasModified}) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.attachmentsSection.${key}`);

    const [pendingAction, setPendingAction] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState('');
    const [showError, setErrorState] = useState(false);
    const [rowMap, setRowMap] = useState<IFileSnapshot[]>(Array.from(files.values()));
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const fileUploadEl = useRef<HTMLInputElement>(null);
    const formEl = useRef<HTMLFormElement>(null);

    useEffect(() => {
        const pending = rowMap.some((item) => (item.mandatory && !item.fileDetails?.fileName));
        setTabFullFilled(!pending && !pendingAction);
    }, [rowMap, pendingAction]);

    const downloadFile = async (fileId: string) => {
        setSelectedFileId(fileId);
        setPendingAction(true);
        const status = await GlobalDonationDataService
            .downloadAdditionalAttachment(donationId, fileId, files.get(fileId)?.fileDetails.fileName??'newFile');
        if(!status) {
            errorMessage = getTranslation('failedToDownload').replace('{0}', files.get(fileId)?.fileDetails.fileName??'');
            setErrorState(true);
        }
        setPendingAction(false);
    }

    const uploadFile = (fileId: string) => {
        setSelectedFileId(fileId);
        fileUploadEl.current?.click();
    }

    const handlErrorClose = () => {
        setErrorState(false);
    }

    const deleteFile = (fileId: string) => {
        setDialogOpen(true);
        setSelectedFileId(fileId);
    }

    const handleDeleteFile = async (isDelete: boolean) => {
        setDialogOpen(false);
        if(isDelete) {
            setPendingAction(true);
            const status = await GlobalDonationDataService.deleteAdditionalAttachment(donationId, selectedFileId);
            const fileElem = files.get(selectedFileId);
            if(status && fileElem) {
                fileElem.fileDetails = {
                    container: null,
                    fileAdditionalDetails: null,
                    fileId: null,
                    fileName: null,
                    uploadDir: null,
                };
                files.set(selectedFileId, fileElem);
                setRowMap(Array.from(files.values()));
                setLasModified(status);
            } else {
                errorMessage = getTranslation('failedToDelete').replace('{0}', files.get(selectedFileId)?.fileDetails.fileName ??'');
                setErrorState(true);
            }
            setPendingAction(false);
        }
    };

    const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file: File = event.target.files?.item(0) as File;
        if(file) {
            if(validateFileSize(file)) {
                setErrorState(false);
                const formData = new FormData();
                formData.append('file',file,file.name);
                setPendingAction(true);
                const fileData = await GlobalDonationDataService.uploadAdditionalAttachment(donationId, selectedFileId, formData);
                setPendingAction(false);
                const fileElem = files.get(selectedFileId);
                if(fileData && fileElem) {
                    fileElem.fileDetails = fileData;
                    if(fileData.uploadedOn) {
                        setLasModified(fileData.uploadedOn);
                    }
                    files.set(selectedFileId, fileElem);
                    setRowMap(Array.from(files.values()));
                } else {
                    errorMessage = getTranslation('failedToUpload').replace('{0}', file.name);
                    setErrorState(true);
                }

            } else {
                errorMessage = getTranslation('fileSizeError');
                setErrorState(true);
            }
            formEl.current?.reset();
        }
    };
    return <Fragment>
            <Box className={classes.attachmentsContainer}>
                <Typography classes={{ root: classes.tittle }} >{getTranslation('uploadDocuments')}</Typography>
                <Typography classes={{ root: classes.tittleDescription }} >{getTranslation('maxFileSize')}: {maxFileSize} | {getTranslation('fileType')}: {supportedFileTypes.toString()}</Typography>
                <TableContainer component={Grid} classes={{root: classes.tableContainer}}>
                    { rowMap ?
                    <Table data-testid='attachmentsTable' aria-label={getTranslation('table.ariaLabel')}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{head: classes.tableHead}} variant='head'>
                                    {getTranslation('table.header.documentName')}
                                </TableCell>
                                <TableCell classes={{head: classes.tableHead}} align='center'>
                                    {getTranslation('table.header.status')}
                                </TableCell>
                                <TableCell classes={{head: classes.tableHead}} variant='head'>
                                    {getTranslation('table.header.uploadedFiles')}
                                </TableCell>
                                <TableCell classes={{head: classes.tableHead}} variant='head' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowMap.map((row) => {
                                const fileName: string | null = row.fileDetails.fileName
                                    && row.fileDetails.fileName.length > 0 ? row.fileDetails.fileName: null;
                                return (<TableRow key={row.code}>
                                    <TableCell classes={{body: classes.tableBody}} variant='body'>
                                        <Box display='inline' fontWeight='bold'>
                                            {row.label.language[lang]}{row.mandatory&&' *'}
                                        </Box>
                                    </TableCell>
                                    <TableCell size='small' align='center' style={{minWidth: '100px'}}
                                        classes={{body: classes.tableBody}} variant='body'>
                                        {fileName ? <CheckCircle style={{color: 'green'}} />: <Cancel color='error' />}
                                    </TableCell>
                                    <TableCell classes={{body: classes.tableBody}} variant='body'>{fileName ?
                                        <Box display='inline' fontWeight='bold'>{fileName??''}</Box>:
                                        <Typography classes={{ root: classes.tittleDescription }} >
                                            {getTranslation('table.uploadMessage')}
                                        </Typography>}
                                    </TableCell>
                                    <TableCell size='small' align='right' style={{minWidth: '100px'}}
                                        classes={{body: classes.tableBody}} variant='body'>
                                        {pendingAction && selectedFileId === row.code ? <CircularProgress size={30}/> :
                                        fileName ?
                                            <Fragment>
                                                <IconButton disabled={pendingAction} data-testid={`download_${row.code}`}
                                                    color='default' classes={{root: classes.actionButton}}
                                                    onClick={() => downloadFile(row.code)}>
                                                    <CloudDownload color='primary' />
                                                </IconButton>
                                                <IconButton disabled={pendingAction} data-testid={`delete_${row.code}`}
                                                    color='default'classes={{root: classes.actionButton}}
                                                    onClick={() => deleteFile(row.code)}>
                                                    <Delete color='primary' />
                                                </IconButton>
                                            </Fragment>
                                        : <IconButton disabled={pendingAction} data-testid={`upload_${row.code}`}
                                            color='default' classes={{root: classes.actionButton}}
                                            onClick={() => uploadFile(row.code)}>
                                            <CloudUpload color='primary' />
                                        </IconButton>
                                    }
                                    </TableCell>
                                </TableRow>
                            )})}
                        </TableBody>
                    </Table>
                    :<Typography component='p'>
                        {getTranslation('noFileData')}
                    </Typography>
                    }
                </TableContainer>
                <Typography variant='body2'><b>*</b> {getTranslation('mandatoryFiles')}</Typography>
                <form ref={formEl}>
                    <input data-testid='fileUpload' type='file' accept={supportedFileExtensions.toString()}
                        width='0' height='0' ref={fileUploadEl} onChange={onFileChange}/>
                </form>
            </Box>
            <Snackbar open={showError} autoHideDuration={6000} onClose={handlErrorClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handlErrorClose} severity='error'>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <GenericConfirmDialog showDialog={dialogOpen}
                title={getTranslation('deleteFileDialogTitle')}
                message={getTranslation('deleteFileDialogMessage').replace('{0}', files.get(selectedFileId)?.fileDetails.fileName??'')}
                confirmButton={t('common.buttonLabels.yes')}
                cancelButton={t('common.buttonLabels.no')}
                cancel={() => handleDeleteFile(false)}
                confirm={() => handleDeleteFile(true)}
                cancelBtnClasses={classes.noButton}
                confirmBtnClasses={classes.yesButton}
            />

    </Fragment>
}

export default AttachmentsTableComponent;