import { Action } from 'redux';

export enum ConfigActionTypes {
    CONFIG_DATA_LOAD = 'CONFIG_DATA_LOAD',
    CONFIG_LOAD_SUCCESS = 'CONFIG_LOAD_SUCCESS',
    CONFIG_LOAD_FAILURE = 'CONFIG_LOAD_FAILURE'
}

export interface ConfigData extends Action {
    type: ConfigActionTypes.CONFIG_DATA_LOAD;
    data: any;
}

export const configData = (data: string): ConfigData => ({
    type: ConfigActionTypes.CONFIG_DATA_LOAD,
    data
});

export interface ConfigSuccess extends Action {
    type: ConfigActionTypes.CONFIG_LOAD_SUCCESS;
    data: any;
}

export const configSuccess = (data: any): ConfigSuccess => ({
    type: ConfigActionTypes.CONFIG_LOAD_SUCCESS,
    data
});

export interface ConfigFailure extends Action {
    type: ConfigActionTypes.CONFIG_LOAD_FAILURE;
    data?: any;
}

export const configFailure = (data: string): ConfigFailure => ({
    type: ConfigActionTypes.CONFIG_LOAD_FAILURE,
    data
});

export type ConfigDataActions = ConfigData | ConfigSuccess | ConfigFailure;