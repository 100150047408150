import { useQuery } from 'react-query'
import { reactQueryConfigOptions } from '../constants';
import { getDonationDataByIdAndType } from '../service/apiService';

export const useFetchDonationDetailsByIdAndType = (donationId: number | string, donationType?:string) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        data
    } = useQuery(['fetchDonationDetailsByIdAndType', { donationId, donationType }], getDonationDataByIdAndType,
        { ...reactQueryConfigOptions, cacheTime: 0 }
    );

    return {
        donationData: data?.donation,
        waaSTaskMessage: data?.waaSTaskMessage,
        isLoading,
        isSuccess,
        isError,
        error
    };
}
