import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import _ from 'lodash';
import  { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Loader from 'src/components/spacedonation/commonComponents/appComponents/loader/loader';
import NgoDownloadModal from './ngoDownloadModal';
import TextField from '@material-ui/core/TextField';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { validateEmailSeparatedBySymbol } from 'src/components/spacedonation/commonComponents/common/utils';
import { getLoggedInUserEmailService } from 'src/spaceDonationService';
import { reactQueryConfigOptions } from 'src/constants';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
const service: Service = SpaceDonationService;

interface ModalProps {
    userAction: any;
    isDetailsLoading: any;
    modaldetails: any;
    orgId: any;
    ncId: any;
    setShowApprove: any;
    setIsDownloadSuccess: any;
    setIsDownloadFailure: any;
}
function NgoDownloadReport({ userAction, setShowApprove,setIsDownloadSuccess,setIsDownloadFailure,isDetailsLoading, modaldetails, ncId, orgId }: ModalProps) {
    const [eventInformation, seteventInformation] = useState<any[]>([]);
    const [storeInformation, setStoreInformation] = useState<any[]>([]);
    const [organizationInformation, setOrganizationInformation] = useState<any[]>([]);
    const [downloadDisabled, setDownloadDisabled] = useState(true);
    const [name, setName] = useState([] as any);
    const [validEmail, setValidEmail] = useState(true);
    const getupdatedInfoArr = (dataObj: any) => {
        return Object?.keys(dataObj).map(key => {
            return {
                id: key,
                val: dataObj[key],
                isCheckedFlag: false
            }
        });
    };

    const { data,isLoading } = useQuery(
        ['getLoggedInUserEmailService'],
        getLoggedInUserEmailService,
        { ...reactQueryConfigOptions }
    );
    useEffect(() => {
        setName([data]);
    }, [isLoading]);
    const handleChange = (event: any) => {
        setName([data, ...event.target.value?.split(';')]);
        if (event?.target?.value?.length > 0) {
            validateEmail(event.target.value);
        } else {
            setValidEmail(true);
        }
    };

    const changeFlagValue = (dataArr: any, checkFlag: any = false) => {
        return dataArr.map((dataa: any) => {
            return { ...dataa, isCheckedFlag: checkFlag }
        })
    }

    const validateEmail = (text: string) => {
        const response = validateEmailSeparatedBySymbol(text, ';');
        setValidEmail(response);
    };


    useEffect(() => {
        if (!_.isEmpty(modaldetails)) {
            const { generalInfo, storeInfo, organizationInfo } = modaldetails;
            seteventInformation(getupdatedInfoArr(generalInfo));
            setStoreInformation(getupdatedInfoArr(storeInfo));
            setOrganizationInformation(getupdatedInfoArr(organizationInfo));
        }
    }, [modaldetails])

    const selectAllBoxes = (event: any) => {
        if (event.target.checked) {
            seteventInformation(changeFlagValue(eventInformation, true));
            setStoreInformation(changeFlagValue(storeInformation, true));
            setOrganizationInformation(changeFlagValue(organizationInformation, true));
            setDownloadDisabled(false);
        } else {
            seteventInformation(changeFlagValue(eventInformation));
            setStoreInformation(changeFlagValue(storeInformation));
            setOrganizationInformation(changeFlagValue(organizationInformation));
            setDownloadDisabled(true);
        }
    }
    const toggleDownload = (dataArr: any, eventName: string) => {
        let eventdata = [];
        let storeData = [];
        let orgdata = [];
        switch (eventName) {
            case 'event':
                eventdata = dataArr.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                storeData = storeInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                orgdata = organizationInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                break;
            case 'store':
                eventdata = eventInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                storeData = dataArr.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                orgdata = organizationInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                break;
            case 'org':
                eventdata = eventInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                storeData = storeInformation.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                orgdata = dataArr.filter((dataa: { isCheckedFlag: boolean; }) => dataa.isCheckedFlag === true);
                break;
        }
        const flag = (eventdata.length || storeData.length || orgdata.length) ? false : true;
        setDownloadDisabled(flag);

    }

    const handleEventInfocheckbox = (id: any, flag: any) => {
        const eventInformationRequestData = eventInformation.map((dataa: any) => {
            return { ...dataa, isCheckedFlag: dataa.id === id ? flag.checked : dataa.isCheckedFlag }
        });
        seteventInformation(eventInformationRequestData);
        toggleDownload(eventInformationRequestData, 'event');
    }

    const handleStoreInfocheckbox = (id: any, flag: any) => {
        const storeInfoRequestData = storeInformation.map((dataa: any) => {
            return { ...dataa, isCheckedFlag: dataa.id === id ? flag.checked : dataa.isCheckedFlag }
        });
        setStoreInformation(storeInfoRequestData);
        toggleDownload(storeInfoRequestData, 'store');
    }

    const handleOrgInfocheckbox = (id: any, flag: any) => {
        const orgInfoRequestData = organizationInformation.map((dataa: any) => {
            return { ...dataa, isCheckedFlag: dataa.id === id ? flag.checked : dataa.isCheckedFlag }
        });
        setOrganizationInformation(orgInfoRequestData);
        toggleDownload(orgInfoRequestData, 'org');
    }
    const payload = {
        ncId,
        orgId,
        toEmailIds: name,
        generalInfoList: eventInformation.filter((item) => item.isCheckedFlag === true).map((item) => item.id),
        storeInfoList: storeInformation.filter((item) => item.isCheckedFlag === true).map((item) => item.id),
        organizationInfoList: organizationInformation.filter((item) => item.isCheckedFlag === true).map((item) => item.id),
    }
    const mutation = useMutation((dataa: any) => service.generateExcelReport(dataa));
    useEffect(() => {
        if (mutation?.isError === true) {
          setIsDownloadFailure(true);
        }
      }, [mutation?.isError]);
    const downloadFile = () => {
        mutation?.reset()
        mutation?.mutate(payload,{onSuccess(){
            setShowApprove(false);
            setIsDownloadSuccess(true);
        }});
    }
    return (
        <>
        {isDetailsLoading && <Loader />}
            <div className='row'>
                {mutation.isLoading && <div style={{zIndex: 99999}}><Loader /></div>}
                <NgoDownloadModal
                    headerText='Download Report'
                    subHeading='Customize your selection from below'
                    smallText='Please confirm the fields you want in your report from the selection below'
                    bodyText={
                        <Fragment>
                            <div className='row select-all-heading'>
                                <FormGroup className='nc-download-parent'>
                                    <FormControlLabel className='info-heading' control={<Checkbox />}
                                        label='Select All' data-testid='selectall' onChange={selectAllBoxes} />
                                </FormGroup>
                            </div>
                            <div className='row divider'>&nbsp;</div>
                            <div className='row'>
                                <div className='col-md-4 checkbox-header'>
                                    <span className='info-heading'>General Info</span>
                                    <FormGroup className='nc-download-parent'>
                                        {eventInformation && eventInformation.map(dataa => {
                                            return <FormControlLabel checked={dataa.isCheckedFlag}
                                            key={dataa.id} className='info-heading' control={<Checkbox />}
                                            label={dataa.val} data-testid='eventinfobtn'
                                            onChange={(event) => handleEventInfocheckbox(dataa.id, event.target)}/>
                                        })}
                                    </FormGroup>
                                </div>
                                <div className='col-md-4 checkbox-header'>
                                    <span className='info-heading'>Store Info</span>
                                    <FormGroup className='nc-download-parent'>
                                        {storeInformation && storeInformation.map(dataa => {
                                            return <FormControlLabel checked={dataa.isCheckedFlag}
                                            key={dataa.id} className='info-heading' control={<Checkbox />}
                                            label={dataa.val} data-testid='storeinfobtn'
                                            onChange={(event) => handleStoreInfocheckbox(dataa.id, event.target)} />
                                        })}
                                    </FormGroup>
                                </div>
                                <div className='col-md-4 checkbox-header'>
                                    <span className='info-heading'>Organization info</span>
                                    <FormGroup className='nc-download-parent'>
                                        {organizationInformation && organizationInformation.map(dataa => {
                                            return <FormControlLabel checked={dataa.isCheckedFlag}
                                            key={dataa.id} className='info-heading' control={<Checkbox />}
                                            label={dataa.val} data-testid='orginfobtn'
                                            onChange={(event) => handleOrgInfocheckbox(dataa.id, event.target)} />
                                        })}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                            {isLoading ?
                                <Loader /> :
                                <div className='mail-header'>The report of the download will be sent over on the email address : {data}
                                <TextField
                                            id='email-address'
                                            margin='normal'
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            fullWidth={true}
                                            label='Additional Email address'
                                            placeholder='Additional Email address'
                                            helperText='Multiple email IDs can be entered for this field separated by ;'
                                            onChange={handleChange}
                                            error={!validEmail}
                                            data-testid='multiple-email-ids'
                                            inputProps={{ 'data-testid': 'multiple-emails-input' }}
                                />
                                </div>}
                            </div>
                        </Fragment>
                    }
                    cancelBtn={{ label: 'Cancel', actionFn: () => userAction('cancel') }}
                    applyBtn={{ label: 'Download', actionFn: () => downloadFile(), isApplyButtonDisabled: downloadDisabled }}
                />
            </div>
        </>
    );
}

export default NgoDownloadReport;

export const AlertInfo = (props: any) => <div className='alert-box'><Alert severity={props.level}>
    {props.message}
</Alert><><CloseIcon onClick={props.hideAllAlerts} /></></div>
