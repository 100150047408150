
import _ from "lodash";

export const detectChanges = (oldArray: any[], newArray: any[]) => {
    const changes: any = {
        added: [],
        updated: [],
        removed: [],
        unchanged: [],
    };

    // Create a map of ids for faster lookup
    const oldIdsMap = new Map(oldArray?.map((item) => [item._id, item]));
    const newIdsMap = new Map(newArray?.map((item) => [item._id, item]));

    // Find added and updated items
    newArray?.forEach((newItem: any) => {
        const oldItem = oldIdsMap.get(newItem._id);

        if (oldItem) {
            if (!_.isEqual(oldItem, newItem)) {
                changes.updated.push(newItem);
            } else {
                changes.unchanged.push(newItem);
            }
        } else {
            changes.added.push(newItem);
        }
    });

    // Find removed items
    oldArray?.forEach((oldItem) => {
        if (!newIdsMap.has(oldItem._id)) {
            changes.removed.push(oldItem);
        }
    });

    return changes;
}

// function to add _id field to for each org members to identify uniquly
export const getOrgGovernanceDetails = (boardMembers: any[], executiveOfficers: any[], keyPersons: any[]) => {
    const updatedBoardMembers = boardMembers?.map((boardMember: any, index: number) => {
        return { _id: `bm_${index + 1}`, ...boardMember };
    });
    const updatedExecutiveOfficers = executiveOfficers?.map((executiveOfficer: any, index: number) => {
        return { _id: `eo_${index + 1}`, ...executiveOfficer };
    });
    const updatedKeyPersons = keyPersons?.map((keyPerson: any, index: number) => {
        return { _id: `kp_${index + 1}`, ...keyPerson };
    });
    return {
        boardMembers: updatedBoardMembers,
        executiveOfficers: updatedExecutiveOfficers,
        keyPersons: updatedKeyPersons
    }
}

export const getOldandNewValuesofAnUpdateObject = (oldObj: any, newObj: any) => {
    const changes: any = {}
    for (let prop in newObj) {
        if (newObj?.[prop] !== oldObj?.[prop]) {
            changes[prop] = {
                oldValue: oldObj?.[prop],
                newValue: newObj?.[prop]
            }
        } else {
            changes[prop] = {
                oldValue: oldObj?.[prop],
                newValue: ''
            }
        }
    }
    return changes
}

// funation to get org members(board memmebr, keypersons etc) old and new values request payload format to send to backend
export const getOrgMembersFormat = (oldGovernanceDetails: any, updatedGovernceDetails: any,
    orgMemberType: string) => {

    const added: any[] = [];
    const updated: any[] = [];
    const deleted: any[] = [];

    updatedGovernceDetails?.added?.forEach((row: any) => {
        added.push({
            fieldName: orgMemberType,
            newUserTitle: row.userTitle,
            newUserName: row.userName,
            newCityOfResidence: row.cityOfResidence,
            newStateOfResidence: row.stateOfResidence,
            newCountryOfResidence: row.countryOfResidence,
            newBirthYear: row.birthYear
        })
    })

    updatedGovernceDetails?.updated?.forEach((row: any) => {
        const oldObj = oldGovernanceDetails.find((obj: any) => obj?._id === row?._id);
        const result: any = getOldandNewValuesofAnUpdateObject(oldObj, row)
        if (Object.keys(result).length === 0) {
            return
        }
        updated.push({
            fieldName: orgMemberType,
            newUserTitle: result?.['userTitle']?.newValue,
            newUserName: result?.['userName']?.newValue,
            newCityOfResidence: result?.['cityOfResidence']?.newValue,
            newStateOfResidence: result?.['stateOfResidence']?.newValue,
            newCountryOfResidence: result?.['countryOfResidence']?.newValue,
            newBirthYear: result?.['birthYear']?.newValue,
            oldUserTitle: result?.['userTitle']?.oldValue,
            oldUserName: result?.['userName']?.oldValue,
            oldCityOfResidence: result?.['cityOfResidence']?.oldValue,
            oldStateOfResidence: result?.['stateOfResidence']?.oldValue,
            oldCountryOfResidence: result?.['countryOfResidence']?.oldValue,
            oldBirthYear: result?.['birthYear']?.oldValue
        })
    })

    updatedGovernceDetails?.removed?.forEach((row: any) => {
        deleted.push({
            fieldName: orgMemberType,
            oldUserTitle: row.userTitle,
            oldUserName: row.userName,
            oldCityOfResidence: row.cityOfResidence,
            oldStateOfResidence: row.stateOfResidence,
            oldCountryOfResidence: row.countryOfResidence,
            oldBirthYear: row.birthYear
        })
    })

    return {
        added,
        updated,
        deleted
    }
}

export const removeEmpty = (obj: any) => {
    Object.entries(obj).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
            removeEmpty(value);
            if (Object.keys(value).length === 0) {
                delete obj[key];
            }
        } else if (value === null || value === '' || value === undefined) {
            delete obj[key];
        }
    });
    return obj;
}

export const getOrgGovernanceDetailsFormat = (membersData: any, governaceDetails: any) => {

    if (membersData?.added?.length > 0) {
        governaceDetails.added = [...governaceDetails?.added, ...membersData?.added]
    }
    if (membersData?.updated?.length > 0) {
        governaceDetails.updated = [...governaceDetails?.updated, ...membersData?.updated]
    }
    if (membersData?.deleted?.length > 0) {
        governaceDetails.deleted = [...governaceDetails?.deleted, ...membersData?.deleted]
    }
}
