import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import KeyValueDropdown from '../../../components/donations/moreDetailsSections/common/keyValueDropdown';
import { countryList } from '../../../static/countryList';
import { IUIConfig } from './FormSection';


const CountryDropDown: FC<IUIConfig> = ({
  fieldName,
  label = '',
  id,
  disabled = false,
  required = false
}) => {

  const {control, errors, getValues, setValue} = useFormContext()

  useEffect(() => {
    const val: string = getValues(fieldName) ?? '';
    if(val !== val.toUpperCase()) {
      setValue(fieldName, val.toUpperCase());
    }
  })

  return <KeyValueDropdown
    fieldName = {fieldName}
    errorKeyName = {fieldName}
    required = {required}
    defaultValue = ''
    disabled = {disabled}
    autoWidth = {true}
    id = {id}
    inputLabel = {label}
    options = {countryList.map((c) => ({
      label: c.label,
      value: c.label.toUpperCase()
    }))}
    errors={errors}
    control={control}

  />
}

export default CountryDropDown;
