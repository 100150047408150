/* eslint-disable */

import {
    Button,
    ButtonGroup
} from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getOrganizationObject } from "../../../components/common/utils";
import { DONATION_TYPE_VALUES } from "../../../constants";
import withRouter from "../../../polyfils/customRouter";
import CommonLDModal from "../../common/CommonLDModal";
import TypicalDonationTemplateForm from "../CommonComponents/TypicalDonationTemplateForm";
import StatusCards from "../emergencyDonation/components/StatusCards";
import { cancelModal, createPayloadForAction } from "./serviceDonationDataFunctions";

export interface OwnProps {
    history: any;
    selectedLang: any;
    donationActionMutate: any;
    donationData: any;
    donationDetailsQuery: any;
    donationCategory: any;
}

const ServiceDonationView: FC<OwnProps> = ({ history, selectedLang, donationActionMutate, donationCategory, donationDetailsQuery }) => {
    const { t } = useTranslation();

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<any>({});

    const [donationData, setDonationData] = useState<any>({});
    const [donationStatus, setDonationStatus] = useState<any>([]);
    const [waasTaskProps, setWaasTaskProps] = useState<any>([]);
    let [organizationForm, setOrganizationForm] = useState<any>([]);
    // Re - run this piece of code for fetcing details again
    useEffect(() => {
        setDonationStatus(donationDetailsQuery?.data?.spaceAndServiceDonation?.currentStatusCode);
        setWaasTaskProps(donationDetailsQuery?.data?.waaSTaskMessage?.formProperties || []);
        setDonationData(donationDetailsQuery?.data);
        let orgProfile = donationData?.spaceAndServiceDonation?.organization ? donationData?.spaceAndServiceDonation?.organization : [];
        setOrganizationForm(getOrganizationObject(orgProfile, "serviceDonations"));
    }, [donationData?.spaceAndServiceDonation]);

    const onAction = (actionAllowed: string) => {
        let acceptPayload = createPayloadForAction(donationData, window.modalBoxComments, actionAllowed);
        donationActionMutate.mutate({ form: acceptPayload, action: actionAllowed });
        window.modalBoxComments = "";
        setIsPopupOpen(false);
    }

    const cancelSubmittedDonation = () => {
        let createModalProps = cancelModal(t, setIsPopupOpen, onAction, 'cancel');
        setModalProps(createModalProps);
        setIsPopupOpen(true);
    }

    return (
        <>
            {donationData?.spaceAndServiceDonation ?
                <>
                    <ButtonGroup className="service-donation-top-right-button">

                        {
                            (donationStatus === "UNDER_RDA_REVIEW" || donationStatus === "APPROVED") &&
                            (waasTaskProps.indexOf('cancel') >= 0) &&
                            (
                                <Button onClick={cancelSubmittedDonation} variant="primary" data-testid="cancel-new-service-donation-request">
                                    {t("serviceDonations.form.cancel")}
                                </Button>)
                        }
                    </ButtonGroup>


                    <StatusCards
                        status={donationStatus}
                        data={donationData?.spaceAndServiceDonation}
                        donationType={DONATION_TYPE_VALUES.SERVICE_DONATION}
                    />

                    <TypicalDonationTemplateForm
                        history={history}
                        data={donationData?.spaceAndServiceDonation}
                        orgType={donationData?.spaceAndServiceDonation?.organization?.organizationTypePersisted}
                        editable={false}
                        selectedLang={selectedLang}
                        isLocationMandatory={false}
                        donationCategory={donationCategory}
                        organizationForm={organizationForm}
                    />

                </> : ""}

            <CommonLDModal setOpen={setIsPopupOpen} isOpen={isPopupOpen} modalConfig={modalProps} />

        </>
    );
};

export default withRouter(ServiceDonationView);
