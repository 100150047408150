
import { CheckCircle } from '../../commonComponents/icons/icon';
import './styles.css';
import ADCard from '../../commonComponents/appComponents/advertisementCard/advertisementCard';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import { contextPath } from '../../../../constants';
import { Link } from 'react-router-dom';
import { navBarOpen } from '../../../../state/spaceDonation/navbar/navBarActions';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

const service: Service = SpaceDonationService;
interface IState {
    isVerified?: string;
  }
const orgType =(org:any)=>{
        if(org){
             return 'verified'
        }
        return 'nonVerified';
}
const SpaceDonationCreationSuccess = () => {
    const { data,isFetching} = useQuery(['fetchFocusAreas', { key: 'spaceAds', countryCode: 'US' }],
    service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });
    const isVerified = (useLocation().state as IState).isVerified;
    return <>{isFetching ? <LoaderComponent /> : <div className='row success-message-container'>
        <div className='col-12 success-icon-container marginBottom'>
            <CheckCircle className='success-icon' />
            <div className='success-icon-header'>Thank you for your request</div>
            <div>We will send you a notification once a store manager reviews your request.
            Requests may take up to 7 days ahead of your event date to be approved or declined.</div>
        </div>
        <Link className='success-back-to-home-button' to={`${contextPath}/space-donation/events`}>
            <LDButton variant='primary' onClick={() => navBarOpen}>
            Back to Events</LDButton>
        </Link>
        <div className='col-12 ad-container'>
        {data.spaceAdsList.filter((ad:any)=> Object.keys(ad).length)?.length>0 ?
       <><div className='ad-container-header'>Discover more opportunities for your organization</div>
            <div className='add-list row'>
            {data.spaceAdsList.filter((ad:any)=>ad?.adFile?.fileId && (ad?.orgType===orgType(isVerified))).map((ad:any,i:number) => {
                return <div className='col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 add-card' key={i}>
                <ADCard
                fileId={ad.adFile.fileId}
                directory={ad.adFile.container}
                header={ad.headLine}
                buttonName={ad.textToDisplay}
                buttonLink={ad.websiteUrl}>
                {ad.body}</ADCard></div>
            })}
            </div></> :<></>}
        </div>
        <br/>
    </div>
    }</>
}

export default SpaceDonationCreationSuccess;
