import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import withRouter from "src/polyfils/customRouter";
import { exportToExcel } from "src/service/apiService";
import { BreadcrumbsComponent } from "../../../components/common/index";
import "./styles.css";

import { CloudDownload } from "@livingdesign/icons";
import {
  Button,
  ButtonGroup,
  Card, Heading
} from "@walmart-web/livingdesign-components";
import LoaderComponent from "src/components/LoaderComponent";
import { contextPath } from "../../../constants";
import { DonationFlow } from "./common/DataTypes";
import EmergencyDonationDataTable from "./components/EmergencyDonationDataTable";
export interface OwnProps {
  history: any;
  selectedLang?: any;
}

const breadcrumbsData = [
  {
    name: "emergencyDonations.nav.home",
    path: "/",
  },
  {
    name: "emergencyDonations.nav.emergencyDonations",
    active: true,
    applyFormat: false,
  },
];

const EmergencyDonations: FC<OwnProps> = ({ history,selectedLang }) => {
  const { t } = useTranslation();
  const [donationFlow, setDonationFlow] = useState<DonationFlow>(
    DonationFlow.Dashboard
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [lazyParams, setlazyParams] = useState({});
  
  const exportBudgetDetails = () => {
    setLoading(true)
    exportToExcel({
      payload: { ...lazyParams },
      endpoint: "/emergency-donation/excel",
      fileName: "emergencyDonations",
    }).then(_res => {
      setLoading(false)
    })
    ;
  };
  // Go to add donation flow on button click
  if (donationFlow === DonationFlow.AddDonation) {
    history.push({
      pathname: `${contextPath}/dashboard/add/emergency-donation?type=emergency-donation`,
    });
  }
  return (
    <>
      <div>
        <BreadcrumbsComponent
          breadcrumbsData={breadcrumbsData}
          history={history}
        />

        <div style={{ paddingTop: "5px", marginTop: "0px" }}>
          <Heading as="h2" size="medium" weight={700}>
            {t("emergencyDonations.dashboard.heading")}
          </Heading>
        </div>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <ButtonGroup>
            <Button onClick={exportBudgetDetails} trailing={<CloudDownload />}>
            {t("emergencyDonations.form.export")}
          </Button>
            <Button
              onClick={() => {
                setDonationFlow(DonationFlow.AddDonation);
              }}
              variant="primary"
              data-testId="add-new-request"
            >
              {t("emergencyDonations.form.newDonation")}
            </Button>
          </ButtonGroup>
        </div>

        <Card style={{ marginTop: "25px" }}>
          <div
            className=" col-xs-12 col-md-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px", marginBottom: "10px" }}
          >
            <EmergencyDonationDataTable
              history={history}
              setParentParams={setlazyParams}
              filters={[
                // "UNDER_CA_REVIEW",
                "UNDER_RDA_REVIEW",
                "WAITING_FOR_APPENDIX_H",
                // "WAITING_FOR_COLLECTION_FROM_STORE",
                "REJECTED",
                "COMPLETED",
              ]}
              selectedLang={selectedLang}
            />
          </div>
        </Card>
      </div>
      {loading && <LoaderComponent fullScreen={true} />}

    </>
  );
};
export default withRouter(EmergencyDonations);
