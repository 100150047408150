import {  FormLabel } from '@material-ui/core'
import { FC, } from 'react'
import { InputTextField }from '../../common/Input/InputTextField'
import TextFieldMaxSizeIndicator from './TextFieldMaxSizeIndicator'

interface OwnProps {
  question: string,
  label: string,
  id: string,
  fieldName: string,
  qno: string,
  disabled: boolean
  required: boolean,
  maxSize?: number
}
const DescriptionBox: FC<OwnProps> = ({
  id,
  question,
  fieldName,
  label,
  qno,
  disabled,
  required,
  ...props
}) => {

  return <>

       <FormLabel id={id}>{qno ?`${qno} .`: ''} {question}</FormLabel>

        <InputTextField
          errorKeyName = {fieldName}
          fieldName = {fieldName}
          label = {label}
          id = {`ans_${id}`}
          rows = {4}
          disabled = {disabled}
          multiline = {true}
          required = {required}
        />
        {props?.maxSize && <TextFieldMaxSizeIndicator
            fieldName = {fieldName}
            maxSize = {props?.maxSize }/>}
  </>
}
export default DescriptionBox;
