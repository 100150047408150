import * as yup from 'yup';
import { getAppendixFormValidations } from '../donationOfFood/common/validation'


export const getRecurringGoodsAppendixCValidationSchema = () => {
    const { stringRule, beforeOrTodaysDate, required } = getAppendixFormValidations()
    const schema:any = {
        orgLegalName: stringRule,
        telephone: stringRule,
        donationReceivedDate:beforeOrTodaysDate,
        reasonOfDonation: stringRule,
        address: yup.object().shape({
            country: stringRule,
            addressLine1: stringRule,
            postalCode: yup.string().notRequired()
        }),
        recipientInfo: yup.object().shape({
            name:stringRule,
            signature:stringRule,
            date:beforeOrTodaysDate,
        }),
        companyEmployee: yup.object().shape({
            name:stringRule,
            title:stringRule,
            date:beforeOrTodaysDate,
            signature:stringRule
        }),
        action: yup.object().required(required).typeError(required)
    }

    return yup.object().shape(schema);
}
