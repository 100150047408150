
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomAccordion } from 'src/components/common/Accordion/CustomAccordion';
import { IMonetarySocialImpactReport, MonetarySocialImpactSections, ISocialImpactResponse, populateData, transformSocialImpactData } from './socialImpactReportData';
import SocialImpactSummary from './tabs/SocialImpactSummary'
import Beneficiaries from './tabs/Beneficiaries'
import Activities from './tabs/Activities'
import ProjectObjectives from './tabs/ProjectObjectives';
import Attachments from './tabs/Attachments'
import { yupResolver } from '@hookform/resolvers/yup';
import getSocialImpactReportValidations from './monetarySocialImpactReportValidations'
import FooterButtons from './FooterButtons'
import GlobalDonationDataService from 'src/service';
import MUISnackBar from 'src/components/common/AlertBox/MUISnackBar';
import LoaderComponent from 'src/components/LoaderComponent';
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';

interface OwnProps {
  reportData: IMonetarySocialImpactReport,
  responseData: ISocialImpactResponse | null,
  isFormEditable: boolean,
  selectedLang: string,
  reportId: number
  onPageBack: () => void
}

let errorMessage = '';

const MonetarySocialImpactReportTab: FC<OwnProps> = ({isFormEditable,
  reportData,
  selectedLang,
  onPageBack,
  responseData}) => {

  const {t} = useTranslation();
  const [showError, setErrorState] = useState(false);
  const [fileError, setFileErrorState] = useState(false);
  const [tabData, setTabData] = useState(reportData);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(new Map());
  const methods = useForm({
      mode: 'onChange',
      defaultValues: {...reportData, 'readOnly': responseData?.finalized??false},
      resolver: yupResolver(getSocialImpactReportValidations())
  });

  useEffect(() => {
    if(Object.keys(methods.errors).length > 0) {
      errorMessage = t('common.validationMsg.pleaseFillAllRequiredFields');
      setErrorState(true);
    }
  }, [methods.errors]);

  useEffect(() => {
    methods.reset( {...tabData, 'readOnly': responseData?.finalized??false});
  }, [tabData])

  const onFormSubmit = async (data: IMonetarySocialImpactReport) => {
      const narrativeProjectReport = tabData?.attachments.get('narrativeProjectReport');
      const financialReport = tabData?.attachments.get('financialReport');
      if((!narrativeProjectReport || !financialReport)) {
        errorMessage = t('socialImpactReport.uploadFileMsg');
        setFileErrorState(true);
        setErrorState(true);
      } else {
        setFileErrorState(false);
        if(responseData) {
          setLoading(true);
          const response = await GlobalDonationDataService.finalizeMonetarySocialImpactReport(populateData(responseData, data, fileData));
          setLoading(false);
          if(response) {
            onPageBack();
          } else {
            errorMessage = t('socialImpactReport.failToFinalize');
            setErrorState(true);
          }
       }
      }
   };

   const handlErrorClose = () => {
    setErrorState(false);
  }

  const onSave = async () => {
    debugger
    const data = methods.getValues();
    if (!(await methods.trigger('activitySummary'))) {
      errorMessage = t('common.validationMsg.pleaseCorrectTheErrors');
      setErrorState(true);
      setLoading(false);
      return errorMessage;
    }
    if(responseData) {
      setLoading(true);
      const response = await GlobalDonationDataService.saveMonetarySocialImpactReport(populateData(
        responseData, data, fileData));
      setLoading(false);
      if(response) {
        responseData = response;
        setTabData(transformSocialImpactData(response));
        setFileData(new Map())
      }
      return response;
    }
    return null
  }
  const getFileDetails = (sno: number, fileDetails: IFileDetails) => {
    const updatedMap = new Map(fileData);
    updatedMap.set(sno, fileDetails)
    setFileData(new Map(updatedMap))
  }

  if(loading) {
    return <LoaderComponent fullScreen={true} />
  }
  return <FormProvider {...methods}>
           <form id='monetary_social_report' noValidate={true} autoComplete='off'
                                      encType='application/json' onSubmit={methods.handleSubmit(onFormSubmit)}>
              <CustomAccordion
                      isEditable={isFormEditable}
                      id = {MonetarySocialImpactSections.SocialImpactSummary}
                      title={t('socialImpactReport.summary')}
                      showStatusIcon = {methods.errors[MonetarySocialImpactSections.SocialImpactSummary] ? true: false}
                      hasError = {methods.errors[MonetarySocialImpactSections.SocialImpactSummary] ? true: false}
                      defaultExpanded = {true}
                      showStatusMessage={false}>
                      <SocialImpactSummary socialImpactSummary = {tabData[MonetarySocialImpactSections.SocialImpactSummary]} />
              </CustomAccordion>
              <CustomAccordion
                      isEditable={isFormEditable}
                      id = {MonetarySocialImpactSections.Beneficiaries}
                      title={t('socialImpactReport.beneficiaries')}
                      showStatusIcon = {methods.errors[MonetarySocialImpactSections.Beneficiaries] ? true: false}
                      hasError = {methods.errors[MonetarySocialImpactSections.Beneficiaries] ? true: false}
                      showStatusMessage={false}>
                      <Beneficiaries beneficaries = {tabData.beneficiaries} />
              </CustomAccordion>
              <CustomAccordion
                      isEditable={isFormEditable}
                      id = {MonetarySocialImpactSections.Activities}
                      title={t('socialImpactReport.activities')}
                      showStatusIcon = {methods.errors[MonetarySocialImpactSections.Activities] ? true: false}
                      hasError = {methods.errors[MonetarySocialImpactSections.Activities] ? true: false}
                      showStatusMessage={false}>
                      <Activities reportId = {responseData?.id??''}
                      countryCode = {responseData?.countryCode ?? ''}
                      finalized = {responseData?.finalized??false}
                      selectedLang={selectedLang} activity={tabData.activitySummary}
                      getFileDetails={getFileDetails} />
              </CustomAccordion>
              <CustomAccordion
                      isEditable={isFormEditable}
                      id = {MonetarySocialImpactSections.ProjectObjectives}
                      title={t('socialImpactReport.projObjectives')}
                      showStatusIcon = {methods.errors[MonetarySocialImpactSections.ProjectObjectives] ? true: false}
                      hasError = {methods.errors[MonetarySocialImpactSections.ProjectObjectives] ? true: false}
                      showStatusMessage={false}>
                      <ProjectObjectives projectObjectives = {tabData.projectObjectives}/>
              </CustomAccordion>
              <CustomAccordion
                      isEditable={isFormEditable}
                      id = {MonetarySocialImpactSections.Attachments}
                      title={t('monetaryDonation.tabs.attachment')}
                      showStatusIcon = {fileError}
                      hasError = {fileError}
                      showStatusMessage={false}>
                      <Attachments
                        finalized = {responseData?.finalized??false}
                        reportId = {responseData?.id??''}
                        setFileErrorState = {setFileErrorState}
                        files = {tabData?.attachments ?? new Map()} />
              </CustomAccordion>
              <FooterButtons onPageBack = {onPageBack} onSave = {onSave} finalized = {responseData?.finalized??false}/>
              <MUISnackBar  open={showError} onClose={handlErrorClose} message = {errorMessage}/>
      </form>
  </FormProvider>
}

export default MonetarySocialImpactReportTab;
