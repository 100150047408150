import * as React from 'react';
import { Backdrop, withStyles } from '@material-ui/core';
import CircularProgress from '../../uiComponents/LDSpinner';


export interface OwnProps {
    backgroundColor: string;
    classes?: any;
}

const styles = (theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#ffc220',
        backgroundColor: '#fff !important',
        position: 'unset' as 'unset',
        height: '100%'
    },
});

class LoaderComponentFitDiv extends React.PureComponent<any> {
    public render() {
        const {classes} = this.props;
        return <Backdrop className={classes.backdrop} open={true} >
                    <CircularProgress color='inherit' size='small'/>
                </Backdrop>
        ;
    }
}

export default withStyles(styles) (LoaderComponentFitDiv);
