

import { Calendar } from 'primereact/calendar';
import FormHelperText from '@mui/material/FormHelperText';

const DatePickerComponent = (props: any) => {
    const { errorMessage = "", placeholder,
        dateFormat, attributeName, dateFilter, updateCalendarDate, minDate, allowEndDate,
        isDisabled = false
    } = props;

    const onDateChange = (event: any) => {
        updateCalendarDate(event, attributeName);
    };

    return (
        <>
            <Calendar
                minDate={allowEndDate ? allowEndDate : minDate}
                showIcon={false}
                showButtonBar={true}
                value={dateFilter}
                onChange={onDateChange}
                placeholder={placeholder}
                readOnlyInput
                dateFormat={dateFormat}
                className={`custom-calendar p-column-filter ${errorMessage.length > 0 ? 'higlightCalendar' : ''}`}
                // @ts-ignore
                showIcon={true}
                disabled={isDisabled}
            />
            {errorMessage ? <FormHelperText className="helperText">{errorMessage}</FormHelperText> : ""}
        </>
    );
}
export default DatePickerComponent;