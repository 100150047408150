import { Action } from 'redux';

export enum DeleteFileActionTypes {
    DELETE_FILE = 'DELETE_FILE',
    DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE',
}

export interface DeleteFile extends Action {
    type: DeleteFileActionTypes.DELETE_FILE,
    data: any;
}

export const deleteFile = (data: any): DeleteFile => ({
    type: DeleteFileActionTypes.DELETE_FILE,
    data
});

export interface DeleteFileSuccess extends Action {
    type: DeleteFileActionTypes.DELETE_FILE_SUCCESS,
    data: any
}

export const deleteFileSuccess = (data: any): DeleteFileSuccess => ({
    type: DeleteFileActionTypes.DELETE_FILE_SUCCESS,
    data
});

export interface DeleteFileFailure extends Action {
    type: DeleteFileActionTypes.DELETE_FILE_FAILURE,
    data?: any
}

export const deleteFileFailure = (data: any): DeleteFileFailure => ({
    type: DeleteFileActionTypes.DELETE_FILE_FAILURE,
    data
});

export type DeleteFileActions = DeleteFile | DeleteFileSuccess | DeleteFileFailure;