import { DONATION_TYPE_VALUES } from "../constants";
import { getDashBoardData } from "../components/donations/gdmsSpaceDonation/SpaceInternationalDataFunctions";
import { getServiceDashBoardData } from "../components/donations/serviceDonation/serviceDonationDataFunctions";

const defaultTableFilters = {
    selectedLang: "en",
    defaultFilters: {
        currentStatusCode: {
            value: ["Test1"],
            matchMode: 'equals'
        }
    },
    showStatusFilter: false,
    showExportExcel: false
};
 
export const defaultButton = {
    buttonText: "Test Button",
    callback: () => {},
    buttonId: 'test-id',
    variant : "primary",
    className: "testClass",
}

const defaultData = {
    pageTitle: "DEFAULT TITLE",
    breadcrumbs: [ {
        name: "Test Page",
        active: true,
        applyFormat: false,
    }],
    tabs: [ { label: "Tab 1 custom", isActive: true, tableFilters: defaultTableFilters}],
    topRightHeaderButtons: [defaultButton ],
    isTabs: true
}

export const getDonationDataProps = (donationType: DONATION_TYPE_VALUES, t: any, lang: string, history: any) => {
    switch (donationType) {
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return getDashBoardData(t, lang, history);
        case DONATION_TYPE_VALUES.SERVICE_DONATION:
            return getServiceDashBoardData(t, lang, history);
        default:
            return defaultData;
    }
}