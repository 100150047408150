import React, { useContext, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { navBarClose, navBarOpen } from '../../../../../state/spaceDonation/navbar/navBarActions';
import { GlobalDonationState } from './../../../../../models/globalDonationState';
import { Link } from 'react-router-dom';
import { contextPath } from 'src/constants';
import { Spark } from '../../icons/icon';
import UserContext from '../../../store/user-context';
import './styles.css';
import AvatarLogo from '../../appComponents/Avatar/Avatar';
import ProfileCard from '../../appComponents/profilecard/profilecard';
import { NotificationIcon } from '../../../../../../src/components/spacedonation/scenes/notification/NotificationIcon';
import withRouter from 'src/polyfils/customRouter';

export interface Props {
    showSideBar?: any,
    showAppLogo?:any,
    navBarOpen?: any,
    navBarClose?: any
}


class TopBar extends React.Component<Props> {
    public render() {
        return <TopBarComponent {...this.props} />
    }
}

const mapStateToProps = (state: GlobalDonationState): any => ({
    showSideBar: state.navBarState.open,
    showAppLogo: state.appLogoState.show
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): any => ({
    navBarOpen: () => dispatch(navBarOpen()),
    navBarClose: () => dispatch(navBarClose())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));



const TopBarComponent: any = (props: any) => {
    const [showProfileCard, setShowProfileCard] = useState(false);
    const userContext: any = useContext(UserContext);
    const currentOrgName = userContext?.organization?.orgName;
    return <AppBar position='static' className='app-bar'>
        {showProfileCard && <ProfileCard setShowProfileCard={setShowProfileCard} showProfileCard={showProfileCard} {...props}/>}
        <div className='app-bar-contents'>
            <div className='app-bar-logo-section'>
                {isMobileResolution() && props.showSideBar && props.showAppLogo && <SparkLogo {...props} />}
                {isMobileResolution() && !props.showSideBar && props.showAppLogo && <MenuLogo {...props} />}
                {!isMobileResolution() && props.showAppLogo && <SparkLogo {...props} />}
                {!isMobileResolution() && !props.showAppLogo && <DummyLogo/>}
            </div>
            {isMobileResolution() && !props.showSideBar && <AppName />}
            {!isMobileResolution() && <AppName />}
            {props.showAppLogo && <NotificationLogo />}
            {props.showAppLogo && <AvatarLogo setShowProfileCard = {setShowProfileCard} showProfileCard={showProfileCard}
                bgColor='gray' name={currentOrgName ? currentOrgName.toUpperCase() : 'N A'} />}
            <div />
        </div>
    </AppBar>
}


const isMobileResolution = () => window.innerWidth <= 425;

const AppName = () => <Typography className='app-bar-header' variant='h6'>Space Request Tool</Typography>

const SparkLogo = (props: any) => <div><Link to={`${contextPath}/space-donation/create`}
    onClick={() => { props.navBarOpen() }}><Spark className='app-bar-logo' /></Link></div>

const DummyLogo = () => <Spark className='app-bar-logo' />

const MenuLogo = (props: any) => <div className='app-bar-drawer-button'><MenuIcon
    onClick={() => props.showSideBar ? props.navBarClose() : props.navBarOpen()} /></div>

    const NotificationLogo = () => {
        return <>
           <NotificationIcon/>
        </>
    }
