import * as React from 'react';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { GlobalDonationState, UiHubProps, Privileges } from './../../models/globalDonationState';
import { SubmissionState } from 'dynamic-form-components/lib/models/ApplicationState';
import { selectedData, setSelectedDataLoad } from './../../state/selectedData/selectedDataActions';
import { GetColumnConfigPayload } from 'dynamic-form-components/lib/models/SubmissionModel';
import { getColumnConfig } from 'dynamic-form-components/lib/state/submission/submissionDataActions';
import { saveFormDataFailure, updateFormFailure } from 'dynamic-form-components/lib/state/form/formDataActions';
import { loggedInUserData } from '../../state/loggedInUser/loggedInUserActions';
import GlobalDonationService, { Service } from '../../service';
import { socialImpactgridTranslations } from 'src/static/GridTranslations';
import moment from 'moment';
import { contextPath, landigPageUrl, socialImpactTranslations } from './../../constants';
import { getFormattedDate } from '../common/DateUtils';
import { ExtractDialogComponent } from '../common/ExtractDialogComponent';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
export let service: Service = GlobalDonationService;

export interface OwnProps {
    classes?: any;
    appi18n: any;
    formLink: string;
    remoteDataUri?: string;
    privileges: Privileges;
    uiHubProps: UiHubProps;
    showViewDiagramDialog?: boolean;
    loggedInUserDetail: any;
}

export interface DispatchProps {
    addSelectedDataToStore: (data: any) => void;
    getColumnConfig: (data: any) => void;
    updateFormSuccessStatus: () => void;
    setSelectedDataLoad: (data: any) => void;
    getLoggedInUserDetail: () => void;
    updateReportResponseStatus: () => void;
}

export interface StateProps {
    columns: any[];
    selectedLang: string;
    submissionState: SubmissionState;
    loggedInUserDetail: any;
}

const styles = {
    buttonStyles: {
        marginBottom: 20,
        fontWeight: 600,
        backgroundColor: '#0071ce',
        '&:hover': {
            backgroundColor: '#0071ce',
        }
    }
}

const appi18n: any = {
    ...socialImpactgridTranslations,
    gridTitle: {
        en: 'List of Reports',
        es: 'Lista de informes',
        fr: 'Liste des rapports',
        ja: 'レポートのリスト',
        zh: '報告清單'
    },
    createNewBtnLable: {
        en: 'Create Report',
        es: 'Crear reporte',
        fr: 'Creer un rapport',
        ja: 'レポートを作成する',
        zh: '建立報告'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Arrière',
        ja: 'バック',
        zh: '背部'
    },
    viewReport: {
        en: 'View',
        es: 'Ver',
        fr: 'Vue',
        ja: '見る',
        zh: '視圖'
    },
    editReport: {
        en: 'Edit Report',
        es: 'Editar informe',
        fr: 'Modifier le rapport',
        ja: 'レポートの編集',
        zh: '編輯報告'
    },
    headerText: {
        en: 'Monthly In Kind Social Impact Questionnaire And Donation Confirmation',
        es: 'Cuestionario mensual en especie de impacto social y confirmación de donación',
        fr: 'Questionnaire mensuel sur l\'impact social en nature et confirmation de don',
        ja: '毎月の現物での社会的影響に関するアンケートと寄付の確認',
        zh: '每月實物社會影響問卷調查和捐贈確認'
    },
    infoText: {
        en: 'This report must be submitted monthly for recurring donation recipients. Failure to submit this report may result in an interruption of donations schedule or service.',
        es: 'Este informe debe enviarse mensualmente para los destinatarios de donaciones recurrentes. No enviar este informe puede resultar en una interrupción del programa o servicio de donaciones.',
        fr: 'Ce rapport doit être soumis tous les mois pour les récipiendaires de dons récurrents. Le non-respect de ce rapport peut entraîner une interruption du calendrier ou du service des dons.',
        ja: 'このレポートは、定期的な寄付の受領者に対して毎月提出する必要があります。 このレポートを提出しないと、寄付のスケジュールやサービスが中断される可能性があります',
        zh: '此報告必須每月提交給經常性的捐贈者。 未能提交此報告可能會導致捐贈時間表或服務中斷。'
    },
    monthText: {
        ...socialImpactTranslations.monthText
    },
    extractReportLabel: {
        en: 'Extract Report Summary',
        es: 'Extraer resumen del informe',
        fr: 'Extraire le résumé du rapport',
        ja: 'レポートの概要を抽出する',
        zh: '提取報告摘要'
    },
    extractDiaologHeading: {
        en: 'Extract Goods Donation Report Summary',
        es: 'Resumen del informe de donación de bienes de extracción',
        fr: 'Résumé du rapport sur les dons de biens d extraction',
        ja: '寄付金レポートの概要を抽出する',
        zh: '提取商品捐赠报告摘要'
    },
}
const momentLocales = {
    ...socialImpactTranslations.momentLocales
}
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        },
    }, overrides: {
        MuiPaper: {
            root: {
                width: 'auto !important'
            }
        },
        MuiSelect: {
            selectMenu: {
                minWidth: '150px',
                whiteSpace: 'pre-wrap'
            }
        },
        MuiInputBase: {
            input: {
                minWidth: '80px'
            }
        }
    }
});
class SocialImpactDashboard extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.socialImpact.dashboardPage);
    }

    public state = {
        submissionForEdit: {},
        listAlias: 'socialImpactExternal',
        columns: [],
        showExtractDialog: false,
        options: {}
    }

    private type: string | null = 'social-impact';
    private formOptions: any;

    public UNSAFE_componentWillMount() {
        this.props.getColumnConfig({ listAlias: this.state.listAlias })
        this.props.updateFormSuccessStatus();
        this.props.updateReportResponseStatus();
        import(`moment/locale/${momentLocales[this.props.selectedLang]}`).then((_data: any) => {
            moment.locale(momentLocales[this.props.selectedLang])
        });
        this.setState({
            options: {
                exportButton: false,
                debounceInterval: 400,
                minBodyHeight: '250px',
                maxBodyHeight: '530px',
                headerStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                    color: '#041f41',
                },
                rowStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                },
                filtering: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50, 100],
                draggable: false
            }
        })
    }
    public UNSAFE_componentWillReceiveProps(newProps: StateProps) {
        this.formOptions = {
            noAlerts: true,
            language: newProps.selectedLang
        }
        if (newProps.selectedLang !== this.props.selectedLang) {
            import(`moment/locale/${momentLocales[newProps.selectedLang]}`).then((_data: any) => {
                moment.locale(momentLocales[newProps.selectedLang])
            });
        }
        const columns: any = [];
        if ((newProps.columns !== this.props.columns && newProps.columns.length > 0) ||
            (newProps.selectedLang !== this.props.selectedLang)) {
            newProps.columns.map((column: any) => {
                let customCoulmns: any = {};
                if (column.field === 'action') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            return <>
                                <button className='btn-link' onClick={() => this.viewDonationDetails(rowData, true)}>
                                    {appi18n.viewReport[newProps.selectedLang]}
                                </button>/<button className='btn-link' onClick={() => this.viewDonationDetails(rowData, false)}>
                                    {appi18n.editReport[newProps.selectedLang]}
                                </button>
                            </>
                        }
                    }
                }
                if (column.field === 'month') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            return rowData && rowData.month ? appi18n.monthText[rowData.month].label[newProps.selectedLang] : ''
                        }
                    }
                }
                if (column.field === 'submittedDate') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            return getFormattedDate(rowData.submittedDate)
                        }
                    }
                }
                columns.push({
                    ...column,
                    ...customCoulmns
                })
            })
            this.setState({ columns })
        }
    }
    public render() {

        let createBtn: any;
        let exportReportBtn: any;
        createBtn = <Button className={`back-btn ${this.props.classes.buttonStyles}`}
            variant='contained' color='primary'
            // onClick={this.handleCreateDonation}>
            onClick={() => {
                this.handleCreateDonation()
                analytics.trackEventInfo({
                    type: tracker.EVENTS.CLICK,
                    actionOn: tracker.ACTION_ON.CREATE_REPORT_FOR_SOCIAL_IMPACT.name,
                    location: ANALYTICS.socialImpact.dashboardPage
                });
            }}>
            {appi18n.createNewBtnLable[this.props.selectedLang]}
        </Button>
        exportReportBtn = <Button className={`back-btn ${this.props.classes.buttonStyles}`}
            variant='contained' color='primary'
            onClick={() => {
                this.handleModalAction(true)
                analytics.trackEventInfo({
                    type: tracker.EVENTS.CLICK,
                    actionOn: tracker.ACTION_ON.EXPORT_REPORT.name,
                    location: ANALYTICS.socialImpact.dashboardPage
                });
            }}>
            {appi18n.extractReportLabel[this.props.selectedLang]}
        </Button>
        return <>
            <h4>{appi18n.headerText[this.props.selectedLang]}</h4>
            <hr />
            <p>{appi18n.infoText[this.props.selectedLang]}</p>
            {createBtn}
            {exportReportBtn}
            <MuiThemeProvider theme={theme}>
                <SubmissionGridComponent
                    key={this.props.selectedLang}
                    title={appi18n.gridTitle[this.props.selectedLang]}
                    listAlias={this.state.listAlias}
                    columns={this.state.columns}
                    i18n={appi18n}
                    privileges={this.props.privileges}
                    formOptions={this.formOptions}
                    options={this.state.options}
                    selectedLanguage={this.props.selectedLang}
                    remoteDataFunc={this.getData}
                />
            </MuiThemeProvider>
            {this.state.showExtractDialog && <ExtractDialogComponent
                handleModalAction={this.handleModalAction}
                showModal={this.state.showExtractDialog}
                lang={this.props.selectedLang}
                donationType='DONATION_OF_GOODS_RECURRING'
                heading={appi18n.extractDiaologHeading[this.props.selectedLang]}
            />}
        </>
    };
    private getData = (query: any) => {
        const sortBy = (query.orderBy && query.orderBy.field ? query.orderBy.field : '');
        const orderDirection = query.orderDirection ? query.orderDirection.toUpperCase() : '';
        const queryString = `pageSize=${query.pageSize}&pageNum=${query.page}&sortBy=${sortBy}&sortOrder=${orderDirection}&searchText=${query.search}`;
        return service.getSocialImpactReport(queryString).catch(e => {
            if (e.response && e.response.status === 401) {
                window.location.href = window.location.origin + landigPageUrl;
            }
        })
    }
    private viewDonationDetails = (rowData: any, readOnly: boolean) => {
        const payload = {
            value: {
                ...rowData,
                readOnly
            },
            key: 'socialImpactReport'
        }
        this.props.addSelectedDataToStore(payload);
        this.props.history.push(`${contextPath}/report/view/inkind-social-impact?type=${this.type}`);
    }
    private handleCreateDonation = () => {
        this.props.setSelectedDataLoad({});
        this.props.history.push(`${contextPath}/report/inkind-social-impact?type=${this.type}`);
    }
    public handleModalAction = (show: boolean) => {
        this.setState({
            showExtractDialog: show
        })
    }

}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    columns: state.submissionState.columnConfig,
    selectedLang: state.selectedLang,
    submissionState: state.submissionState,
    loggedInUserDetail: state.loggedInUserState.userDetail,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    addSelectedDataToStore: (data: any) => dispatch(selectedData(data)),
    setSelectedDataLoad: (data: any) => dispatch(setSelectedDataLoad(data)),
    getColumnConfig: (data: GetColumnConfigPayload) => dispatch(getColumnConfig(data)),
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    updateFormSuccessStatus: () => dispatch(saveFormDataFailure('')),
    updateReportResponseStatus: () => dispatch(updateFormFailure(''))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialImpactDashboard)));
