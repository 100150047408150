import { FormControl, FormLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getSelectedLanguage } from '../../../common/utils';

import { ApprovalActionProps } from './../../../../types/formComponentTypes';

export const ApprovalActionDropDownComponent = React.memo((props: ApprovalActionProps) => {
    const { t, i18n } = useTranslation()
    const selectedLang = getSelectedLanguage(i18n?.language)
    return (
        <div>
            <FormControl
                required={props.required}
                style={{ width: '100%' }}
                classes={{ root: props?.classes?.selectFormControl }}
                size='medium' variant='outlined'
                error={props.formErrors?.[props.fieldName]?.message}
                disabled={props.disabled ? props.disabled : false}
            >
                <FormLabel
                    classes={{ root: props.classes.selectLabel }}
                    id='action-selecct-input'
                    style={{ fontWeight: 'normal' }}
                >
                    {t(props.translationKey)}
                </FormLabel>
                <Controller
                    control={props.control}
                    name={props.fieldName}
                    defaultValue={props?.defaultValue}
                    as={
                        <Select
                            classes={{ root: props.classes.selectRoot, icon: props.classes.selectIcon }}
                            labelId='action-select-label'
                            id={props.fieldName}
                            name={props.fieldName}
                            autoWidth={false}
                            defaultValue={props?.defaultValue}
                            displayEmpty={true}
                        >
                            {
                                // @ts-ignore
                                props?.actionOptions?.map((item: any) => {
                                    return (<MenuItem
                                        classes={{
                                            root: props.classes.menuItemRoot,
                                            selected: props.classes.menuItemselected
                                        }}
                                        key={item?.value} value={item}>{item?.label?.[selectedLang]}</MenuItem>)
                                })}
                         
                        </Select>
                    }
                    rules={{ ...props.rules }}
                />
                {props.formErrors?.[props.fieldName]?.message &&
                    <span className='reg-form-error-container' style={{ color: 'red' }}>
                        {props.formErrors?.[props.fieldName]?.message}
                    </span>
                }
            </FormControl>
        </div>
    )
})
