/* eslint-disable */

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { contextPath, PAGETYPES } from "../../../constants";
import withRouter from "../../../polyfils/customRouter";
import MUISnackBar from "../../common/AlertBox/MUISnackBar";
import WmSpinner from "../../common/Loaders/WmSpinner";
import { baseSubContextUrl, GetDonationById, useDonationAction } from "./commonApi";

import { getLangObject, getThankyouRoute } from "../../common/utils";
import {
    getViewBreadCrumbData
} from "./BreadCrumbRouting";
import { CustomTemplateContainer } from "./CustomTemplate";
import { PageBanner } from "./PageBanner";

export interface OwnProps {
    history: any;
    selectedLang: any;
    donationCategory: any;
}

const ReviewDonationComponent: FC<OwnProps> = ({ history, selectedLang, donationCategory }) => {
    const { t } = useTranslation();
    let { state } = useLocation();

    const { pathname } = window.location;
    const eachPathValue = pathname.split('/');
    const dashBoardPath = eachPathValue[eachPathValue.length - 1];
    let donationDetailsQuery = GetDonationById(state.donationId ?? 0, `${baseSubContextUrl}/one`);
    const [donationActionError, setDonationActionError] = useState<any>(false);
    const langObjectPath = getLangObject(donationCategory);

    if (!state || !state.donationId) {
        history.push(`${contextPath}/dashboard/${dashBoardPath}`);
    }

    const invokeOnDonationActionApiCompleted = (data: any, variables: any, isSuccess: boolean) => {
        if (isSuccess) {
            if (data.hasOwnProperty("errorMessage")) {
                setDonationActionError(true);
            } else {
                history.push({
                    pathname: getThankyouRoute(donationCategory),
                    state: {
                        status: variables.action,
                        donationId: state.donationId
                    }
                });
                setDonationActionError(false);
            }
        } else {
            setDonationActionError(true);
        }
    }

    const donationActionMutate = useDonationAction(invokeOnDonationActionApiCompleted, `${baseSubContextUrl}/tasks`);

    // Add loader while data is loading
    if (donationDetailsQuery.isLoading || donationActionMutate.isLoading) {
        return (
            <WmSpinner></WmSpinner>
        );
    }

    const bradCrumbArray = getViewBreadCrumbData(t(`${langObjectPath}.nav.donationId`), state.donationId, langObjectPath, donationCategory);
    return (
        <>
            <PageBanner breadcrumbsData={bradCrumbArray}
                history={history}
                heading={`${t(`${langObjectPath}.dashboard.heading`)} #${state.donationId}`} />

            {donationDetailsQuery?.error || donationActionError && (
                <MUISnackBar open={donationActionError} onClose={() => { setDonationActionError(false) }} message={t(`${langObjectPath}.form.errorResponse`)} />
            )}

            {/* Actual Component comes Here */}
            <CustomTemplateContainer selectedLang={selectedLang} history={history}
                donationActionMutate={donationActionMutate}
                donationCategory={donationCategory}
                donationDetailsQuery={donationDetailsQuery} childType={PAGETYPES.VIEW} />
        </>
    );
};

export default withRouter(ReviewDonationComponent);