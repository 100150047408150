import {IAnalyticsSpacePage} from './adobe-analytics-model';
import {SPACECONSTANT} from './adobe-space-constants';
export const SPACEANALYTICS : {[key: string]: IAnalyticsSpacePage} = {
    onboardingSpaceExternalPage : {
      id: 'onboardingSpaceExternalPage',
      name: 'OnboardingSpaceExternal',
      applicationName: SPACECONSTANT.app_name,
      applicationType : SPACECONSTANT.app_type
    },
    createSpaceLandingExternalPage : {
        id: 'createSpaceLandingExternalPage',
        name: 'CreateSpaceExternal',
        applicationName: SPACECONSTANT.app_name,
        applicationType : SPACECONSTANT.app_type
    },
    displayEventsSpaceExternalPage : {
        id: 'displayEventsSpaceExternalPage',
        name: 'DisplayEventsSpaceExternal',
        applicationName: SPACECONSTANT.app_name,
        applicationType : SPACECONSTANT.app_type
    },
    displayUserAccountSpaceExternalPage : {
        id: 'displayUserAccountSpaceExternalPage',
        name: 'DisplayUserAccountSpaceExternal',
        applicationName: SPACECONSTANT.app_name,
        applicationType : SPACECONSTANT.app_type
    },
    FAQSpaceExternalPage : {
        id: 'FAQSpaceExternalPage',
        name: 'FAQSpaceExternal',
        applicationName: SPACECONSTANT.app_name,
        applicationType : SPACECONSTANT.app_type
    },
    signoutSpaceExternalPage : {
        id: 'signoutSpaceExternalPage',
        name: 'SignoutSpaceExternal',
        applicationName: SPACECONSTANT.app_name,
        applicationType : SPACECONSTANT.app_type
    },
}
