import '../../../styles/formStyles.css';
import '../../../styles/orgProfile.css';

import { Button, withStyles } from '@material-ui/core';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { saveFormData, updateForm } from 'dynamic-form-components/lib/state/form/formDataActions';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import SuccessAlertDailog from 'src/components/dialogComponents/SuccessAlertDailog';

import { contextPath, donationSuccessTxt } from '../../../constants';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { loggedInUserLoadSuccess } from '../../../state/loggedInUser/loggedInUserActions';
import LoaderComponent from '../../LoaderComponent';
import styles from '../../menu/ThemeStyle';

const config = require(`./../../../config.${process.env.NODE_ENV}.json`);
// const formJson = require('./unmannedCollectionForm.json');
const formJson: any = {
    unmannedCollectionForm: require('./unmannedCollectionForm.json'),
    unmannedCollectionFormForSecondaryUser: require(`./unmannedCollectionFormForSecondaryUser.json`)
}

export interface OwnProps {
    formAlias: string;
    classes: any;
    appi18n: any;
    isNewRequest: any;
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    selectedDonationData?: any;
    formState: any;
    loggedInUserDetail: any;
}

export interface DispatchProps {
    submitDonation: (data: any) => void;
    editUnmannedCollection: (data: any) => void;
    setLoggedInUserData: (data: any) => void;
}

class UnmannedCollection extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        formAlias: 'unmannedCollection',
        formJson: ''
    };

    public options: any;
    public formData: any;
    public componentWillMount() {
        if (this.props.isNewRequest) {
            this.options = {
                ...this.options,
                noAlerts: true,
                language: this.props.selectedLang
            }
        } else {
            this.options = {
                ...this.options,
                noAlerts: true,
                readOnly: true,
                language: this.props.selectedLang
            }
        }
        // @ts-ignore
        window.facility = false;
        this.setFormJson()
    }

    public componentWillUnmount() {
        // @ts-ignore
        window.formData = {}
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang
            }
        }
        this.setFormJson()
    }

    private setFormJson = () => {
        this.setState({
            formJson: this.props?.loggedInUserDetail?.primary ?
                formJson.unmannedCollectionForm : formJson.unmannedCollectionFormForSecondaryUser
        })
    }

    private onFormSubmit = (submission: any) => {
        const orgData = {
            ...submission.data.organization,
        }
        delete submission.data.organization
        this.props.setLoggedInUserData(orgData);
        if (!submission.data.facility.facilityNo && submission.data.facility.facilityNo === '') {
            // @ts-ignore
            window.facility = false;
            return;
        } else {
            // @ts-ignore
            window.facility = true;
        }
        this.props.submitDonation(
            {
                formAlias: this.state.formAlias,
                formData: {
                    data: {
                        donationDetail: {
                            ...submission.data,
                            collectionType: submission.data.collectionType.label,
                            facilityNo: submission.data.facility.facilityNo
                        },
                        organization: orgData,
                        donationType: 'UNMANNED_COLLECTION',
                        countryCode: 'GB',
                        divisionCode: 'GB',
                        marketCode: 'GB',
                        isInternal: false,
                    },
                },
                submissionUrl: `${config.appBaseUrl}/donations`
            }
        );
    }


    private handleClick = () => {
        this.props.history.push(`${contextPath}/dashboard/unmanned-collection?type=unmanned-collection`)
    }

    private onFormChange = (event: any) => {
        // @ts-ignore
        if (event && event.changed && event.changed.value !== null) {
            // @ts-ignore
            window.formData = {
                organization: {
                    ...event.data.organization
                },
                ...event.data
            }
        }
    }
    public render() {
        const { loggedInUserDetail, formState, classes } = this.props;
        const userData = this.props.selectedDonationData && this.props.selectedDonationData.unmannedCollection ?
            JSON.parse(JSON.stringify(this.props.selectedDonationData.unmannedCollection.donation)) : {};
        // @ts-ignore
        this.formData = { ...window.formData };
        return (<>
            <Button className={`back-btn ${this.props.classes.buttonStyles}`}
                variant='contained' color='primary'
                onClick={this.handleClick}>
                Back
            </Button>
            <div>
                <FormComponent
                    key={this.state.formAlias}
                    formJsonDefinition={this.state.formJson}
                    formAlias={this.state.formAlias}
                    options={this.options}
                    submission={{
                        data: this.props.isNewRequest ? {
                            organization: {
                                ...loggedInUserDetail,
                            },
                            ...this.formData,
                            isNewRequest: this.props.isNewRequest,

                        } :
                            {
                                organization: {
                                    ...userData.organization,
                                },
                                ...userData.donationDetail,
                                currentStatusCode: userData.currentStatusCode,
                                isNewRequest: this.props.isNewRequest,

                            }
                    }}
                    onSubmit={this.onFormSubmit}
                    submissionUrl={''}
                    handleOnFormChange={this.onFormChange}
                />
            </div>
            <SuccessAlertDailog
                isAsdaTheme={true}
                showDialog={formState.formSaveSuccess}
                ok={this.handleClick}
                title={donationSuccessTxt.title[this.props.selectedLang]}
                message={donationSuccessTxt.content[this.props.selectedLang]}
            />
            {this.props.formState.formSaveLoading ? <LoaderComponent fullScreen={true} /> : <></>}
            <ErrorSnackbarComponent isError={formState.formSaveFailure} classes={classes}
                message={`Failed to create donation request, please try again!`} />
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    selectedDonationData: state.selectedDataState.addDataToStore,
    formState: state.formState,
    loggedInUserDetail: state.loggedInUserState.userDetail,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    editUnmannedCollection: (data: any) => dispatch(updateForm(data)),
    submitDonation: (data: any) => dispatch(saveFormData(data)),
    setLoggedInUserData: (data: any) => dispatch(loggedInUserLoadSuccess(data))
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UnmannedCollection)));