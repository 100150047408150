import { deepOrange } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import styleConfig from '../../styleConfig/styleConfig';

const drawerWidth = 250;
 const styles: any = (theme: any) => ({
    grow: {
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        // [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        // },
        background: theme.palette.primary.main
    },
     appBarConfirmationScreen: {
         marginLeft: drawerWidth,
         // [theme.breakpoints.up('sm')]: {
         //     width: `calc(100% - ${drawerWidth}px)`,
         // },
         background: '#68a51c !important',
     },
    toolbar: theme.mixins.toolbar,
    logo: {
        textAlign: 'center' as 'center',
        padding: 16,
        color: 'white',
        background: theme.palette.primary.main
    },
     headerLogo: {
         padding: 16,
         color: 'white',
         background: theme.palette.primary.main
     },
     logoConfirmationScreen: {
         textAlign: 'center' as 'center',
         padding: 16,
         color: 'white',
         background: '#68a51c'
     },
     refresh: {
        background: '#fff',
         border: 'none'
     },
    drawerPaper: {
        minWidth: drawerWidth,
        background: theme.palette.primary.light,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        maxHeight: 'calc(100vh - 30px)'
    },
    inputRoot: {
        color: 'inherit' as 'inherit',
        width: '100%',
    },
    orangeAvatar: {
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none' as 'none',
        },
    },
    root: {
        width: '100%',
        display: 'flex' as 'flex',
    },
    title: {
        display: 'none' as 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block' as 'block',
        },
    },
    search: {
        position: 'relative' as 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(9),
        height: '100%',
        position: 'absolute' as 'absolute',
        pointerEvents: 'none' as 'none',
        display: 'flex' as 'flex',
        alignItems: 'center' as 'center',
        justifyContent: 'center' as 'center',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex' as 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none' as 'none',
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginTop: 64,
        height: '90vh'
    },
     contentConfirmationScreen: {
         flexGrow: 1,
         padding: theme.spacing(3),
         marginTop: 50,
         backgroundColor: '#fff',
         height: '100vh'
     },
    pageLoading: {
        filter: 'blur(1px)'
    },
    snackbar: {
        position: 'absolute' as 'absolute'
    },
    snackbarContent: {
        width: 360,
    },
    error: {
        color: '#f44336'
    },
    loader: {
        margin: '25%'
    },
    loadWrapper: {
        position: 'fixed' as 'fixed',
        width: '100%',
        height: '100%',
        textAlign: 'center' as 'center',
        zIndex: 10000,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
    },
    formCenter: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
    },
    formContainer: {
        flexGrow: 1,
        maxWidth: 650,
    },
    formElement: {
        padding: 60,
        border: styleConfig.borderStyle,
        boxShadow: styleConfig.boxShadow,
        borderRadius: styleConfig.borderRadius,
    },
    headerSection: {
        textAlign: 'center',
    },
    header: {
        paddingBottom: 15,
        fontFamily: styleConfig.appFont,
        fontSize: styleConfig.pageHeaderFontSize,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#041f41',
    },
    subHeader: {
        paddingBottom: 15,
        fontFamily: styleConfig.appFont,
        fontSize: styleConfig.textFontSize,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
        letterSpacing: 0.25,
        color: 'rgba(4, 31, 65, 0.4)',
    },
    tempPwdTxt: {
         paddingBottom: 15,
         fontFamily: styleConfig.appFont,
         fontWeight: 'normal',
         fontStretch: 'normal',
         fontStyle: 'italic',
         lineHeight: 'normal',
         textAlign: 'center',
         letterSpacing: 0.25,
         color: '#041f41',
     },
     selectRoot: {
         color: 'var(--inputTextColor);',
         fontFamily: ' var(--appFont)',
     },
     outlined: {
         color: 'red'
     },
     selectIcon: {
         color: 'var(--inputTextColor) !important'
     },
     selectFormControl: {
         color: 'var(--labelTextColor) !important',
         minWidth: '300px',
         fontFamily: ' var(--appFont)',
         backgroundColor: '#fff',
         marginRight: '1rem',
         marginBottom: '1rem'
     },
     selectLabel: {
         color: 'var(--labelTextColor) !important',
         fontFamily: ' var(--appFont)',
         marginLeft: '10px',
         backgroundColor: '#fff'
     },
     menuItemRoot: {
         color: 'var(--inputTextColor);',
         fontFamily: ' var(--appFont)',
         minWidth: '250px',
     },
     menuItemselected: {
         color: 'var(--inputTextColor);'
     },
     selectBoxBorder: {
         marginBottom: '12px',
         marginRight: '12px',
         display: 'inline-flex'
     },
});

export default styles;