import {createStyles, makeStyles, Theme} from '@material-ui/core';

// Note: These styles are specific to MX changes.
// If any of the below styles are used at other places, move them to global style

// @ts-ignore
export const TotalTableStyles = makeStyles((theme: Theme) => createStyles({

    cell: {
        border: 'none',
    },
    totalCellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
    },
    tableRoot: {
        backgroundColor: '#f2f2f2',
        borderRadius: '4px',
        marginTop: '15px'
    },
}));

export const SectionStyles: any = () => {

    const sectionStyles = makeStyles(() => createStyles({
        root: {
            flexGrow: 1,
        },
        title: {
            fontWeight: 'bold'
        },
        buttonText: {
            fontSize: '14px',
            fontFamily: 'Bogle',
            fontWeight: 700,
            marginTop: '15px',
            marginLeft: '12px'

        },
        fabRoot: {
            marginTop: '14px',
            marginLeft: '12px',
            backgroundColor: '#0071dc'
        },
        formElement: {
            width: '100%'
        }
    }))

    return sectionStyles;

}

export const sectionClasses = SectionStyles()