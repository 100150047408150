/* eslint-disable */

import { Heading, Modal } from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { getFormattedDate } from "src/components/common/DateUtils";
import WmSpinner from "src/components/common/Loaders/WmSpinner";
import withRouter from "src/polyfils/customRouter";
import { BreadcrumbsComponent } from "../../../components/common/index";
import { contextPath } from "../../../constants";
import EmergencyDonationWorkflow from "../../../resources/EmergencyDonationWorkflow.svg";
import { GetDonationById } from "../CommonComponents/commonApi";
import RecepientDetails from "./components/RecepientDetails";
import StatusCards from "./components/StatusCards";
import "./styles.css";

export interface OwnProps {
  history: any;
  propsData?: any;
}
const EmergencyDonations: FC<OwnProps> = ({ history, propsData }) => {
  const { t } = useTranslation();
  let { state } = useLocation();
  const [isWorkflowOpen, setIsWorkflowOpen] = useState<boolean>(false);
  if (!state || !state.donationId) {
    history.push(
      `${contextPath}/dashboard/emergency-donation?type=emergency-donation`
    );
  }
  const [donationData, setDonationData] = useState({
    donationProducts: "",
    purposeOfDonation: "",
    locationType: "STORE",
    locationKnown: "No",
  });
  const [, setShowStoreSearchDialog] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<any>({
    facilityNo: "",
    postalCode: "",
    county: "",
    state: "",
    streetAddress: "",
    city: "",
    facilityType: "",
  });

  useEffect(() => {
    if (propsData) {
      setSelectedFacility(propsData);
      setDonationData(propsData);
    }
  }, [])

  const breadcrumbsData = [
    {
      name: "emergencyDonations.nav.home",
      path: "/",
    },
    {
      name: "emergencyDonations.nav.emergencyDonations",
      path: `${contextPath}/dashboard/emergency-donation?type=emergency-donation`,
    },
    {
      name: `${t("emergencyDonations.nav.emergencyDonationId")}${state.donationId ?? ""
        }`,
      active: true,
      applyFormat: false,
    },
  ];
  const query = GetDonationById(state.donationId ?? 0, "emergency-donation");

  // Appendix H Flow form
  const methods = useForm();

  useEffect(() => {
    if (query.data?.emergencyDonation?.currentStatusCode === "COMPLETED") {
      const appendixHDetails = query.data.emergencyDonation?.appendixH;
      methods.reset({
        purpose:
          query.data.emergencyDonation?.donationDetails?.purposeOfDonation,
        deliveryDate: getFormattedDate(appendixHDetails.deliveryDate),
        deliveryDetails: appendixHDetails.deliveryDetails,
        itemsList: appendixHDetails.itemsList,
        supportingDocs: appendixHDetails.supportingDocs,
        certificationDetails: {
          ...appendixHDetails.certificationDetails,
          date: appendixHDetails.certificationDetails?.date
            ? getFormattedDate(appendixHDetails.certificationDetails.date)
            : "",
        },
      });
    }
    if (query.data?.emergencyDonation?.donationDetails?.pickUpAddress) {
      setSelectedFacility(query.data.emergencyDonation.donationDetails.pickUpAddress);
    }
    if (query.data?.emergencyDonation?.donationDetails) {
      setDonationData({
        ...query.data?.emergencyDonation?.donationDetails,
        locationKnown: query.data?.emergencyDonation?.locationTypeSelected ? "Yes" : "No",
        locationType: query.data?.emergencyDonation?.donationDetails?.locationType,
        primaryEmail: query.data?.emergencyDonation?.donationDetails?.primaryEmail,
        secondaryEmail: query.data?.emergencyDonation?.donationDetails?.secondaryEmail
      })
    }

  }, [query?.data]);

  // Add loader while data is loading
  if (query.isLoading) {
    return <WmSpinner ></WmSpinner>
  }

  return (
    <>
      <BreadcrumbsComponent
        breadcrumbsData={breadcrumbsData}
        history={history}
      />

      <div style={{ paddingTop: "5px", margin: "10px 0px" }}>
        <Heading as='h2' size='medium' weight={700}>
          {`${t("emergencyDonations.dashboard.heading")} #${state.donationId}`}
        </Heading>
      </div>
      <StatusCards
        setIsWorkflowOpen={setIsWorkflowOpen}
        status={query.data?.emergencyDonation?.currentStatusCode}
        data={query.data?.emergencyDonation}
      />
      <FormProvider {...methods}>
        <RecepientDetails
          editable={false}
          data={query.data?.emergencyDonation}
          orgType={
            query.data?.emergencyDonation?.organization
              .organizationTypePersisted
          }
          donationData={donationData}
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
          setShowStoreSearchDialog={setShowStoreSearchDialog}
          isLocationMandatory={false}
        />
      </FormProvider>
      <Modal
        isOpen={isWorkflowOpen}
        onClose={() => setIsWorkflowOpen(false)}
        size='large'
        title='Workflow'
      >
        <img
          style={{ height: "100%", width: "100%" }}
          src={EmergencyDonationWorkflow}
        />
      </Modal>
    </>
  );
};

export default withRouter(EmergencyDonations);
