
import TextField from '../../../commonComponents/uiComponents/LDTextField';
import CardItem from '../../../commonComponents/uiComponents/LDCardItem';
import Select from '../../../commonComponents/uiComponents/LDSelect';
import { Controller } from 'react-hook-form';


const Questions: any = (props: any) => {
    return <div className='questions-container'>
        <div className='checkout-text-header'>Tell us about your event</div>
        <div className='checkout-text-info'> We would love to share your story
        </div>{
            props.questions.map((question: any, index: any) => {
                const ques = question.question;
                return <>{question.answerType && ['number', 'text'].includes(question.answerType) && <CardItem key={index} className='checkout-text-question' >
                    {`${index + 1}. ${ques}`}
                    <Controller
                        defaultValue={''}
                        control={props.control}
                        name={question.question}
                        data-testid={question.key}
                        as={<TextField type={question.answerType} />
                        }
                    />
                </CardItem>}
                    {question.answerType && ['options'].includes(question.answerType) && <CardItem key={index} className='checkout-text-question' >
                        {`${index + 1}. ${ques}`}
                        <Controller
                            defaultValue={''}
                            control={props.control}
                            name={question.question}
                            data-testid={question.key}
                            as={<Select
                            >
                                <option selected={true} value=''> --Select An Option-- </option>
                                {question?.values?.map((fa: any) => {
                                    return <option key={fa} value={fa}>
                                        {fa}
                                    </option>
                                })}
                            </Select>
                            }
                        />
                    </CardItem>}
                </>
            })
        }
    </div>
}
export default Questions;
