import { useQuery } from 'react-query'
import { reactQueryConfigOptions } from '../constants';
import { getOneTimeGoodsDonationDataById } from '../service/apiService';

export const useFetchOneTimeGoodsDonationDetailsById = (donationId: number) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        data
    } = useQuery(['fetchOneTimeGoodsDonationDetailsById', { donationId }], getOneTimeGoodsDonationDataById,
        { ...reactQueryConfigOptions, cacheTime: 0 });

    return { response: data, isLoading, isSuccess, isError, error };
}
