import { Action } from 'redux';

export enum ManageWorkflowActionTypes {
    UPLOAD_FILE = 'UPLOAD_FILE',
    UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE',
    VIEW_OR_DOWNLOAD_FILE = 'VIEW_OR_DOWNLOAD_FILE',
    VIEW_OR_DOWNLOAD_FILE_SUCCESS = 'VIEW_OR_DOWNLOAD_FILE_SUCCESS',
    VIEW_OR_DOWNLOAD_FILE_FAILURE = 'VIEW_OR_DOWNLOAD_FILE_FAILURE',
    SHOW_VIEW_DIAGRAM_DIALOG = 'SHOW_VIEW_DIAGRAM_DIALOG',
    CLOSE_VIEW_DIAGRAM_DIALOG = 'CLOSE_VIEW_DIAGRAM_DIALOG',
}

export interface UploadFile extends Action {
    type: ManageWorkflowActionTypes.UPLOAD_FILE;
    data: any;
}

export const uploadFile = (data: string): UploadFile => ({
    type: ManageWorkflowActionTypes.UPLOAD_FILE,
    data
});

export interface UploadFileSuccess extends Action {
    type: ManageWorkflowActionTypes.UPLOAD_FILE_SUCCESS;
    data: any;
}

export const uploadFileSuccess = (data: any): UploadFileSuccess => ({
    type: ManageWorkflowActionTypes.UPLOAD_FILE_SUCCESS,
    data
});

export interface UploadFileFailure extends Action {
    type: ManageWorkflowActionTypes.UPLOAD_FILE_FAILURE;
    data?: any;
}

export const uploadFileFailure = (data: string): UploadFileFailure => ({
    type: ManageWorkflowActionTypes.UPLOAD_FILE_FAILURE,
    data
});

export interface ViewOrDownloadFile extends Action {
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE,
    data: any;
}

export const viewOrDownloadFile = (data: any): ViewOrDownloadFile => ({
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE,
    data
});

export interface ViewOrDownloadFileSuccess extends Action {
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE_SUCCESS,
    data: any
}

export const viewOrDownloadFileSuccess = (data: any) : ViewOrDownloadFileSuccess => ({
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE_SUCCESS,
    data
});

export interface ViewOrDownloadFileFailure extends Action {
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE_FAILURE,
    data: any
}

export const viewOrDownloadFileFailure = (data: any) : ViewOrDownloadFileFailure => ({
    type: ManageWorkflowActionTypes.VIEW_OR_DOWNLOAD_FILE_FAILURE,
    data
});

export interface ShowViewDiagramDialog extends Action {
    type: ManageWorkflowActionTypes.SHOW_VIEW_DIAGRAM_DIALOG
}

export const showViewDiagramDialog = () : ShowViewDiagramDialog => ({
    type: ManageWorkflowActionTypes.SHOW_VIEW_DIAGRAM_DIALOG
});

export interface CloseViewDiagramDialog extends Action {
    type: ManageWorkflowActionTypes.CLOSE_VIEW_DIAGRAM_DIALOG
}

export const closeViewDiagramDialog = () : CloseViewDiagramDialog => ({
    type: ManageWorkflowActionTypes.CLOSE_VIEW_DIAGRAM_DIALOG
});

export type ManageWorkflowActions = UploadFile | UploadFileSuccess | UploadFileFailure | ViewOrDownloadFile | ViewOrDownloadFileFailure
| ViewOrDownloadFileSuccess | ShowViewDiagramDialog | CloseViewDiagramDialog;