import { useContext, useEffect} from 'react';
import UserContext from 'src/components/spacedonation/store/user-context';
import LoaderComponent from 'src/components/spacedonation/commonComponents/appComponents/loader/LoaderComponent';
import analytics from '../../../../adobe-analytics/analytics';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';
const config = require(`src/config.${process.env.NODE_ENV}.json`);

const SignOut = () => {
    useEffect(() => {
        analytics.trackPageInfoSpaceExternal(SPACEANALYTICS.signoutSpaceExternalPage);
    }, []);
    const ctx = useContext(UserContext);
    ctx.logout();
    window.location.href = `//${config.spaceDonationLoginPage}`;
    return <><LoaderComponent /></>
}

export default SignOut;