export const enum LocationTypes {
    store = "STORE",
    dc = "DC",
    home_office = "HOME_OFFICE",
    na = "NA",
    other = "OTHER"
}

export const getFacilityTypes = (t: any) => {
    return [
        {
            label: t("common.location.store"),
            value: LocationTypes.store
        },
        {
            label: t("common.location.dc"),
            value: LocationTypes.dc
        },
        {
            label: t("common.location.home_office"),
            value: LocationTypes.home_office
        }
    ]
}

export const createButtons = (callBack1: any,
    callBack2: any, text1: string, text2: string, variant1: string, variant2: string) => {
    return [{
        variant: variant1, buttonId: text1,
        callback: callBack1, text: text1
    },
    {
        variant: variant2, buttonId: text2,
        callback: callBack2, text: text2
    }
    ];
}

export const getFacilityLabel = (t: any, facilityValue: string) => {
    if (facilityValue && facilityValue !== "NA") {
        let typeObject = getFacilityTypes(t).find((facilty: any) => facilty.value.toLowerCase() === facilityValue.toLowerCase());
        return typeObject?.label;
    }
    return facilityValue;
}

export const selectionChoices: any = (t:any) => [
    {
        label: t("common.buttonLabels.Yes2"),
        value: "Yes"
    },
    {
        label: t("common.buttonLabels.no"),
        value: "No"
    }
]

export const getTomorrowDate = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
}

export const get2DaysAfterDate = () => {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    return currentDate;
}

export const formatDateToYYYYMMDD = (dateTime: any) => {
    let dateFormat = new Date(dateTime);
    let month = dateFormat.getMonth() + 1;
    let formattedMonth = month < 10 ? '0' + month : month;
    let dateValue = dateFormat.getDate();
    let formattedDate = dateValue < 10 ? '0' + dateValue : dateValue;
    return dateFormat.getFullYear() + '-' + formattedMonth + '-' + formattedDate;
}

export const formatTimeToAMPM = (dateString: string) => {
    const date = new Date(dateString);
    var hours = date.getHours();
    let minutes: any = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = date.getMinutes() < 10 ? '0' + minutes : minutes;
    var strTime = (hours < 10 ? '0' + hours : hours) + ':' + minutes + ' ' + ampm;
    return strTime;
}