import { DONATION_TYPE_VALUES } from "src/constants";
//import SpaceDonationView from "../gdmsSpaceDonation/SpaceDonationView";
import { SpaceInternationalTemplateContainer } from "../gdmsSpaceDonation/SpaceInternationalTemplateContainer";
import { ServiceDonationTemplateContainer } from "../serviceDonation/ServiceDonationTemplateContainer";


export const CustomTemplateContainer = (props: any) => {
    const { donationCategory } = props;
    switch (donationCategory) {
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return <SpaceInternationalTemplateContainer {...props} />
        case DONATION_TYPE_VALUES.SERVICE_DONATION:
            return <ServiceDonationTemplateContainer {...props} />
            default:
                return <>DEFAULT CUSTOM TEMPLATE</>
    }
    
  };