import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles({
    cell: {
        padding: '6px ',
        verticalAlign: 'baseline'
    },
    cellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
        '&:not(:last-child):after': {
            content: '"  *"'
        },
    },

})

export interface MoreDetailsTableProps {
    header: IMDTColoumn[]
    getTranslation: (key: string) => string,
    id?: string,
    children: any
}

export interface IMDTColoumn {
    key: string,
    width: string,

}

const MoreDetailsTable: FC<MoreDetailsTableProps> = ({ header, getTranslation, children, id = 'moreDetailsTable' }) => {
    const classes = useStyles();

    return (<TableContainer>
        <Table size='small' id={id}>
            <TableHead>
                <TableRow>
                    {
                        header.map((column: any, index: number) => {
                            return (<TableCell key={`header_${index}`}
                                classes={{ head: classes.cellHead }}
                                style={{ width: column.width }}>
                                {column.key === 'actions' ? null : getTranslation(column.key)}
                            </TableCell>)
                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    </TableContainer>);
}

export default MoreDetailsTable;