import { UploadDownloadActions, UploadDownloadActionTypes } from './uploadDownloadFileActions';
import { UploadDownloadActionState } from '../../models/globalDonationState';

export const defaultUploadDownloadActionState: UploadDownloadActionState = {
  data: {},
  uploadingAssignmentLoading: false,
  uploadingAssignmentSuccess: false,
  uploadingAssignmentFailure: false,
  uploadingAssignmentMessage: '',

  downloadData: {},
  downloadDataLoading: false,
  downloadDataFailure: false,
  downloadDataSuccess: false,
  downloadAssignmentMessage: ''
};

export const uploadDownloadReducer = (state: UploadDownloadActionState = defaultUploadDownloadActionState, action: UploadDownloadActions):
  UploadDownloadActionState => {
  switch (action.type) {
    case UploadDownloadActionTypes.UPLOAD_ASSIGNMENT:
      return {
        ...state,
        data: {},
        uploadingAssignmentLoading: true,
        uploadingAssignmentSuccess: false,
        uploadingAssignmentFailure: false,
      };
    case UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_FAILURE:
      return {
        ...state,
        uploadingAssignmentMessage: action.data,
        data: action.data.responseObj,
        uploadingAssignmentLoading: false,
        uploadingAssignmentSuccess: false,
        uploadingAssignmentFailure: true,
      };
    case UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        uploadingAssignmentMessage: action.data.message,
        data: action.data.responseObj,
        uploadingAssignmentLoading: false,
        uploadingAssignmentFailure: false,
        uploadingAssignmentSuccess: true,
      };

    case UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT:
      return {
        ...state,
        downloadDataLoading: true,
        downloadDataFailure: false,
        downloadDataSuccess: false
      };
    case UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        downloadAssignmentMessage: action.data,
        downloadData: action.data,
        downloadDataLoading: false,
        downloadDataFailure: false,
        downloadDataSuccess: true
      };
    case UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_FAILURE:
      return {
        ...state,
        downloadAssignmentMessage: action.data,
        downloadData: {},
        downloadDataLoading: false,
        downloadDataFailure: true,
        downloadDataSuccess: false
      };
    default: return state;
  }
}
