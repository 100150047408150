import { Box, FormLabel, Grid } from '@material-ui/core'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { resolvePath } from '../../common/utils'
import { InputTextField } from '../../common/Input/InputTextField'
import { Alert } from '@material-ui/lab';

interface OwnProps {
  question: string,
  label: string,
  id: string,
  fieldName: string,
  qno: string,

  disabled: boolean
  required: boolean
}
const RequestAmount: FC<OwnProps> = ({
  id,
  question,
  fieldName,
  label,
  qno,
  disabled,
  required
}) => {
  const {errors} = useFormContext()
  const fieldError = resolvePath(fieldName, errors, '')?.message;
  return <>

      <Grid xs = {12} style = {{marginLeft: '5px'}}>
        <FormLabel id={id}>{qno}. {question}</FormLabel>
      </Grid>
      <Grid item = {true} md = {8} sm = {12}>
        <Box display = 'flex' alignItems = 'center'>
          <InputTextField
            defaultValue = ''
            errorKeyName = {fieldName}
            fieldName = {fieldName}
            disabled = {disabled}
            required = {required ?? true}
            label = {label}
            type = 'number'
            style = {{maxWidth: 250}}
            hideError = {true}
            id = {id}
          />
        {fieldError &&
        <Alert
            style = {{ marginLeft: 5 }}
            severity={'error'}
          >
            {fieldError}
          </Alert>
        }
        </Box>
      </Grid>

  </>
}
export default RequestAmount;
