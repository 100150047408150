
import Avatar from 'react-avatar';

const AvatarLogo = (props: any) => {
    return <div className='avatar-logo'><Avatar
        onClick={() => props.setShowProfileCard && props.setShowProfileCard(!props.showProfileCard)}
        name={props.name} size='40' round={true} color={props.bgColor}/>
    </div>
}

export default AvatarLogo;