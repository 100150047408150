
import { Box, Grid, GridSize, GridSpacing, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { InputTextField } from '../../common/Input/InputTextField'
import CountryDropDown from './CountryDropdown'
import YesOrNoQuestion from './YesOrNoQuestion'
import DescriptionBox from './DescriptionBox'
import YesOrNoOption from './YesOrNoOption'
import RequestAmount from './RequestAmount'
import PermissionCheckbox from './PermissionCheckbox'
import { Variant } from '@material-ui/core/styles/createTypography'
import { FileUploadComponent } from '../../../components/common'
import TextField from './TextField'
import { IDropdownOptions } from '../Dropdown/keyValueDropdown'
import { OrgMemberComponent } from '../formComponents/OrgMemberComponent'
import { FormBlockHeading } from '../../../components/donations/utils'
import CheckboxCombo from './CheckboxCombo'

export interface ILabelValue { value: string, labelKey: string }
interface OwnProps {
  configs: IFormConfig[]
  heading: string,
  subTitle?: string
}

export interface IUIConfig {
  fieldName: string,
  type: string,
  label?: string,
  placeholder?: string,
  id: string,
  disabled?: boolean,
  required?: boolean,
  defaultValue?: any,
  options?: IDropdownOptions[] | ILabelValue[],
  yesLabel?: string,
  noLabel?: string,
  optionName?: string
  qno?: string,
  question?: string,
  description?: string,
  showTextAreaOnly?: boolean,
  showDescOnFalse?: boolean,
  multiline?: boolean,
  rows?: number,
  variant?: Variant | 'inherit',
  labelInjected?: any,
  maxSize?: number,
  hidden?: boolean
}

export interface IFormConfig {
  uiConfig?: IUIConfig
  gridConfig: {
    xs?: GridSize,
    lg?: GridSize,
    md?: GridSize,
    sm?: GridSize,
    xl?: GridSize,
    container?: boolean
    spacing?: GridSpacing
  }

}

const uiMap = new Map<string, any>();

uiMap.set('text', InputTextField);
uiMap.set('country', CountryDropDown);
uiMap.set('yesOrNoQuestion', YesOrNoQuestion);
uiMap.set('requestAmount', RequestAmount);
uiMap.set('orgMember', OrgMemberComponent);
uiMap.set('descriptionBox', DescriptionBox);
uiMap.set('yesOrNoOption', YesOrNoOption);
uiMap.set('textField', TextField);
uiMap.set('permission', PermissionCheckbox);
uiMap.set('checkboxCombo', CheckboxCombo);



const getui = (configs: IFormConfig[]) => {

  return <>
    {configs.map((config, index) => {
      const UIElem = uiMap.get(config?.uiConfig?.type ?? '');
      if (config?.uiConfig?.type === 'orgMember') {
        return <Grid xs={12}>
          <OrgMemberComponent
            key={config?.uiConfig?.type ?? index}
            title={config?.uiConfig?.label ?? ''}
            parentFieldName={config?.uiConfig.fieldName}
            disabled={config?.uiConfig.disabled}
          />
        </Grid>
      } else if (config?.uiConfig?.type === 'fileUpload') {
        const name = config?.uiConfig?.fieldName;
        const disabled = config?.uiConfig?.disabled;

        return <Grid xs={12}>
          <FileUploadComponent
            name={name}
            heading={config?.uiConfig?.label ?? ''}
            defaultFiles={config?.uiConfig?.defaultValue}
            disabled={disabled}
            readOnly={disabled}
            allowMultiple={true}
            required={config?.uiConfig?.required}
            showFileUpload={!disabled}
            showEmptyFileMessage={true}
          />
        </Grid>

      }
      return <Grid
        key={config?.uiConfig?.id ?? index}
        item={true}
        {...config.gridConfig}
      >
        {/* @ts-ignore */}
        {config.uiConfig && <UIElem
          {...config.uiConfig}
          errorKeyName={config.uiConfig.fieldName}
        />}

      </Grid>
    })}
  </>
}

const FormSection: FC<OwnProps> = ({ configs, heading, subTitle = '' }) => {

  return <Box p={2} style={{ width: '100%' }}>
    <FormBlockHeading heading={heading} />
    {subTitle &&
      <Typography
        style={{ marginBlock: '5px' }}
        variant='body1'>
        {subTitle}
      </Typography>
    }
    <Grid container={true} spacing={2}>
      {
        getui(configs)
      }

    </Grid>
  </Box>
}


export default React.memo(FormSection)
