import React, { useEffect, useState } from 'react';
import Card from '../../commonComponents/uiComponents/LDCard';
import Divider from '../../commonComponents/uiComponents/LDDivider';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import Button from '../../commonComponents/uiComponents/LDButton';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useQuery } from 'react-query';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import _ from 'lodash';

export interface Props {
    nextPage?: any,
    prevPage?: any,
    setSelectedForm: any,
    setSelectedSpace:any,
    selectedStore:any,
    selectedSpace:any
}

const service: Service = SpaceDonationService;

const SpaceDonationSpaceChooser: React.SFC<Props> = (props) => {
    const { data, isFetching, isSuccess } = useQuery([props?.selectedStore?.storeNbr, 'US'],
        service.fetchSpaceAreas, { refetchOnWindowFocus: false });
    const [spaceLists, setSpaceList] = useState([]);
    useEffect(() => {
        if (isSuccess) {
            const res = _.groupBy(data, (d:any) => d.location);
            const result:any[] = [];
            Object.keys(res).forEach(key => result.push({ header: key, spaces: res[key] }));
            // @ts-ignore
            setSpaceList(result);
        }
    }, [isSuccess]);
    return <>
        {isFetching && <LoaderComponent />}
        {!isFetching && <SpaceDonationSpaceChooserSection {...props} spaceList={spaceLists} />}
    </>
}


const SpaceDonationSpaceChooserSection = (props: any) => {
    const [selectedSpaceId, setSelectedSpaceId] = useState(props.selectedSpace?.parentId);
    const [selectedSpaceObj, setSelectedSpaceObj] = useState(props.selectedSpace);
    return <div className='space-donation-space-chooser-container'>
        <Card className='space-donation-space-chooser-card'>
            <CardItem className='row'>
                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12'>
                    <div className='space-donation-space-chooser-store-details'>
                        <div className='space-donation-space-chooser-store-details-header'>
                        {props?.selectedStore?.city} - {props?.selectedStore?.storeName} {props.selectedStore?.storeNbr}
                    </div>
                        <div className='space-donation-space-chooser-store-details-value'>
                        {props?.selectedStore?.addressLine1}-{props?.selectedStore?.state}-{props.selectedStore?.postalCode}
                    </div>
                    </div>
                </div>
                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-0 col-xs-0'>
                    <div className='back-button' onClick={() => props.setSelectedForm(props.prevPage)}>Change</div>
                </div>
            </CardItem>
            <Divider />
            <div className='space-donation-space-choose-content'>
                <CardItem><div className='space-details-contents-header'>Choose a space for your event</div></CardItem>
                {props.spaceList.map((space:any) => {
                    return <><CardItem key={space.header}>
                        <div className='space-location-header'>{space.header}</div>
                        <div className='row'>
                            {space.spaces.map((sp:any) => {
                                return <div key={sp.parentId} className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 space-description-container'>
                                    <Card className={`space-description ${selectedSpaceId === sp.parentId ? 'selected-space' : ''}`}
                                        onClick={() => { setSelectedSpaceId(sp.parentId); setSelectedSpaceObj(sp) }}>
                                        <CardItem>
                                            <div className='space-description-header'>{sp.name}</div>
                                            <div className='space-description-content'>{sp.description}</div>
                                        </CardItem>
                                    </Card>
                                </div>
                            })}
                        </div>
                    </CardItem>
                        </>
                })}
            </div>
            <CardItem className='row desktop-button-section'>
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12' />
                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 right-aligned'>
                    <Button variant='primary' onClick={ () => {
                        props.setSelectedSpace(selectedSpaceObj);
                        props.setSelectedForm(props.nextPage);
                        }} disabled={!!!selectedSpaceId} >Continue</Button></div>
            </CardItem>
            <div className='phone-buffer-section'/>
        </Card>
        <CardItem className='phone-button-section space-phone-button-section'>
                    <Button className='phone-button' variant='primary' onClick={ () => {
                        props.setSelectedSpace(selectedSpaceObj);
                        props.setSelectedForm(props.nextPage);
                        }} disabled={!!!selectedSpaceId} >Continue</Button>
            </CardItem>
    </div>
}

export default SpaceDonationSpaceChooser;
