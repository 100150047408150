const moment = require('moment');

export const DEFAULT_TIME_ZONE = 'CST';
export const getTimeFromStoreTimeZone =
    (timestamp: string, format: string, zone: string) =>
        moment.tz(timestamp, format, usTimeZonesToLocaesMap()[zone]).utc().format(format);
export const getLocalTimeFromStoreTimeZone =
    (timestamp: string, format: string, zone: string) =>
        moment.tz(timestamp, format, usTimeZonesToLocaesMap()[zone]).format(format);
export const convertDateToTimeZone =
    (date: any, zone: string) => {// var browserTz = moment.tz.guess();
        return moment.utc(date, 'YYYY-MM-DD hh:mm:ss A').tz(usTimeZonesToLocaesMap()[zone])?.format('YYYY-MM-DD hh:mm:ss A');
    };

export const usTimeZonesToLocaesMap: any = () => {
    return {
        EST: 'America/Cancun',
        EDT: 'America/New_York',
        CST: 'America/Chicago',
        CDT: 'America/Chicago',
        MST: 'America/Phoenix',
        MDT: 'America/Denver',
        PST: 'America/Los_Angeles',
        PDT: 'America/Los_Angeles',
        AKST: 'America/Anchorage',
        AKDT: 'America/Anchorage',
        HST: 'Pacific/Honolulu',
        IST: 'Asia/Calcutta'
    }
}
