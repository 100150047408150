import { useMutation } from 'react-query'
import { updateOneTimeGoodsDonation } from './../service/apiService'

export const useUpdateOneTimeGoodsDonation = () => {
    const { data, mutateAsync, isError, isLoading, isSuccess, error } = useMutation(updateOneTimeGoodsDonation)
    return {
        updateRes:data,
        mutateAsync,
        isUpdateError:isError,
        isUpdateLoading:isLoading,
        isUpdateSuccess:isSuccess,
        updateError:error
    }
}


