import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import GridItem from '../../../common/Grid/GridItem';
import { IActivityWithHistory, ISocialImpactActivity } from '../socialImpactReportData';
import ActivitiesHistory from './ActivitiesHistory';
import { countryCodeToCurrenccySymbol } from 'src/constants';
import { currencyFormat } from 'src/components/common/utils';
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';



interface OwnProps {
    activity: ISocialImpactActivity,
    selectedLang: string,
    reportId: string,
    countryCode: string,
    finalized: boolean,
    getFileDetails: (sno: number, fileDetails: IFileDetails) => void
}
const useStyles = makeStyles({
    title: {
        fontWeight: 'bold'
    },
    paper: { padding: '15px', backgroundColor: '#fbfbfb' },
    highLightRow: {
        background: '#ebebeb'
    }

})

const findSum = (list: IActivityWithHistory[]): number => {
    let sum = 0;
    list.forEach((act) => {
        sum += act.amount ? Number(act.amount) : 0;
    });
    return sum;
}

const maxFileSize = '10 mb';
const supportedFileTypes = ['pdf', 'jpeg', 'png', 'xlx'];
const Activities: FC<OwnProps> = ({ activity, selectedLang, finalized, reportId, countryCode, getFileDetails }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [total, setTotal] = useState(findSum(activity.activityForSocialReport));
    useEffect(() => {
        setTotal(findSum(activity.activityForSocialReport));
    }, [activity])

    const getCurrencyFormat = (amount: number) => currencyFormat(amount || 0, countryCodeToCurrenccySymbol[countryCode]);


    return <>
        <Box display='flex' mb={3} component='span'>
            {activity.category &&
                <Box component='span' mr={10}>
                    <Typography variant='body2'>
                        {activity.category[selectedLang] ?? activity.category.en}
                    </Typography>
                    <Typography classes={{ root: classes.title }} variant='body2'>
                        {t('monetaryDonation.projectDetailsSection.category')}
                    </Typography>
                </Box>
            }
            {activity.subCategory &&
                <Box component='span'>
                    <Typography variant='body2'>
                        {activity.subCategory[selectedLang] ?? activity.subCategory.en}
                    </Typography>
                    <Typography classes={{ root: classes.title }} variant='body2'>
                        {t('monetaryDonation.projectDetailsSection.subCategory')}
                    </Typography>
                </Box>}
        </Box>
        <Paper classes={{ root: classes.paper }}>
            {activity.activityForSocialReport.map((field: IActivityWithHistory, index: number) => (
                <ActivitiesHistory index={index} field={field} selectedLang={selectedLang}
                    reportId={reportId} finalized={finalized} activity={activity}
                    countryCode={countryCode} getFileDetails={getFileDetails} />
            ))}
        </Paper>
        <GridItem lg={12} md={12}>
            <Grid container={true} xs={12} spacing={1} classes={{ root: classes.highLightRow }} >
                <GridItem xs={2} />
                <GridItem xs={2} >
                    <b>{t('monetaryDonation.projectDetailsSection.total')}</b>
                </GridItem>
                <GridItem xs={4} data-testid={'test-grid'}>
                    <b>{getCurrencyFormat(total ?? 0)}</b>
                </GridItem>
            </Grid>
        </GridItem>
        <Typography variant='subtitle2' color='textSecondary' >*{t('monetaryDonation.attachmentsSection.maxFileSize')}: {maxFileSize} | {t('monetaryDonation.attachmentsSection.fileType')}: {supportedFileTypes.toString()}</Typography>
    </>
};
export default Activities;
