//@ts-nocheck
import Axios from 'axios';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { getCookieValue, getUserRole } from 'dynamic-form-components/lib/service';

import { getTimeZone } from './components/common/DateUtils';
import { IFileDataResponse, IFileDetails } from './components/donations/moreDetailsSections/attachments/attachmentsTable';
import {
  IMDDropdownOption,
  IMDGroupDropdownOption,
} from './components/donations/moreDetailsSections/common/biLingualDropdown';
import { ISocialImpactResponse, SocialImpactRequest } from './components/socialImpact/monetary/socialImpactReportData';
import { landigPageUrl } from './constants';

// import { LoadSectionServiceResponse } from './models/sectionsModel';
const config = require(`./config.${process.env.NODE_ENV}.json`);

const downloadAxios = Axios.create();

const regUserAxios = Axios.create();

Axios.interceptors.request.use((axiosConfig: any) => {
  const token = getCookieValue('authToken');
  const userId = getCookieValue('userId');
  if(!axiosConfig.headers){
    axiosConfig.headers = {}
  }
  axiosConfig.headers.Accept = 'text/html, application/json';
  axiosConfig.headers.Authorization = `Bearer ${token}`;
  axiosConfig.headers['X-Timezone'] = getTimeZone();
  axiosConfig.withCredentials = true;
  axiosConfig.headers.userId = userId;
  axiosConfig.headers.userRole = getUserRole();
  //axiosConfig.headers["DONATION-TYPE"] = setDonationTypeHeader();
  return axiosConfig;
});

downloadAxios.interceptors.request.use((axiosConfig: any) => {
  const token = getCookieValue('authToken');
  const userId = getCookieValue('userId');
  if(!axiosConfig.headers){
    axiosConfig.headers = {}
  }
  axiosConfig.headers.Accept = 'application/pdf';
  axiosConfig.headers.Authorization = `Bearer ${token}`;
  axiosConfig.headers['X-Timezone'] = getTimeZone();
  axiosConfig.withCredentials = true;
  axiosConfig.headers.userId = userId;
  axiosConfig.headers.userRole = getUserRole();
  return axiosConfig;
});

regUserAxios.interceptors.request.use((axiosConfig: any) => {
  if(!axiosConfig.headers){
    axiosConfig.headers = {}
  }
  axiosConfig.headers.Accept = 'application/pdf';
  axiosConfig.withCredentials = true;
  axiosConfig.headers['X-Timezone'] = getTimeZone();
  return axiosConfig;
});

export interface GlobalDonationService {
  uploadAssignment: (data: any) => Promise<any>;
  getFileDownloadData: (data: any) => Promise<any>;
  getNotification: (filter: any) => Promise<any>;
  getSubmissionData: (data: any) => Promise<any>;
  getConfigurations: (data: any) => Promise<any>;
  getTranslation: (data: any) => Promise<any>;
  registerNewUser: (data: any) => Promise<any>;
  getVerificationCode: (data: any) => Promise<any>;
  validateCode: (data: any) => Promise<any>;
  changePassword: (data: any) => Promise<any>;
  getFacilityDetails: (data: any) => Promise<any>;
  downloaReport: (data: any) => Promise<any>;
  getSelectAreaData: (data: any) => Promise<any>;
  getOrganizationTypes: (data: any) => Promise<any>;
  getOrgProfileData: () => Promise<any>;
  saveOrgProfileData: (data: any) => Promise<any>;
  foodSurplusConfirmationService: (data: any) => Promise<any>;
  downloadProjectFile: (data: any) => Promise<any>;
  getLoggedInUserDetail: () => Promise<any>;
  getSidebarDetail: () => Promise<any>;
  approveDonationRequest: (data: any) => Promise<any>;
  getFileData: (data: any) => Promise<any>;
  logout: () => Promise<any>;
  getSocialImpactReport: (queryString: any) => Promise<any>;
  deleteFile: (data: any) => Promise<any>;
  getSocialImpactReportById: (data: any) => Promise<any>;
  getDivisions: (data: any) => Promise<any>;
  getStateList: (data: any) => Promise<any>;
  getCountry: (data: any) => Promise<any>;
  getOrgUserList: (queryString: any) => Promise<any>;
  addNewOrgUser: (data: any) => Promise<any>;
  editOrgUser: (emailId: any, data: any) => Promise<any>;
  removeOrgUser: (emailId: any, orgId: any) => Promise<any>;
  setAsPrimaryOrgUser: (emailId: any, orgId: any) => Promise<any>;
  sendOrgUserTemporaryPassword: (emailId: any, orgId: any) => Promise<any>;
  checkFirstTimeUserLogin: () => Promise<any>;
  saveOrgUserPassword: (data: any) => Promise<any>;
  autheticate: (data: any) => Promise<any>;
  fetchNgoPage: (data: any) => Promise<any>;
  getAdditionalAttachmentsDetails: (donationId:string) => Promise<IFileDataResponse | null>;
  uploadAdditionalAttachment: (donationId:string, fileId: string, formData: FormData) => Promise<IFileDetails | null>;
  downloadAdditionalAttachment: (donationId:string, fileId: string, fileName: string) => Promise<boolean> ;
  deleteAdditionalAttachment: (donationId:string, fileId: string) => Promise<Date | null> ;
  getOrganizationProfileData: (donationId:string) => Promise<any>;
  getAdditionProjectDetailsMonetaryDonation: (donationId:string) => Promise<any>;
  getDropdownDetails: () => Promise<Map<string, IMDDropdownOption[] | IMDGroupDropdownOption[]>>;
  getConfigurationsData: (data: any) => Promise<any>;
  saveOrganizationProfileData: (
    donationId: string,
    orgFormData: FormData
  ) => Promise<any>;
  saveAdditionProjectDetailsMonetaryDonation: (
    donationId: string,
    projectData: any
  ) => Promise<any>;
  submitAdditionalDetails:(donationId:string) => Promise<any>;
  deleteDonation:(donationId:string) => Promise<boolean>;
  getMonetarySocialImpactReport: (projectId: number) => Promise<ISocialImpactResponse | null>;
  saveMonetarySocialImpactReport: (data: SocialImpactRequest) =>  Promise<ISocialImpactResponse | null>;
  finalizeMonetarySocialImpactReport: (data: SocialImpactRequest) => Promise<ISocialImpactResponse | null>;
  deleteMonetoryReportAttachment: (reportId:string, fileId: string) => Promise<boolean> ;
  uploadMonetoryReportAttachment: (reportId:string, fileId: string, formData: FormData) => Promise<IFileDetails | null> ;
  deleteActivityAttachment: (reportId:string, fileId: number) => Promise<boolean> ;
  uploadActivityAttachment: (reportId:string, fileId: number, formData: FormData) => Promise<IFileDetails | null>;
  submitChinaMonetary: (donationId:string, file: File, isAppendixC?: boolean) => Promise<any | null>;
}
export const buildQueryString = (params: any) => {
  return Object.keys(params)
    .map((key: string) => key + '=' + params[key])
    .join('&');
};

const GlobalDonationDataService: Service = {
  uploadAssignment: (data: any) => {
    return Axios.post(data.dataUri, data.formData)
        .then(response => response.data);
  },
  getNotification: (filter: any) => {
    return Axios.get(
      `${config.appBaseUrl}/config?q=${filter}&pageNum=1&pageSize=100`
    ).then((response) => response.data.data);
  },
  getSubmissionData: (data: string) => {
    // @ts-ignore
    return Axios.get(`${config.appBaseUrl}/${data.formAlias}/${data.submissionId}`)
    .then((response) => response.data.data);
  },
  getConfigurations: (params: any) => {
    return Axios.get(
      `${config.appBaseUrl}/app-configs/level?${buildQueryString(params)}`
    ).then((response) => response.data.data);
  },
  getTranslation: (filter: any) => {
    return Axios.get(
      `${config.appBaseUrl}/app-configs/list?q=${filter}&pageNum=1&pageSize=100`
    ).then((response) => response.data.data);
  },
  registerNewUser: (data: any) => {
    const isRegister = data.isRegister;
    delete data.isRegister;
    return Axios.post(
      `${config.appBaseUrl}/public/organizations?isRegister=${isRegister}`,
      data
    ).then((response) => response.data);
  },

  getVerificationCode: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/public/organization-users/verification/email?emailId=${data.email}`,
      data
    ).then((response) => response.data);
  },

  validateCode: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/public/organization-users/verification`,
      data
    ).then((response) => response.data);
  },

  changePassword: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/public/organization-users/password`,
      data
    ).then((response) => response.data);
  },

  getFacilityDetails: (params: any) => {
    return Axios.get(
      `${config.appBaseUrl}/facilities?${buildQueryString(params)}`
    )
      .then((result) => {
        return {
          data: result.data.data,
          page: (params.pageNum || 1) - 1,
          totalCount: result.data.recordsTotal,
        };
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = landigPageUrl;
        }
        return {
          data: [],
          page: 0,
          totalCount: 0,
        };
      });
  },
  getSelectAreaData: (_params: any) => {
    return Axios.get(
      `${config.appBaseUrl}/public/multiLingual/type?type=org_govt_category`
    ).then((response) => response.data);
  },
  getOrganizationTypes: (_params: any) => {
    return Axios.get(
      `${config.appBaseUrl}/public/multiLingual/type?type=org_type`
    ).then((response) => response.data);
  },
  getOrgProfileData: () => {
    return Axios.get(`${config.appBaseUrl}/organizations/email`).then(
      (response) => response.data
    );
  },
  saveOrgProfileData: (data: any) => {
    const formData = new FormData();
    formData.append('organization', JSON.stringify(data));
    formData.append('governanceDetails', null)
    return Axios.put(`${config.appBaseUrl}/organizations/update`, formData).then(
      (response) => response.data
    );
  },
  downloaReport: (data: any) => {
    return downloadAxios
      .post(`${config.appBaseUrl}/reports`, data, {
        responseType: 'blob',
      })
      .then((response: any) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      });
  },
  foodSurplusConfirmationService: (data: any) => {
    // @ts-ignore
    const url = `${config.appBaseUrl}/public/foodsurplus/response`;
    return Axios.post(url, data).then((response) => response);
  },
  downloadProjectFile: (data: any) => {
    const pdfInfo = {
      donationId: data.donation.id.toString(),
      donationType: data.donationType,
      langCode: data.langCode,
      appendixType: data?.appendixType || 'community_grants',
      date:data?.date // added for recurring goods appendix c
    };
    const encryptedPdfInfo = encodeURIComponent(getEncryptedString(pdfInfo));
    return downloadAxios(
      `${config.appBaseUrl}/reports?pdfInfo=${encryptedPdfInfo}`,
      {
        method: 'GET',
        responseType: 'blob',
      }
    ).then((response: any) => {
      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // Open the URL on new Window
      window.open(fileURL);
    });
  },
  getLoggedInUserDetail: () => {
    return Axios.get(`${config.appBaseUrl}/organizations/email`).then(
      (response) => response.data
    );
  },
  getSidebarDetail: () => {
    return Axios.get(`${config.appBaseUrl}/donationFeatures`).then(
      (response) => response.data
    );
  },
  approveDonationRequest: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/donations/tasks/${data.donationType}/bulk`,
      data.waasTasks
    );
  },
  getFileDownloadData: (requestObject: any) => {
    if (requestObject.type === 'downloadFiles') {
      return Axios.get(requestObject.actionUrl,
        { 'responseType': 'blob' })
        .then((response) => {
          const data = response.data
          const url = URL.createObjectURL(data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${requestObject.name}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }
    else if (requestObject.type === 'downloadTemplate') {
      return Axios.get(requestObject.actionUrl,
        { 'responseType': 'blob' })
        .then((response) => {
          const data = response.data
          const url = URL.createObjectURL(data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${requestObject.name}.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    }
    else if (requestObject.type === 'download') {
      return Axios.get(`${config.waasUrl}/${requestObject.actionUrl}/${requestObject.id}/attachment`,
        { 'responseType': 'blob' })
        .then((response) => {
          const data = response.data
          const url = URL.createObjectURL(data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${requestObject.name}.xml`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      return Axios.get(`${config.waasUrl}/${requestObject.actionUrl}/${requestObject.id}/attachment`,
        { 'responseType': 'document' })
        .then((response) => {
          const svgElement = document.createElement('div')
          svgElement.setAttribute('id', 'svg-image');
          svgElement.setAttribute('hidden', 'true');
          svgElement.appendChild(response.data.documentElement);
          document.body.appendChild(svgElement)
          return {};
        });
    }
  },
  getFileData: (requestObject: any) => {
    if (requestObject.type === 'downloadFiles') {
      return Axios.get(requestObject.actionUrl, { responseType: 'blob' }).then(
        (response) => {
          const data = response.data
          const url = URL.createObjectURL(data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${requestObject.name}`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      );
    } else if (requestObject.type === 'downloadTemplate') {
      return Axios.get(requestObject.actionUrl, { responseType: 'blob' }).then(
        (response) => {
          const data = response.data
          const url = URL.createObjectURL(data);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = `${requestObject.name}.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      );
    } else {
      return Axios.get('').then((res: any) => {
        return res;
      });
    }
  },
  logout: () => {
    return Axios.get(`${config.appBaseUrl}/invalidate-session`).then(
      (response) => response.data
    );
  },
  getSocialImpactReport: (queryString: any) => {
    return Axios.get(
      `${config.appBaseUrl}/social-impact-reports/dashboard?${queryString}`
    )
      .then((result) => {
        return {
          data: result.data.content,
          page: result.data.number || 0,
          totalCount: result.data.totalElements,
        };
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          data: [],
          page: 0,
          totalCount: 0,
        };
      });
  },
  deleteFile: (fileInfo: any) => {
    return Axios.delete(`${config.appBaseUrl}/files?fileInfo=${fileInfo}`).then(
      (response) => response.data
    );
  },
  getSocialImpactReportById: (data: any) => {
    return Axios.get(
      `${config.appBaseUrl}/social-impact-reports/${data.id}`
    ).then((response) => response.data);
  },
  getDivisions: (data: any) => {
    const url = `${config.appBaseUrl}/donations/divisions/${data.marketCode}`;
    return Axios.get(url).then((response) => response.data);
  },
  getStateList: (data: any) => {
    const url = `${config.appBaseUrl}/public/state?countryCode=${data.countryCode}`;
    return Axios.get(url).then((response) => response.data);
  },
  getCountry: (data: any) => {
    const url = `${config.appBaseUrl}/public/countries/${data.code}`;
    return Axios.get(url).then((response) => response.data);
  },
  getOrgUserList: (queryString: any) => {
    return Axios.get(
      `${config.appBaseUrl}/organization-users-external?${queryString}`
    )
      .then((result) => {
        return {
          data: result.data.content,
          page: result.data.number || 0,
          totalCount: result.data.totalElements,
        };
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          data: [],
          page: 0,
          totalCount: 0,
        };
      });
  },
  addNewOrgUser: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/organization-users-external/add`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          failedOrgUserData: data,
          error: true,
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : '',
          validationError:
            error &&
            error.response &&
            error.response.status &&
            (error.response.status === 400 || error.response.status === 409),
        };
      });
  },
  editOrgUser: (emailId: any, data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/organization-users-external/update?emailId=${emailId}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          failedOrgUserData: data,
          error: true,
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : '',
          validationError:
            error &&
            error.response &&
            error.response.status &&
            (error.response.status === 400 || error.response.status === 409),
        };
      });
  },
  removeOrgUser: (emailId: any, orgId: any) => {
    return Axios.delete(
      `${config.appBaseUrl}/organization-users-external?orgId=${orgId}&emailId=${emailId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          failedOrgUserData: {},
          error: true,
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : '',
          validationError:
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 400,
        };
      });
  },
  setAsPrimaryOrgUser: (emailId: any, orgId: any) => {
    return Axios.put(
      `${config.appBaseUrl}/organization-users-external/set-as-primary?orgId=${orgId}&emailId=${emailId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          failedOrgUserData: {},
          error: true,
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : '',
          validationError:
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 400,
        };
      });
  },
  sendOrgUserTemporaryPassword: (emailId: any, orgId: any) => {
    return Axios.put(
      `${config.appBaseUrl}/organization-users-external/set-temporary-password?orgId=${orgId}&emailId=${emailId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = config.pingfedUrl;
        }
        return {
          failedOrgUserData: {},
          error: true,
          errorMessage:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorMessage
              ? error.response.data.errorMessage
              : error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ? error.response.data.message
              : '',
          validationError:
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 400,
        };
      });
  },

  checkFirstTimeUserLogin: () => {
    return Axios.get(
      `${config.appBaseUrl}/organization-users-external/is-first-time-login`
    ).then((response) => response.data);
  },

  saveOrgUserPassword: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/organization-users-external/set-new-password`,
      { newPassword: data.newPassword }
    ).then((response) => response.data);
  },
  autheticate: (data: any) => {
    return Axios.post(`${config.appBaseUrl}/public/authenticate`, data).then(
      (response) => response.data
    );
  },
  fetchNgoPage: (payload: any) => {
    const URL = `${config.appBaseUrl}/good-onetime?donationId=${payload}`;
    return Axios.get(URL).then((response) => response.data);
  },
  getConfigurationsData: (params: any) => {
      return Axios.get(`${config.appBaseUrl}/app-configs/level?${buildQueryString(params)}`)
      .then(response => response.data.data);
  },
  getAdditionalAttachmentsDetails: async (donationId: string): Promise<IFileDataResponse | null> => {
    const uploadedFiles = new Map();
    try {
      const response = await Axios.get(`${config.appBaseUrl}/donations/additional-attachments/${donationId}`);

      if(response.status !== 200) {
        return {
          lastModifiedOn: null,
          uploadedFiles
        };
      }
      if(response.data) {
        return {
          lastModifiedOn: response.data.lastModifiedOn,
          uploadedFiles: new Map(Object.entries(response.data.uploadedFiles))
        };
      }
      return {
        lastModifiedOn: null,
        uploadedFiles
      };
    } catch {
      return {
        lastModifiedOn: null,
        uploadedFiles
      };
    }
  },
  uploadAdditionalAttachment: async (donationId:string, fileId: string, formData: FormData): Promise<IFileDetails | null> => {
    try {
      const response = await Axios.put(`${config.appBaseUrl}/donations/additional-attachments?donationId=${donationId}&fileCode=${fileId}`
      , formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch {
      return null;
    }
  },
  downloadAdditionalAttachment: async(donationId:string, fileId: string, fileName: string): Promise<boolean> => {
    try {
      const response = await Axios.
        get(`${config.appBaseUrl}/donations/additional-attachments/${donationId}/${fileId}`,{responseType: 'blob'});
      if(response.status !== 200) {
        return false;
      }
      const data = response.data
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');

      link.href = downloadUrl;

      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      link.remove();
      return true;
    } catch {
      return false;
    }
  },
  deleteAdditionalAttachment: async(donationId:string, fileId: string): Promise<Date | null> => {
    try {
      const response = await Axios.delete(`${config.appBaseUrl}/donations/additional-attachments/${donationId}/${fileId}`);
      if(response.status !== 200) {
        return null;
      }
      return response.data;
    } catch {
      return null;
    }
  },
  getOrganizationProfileData:(donationId:string)=>{
    return Axios.get(
        `${config.appBaseUrl}/donations/additional-org-details/${donationId}`
      ).then((response) => response.data);
  },
  getAdditionProjectDetailsMonetaryDonation:(donationId:string)=>{
    return Axios.get(
        `${config.appBaseUrl}/donations/additional-project-details/${donationId}`
      ).then((response) => response.data);
  },
  getDropdownDetails: async (): Promise<Map<string, IMDDropdownOption[] | IMDGroupDropdownOption[]>> => {
    try {
      const response = await Axios.get(`${config.appBaseUrl}/donations/additional-details/drop-downs`);
      if(response.data) {
        return new Map(Object.entries(response.data));
      }
      return new Map();
    } catch {
      return new Map();
    }
  },
  saveOrganizationProfileData: (donationId: string, orgData: FormData) => {
    return Axios.put(
        `${config.appBaseUrl}/donations/additional-org-details?donationId=${donationId}`,
        orgData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
    );
  },
  saveAdditionProjectDetailsMonetaryDonation: (
    donationId: string,
    projectData: any
  ) => {
    return Axios.put(
      `${config.appBaseUrl}/donations/additional-project-details?donationId=${donationId}`,
      projectData
    );
  },
  submitAdditionalDetails:(donationId:string) =>{
     return Axios.put(
      `${config.appBaseUrl}/donations/submit-additional-details/${donationId}`
    );
  },
  deleteDonation: async (donationId:string) => {
    try {
      const response = await Axios.delete(`${config.appBaseUrl}/donations/${donationId}`);
      if(response.status !== 200) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  },
  getMonetarySocialImpactReport: async (projectId: number) => {
    try {
        const response = await Axios.get(`${config.appBaseUrl}/social-impact-reports/monetary/${projectId}`);
        if (response.status === 200) {
            return response.data;
        }
        return null;
    } catch {
        return null;
    }
},
saveMonetarySocialImpactReport: async (data: SocialImpactRequest) => {
    try {
        const response = await Axios.put(`${config.appBaseUrl}/social-impact-reports/monetary`, data);
        if (response.status === 200) {
            return response.data as ISocialImpactResponse;
        }
        return null;
    } catch {
        return null;
    }
},
finalizeMonetarySocialImpactReport: async (data: SocialImpactRequest) => {
  data.finalize = true;
  return await GlobalDonationDataService.saveMonetarySocialImpactReport(data);
},
uploadMonetoryReportAttachment: async(reportId:string, fileId: string,formData: FormData): Promise<IFileDetails | null> => {
  try {
    const response = await Axios.put(`${config.appBaseUrl}/social-impact-reports/monetary/report-attachment/${reportId}/${fileId}`
    , formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch {
    return null;
  }
},
deleteMonetoryReportAttachment: async (reportId: string, fileId: string): Promise<boolean> => {
    try {
        const response = await Axios.delete(`${config.appBaseUrl}/social-impact-reports/monetary/report-attachment/${reportId}/${fileId}`);
        if (response.status !== 200) {
            return false;
        }
        return true;
    } catch {
        return false;
    }
},
deleteActivityAttachment: async (reportId: string, fileId: number): Promise<boolean> => {
    try {
        const response = await Axios.delete(`${config.appBaseUrl}/social-impact-reports/monetary/activity-file/${reportId}/${fileId}`);
        if (response.status !== 200) {
            return false;
        }
        return true;
    } catch {
        return false;
    }
},
uploadActivityAttachment: async (reportId: string, fileId: number, formData: FormData): Promise<IFileDetails | null> => {
    try {
        const response = await Axios.put(`${config.appBaseUrl}/social-impact-reports/monetary/activity-file/${reportId}/${fileId}`
            , formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch {
        return null;
    }
},
submitChinaMonetary: async (donationId:string, file: File, isAppendixC = false): Promise<any> => {
  const formData = new FormData();
  formData.append(isAppendixC ? 'sealedAppendixCDocument' :'sealedAppendixBDocument', file)
  const path = isAppendixC ? 'uploadSignedAppendixC': 'uploadSignedAppendixB';
  try {
    const response = await Axios.post(`${config.appBaseUrl}/donations/${path}?donationId=${donationId}`
        , formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
  } catch {
    return null;
  }
}

}
export type Service = GlobalDonationService;

export default GlobalDonationDataService;
n