import {Grid, Typography} from '@material-ui/core';
import {useEffect,FC} from 'react';
import {useTranslation} from 'react-i18next';

import {sectionClasses} from '../common/styles';
import {InputTextField} from '../../../common/Input/InputTextField';
import {BaseSectionProps} from './orgDetailsDefaults';
import analytics from '../../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../../adobe-analytics/analytics-dataelemets';

export interface OrganizationProfileProps extends BaseSectionProps {
    data: any
}

export const OrganizationProfile: FC<OrganizationProfileProps> = (props: OrganizationProfileProps) => {

    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    useEffect(() => {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.organization.organizationProfilePage);
    }, []);
    return (
        <div className={sectionClasses.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography classes={{ root: sectionClasses.title }}>{getTranslation('aboLegRep')}</Typography>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            required={true}
                            disabled={true}
                            fieldName={`${props.fieldName}[firstName]`}
                            errorKeyName={`${props.fieldName}.firstName`}
                            label={getTranslation('repName')}
                            id={'orgProfileName'}
                            InputProps={{ readOnly: true }}
                            fullWidth={true}
                            defaultValue={props?.data?.name}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
