
import { ArrowBack } from '@material-ui/icons';
import { Box, IconButton } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeading } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { contextPath } from '../../../../constants';


interface OwnProps {
  heading: string,
  url: string
}
const BackWithHeading: FC<OwnProps>  = ({
  heading,
  url
}) => {
  const {t} = useTranslation();
  const navigateTo = useNavigate()

  const onBack = () => {
    navigateTo(`${contextPath}${url}`)
  }

  return <Box display = 'flex'>
  <IconButton
      style = {{
        position: 'relative',
        top: '-5px'
      }}
      onClick = {onBack}>
      <ArrowBack />
  </IconButton>
  <PageHeading heading={t(heading)} />
  </Box>
}

export default BackWithHeading
