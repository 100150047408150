import { NotificationDataActions, NotificationDataActionTypes } from './notificationDataActions';
import { NotificationDataState } from '../../models/globalDonationState';

export const defaulNotificationDataState: NotificationDataState = {
    notificationData: {},
    notificationLoading: false,
    notificationLoadingSuccess: false,
    notificationLoadingFailure: false,
};

export const notificationReducer = (state: NotificationDataState = defaulNotificationDataState, action: NotificationDataActions):
    NotificationDataState => {
    switch (action.type) {
        case NotificationDataActionTypes.NOTIFICATION_DATA_LOAD: return {
            ...state,
            notificationData: {},
            notificationLoading:true,
            notificationLoadingFailure: false,
            notificationLoadingSuccess: false,
        };
        case NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_FAILURE: return {
            ...state,
            notificationData: action.data,
            notificationLoading: false,
            notificationLoadingSuccess: false,
            notificationLoadingFailure: true,
        };
        case NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_SUCCESS: return {
            ...state,
            notificationData: action.data,
            notificationLoading: false,
            notificationLoadingFailure: false,
            notificationLoadingSuccess: true,
        };
        default: return state;
    }
}