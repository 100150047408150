import {IAnalyticsSpacePage} from '../adobe-analytics-space/adobe-analytics-model';
import {IAnalyticsGdmsPage} from './adobe-analytics-gdms-model';
const config = require(`src/config.${process.env.NODE_ENV}.json`);

function loadAdobeScript() {
    const adobeScriptURL = `${config.adobeUrl}` ;

    loadScriptFile(
        adobeScriptURL,
        document.getElementsByTagName('head')[0],
        true,
        true
    );
}
const loadScriptFile = (file: string, appendTo: HTMLHeadElement, async: boolean, defer: boolean) => {
  const script = document.createElement('script');

  script.src = file;

  if (async) {
    script.async = true;
  }

  if (defer) {
    script.defer = true;
  }

  return appendTo?.appendChild(script);
};

const loadContent = (appendTo: any) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  return appendTo?.appendChild(script);
};

const trackPageInfoGdmsExternal = (page : IAnalyticsGdmsPage) => {
  if (window.__GDMS_PORTAL__?.dataLayer && page) {
    window.__GDMS_PORTAL__.dataLayer = Object.assign({}, window.__GDMS_PORTAL__.dataLayer, {
      page: [page][0]
    });
    triggerEvent('pageNavigation');
  }
};

const trackPageInfoSpaceExternal = (page : IAnalyticsSpacePage) => {
  if (window.__GDMS_PORTAL__?.dataLayer && page) {
    window.__GDMS_PORTAL__.dataLayer = Object.assign({}, window.__GDMS_PORTAL__.dataLayer, {
      page: [page][0]
    });
    triggerEvent('pageNavigation');
  }
};

const trackUnVerifiedLogins = (vOrgName:any) => {
  if ((window.__GDMS_PORTAL__?.dataLayer?.orgname && Object.entries(window.__GDMS_PORTAL__.dataLayer.vorgname).length === 0) ||
    (window.__GDMS_PORTAL__?.dataLayer && !window.__GDMS_PORTAL__.dataLayer.orgname)) {
    window.__GDMS_PORTAL__.dataLayer = Object.assign({}, window.__GDMS_PORTAL__.dataLayer, {
      orgname: vOrgName
    });
    triggerEvent(`trackunverifiedLogin`);
  }
};

const trackVerifiedLogins = (vorgName:any) => {
  if ((window.__GDMS_PORTAL__?.dataLayer?.vorgname && Object.entries(window.__GDMS_PORTAL__.dataLayer.vorgname).length === 0) ||
    (window.__GDMS_PORTAL__?.dataLayer && !window.__GDMS_PORTAL__.dataLayer.vorgname)) {
    window.__GDMS_PORTAL__.dataLayer = Object.assign({}, window.__GDMS_PORTAL__.dataLayer, {
      vorgname: vorgName
    });
    triggerEvent(`trackverifiedLogin`);
  }
};

const trackEventInfo = ({type, actionOn, location}: any) => {
  window.__GDMS_PORTAL__.dataLayer = Object.assign({}, window.__GDMS_PORTAL__.dataLayer, {
    [type]: actionOn,
    location: {location}
  });
  triggerEvent(`${type}Event`);
};

// const flushTrackingInfo = (info, flushWith = {}) => {
//   window.__GDMS_PORTAL__.dataLayer[info] = flushWith;
//   triggerEvent(`flush${info}`);
// };

const triggerEvent = (type: any) => {
  window.__GDMS_PORTAL__?.dataLayer?.analyticsEvent?.push({ type });
};

export default {
//   flushTrackingInfo,
  loadContent,
  loadAdobeScript,
  trackEventInfo,
  trackPageInfoSpaceExternal,
  trackPageInfoGdmsExternal,
  trackUnVerifiedLogins,
  trackVerifiedLogins
};
